import React from 'react';
import { Fab, Tooltip } from "@mui/material";
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import { useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { testCreateMindmapAtom } from '@/ViewModels/Plan/Mindmap/TestViewModel';

const CreateMindmapTestElementBar = () => {
  const createTestMindmap = useSetAtom(testCreateMindmapAtom);
  const { t } = useTranslation();

  return (
    <Tooltip title={<div style={{ fontSize: "1rem" }}>{t("plan.contents.mindmap.elementbar.createGroup")}</div>} arrow placement="right">
      <Fab
        sx={{
          bgcolor: "#000",
          '&:hover': {
            bgcolor: "#000" + "CC",
          },
          color: "#fff", // 아이콘 색상
        }}
        onClick={createTestMindmap}
      >
        <WorkspacesIcon />
      </Fab>
    </Tooltip>
  );
};

export default CreateMindmapTestElementBar;
