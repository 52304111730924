import mailAtom, { copyRecipientTextAtom, hiddenRecipientTextAtom, Mail, recipientTextAtom } from "@/Atoms/Mail/Atom";
import useIsMobile from "@/Hooks/useIsMobile";
import theme from "@/Styles/theme";
import { recipientOnKeyDownAtom, removeRecipientAtom } from "@/ViewModels/Mail/ViewModel";
import { Autocomplete, Chip, TextField } from "@mui/material";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { lighten } from "polished";
import React from "react";

interface RecipientInputProps {
  type: string; // "recipient", "copy", "hidden" 타입 구분
}

const isValidEmail = (email: string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const RecipientInput: React.FC<RecipientInputProps> = ({ type }) => {
  const { isMobile } = useIsMobile();
  const mail = useAtomValue(mailAtom);
  const recipients = type === "recipients" ? mail.recipients : type === "copyRecipients" ? mail.copyRecipients : mail.hiddenRecipients;
  const [recipient, setRecipient] = useAtom(type === "recipients" ? recipientTextAtom : type === "copyRecipients" ? copyRecipientTextAtom : hiddenRecipientTextAtom);
  const onKeyDown = useSetAtom(recipientOnKeyDownAtom);
  const removeRecipient = useSetAtom(removeRecipientAtom);

  return (
    <Autocomplete
      multiple
      options={[]}
      freeSolo
      sx={{ display: "flex", flexDirection: "column", alignSelf: "center", width: `calc(100% - ${isMobile ? 90 : 110}px)`, height: "auto", padding: "0px", alignItems: "center", justifyContent: "center" }}
      value={recipients}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            label={option}
            {...getTagProps({ index })}
            style={{
              backgroundColor: isValidEmail(option)
                ? lighten(0.2, theme.colors.primary)
                : lighten(0.2, "red"),
              color: "#fff",
              fontWeight: "bold",
            }}
            onDelete={() => removeRecipient(type as keyof Mail, option)}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          value={recipient}
          onChange={(e) => setRecipient(e.target.value)}
          onKeyDown={(e) => onKeyDown(e, type)}
          variant="standard"
          size="medium"
          style={{ padding: "0px", height: "auto" }}
        />
      )}
    />
  );
};
