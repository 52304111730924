import { DataPoint } from "@/Components/Dashboard/Charts/Scatter";
import { CareerPlanning } from ".";

export const dummyDreamCardData = [
  {
    userId: 1,
    dream: "CEO",
    name: "이하나",
    goal: "세계적인 CEO가 되고 싶어",
    catchphrase: "책임감 있는 사람이 되자",
    email: "hana@naver.com",
    grade: 1,
    profileImage: "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png",
  },
  {
    userId: 2,
    dream: "Doctor",
    name: "김영민",
    goal: "사람들을 치료하는 훌륭한 의사가 되고 싶어",
    catchphrase: "헌신적인 사람이 되자",
    email: "youngmin@naver.com",
    grade: 3,
    profileImage: "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png",
  },
  {
    userId: 3,
    dream: "Actor",
    name: "정우성",
    goal: "영화에서 큰 역할을 맡고 싶어",
    catchphrase: "창의적인 사람이 되자",
    email: "woosung@naver.com",
    grade: 2,
    profileImage: "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png",
  },
  {
    userId: 4,
    dream: "Designer",
    name: "이서연",
    goal: "패션 디자이너로 성공하고 싶어",
    catchphrase: "독창적인 사람이 되자",
    email: "seoyeon@naver.com",
    grade: 1,
    profileImage: "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png",
  },
  {
    userId: 5,
    dream: "Engineer",
    name: "한유정",
    goal: "최고의 엔지니어가 되고 싶어",
    catchphrase: "논리적인 사람이 되자",
    email: "yoojung@naver.com",
    grade: 2,
    profileImage: "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png",
  },
  {
    userId: 6,
    dream: "Lawyer",
    name: "최수현",
    goal: "정의를 지키는 변호사가 되고 싶어",
    catchphrase: "정직한 사람이 되자",
    email: "soohyun@naver.com",
    grade: 3,
    profileImage: "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png",
  },
  {
    userId: 7,
    dream: "Scientist",
    name: "김도현",
    goal: "새로운 발견을 이루고 싶어",
    catchphrase: "탐구적인 사람이 되자",
    email: "dohyun@naver.com",
    grade: 2,
    profileImage: "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png",
  },
  {
    userId: 8,
    dream: "Singer",
    name: "이지은",
    goal: "세계적인 가수가 되고 싶어",
    catchphrase: "열정적인 사람이 되자",
    email: "jieun@naver.com",
    grade: 1,
    profileImage: "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png",
  },
  {
    userId: 9,
    dream: "Athlete",
    name: "박지훈",
    goal: "올림픽에서 메달을 따고 싶어",
    catchphrase: "끈기 있는 사람이 되자",
    email: "jihoon@naver.com",
    grade: 3,
    profileImage: "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png",
  },
  {
    userId: 10,
    dream: "Architect",
    name: "김민수",
    goal: "세계적인 건축물을 디자인하고 싶어",
    catchphrase: "창의적인 사람이 되자",
    email: "minsoo@naver.com",
    grade: 1,
    profileImage: "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png",
  },
];

export const dummyCareerProgressData = [
  { userId: 1, name: "박지훈", size: 8, x: 10, y: 20 },
  { userId: 2, name: "김민수", size: 12, x: 15, y: 30 },
  { userId: 3, name: "이서연", size: 6, x: 5, y: 35 },
  { userId: 4, name: "정우성", size: 10, x: 12, y: 50 },
  { userId: 5, name: "한유정", size: 14, x: 17, y: 55 },
  { userId: 6, name: "김영민", size: 7, x: 12, y: 60 },
  { userId: 7, name: "최수현", size: 9, x: 17, y: 65 },
  { userId: 8, name: "김도현", size: 11, x: 18, y: 70 },
  { userId: 9, name: "이하나", size: 8, x: 5, y: 75 },
  { userId: 10, name: "이지은", size: 12, x: 20, y: 80 },
];

export const dummyCareerPlanningData: CareerPlanning[] = [
  {
    userId: 1,
    crew: "이음고등학교 1학년 4반 숙제 관리 그룹",
    mentee: "이하나",
    content: "AI 학습 프로젝트",
    lastStudyDate: "24.09.01",
    numberOfUltimateSkills: 15,
    numberOfTodos: 20,
    careerAchievement: 85,
  },
  {
    userId: 2,
    crew: "이음고등학교 1학년 4반 숙제 관리 그룹",
    mentee: "김영민",
    content: "개발자로서의 성장",
    lastStudyDate: "24.08.25",
    numberOfUltimateSkills: 12,
    numberOfTodos: 18,
    careerAchievement: 90,
  },
  {
    userId: 3,
    crew: "이음고등학교 1학년 4반 숙제 관리 그룹",
    mentee: "정우성",
    content: "UX/UI 디자인 기초",
    lastStudyDate: "24.08.30",
    numberOfUltimateSkills: 8,
    numberOfTodos: 15,
    careerAchievement: 70,
  },
  {
    userId: 4,
    crew: "이음고등학교 1학년 4반 일정 관리 그룹",
    mentee: "이서연",
    content: "데이터 사이언스 심화",
    lastStudyDate: "24.09.03",
    numberOfUltimateSkills: 20,
    numberOfTodos: 22,
    careerAchievement: 95,
  },
  {
    userId: 5,
    crew: "이음고등학교 1학년 4반 일정 관리 그룹",
    mentee: "한유정",
    content: "비즈니스 영어",
    lastStudyDate: "24.08.28",
    numberOfUltimateSkills: 10,
    numberOfTodos: 16,
    careerAchievement: 88,
  },
  {
    userId: 6,
    crew: "이음고등학교 1학년 4반 일정 관리 그룹",
    mentee: "최수현",
    content: "미래 직업 관련 도서 읽기",
    lastStudyDate: "24.07.06",
    numberOfUltimateSkills: 10,
    numberOfTodos: 11,
    careerAchievement: 73,
  },
  {
    userId: 7,
    crew: "이음고등학교 1학년 4반 학습 관리 그룹",
    mentee: "김도현",
    content: "경영학 기초",
    lastStudyDate: "24.09.02",
    numberOfUltimateSkills: 18,
    numberOfTodos: 21,
    careerAchievement: 82,
  },
  {
    userId: 8,
    crew: "이음고등학교 1학년 4반 학습 관리 그룹",
    mentee: "이지은",
    content: "웹 개발 실습",
    lastStudyDate: "24.08.18",
    numberOfUltimateSkills: 14,
    numberOfTodos: 19,
    careerAchievement: 78,
  },
  {
    userId: 9,
    crew: "이음고등학교 1학년 4반 학습 관리 그룹",
    mentee: "박지훈",
    content: "공학적 문제 해결",
    lastStudyDate: "24.07.30",
    numberOfUltimateSkills: 12,
    numberOfTodos: 10,
    careerAchievement: 75,
  },
  {
    userId: 10,
    crew: "이음고등학교 1학년 4반 학습 관리 그룹",
    mentee: "김민수",
    content: "마케팅 전략 분석",
    lastStudyDate: "24.08.10",
    numberOfUltimateSkills: 11,
    numberOfTodos: 17,
    careerAchievement: 88,
  },
];
