import React from 'react';
import * as S from './styled';
import useIsMobile from '../../../Hooks/useIsMobile';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { Tooltip } from '@mui/material';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { createRoutineDialogOpenAtom } from '@/ViewModels/Dialogs/Create/RoutineViewModel';
import { planHeaderChangeViewTypeAtom } from '@/ViewModels/Plan/HeaderViewModel';
import GetIdFromQuerystring from '@/Utils/GetIdFromQuerystring';
import planAtom from '@/Atoms/Plan';
import { useNavigate } from 'react-router-dom';
import { syncPlanAtom } from '@/ViewModels/Plan/PlanViewModel';
import deleteRoutineDialogAtom from '@/Atoms/Dialogs/Delete/RoutineAtom';
import { isChangedMindmapAtom } from '@/Atoms/Plan/MindmapAtom';
import { updateMindmapAtom } from '@/ViewModels/Plan/Mindmap/ViewModel';
import { languageAtom } from '@/Atoms/RootAtom';
import { useTranslation } from 'react-i18next';


const PlanHeader = () => {
  const { isMobile } = useIsMobile();
  const [planHeader, changeViewType] = useAtom(planHeaderChangeViewTypeAtom);
  const updateMindmap = useSetAtom(updateMindmapAtom);
  const isChangedMindmap = useAtomValue(isChangedMindmapAtom);
  const createRoutineDialogOpen = useSetAtom(createRoutineDialogOpenAtom);
  const planId = GetIdFromQuerystring("id");
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  const plans = useAtomValue(planAtom);
  const plan = plans.find((plan) => plan.id === planId);
  const routines = plan?.innerTabs.slice(1) || [];
  const navigate = useNavigate();
  const syncPlan = useSetAtom(syncPlanAtom);
  const setDeleteRoutine = useSetAtom(deleteRoutineDialogAtom)
  const language = useAtomValue(languageAtom);
  const { t } = useTranslation();

  const defaultTabs = [
    {
      id: "todo",
      name: t("plan.header.todo"),
    },
    {
      id: "routine",
      name: t("plan.header.routine"),
    },
    {
      id: "gantt",
      name: t("plan.header.gantt"),
    },
    {
      id: "mindmap",
      name: t("plan.header.mindmap"),
    }
  ]

  return (
    <S.Container>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", overflowX: "auto", gap: "20px", flex: 1 }}>
        {defaultTabs.map((tab) => (
          <S.TabItem
            key={tab.id}
            $selected={tab.id === planHeader.currentViewType && innerTabId === plan?.innerTabs[0].id}
            $isMobile={isMobile}
            onClick={() => {
              updateMindmap();
              setTimeout(() => {
                navigate(`/plan?id=${planId}&inner_tab_id=${plan?.innerTabs[0].id}`);
                changeViewType(tab.id as "mindmap" | "todo" | "routine" | "gantt")
                syncPlan(tab.id as "mindmap" | "todo" | "routine" | "gantt");
              }, 100);
            }}
            style={{ whiteSpace: "nowrap" }}
          >
            {tab.name}
          </S.TabItem>
        ))}
        {routines.map((routine) => (
          <S.TabItem
            key={routine.id}
            $selected={routine.id === innerTabId}
            $isMobile={isMobile}
            onClick={() => {
              changeViewType(routine.viewType as "mindmap" | "todo" | "routine" | "gantt");
              navigate(`/plan?id=${planId}&inner_tab_id=${routine.id}`);
              syncPlan(routine.viewType as "mindmap" | "todo" | "routine" | "gantt");
            }}
            style={{ whiteSpace: "nowrap" }}
          >
            {routine.name}
          </S.TabItem>
        ))}
        <Tooltip title={t("plan.header.createRoutine")} placement="bottom" arrow>
          <AddIcon onClick={createRoutineDialogOpen} sx={{ color: "#999" }} />
        </Tooltip>
        {
          routines && routines.find((routine) => routine.id === innerTabId) &&
          <Tooltip title={t("plan.header.deleteRoutine")} placement="bottom" arrow >
            <DeleteOutlinedIcon sx={{ color: "#999" }} onClick={() => setDeleteRoutine({ open: true, complete: false })} />
          </Tooltip>
        }
      </div>
      {/* 로딩 중임을 표시하는 컴포넌트 */}
      {
        planHeader.currentViewType === "mindmap" && isChangedMindmap &&
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px" }}>
          {
            !isMobile &&
            <div style={{ fontSize: "0.9rem", color: "red", fontWeight: "bold" }}>{t("plan.header.notSave")}</div>
          }
          <Tooltip title={t("plan.header.save")} placement="bottom" arrow>
            <div style={{ width: "45px", height: "45px", borderRadius: "10px", backgroundColor: "#d9d9d9", display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer", border: isMobile ? "1px solid red" : "none" }} onClick={updateMindmap}>
              <SaveOutlinedIcon sx={{ color: "black" }} />
            </div>
          </Tooltip>
        </div>
      }
    </S.Container >
  );
}

export default PlanHeader;
