import { useQuery } from 'react-query';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { getPlanHipCrews, parseCrewInPlanHip } from '@/Queries/PlanHipQueries';
import { usePlanHipAtom } from '@/Atoms/HookControllerAtom';
import planHipAtom from '@/Atoms/PlanHip/Atom';
import { getUserId } from '@/ViewModels/UserViewModel';
import { handleReactQueryApiResponseWithJson } from '@/Utils/APIUtil';
import { error401ModalAtom } from '@/Atoms/Dialogs/Error/401Atom';
// import { devConsoleLog } from '@/Utils/ConsoleLogInDevelopment';

const usePlanHip = () => {
  const [controller, setController] = useAtom(usePlanHipAtom);
  const setPlanHip = useSetAtom(planHipAtom);
  const userId = useAtomValue(getUserId);
  const setError401Modal = useSetAtom(error401ModalAtom);

  return useQuery(['planHipCrews'], () => handleReactQueryApiResponseWithJson(getPlanHipCrews, () => setError401Modal(true), userId, 0), {
    enabled: controller,
    onSuccess: (data) => {
      if (data === null) return;
      setController(false);
      setPlanHip((prev) => ({
        ...prev,
        myCrews: parseCrewInPlanHip(data.user_active_crews),
        notMyCrews: parseCrewInPlanHip(data.user_inactive_crews),
      }));
    },
  });
};

export default usePlanHip;
