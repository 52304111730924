import { FeedComment, feedDetailDialogAtom } from '@/Atoms/Dialogs/FeedDetail/atom';
import { LocationProps } from '@/Atoms/RootAtom';
import TextMarkdown from '@/Components/Common/Markdown';
import { Avatar, Box, Dialog, DialogContent, DialogTitle, Divider, Typography } from '@mui/material';
import { useAtom, useSetAtom } from 'jotai';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import ScheduleOutlinedIcon from '@mui/icons-material/ScheduleOutlined';
import dayjs from 'dayjs';
import FeedComments from './Comments';
import convertRelativeTime from '@/Utils/ConvertRelativeTime';
import { feedDetailGetUserSignatureAtom } from '@/ViewModels/Dialogs/FeedDetailViewModel';
import FeedDetailUserSignatureDialog from './Dialog/UserSignature';

const FeedDetailDialog = () => {
  const [dialog, setDialog] = useAtom(feedDetailDialogAtom)
  const { t } = useTranslation();
  const getSignature = useSetAtom(feedDetailGetUserSignatureAtom);

  const formatDateToLocalizedString = (date: Date, timeType: string): string => {
    const dateFormat = t('crew.crewTask.date.dateFormat');
    const timeFormat = t('crew.crewTask.date.timeFormat');
    const formattedDate = dayjs(date).format(dateFormat);
    const formattedTime = dayjs(date).format(timeFormat);

    return t('crew.crewTask.date.from', { date: formattedDate, time: formattedTime, timeType: timeType });
  };

  return (
    dialog && dialog.data &&
    <>
      <Dialog
        open={dialog !== null}
        onClose={() => setDialog(null)}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle sx={{ fontSize: "0.9rem", fontWeight: "bold", display: "flex", flexDirection: "row", borderBottom: "1px solid rgba(0,0,0,0.12)", paddingBottom: "6px" }}>
          <div style={{ width: "70%", display: "flex", alignItems: "center" }}>
            {dialog.data.label as string}
          </div>
          <div style={{ width: "calc(30% -20px)", display: "flex", padding: "0 10px" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                gap: "10px",
              }}
            >
              <Avatar src={`${process.env.REACT_APP_USER_API_ADDRESS}${dialog.data.image_url}`} alt={dialog.data.nickname as string} sx={{ cursor: "pointer" }} onClick={() => getSignature(dialog.data.authorId as number)} />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                  flex: 1,
                }}
              >
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "bold", fontSize: "0.9rem", cursor: "pointer" }}
                  onClick={() => getSignature(dialog.data.authorId as number)}
                >
                  {dialog.data.nickname as string}
                </Typography>
                <Typography variant="caption" sx={{ color: "gray" }}>
                  {convertRelativeTime(new Date(dialog.data.createdAt as string))}
                </Typography>
              </Box>
            </Box>
          </div>
        </DialogTitle>
        <DialogContent sx={{ display: "flex", flexDirection: "row", height: "500px" }}>
          <div style={{ width: "70%", height: "100%", overflowY: "auto", overflowX: "hidden" }}>
            <div style={{ display: "flex", flexDirection: "column", gap: "10px", padding: "10px" }}>
              {(dialog.data.startDate as string) && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <CalendarTodayOutlinedIcon sx={{ fontSize: "1rem" }} />
                  <Typography style={{ fontSize: "0.8rem" }}>
                    {formatDateToLocalizedString(new Date(dialog.data.startDate as string), t("crew.crewTask.date.timeType.from"))}
                  </Typography>
                </div>
              )}
              {(dialog.data.endDate as string) && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <CalendarTodayOutlinedIcon sx={{ fontSize: "1rem" }} />
                  <Typography style={{ fontSize: "0.8rem" }}>
                    {formatDateToLocalizedString(new Date(dialog.data.endDate as string), t("crew.crewTask.date.timeType.until"))}
                  </Typography>
                </div>
              )}
              {(dialog.data.location as LocationProps).address && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <LocationOnOutlinedIcon sx={{ fontSize: "1rem" }} />
                  <Typography style={{ fontSize: "0.8rem" }}>
                    {(dialog.data.location as LocationProps).address as string}
                  </Typography>
                </div>
              )}
              {(dialog.data.termType as number) !== null && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <ScheduleOutlinedIcon sx={{ fontSize: "1rem" }} />
                  <Typography style={{ fontSize: "0.8rem" }}>
                    {(dialog.data.termType as number) === 1
                      ? "매주"
                      : (dialog.data.termType as number) === 2
                        ? "매월"
                        : "매일"}
                  </Typography>
                  {(dialog.data.termType as number) === 1 &&
                    (dialog.data.termData as string[]).map((day, index) => (
                      <Typography key={index} style={{ fontSize: "0.8rem" }}>
                        {day}요일
                      </Typography>
                    ))}
                  {(dialog.data.termType as number) === 2 &&
                    (dialog.data.termData as string[]).map((day, index) => (
                      <Typography key={index} style={{ fontSize: "0.8rem" }}>
                        {day}일
                      </Typography>
                    ))}
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                {dialog.data.content === "" ?
                  <Typography sx={{ fontSize: "0.8rem" }}>설명</Typography>
                  :
                  <TextMarkdown text={dialog.data.content as string} />
                }
              </div>
            </div>
          </div>
          <Divider orientation="vertical" flexItem />
          <div style={{ width: "30%", height: "100%", display: "flex", flexDirection: "column", gap: "10px" }}>
            <FeedComments feedId={dialog.data.backendId as number} />
          </div>
        </DialogContent>
      </Dialog>
      <FeedDetailUserSignatureDialog />
    </>
  )
}

export default FeedDetailDialog;
