import dayjs from "dayjs";

// 진행률 계산 함수
const getProgressPercentage = (startDate: string | null, endDate: string | null): number => {
  if (!startDate || !endDate) return 0;
  const totalDuration = dayjs(endDate).valueOf() - dayjs(startDate).valueOf();
  const timePassed = new Date().getTime() - dayjs(startDate).valueOf();
  return Math.min((timePassed / totalDuration) * 100, 100);
};

export default getProgressPercentage;
