import React from "react";
import * as S from "./styled";
import { Task } from "gantt-task-react";
import { useAtomValue, useSetAtom } from "jotai";
import { nodesAtom } from '@/Atoms/Plan/MindmapAtom';
import { Menu, MenuItem, Typography } from "@mui/material";
import { Node } from "@xyflow/react";
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { languageAtom, LocationProps } from "@/Atoms/RootAtom";
import ScheduleOutlinedIcon from '@mui/icons-material/ScheduleOutlined';
import useIsMobile from "@/Hooks/useIsMobile";
import { ganttChartDeleteTaskAtom, ganttChartDoubleClickAtom } from "@/ViewModels/Components/GanttChartViewModel";
import innerTabDataAtom from "@/Atoms/Plan/InnerTabDataAtom";
import GetIdFromQuerystring from "@/Utils/GetIdFromQuerystring";
import dayjs from "dayjs";
import { DateCalendar, DatePicker } from "@mui/x-date-pickers";
import { updateTaskStatusNodeAtom } from "@/ViewModels/Plan/Mindmap/JobNodeViewModel";
import { updateNodeAtom } from "@/ViewModels/Plan/Mindmap/FlowViewModel";
import { updateNodeDateAtom } from "@/ViewModels/Plan/Mindmap/NodeViewModel";
import TextMarkdown from "@/Components/Common/Markdown";
import { useTranslation } from "react-i18next";
import { devConsoleLog } from "@/Utils/ConsoleLogInDevelopment";

const formatDateToKorean2 = (date: Date): string => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 월은 0부터 시작하므로 +1
  const day = date.getDate().toString().padStart(2, '0');
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');

  return `${year}년 ${month}월 ${day}일 ${hours}시 ${minutes}분`;
}

interface TodoTaskProps {
  item: Node;
}

const TodoTask = ({ item }: TodoTaskProps) => {
  const { isMobile } = useIsMobile();

  // Helper function to break title into spans
  const formatTitle = (title: string) => {
    return title.split(/(?<=[?!.])\s+/g).map((part, index) => (
      <span key={index} style={{ display: 'block' }}>
        {part}
      </span>
    ));
  };

  return (
    <>
      <S.StoryItem $isMobile={isMobile}>
        <S.StoryTitleContainer>
          {
            item.data && item.data.label as string !== "" &&
            <S.StoryTitle>
              {formatTitle(item.data.label as string)}
            </S.StoryTitle>
          }
        </S.StoryTitleContainer>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", height: "auto" }} >
          {
            item.data.content === "" ?
              <Typography sx={{ fontSize: "0.8rem" }}>설명</Typography> :
              <TextMarkdown text={item.data.content as string} />
          }
        </div>
        {
          item.data.startDate as string &&
          <div style={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center" }}>
            <CalendarTodayOutlinedIcon sx={{ fontSize: "1rem" }} />
            <Typography style={{ fontSize: "0.8rem" }}>
              {formatDateToKorean2(new Date(item.data.startDate as string))}부터
            </Typography>
          </div>
        }
        {
          item.data.endDate as string &&
          <div style={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center" }}>
            <CalendarTodayOutlinedIcon sx={{ fontSize: "1rem" }} />
            <Typography style={{ fontSize: "0.8rem" }}>
              {formatDateToKorean2(new Date(item.data.endDate as string))}까지
            </Typography>
          </div>
        }
        {
          (item.data.location as LocationProps).address &&
          <div style={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center" }}>
            <LocationOnOutlinedIcon sx={{ fontSize: "1rem" }} />
            <Typography style={{ fontSize: "0.8rem" }}>
              {(item.data.location as LocationProps).address as string}
            </Typography>
          </div>
        }
        {
          (item.data.termType as number) !== null &&
          <div style={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center" }}>
            <ScheduleOutlinedIcon sx={{ fontSize: "1rem" }} />
            <Typography style={{ fontSize: "0.8rem" }}>
              {item.data.termType as number === 1 ? "매주" : item.data.termType as number === 2 ? "매월" : "매일"}
            </Typography>
            {
              item.data.termType as number === 1 &&
              (item.data.termData as string[]).map((day, index) => (
                <Typography key={index} style={{ fontSize: "0.8rem" }}>
                  {day}
                </Typography>
              ))
            }
            {
              item.data.termType as number === 2 &&
              (item.data.termData as string[]).map((day, index) => (
                <Typography key={index} style={{ fontSize: "0.8rem" }}>
                  {day}일
                </Typography>
              ))
            }
          </div>
        }
        {/* <S.StoryBottomContainer>
          <Button variant="text" sx={{ fontSize: "0.8rem", fontWeight: "bold", color: theme.colors.primary }} onClick={() => setTogetherDialog({ node: item })}>
            함께하기
          </Button>
        </S.StoryBottomContainer> */}
      </S.StoryItem>
    </>
  )
}

export default TodoTask;

// TaskListHeader 인터페이스
interface ITaskListHeader {
  headerHeight: number;
  rowWidth: string;
  fontFamily: string;
  fontSize: string;
}

// TaskListHeader 커스터마이징
export const TaskListHeader: React.FC<ITaskListHeader> = ({ headerHeight = 50, rowWidth = 50, fontSize = "1rem" }) => {
  const { t } = useTranslation();

  return (
    <S.GanttTable
    >
      <S.GanttTableHeader
        style={{
          height: headerHeight - 2,
        }}
      >
        <S.GanttTableHeaderItem
          style={{
            minWidth: rowWidth,
          }}
        >
          &nbsp;{t("plan.contents.gantt.name")}&nbsp;
        </S.GanttTableHeaderItem>
        <S.GanttTableHeaderSeparator
          style={{
            height: headerHeight * 0.5,
            marginTop: headerHeight * 0.2,
          }}
        />
        <S.GanttTableHeaderItem
          style={{
            minWidth: "100px",
          }}
        >
          &nbsp;{t("plan.contents.gantt.startDate")}&nbsp;
        </S.GanttTableHeaderItem>
        <S.GanttTableHeaderSeparator
          style={{
            height: headerHeight * 0.5,
            marginTop: headerHeight * 0.25,
          }}
        />
        <S.GanttTableHeaderItem
          style={{
            minWidth: "100px",
          }}
        >
          &nbsp;{t("plan.contents.gantt.endDate")}&nbsp;
        </S.GanttTableHeaderItem>
        <S.GanttTableHeaderSeparator
          style={{
            height: headerHeight * 0.5,
            marginTop: headerHeight * 0.25,
          }}
        />
        <S.GanttTableHeaderItem
          style={{
            minWidth: "50px",
          }}
        >
          &nbsp;{t("plan.contents.gantt.status")}&nbsp;
        </S.GanttTableHeaderItem>
        <S.GanttTableHeaderSeparator
          style={{
            height: headerHeight * 0.5,
            marginTop: headerHeight * 0.25,
          }}
        />
      </S.GanttTableHeader>
    </S.GanttTable>
  );
};

export const CustomListTable: React.FC<{
  rowHeight: number;
  rowWidth: string;
  fontFamily: string;
  fontSize: string;
  locale: string;
  tasks: Task[];
  selectedTaskId: string;
  setSelectedTask: (taskId: string) => void;
  onExpanderClick: (task: Task) => void;
}> = (
  { rowHeight = 50,
    rowWidth = "100px",
    tasks,
    fontFamily,
    fontSize = "0.9rem",
    locale,
    onExpanderClick,
  }
) => {
    const nodes = useAtomValue(nodesAtom);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [stateAnchorEl, setStateAnchorEl] = React.useState<null | HTMLElement>(null);
    const editTask = useSetAtom(ganttChartDoubleClickAtom);
    const deleteTask = useSetAtom(ganttChartDeleteTaskAtom);
    const [selectedTask, setSelectedTask] = React.useState<Task | null>(null);
    const today = dayjs().format("YYYY-MM-DD");
    const updateTaskStatus = useSetAtom(updateTaskStatusNodeAtom);
    const { t: tt } = useTranslation();

    const handleMenuClick = (key: string) => {
      selectedTask && (key === "edit" ? editTask(selectedTask) : deleteTask(selectedTask));
      setSelectedTask(null);
      setAnchorEl(null);
    }

    return (
      <S.TaskListWrapper
        style={{
          fontSize: fontSize,
        }}
      >
        {tasks.map(t => {
          let expanderSymbol = "";
          if (t.hideChildren === false) {
            expanderSymbol = "▼";
          } else if (t.hideChildren === true) {
            expanderSymbol = "▶";
          }
          const IsExpender = expanderSymbol ? S.TaskListExpander : S.TaskListEmptyExpander;
          const node = nodes.find((n) => n.id === t.id);
          if (!node) return null;
          const taskName = t.name ? t.name : node?.data.label as string || '';
          const isExistTodayStatus = (node.data.taskStatus as { taskStatusId: number, taskId: number, status: number, date: string }[]).length !== 0 && (node.data.taskStatus as { taskStatusId: number, taskId: number, status: number, date: string }[]).find((status: any) => status.date === today);
          const todayStatus = isExistTodayStatus ? (node.data.taskStatus as { taskStatusId: number, taskId: number, status: number, date: string }[]).find((status: any) => status.date === today) : null;

          return (
            <S.TaskListTableRow
              style={{ height: rowHeight }}
              key={`${t.id}row`}
            >
              <S.TaskListCell
                style={{
                  minWidth: rowWidth,
                  maxWidth: rowWidth,
                  cursor: "pointer",
                }}
              >
                <S.TaskListNameWrapper>
                  <IsExpender
                    onClick={() => onExpanderClick(t)}
                  >
                    {expanderSymbol}
                  </IsExpender>
                  <div
                    style={{
                      width: "100%",
                      cursor: "pointer",
                      fontSize: "0.8rem",
                      fontWeight: "bold",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    onClick={(e) => {
                      setSelectedTask(t);
                      setAnchorEl(e.currentTarget)
                    }}
                  >{taskName}</div>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                    MenuListProps={{ style: { padding: 0 } }}
                  >
                    <MenuItem onClick={() => handleMenuClick("edit")}>{tt("plan.contents.gantt.edit")}</MenuItem>
                    <MenuItem onClick={() => handleMenuClick("delete")}>{tt("plan.contents.gantt.delete")}</MenuItem>
                  </Menu>
                </S.TaskListNameWrapper>
              </S.TaskListCell>
              <S.TaskListCell
                style={{
                  minWidth: "100px",
                  maxWidth: "100px",
                  textAlign: "center",
                  cursor: "pointer",
                  fontSize: "0.8rem",
                  fontWeight: "bold",
                }}
                onClick={(e) => {
                  setSelectedTask(t);
                  setAnchorEl(e.currentTarget)
                }}
              >
                &nbsp;{node?.data.startDate ? dayjs(node?.data.startDate as string).format("YYYY-MM-DD") : "-"}
              </S.TaskListCell>
              <S.TaskListCell
                style={{
                  minWidth: "100px",
                  maxWidth: "100px",
                  textAlign: "center",
                  cursor: "pointer",
                  fontSize: "0.8rem",
                  fontWeight: "bold",
                }}
                onClick={(e) => {
                  setSelectedTask(t);
                  setAnchorEl(e.currentTarget)
                }}
              >
                &nbsp;{node?.data.endDate ? dayjs(node?.data.endDate as string).format("YYYY-MM-DD") : "-"}
              </S.TaskListCell>
              <S.TaskListCell
                style={{
                  minWidth: "50px",
                  maxWidth: "50px",
                  textAlign: "center",
                  cursor: "pointer",
                  fontSize: "0.8rem",
                  fontWeight: "bold",
                }}
                onClick={(e) => isExistTodayStatus && setStateAnchorEl(e.currentTarget)}
              >
                &nbsp;{isExistTodayStatus ? todayStatus?.status === 0 ? "대기" : "완료" : "-"}
                <Menu
                  anchorEl={stateAnchorEl}
                  open={Boolean(stateAnchorEl)}
                  onClose={() => {
                    setTimeout(() => {
                      setStateAnchorEl(null);
                    }, 0); // 즉시 실행되도록 0ms 지연 처리 - 안하면 메뉴가 닫히지 않음
                  }}
                  MenuListProps={{ style: { padding: 0 } }}
                >
                  <MenuItem onClick={() => {
                    updateTaskStatus(node, todayStatus?.taskStatusId as number, todayStatus?.status === 0 ? 1 : 0);
                    setTimeout(() => {
                      setStateAnchorEl(null);
                    }, 0); // 즉시 실행되도록 0ms 지연 처리 - 안하면 메뉴가 닫히지 않음
                  }}>{todayStatus?.status === 0 ? tt("plan.contents.gantt.done") : tt("plan.contents.gantt.wait")}</MenuItem>
                </Menu>
              </S.TaskListCell>
            </S.TaskListTableRow>
          );
        })}
      </S.TaskListWrapper>
    );
  };


// 간트차트 호버 이벤트 툴팁 컨텐츠
export const StandardTooltipContent: React.FC<{
  task: Task;
  fontSize: string;
  fontFamily: string;
}> = ({ task, fontSize, fontFamily }) => {
  const style = {
    fontSize,
    fontFamily,
  };
  const node = useAtomValue(nodesAtom).find((n) => n.id === task.id);

  return (
    node && <TodoTask item={node} />
  );
};
