import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  display: flex;
  padding: 20px 0px 0px 20px;
  flex-direction: column;
  overflow: auto;
  background-color: #ececec;
`;

export const InnerContainer = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  height: calc(100% - 80px);
  gap: 20px;
  margin-top: 20px;
  overflow-x: auto;
  overflow-y: hidden;

  @media screen and (max-width: 768px) {
    height: calc(100% - 100px);
  }
`;

export const KanbanList = styled.div<{ $backgroundColor: string }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 335px;
  gap: 10px;
  padding: 20px 20px;
  min-height: 100px;
  // max-height: 80%;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  // overflow-y: scroll;
  ms-overflow-style: none;
  scrollbar-width: none;
  border-radius: 20px;
`;

export const KanbanInnerList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  overflow-y: scroll;
  ms-overflow-style: none;
  scrollbar-width: none;
`;

export const AddTaskButton = styled.button`
  display: flex;
  width: 100%;
  min-height: 50px;
  cursor: pointer;
  border: 1px dashed white;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  z-index: 1;
  background: none;
  color: white;
`;

export const TagInputContainer = styled.div`
  display: flex;
  width: calc(100% - 2rem);
  flex-wrap: wrap;
  gap: 0.5em;
  padding: 0.4rem 1rem;
  border: 1px solid #c0c0c0;
  border-radius: 4px;
`;

export const TagItem = styled.div`
  display: inline-block;
  padding: 0.2em 0.5em;
  font-size: 0.8em;
  border-radius: 20px;
  color: white;
`;

export const TagInput = styled.input`
  flex-grow: 1;
  min-width: 30px;
  max-width: 150px;
  padding: 0.5em 0;
  border: none;
  outline: none;
  font-family: "Noto Sans KR", sans-serif;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 22px); // padding 10px * 2 + border 1px * 2
  height: 200px;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  overflow-y: auto;

  &:hover {
    border-color: black;
  }
`;
