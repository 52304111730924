import React, { useEffect } from 'react';
import * as S from './styled';
import {
  Background,
  BackgroundVariant,
  Controls,
  ReactFlow,
  useReactFlow,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { TemplateNode } from './Nodes/Template';
import TaskNode from './Nodes/Task';
import ElementBar from './ElementBar';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { TextField } from '@mui/material';
import Group from './Group';
import { backgroundClickAtom, edgeClickAtom, nodeClickAtom, onConnectAtom, onDragOverAtom, onDropAtom, onEdgesChangeAtom, onNodeDragAtom, onNodeDragStopAtom, onNodesChangeAtom } from '@/ViewModels/Plan/Mindmap/FlowViewModel';
import { updatePlanNameAtom } from '@/ViewModels/Plan/PlanViewModel';
import planAtom, { planFitViewUpdatedAtom } from '@/Atoms/Plan';
import GetIdFromQuerystring from '@/Utils/GetIdFromQuerystring';
import JobNode from './Nodes/Job';
import ImagePrintButton from './ElementBar/downloadButton';
import useIsMobile from '@/Hooks/useIsMobile';
import CustomEdge from './Edges';

const nodeTypes = {
  template: TemplateNode,
  default: TemplateNode,
  dream: TaskNode,
  task: TaskNode,
  goal: TaskNode,
  job: JobNode,
  ultimate: TaskNode,
  curriculum: TaskNode,
  major: TaskNode,
  group: Group,
};

const edgeTypes = {
  custom: CustomEdge,
}

const Mindmap = () => {
  const [nodes, onNodesChange] = useAtom(onNodesChangeAtom);
  const [edges, onEdgesChange] = useAtom(onEdgesChangeAtom);
  const [, onConnect] = useAtom(onConnectAtom);
  const [, onDragOver] = useAtom(onDragOverAtom);
  const [, onDrop] = useAtom(onDropAtom);
  const { fitView, screenToFlowPosition } = useReactFlow();
  const plans = useAtomValue(planAtom);
  const planId = GetIdFromQuerystring("id");
  const plan = plans.find((plan) => plan.id === planId);
  const [editName, setEditName] = React.useState<string | null>(null);
  const updatePlanName = useSetAtom(updatePlanNameAtom);
  const nodeClick = useSetAtom(nodeClickAtom);
  const edgeClick = useSetAtom(edgeClickAtom);
  const backgroundClick = useSetAtom(backgroundClickAtom);
  const [fitViewUpdated, setFitViewUpdated] = useAtom(planFitViewUpdatedAtom);
  const onNodeDrag = useSetAtom(onNodeDragAtom);
  const onNodeDragStop = useSetAtom(onNodeDragStopAtom);
  const { isMobile } = useIsMobile();

  useEffect(() => {
    // Nodes가 렌더링되었을 때만 fitView를 호출
    if (nodes.length > 0 && !fitViewUpdated) {
      fitView({ padding: 0.2 }); // 노드가 존재하면 fitView 호출
      setFitViewUpdated(true); // fitView가 한 번 호출된 후에는 상태를 true로 변경
    }
  }, [nodes, fitViewUpdated, fitView, setFitViewUpdated]);


  return (
    <S.Container>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        onNodeClick={nodeClick}
        onEdgeClick={edgeClick}
        onDrop={(event) => onDrop(event, screenToFlowPosition)}
        onDragOver={onDragOver}
        // onNodeDrag={onNodeDrag}
        // onNodeDragStop={onNodeDragStop}
        onPaneClick={backgroundClick}
        fitView
      >
        <Background
          color="#d8d8d8"
          variant={BackgroundVariant.Lines}
          gap={200}
          style={{ background: "#ececec" }}
        />
        {/* <MiniMap /> */}
        <Controls />
      </ReactFlow>
      {!isMobile && <ElementBar type="delete" />}
      <ElementBar type="speedDial" />
      {/* <ElementBar type="careerPath" /> */}
      <ElementBar type="group" />
      {/* <ElementBar type="test" /> */}
      {
        !isMobile &&
        // 커리어패스가 포함된다면 left: 240px로 수정해야함
        <div style={{ position: "absolute", top: "20px", left: "168px", padding: "0px 15px", height: "56px", backgroundColor: "white", display: "flex", alignItems: "center", border: "1px solid #d9d9d9", borderRadius: "10px", zIndex: 10, gap: "5px", fontWeight: "bold" }}>
          {
            editName !== null ?
              <>
                <TextField value={editName} onChange={(e) => setEditName(e.target.value)} />
                <CheckOutlinedIcon style={{ cursor: "pointer" }} onClick={() => {
                  updatePlanName(editName);
                  setEditName(null);
                }} />
              </> :
              <>
                {plan?.name}
                <EditOutlinedIcon style={{ marginLeft: "10px", cursor: "pointer" }} onClick={() => setEditName(plan?.name || "")} />
              </>
          }
        </div>
      }
      {/* <ImagePrintButton /> */}
    </S.Container>
  )
}

export default Mindmap;
