import { useQuery } from 'react-query';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useMailAtom } from '@/Atoms/HookControllerAtom';
import { getUserEmailAtom, getUserNickname } from '@/ViewModels/UserViewModel';
import mailAtom from '@/Atoms/Mail/Atom';

const useMail = () => {
  const [controller, setController] = useAtom(useMailAtom);
  const userNickname = useAtomValue(getUserNickname);
  const setMail = useSetAtom(mailAtom);
  const userEmail = useAtomValue(getUserEmailAtom);

  return useQuery(['mail'], () => { }, {
    enabled: controller,
    onSuccess: () => {
      setController(false);
      setMail((prev) => ({
        ...prev,
        senderNickname: userNickname,
        sender: userEmail,
      }));
    },
  });
};

export default useMail;
