import { chatRoomListAtom, ChatRoomProps, createChatRoomSectionAtom, currentChatRoomAtom } from '@/Atoms/ChatAtom';
import { dokgabiChatModalAtom } from '@/Atoms/DokgabiChat/Atom';
import { mentorListAtom, MentorProps } from '@/Atoms/MentorAtom';
import snackbarAtom from '@/Atoms/Snackbar';
import { atom } from 'jotai';
import { sendMessageAtom } from '../ChatViewModel';
import { dokgabiChatMoveChatRoomAtom } from './ModalViewModel';

// 챗봇 대화방을 생성하는 함수
export const dokgabiChatCreateChatRoomAtom = atom(null, async (get, set, mentorIdList: number[]) => {
  const mentorList = get(mentorListAtom);
  const chatRoomList = get(chatRoomListAtom);
  const nextChatRoomId = chatRoomList.length;
  const selectedMentorList: MentorProps[] = mentorList.filter((mentor) =>
    mentorIdList.includes(mentor.id)
  );
  const newChatRoom: ChatRoomProps = {
    id: nextChatRoomId,
    title: selectedMentorList.map((mentor) => mentor.name).join(", "),
    chatbots: selectedMentorList.map((mentor) => ({
      id: mentor.id,
      name: mentor.name,
      flow: mentor.chatbot_socket_parameter,
      image: mentor.character_image.file_path,
    })),
    messages: [],
    chatHistoryPageNum: 1,
  };
  set(chatRoomListAtom, [...chatRoomList, newChatRoom]);
  set(currentChatRoomAtom, newChatRoom);
  set(snackbarAtom, { open: true, message: "챗봇 대화방이 생성되었습니다.", severity: "success" });
  setTimeout(() => {
    set(dokgabiChatModalAtom, (prev) => ({ ...prev, mode: "chat" }));
    set(dokgabiChatMoveChatRoomAtom, nextChatRoomId);
  }, 500);
});
