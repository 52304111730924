import { atom } from 'jotai';

const editDefaultRoutineOptionAtom = atom<{
  open: string | null;
  prevTermType: number | null;
  prevTermData: string[] | null;
  termType: number | null;
  termData: string[] | null;
}>({
  open: null,
  prevTermType: null,
  prevTermData: null,
  termType: null,
  termData: null,
});

export default editDefaultRoutineOptionAtom;
