import { TeacherDashboard } from "@/Atoms/Dashboard/TeacherAtom";

interface UserInfoRequest {
  country: string; // 국가
  motto: string; // 좌우명
  dream: string; // 꿈
  goal: string; // 목표
  ultimate: string; // 필살기
  grade: string; // 성적
  job: string; // 선호 직업
  interest: string[]; // 관심사
  preference: string[]; // 취향
  tendency: string[]; // 성향
  career: string[]; // 진로진학 설정
  worry: string[]; // 고민
}

// 진로진학 탐색 결과
interface CareerSearchResponse {
  image: string; // 학교 이미지 URL
  url: string; // 학교 홈페이지
  school: string; // 학교명
  major: string; // 학과명
  region: string; // 지역
  type: string; // 전형
  competition: string; // 경쟁률
  recruit: string; // 모집인원
  grade50: string; // 50% 이내 성적
  grade70: string; // 70% 이내 성적
}

// 진로진학 탐색에 필요한 API
export const getCareerSearch = async (
  access: string,
  info: UserInfoRequest
) => {
  const response = await fetch(
    `${process.env.REACT_APP_DOKGABI_API_ADDRESS}/search/career`,
    {
      method: "POST",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${access}`,
      },
      body: JSON.stringify(info),
    }
  );
  return response;
};

// 추천 직업 탐색 결과
interface JobSearchResponse {
  image: string; // 직업 이미지 URL
  job: string; // 직업명
  major: string[]; // 관련 학과
  qualifications: string[]; // 우대 자격증
  externalEducation: string[]; // 외부 교육 과정
  recommendedEducation: string[]; // 추천 교육 과정
  hopefulness: string; // 직업 전망
}

// 추천 직업 탐색에 필요한 API
export const getJobSearch = async (
  access: string,
  info: UserInfoRequest
) => {
  const response = await fetch(
    `${process.env.REACT_APP_DOKGABI_API_ADDRESS}/search/job`,
    {
      method: "POST",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${access}`,
      },
      body: JSON.stringify(info),
    }
  );
  return response;
};

// 추천 컨텐츠 탐색 결과
interface ContentSearchResponse {
  image: string; // 컨텐츠 이미지 URL
  link: string; // 컨텐츠 링크
  keywords: string[]; // 컨텐츠 키워드
  name: string; // 컨텐츠명
  author: string; // 저자
}

// 추천 컨텐츠 탐색에 필요한 API
export const getContentSearch = async (
  access: string,
  info: UserInfoRequest
) => {
  const response = await fetch(
    `${process.env.REACT_APP_DOKGABI_API_ADDRESS}/search/content`,
    {
      method: "POST",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${access}`,
      },
      body: JSON.stringify(info),
    }
  );
  return response;
};

// search/money-curve
interface MoneyCurveResponse {
  year: number;
  money: number;
}

interface GetMyLevelResponse {
  usingOurService: MoneyCurveResponse[];
  notUsing: MoneyCurveResponse[];
}

// 현재 나의 수준은? 에 필요한 API
export const getMyLevel = async (access: string, info: UserInfoRequest) => {
  const response = await fetch(`${process.env.REACT_APP_DOKGABI_API_ADDRESS}/search/money-curve`, {
    method: "POST",
    headers: {
      "accept": "application/json",
      "Content-Type": "application/json",
      "Authorization": `Bearer ${access}`,
    },
    body: JSON.stringify(info),
  });
  return response;
}

// get teacher dashboard data
export const getTeacherDashboardData = async (access: string, userId: number) => {
  const response = await fetch(`${process.env.REACT_APP_DEV_BACK_DASHBOARD_ADDRESS}/teacher/${userId}`, {
    method: "GET",
    headers: {
      "accept": "application/json",
      "Authorization": `Bearer ${access}`,
    },
  });
  return response;
}
