import dailyPlanDialogAtom from '@/Atoms/Dialogs/DailyPlan';
import { atom } from 'jotai';
import { NavigateFunction } from 'react-router-dom';
import { planHeaderChangeViewTypeAtom } from '../Plan/HeaderViewModel';
import { syncPlanAtom } from '../Plan/PlanViewModel';

export const dailyPlanDialogMoveAtom = atom(null, async (get, set, navigate: NavigateFunction) => {
  const dialog = get(dailyPlanDialogAtom);
  if (!dialog) return;
  navigate(`/plan?id=${dialog.data.planId as string}&inner_tab_id=${dialog.data.innerTabId as string}`);
  setTimeout(() => {
    set(planHeaderChangeViewTypeAtom, dialog.data.innerTabViewType as ("mindmap" | "todo" | "routine" | "gantt") || "mindmap");
    set(syncPlanAtom, dialog.data.innerTabViewType as ("mindmap" | "todo" | "routine" | "gantt") || "mindmap");
    set(dailyPlanDialogAtom, null);
  }, 500);
})
