import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import { Provider as JotaiProvider } from "jotai";
import router from "./Router";
import theme from "./Styles/theme";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { ReactFlowProvider } from "@xyflow/react";
import { QueryClient, QueryClientProvider } from "react-query";
import i18n from "./Locales/i18n";
import { I18nextProvider } from "react-i18next";

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'NanumSquareNeoBrg';
    src: url('./NanumSquareNeo-bRg.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  body, html, #root {
    margin: 0;
    padding: 0;
    font-family: 'NanumSquareNeoBrg', sans-serif;
    line-height: 1.7;
    overflow: hidden;
  }

  textarea , input , button, div, p, span, h1,h2,h3,h4,h5,h6, select,option,td,th {
    font-family: 'NanumSquareNeoBrg', sans-serif !important;
    line-height: 1.7;
  }

  .canvas {
    height: 100vh;
    left: 0px;
    pointer-events: none;
    position: fixed;
    top: 0px;
    width: 100vw;
  }

  &::-webkit-scrollbar {
    width: 8px; /* 스크롤바의 너비 */
    height: 8px; /* 스크롤바의 높이 */
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) =>
    theme.colors.darkBackground}; /* 스크롤바의 색상 */
    border-radius: 10px; /* 스크롤바의 모서리 둥글게 */
  }

  &::-webkit-scrollbar-track {
    background-color: ${({ theme }) =>
    theme.colors.backgroundColor}; /* 스크롤바 트랙의 색상 */
  }

  :root {
  --fc-button-active-bg-color: transparent;
  --fc-event-bg-color: ${theme.colors.primary};
  --fc-event-border-color: ${theme.colors.primary};
}
`;

// DOMContentLoaded 이벤트를 사용하여 DOM이 완전히 로드된 후에 React 애플리케이션을 마운트

document.addEventListener("DOMContentLoaded", () => {
  const rootElement = document.getElementById("root") as HTMLElement;
  if (rootElement) {
    const root = ReactDOM.createRoot(rootElement);
    const queryClient = new QueryClient();

    root.render(
      <I18nextProvider i18n={i18n}>
        <QueryClientProvider client={queryClient}>
          <JotaiProvider>
            {/* 중복 초기화 방지 */}
            <ThemeProvider theme={theme}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <ReactFlowProvider>
                  <DemoContainer components={["DatePicker"]}>
                    <GlobalStyle />
                    <RouterProvider router={router} />
                  </DemoContainer>
                </ReactFlowProvider>
              </LocalizationProvider>
            </ThemeProvider>
          </JotaiProvider>
        </QueryClientProvider>
      </I18nextProvider>
    );
  } else {
    console.error("Root element not found");
  }
});
