import crewAtom from '@/Atoms/CrewAtom';
import { edgesAtom, nodesAtom } from '@/Atoms/Plan/MindmapAtom';
import planHipAtom from '@/Atoms/PlanHip/Atom';
import { getCrew, parseCrew } from '@/Queries/CrewQueries';
import { getPlanHipCrews } from '@/Queries/PlanHipQueries';
import { atom } from 'jotai';
import { NavigateFunction } from 'react-router-dom';
import { getUserId } from '../UserViewModel';
import { waitingModalAtom } from '@/Atoms/RootAtom';
import { useCrewLectureAtom } from '@/Atoms/HookControllerAtom';
import { handleReactQueryApiResponse } from '@/Utils/APIUtil';
import { error401ModalAtom } from '@/Atoms/Dialogs/Error/401Atom';

// Get Crews
export const getCrewsAtom = atom(null, async (get, set, criteria: number) => {
  const userId = get(getUserId);
  const handleError401Modal = () => set(error401ModalAtom, true);
  const response = await handleReactQueryApiResponse(getPlanHipCrews, handleError401Modal, userId, criteria);
  const jsonedData = await response.json();
  const myCrews = jsonedData.user_active_crews;
  const notMyCrews = jsonedData.user_inactive_crews;
  set(planHipAtom, (prev) => ({
    ...prev,
    myCrews: myCrews,
    notMyCrews: notMyCrews,
  }))
});

// Move to Crew
export const moveToCrewAtom = atom(null, async (get, set, crewId: number, navigate: NavigateFunction) => {
  set(waitingModalAtom, (prev) => ({ ...prev, open: true }));
  set(nodesAtom, []);
  set(edgesAtom, []);
  const handleError401Modal = () => set(error401ModalAtom, true);
  const data = await handleReactQueryApiResponse(getCrew, handleError401Modal, crewId);
  const jsonedData = await data.json();
  const crewData = parseCrew(jsonedData);
  const nodes = jsonedData.nodes_and_edges.nodes;
  const edges = jsonedData.nodes_and_edges.edges;
  set(crewAtom, crewData);
  set(nodesAtom, nodes);
  set(edgesAtom, edges);
  set(useCrewLectureAtom, true);
  navigate(`/crew?crew_id=${crewId}&inner_tab_id=${crewData.crewInfo.innerTabId}`);
  set(waitingModalAtom, (prev) => ({ ...prev, open: false }));
});
