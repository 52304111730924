import cancelLectureDialogAtom from '@/Atoms/Dialogs/Cancel/Lecture';
import CustomBackgroundColorButton from '@/Components/Common/Buttons/CustomBackgroundColor';
import CustomColorButton from '@/Components/Common/Buttons/CustomColor';
import { cancelLectureDialogCancelDialogAtom } from '@/ViewModels/Dialogs/CancelLectureViewModel';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useAtom, useSetAtom } from 'jotai';
import React from 'react';

const CancelLectureDialog = () => {
  const [dialog, setDialog] = useAtom(cancelLectureDialogAtom);
  const cancel = useSetAtom(cancelLectureDialogCancelDialogAtom)

  return (
    dialog &&
    <Dialog
      open={dialog !== null}
      onClose={() => setDialog(null)}
    >
      <DialogTitle sx={{ fontSize: "0.9rem", fontWeight: "bold" }}>강의 취소</DialogTitle>
      <DialogContent sx={{ fontSize: "0.8rem" }}>
        <div>강의를 취소하시겠습니까?</div>
        <div>취소 후에는 다시 신청할 수 있습니다.</div>
      </DialogContent>
      <DialogActions>
        <CustomColorButton onClick={() => setDialog(null)} text="취소" color="gray" fontSize="0.8rem" />
        <CustomBackgroundColorButton onClick={cancel} text="확인" fontSize="0.8rem" />
      </DialogActions>
    </Dialog>
  )
}

export default CancelLectureDialog;
