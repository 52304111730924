import { atom } from 'jotai';

type SettingsTab = "profile" | "setting" | "account" | "group";

export const settingsTabAtom = atom<SettingsTab>("profile");
export const settingsSidebarOpenAtom = atom<boolean>(false);

interface EditProfileDialog {
  userId: number;
  key: string;
  value: string;
}

export const settingsEditProfileDialogAtom = atom<EditProfileDialog | null>(null);

interface ResetPasswordModalProps {
  currentPassword: string;
  isCorrectPassword: boolean;
  newPassword: string;
  newPasswordConfirm: string;
}

export const initialResetPasswordModalInfo: ResetPasswordModalProps = {
  currentPassword: "",
  isCorrectPassword: false,
  newPassword: "",
  newPasswordConfirm: "",
};

export const settingsResetPasswordModalAtom = atom<ResetPasswordModalProps | null>(null);
