import styled from 'styled-components';

export const SignatureContainer = styled.div<{ $borderColor: string | undefined, $backgroundColor: string | undefined, $fontColor: string | undefined, $isHalf: boolean }>`
  // width: 600px;
  // height: ${600 * 5 / 9}px;
  width: 100%;
  max-width: ${({ $isHalf }) => $isHalf ? 300 : 600}px;
  height: auto;
  aspect-ratio: 9 / 5;
  max-height: ${({ $isHalf }) => ($isHalf ? 300 : 600) * 5 / 9}px;
  border: ${({ $borderColor }) => $borderColor ? `3px solid ${$borderColor}` : "1px solid lightgray"};
  border-radius: ${({ $isHalf }) => $isHalf ? "1px" : "2px"};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ $isHalf }) => $isHalf ? "10px" : "20px"};
  padding: ${({ $isHalf }) => $isHalf ? "10px" : "20px"};
  position: relative;
  background-color: ${({ $backgroundColor }) => $backgroundColor || "white"};
  color: ${({ $fontColor }) => $fontColor || "black"};

  @media screen and (max-width: 768px) {
    width: 300px;
    max-width: 90%;
    height: ${300 * 5 / 9}px;
    gap: 10px;
    padding: 10px;
  }

`;

export const LoopIconStyle = (isHalf: boolean) => {
  return {
    position: "absolute",
    top: isHalf ? "5px" : "10px",
    left: isHalf ? "5px" : "10px",
    fontSize: isHalf ? "12px" : "24px",
    cursor: "pointer",

    "@media screen and (max-width: 768px)": {
      top: "5px",
      right: "5px",
      fontSize: "12px",
    }
  }
};

export const MenuIconStyle = (isHalf: boolean) => {
  return {
    position: "absolute",
    top: isHalf ? "5px" : "10px",
    right: isHalf ? "5px" : "10px",
    fontSize: isHalf ? "12px" : "24px",
    cursor: "pointer",

    "@media screen and (max-width: 768px)": {
      top: "5px",
      right: "5px",
      fontSize: "12px",
    }
  }
};

export const SignatureProfileContainer = styled.div<{ $isHalf: boolean }>`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: ${({ $isHalf }) => $isHalf ? "calc(50% - 10px)" : "calc(50% - 20px)"};
  justify-content: flex-start;
`;

export const SignatureImage = styled.img<{ $isHalf: boolean }>`
  width: ${({ $isHalf }) => $isHalf ? "75px" : "150px"};
  height: ${({ $isHalf }) => $isHalf ? "75px" : "150px"};
  border-radius: 50%;
  padding: 0;
  margin: 0;
  object-fit: cover;

  @media screen and (max-width: 768px) {
    width: 75px;
    height: 75px;
  }
`;

export const SignatureProfileInfo = styled.div<{ $isHalf: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  padding: ${({ $isHalf }) => $isHalf ? "10px" : "20px"};
  position: relative;

  @media screen and (max-width: 768px) {
    padding: 10px;
  }
`;

export const SignatureGrid = styled.div<{ $height: string }>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 1fr;
  width: 100%;
  height: ${({ $height }) => $height};
  justify-content: flex-start;
`;

export const GridItem = styled.div<{ $isHalf: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${({ $isHalf }) => $isHalf ? "0px 10px" : "0px 20px"};
  flex: 1;
`;

export const ItemTitle = styled.div<{ $isHalf: boolean }>`
  font-size: ${({ $isHalf }) => $isHalf ? "0.5rem" : "1rem"};
  font-weight: bold;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  @media screen and (max-width: 768px) {
    font-size: 0.5rem;
  }
`;

export const ItemValue = styled.div<{ $isHalf: boolean }>`
  font-size: ${({ $isHalf }) => $isHalf ? "0.5rem" : "1rem"};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  @media screen and (max-width: 768px) {
    font-size: 0.5rem;
  }
`;
