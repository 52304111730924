// send mail
export const sendMail = async (access: string, request: any) => {
  const response = await fetch(`${process.env.REACT_APP_USER_API_ADDRESS}/mysql/sendEmailFromTeacher`, {
    method: "POST",
    headers: {
      "accept": "application/json",
      "Content-Type": "application/json",
      "Authorization": `Bearer ${access}`,
    },
    body: JSON.stringify(request),
  })
  return response;
}
