import { nodesAtom } from '@/Atoms/Plan/MindmapAtom';
import { Node } from "@xyflow/react";
import { atom } from "jotai";
import { v4 as uuidv4 } from "uuid";
import dayjs, { Dayjs } from "dayjs";
import weekOfYear from "dayjs/plugin/weekOfYear";
import isoWeek from "dayjs/plugin/isoWeek";
import dateSelectorAtom from "@/Atoms/Components/DateSelectorAtom";
import innerTabDataAtom from "@/Atoms/Plan/InnerTabDataAtom";
import GetIdFromQuerystring from "@/Utils/GetIdFromQuerystring";
import routineAtom, { expandedRoutineDataAtom, RoutineData } from "@/Atoms/Plan/RoutineAtom";

dayjs.extend(weekOfYear);
dayjs.extend(isoWeek);

// startDate, endDate 사이의 날짜를 기준으로 data를 생성
const setRoutineData = (startDate: Dayjs, endDate: Dayjs, tasks: Node[]): { routineData: RoutineData[], unclassifiedTasks: Node[] } => {
  const routineData: RoutineData[] = [];
  let currentDate = startDate;

  // 분류된 태스크의 id Set
  const classifiedTaskIds = new Set();

  // termType에 따라 태스크를 미리 분류
  const dailyTasks = tasks.filter((task) => task.data?.termType === 0);
  const weeklyTasks = tasks.filter((task) => task.data?.termType === 1);
  const monthlyTasks = tasks.filter((task) => task.data?.termType === 2);

  while (currentDate.isSameOrBefore(endDate, 'day')) {
    const currentLoopDate = currentDate; // currentDate를 안전하게 복사하여 사용
    const tasksForCurrentDate = [...dailyTasks.filter((task) => {
      const taskStartDate = dayjs(task.data?.startDate as string);
      const taskEndDate = dayjs(task.data?.endDate as string);
      return currentLoopDate.isSameOrAfter(taskStartDate, 'day') && currentLoopDate.isSameOrBefore(taskEndDate, 'day');
    })];

    // 매주 루틴 (termData에 요일이 포함된 경우만 추가)
    weeklyTasks.forEach((task) => {
      const taskStartDate = dayjs(task.data?.startDate as string);
      const taskEndDate = dayjs(task.data?.endDate as string);

      // 요일 계산 (0 = 일요일, 6 = 토요일)
      const dayOfWeek = ["일", "월", "화", "수", "목", "금", "토"][currentLoopDate.day()];

      if (
        currentLoopDate.isSameOrAfter(taskStartDate, 'day') &&
        currentLoopDate.isSameOrBefore(taskEndDate, 'day') &&
        (task.data?.termData as string[]).includes(dayOfWeek)
      ) {
        tasksForCurrentDate.push(task);
      }
    });

    // 매월 루틴 (termData에 날짜가 포함된 경우만 추가)
    monthlyTasks.forEach((task) => {
      const taskStartDate = dayjs(task.data?.startDate as string);
      const taskEndDate = dayjs(task.data?.endDate as string);

      // 현재 날짜의 일(day)을 문자열로 변환
      const dayOfMonth = currentLoopDate.date().toString();

      if (
        currentLoopDate.isSameOrAfter(taskStartDate, 'day') &&
        currentLoopDate.isSameOrBefore(taskEndDate, 'day') &&
        (task.data?.termData as string[]).includes(dayOfMonth)
      ) {
        tasksForCurrentDate.push(task);
      }
    });

    // 최종 결과에 추가
    routineData.push({
      id: uuidv4(),
      date: currentLoopDate,
      tasks: tasksForCurrentDate,
    });

    new Set(tasksForCurrentDate.map((task) => task.id)).forEach(id => classifiedTaskIds.add(id));

    currentDate = currentDate.add(1, 'day'); // 날짜를 하루씩 증가
  }

  // 미분류된 태스크 추가
  const unclassifiedTasks = tasks.filter((task) => !classifiedTaskIds.has(task.id));

  return { routineData, unclassifiedTasks };
};

// RoutineAtom 초기값 설정
export const routineInitAtom = atom((get) => get(routineAtom), (get, set) => {
  if (get(routineAtom).isInit) return;
  const innerTabs = get(innerTabDataAtom);
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  const innerTabData = innerTabs.find((data) => data.id === innerTabId);
  if (!innerTabData) return;
  const { startDate, endDate } = get(dateSelectorAtom);
  const tasks = innerTabData.nodes.filter((node) => node.id.includes("task"));
  const { routineData, unclassifiedTasks } = setRoutineData(startDate, endDate, tasks);
  set(routineAtom, (prev) => ({
    ...prev,
    isInit: true,
    startDate,
    endDate,
    data: routineData,
    unclassifiedTasks,
  }));
});

// nodes를 통해 routine 설정
export const routineSetDataAtom = atom(null, (get, set, nodes: Node[]) => {
  set(routineAtom, (prev) => ({
    ...prev,
    data: [],
    unclassifiedTasks: [],
  }));
  const tasks = nodes.filter((node) => node.id.includes("task"));
  const { currentDate, weekOrMonth } = get(dateSelectorAtom);
  const currentStartDate = currentDate.startOf(weekOrMonth === "week" ? "isoWeek" : "month");
  const currentEndDate = currentDate.endOf(weekOrMonth === "week" ? "isoWeek" : "month");
  const { routineData, unclassifiedTasks } = setRoutineData(currentStartDate, currentEndDate, tasks);
  set(routineAtom, {
    ...get(routineAtom),
    startDate: currentStartDate,
    endDate: currentEndDate,
    data: routineData,
    unclassifiedTasks,
  });
});

// RoutineAtom 업데이트
export const routineUpdateAtom = atom(
  null,
  (get, set) => {
    const { startDate, endDate } = get(routineAtom);
    const { currentDate, weekOrMonth } = get(dateSelectorAtom);
    const tasks = get(nodesAtom).filter((node) => node.id.includes("task"));

    const newStartDate = weekOrMonth === "week" ? currentDate.startOf("isoWeek") : currentDate.startOf("month");
    const newEndDate = weekOrMonth === "week" ? currentDate.endOf("isoWeek") : currentDate.endOf("month");

    // 날짜 범위가 변경되지 않았다면 업데이트를 생략
    if (startDate.isSame(newStartDate, 'day') && endDate.isSame(newEndDate, 'day')) {
      return;
    }

    const { routineData, unclassifiedTasks } = setRoutineData(newStartDate, newEndDate, tasks);

    set(routineAtom, {
      ...get(routineAtom),
      startDate: newStartDate,
      endDate: newEndDate,
      data: routineData,
      unclassifiedTasks,
    });
  }
);

// 노드의 값이 변경되었을 경우 data를 동기화하는 로직
export const routineSyncAtom = atom(null, (get, set) => {
  const { startDate, endDate } = get(routineAtom);
  const tasks = get(nodesAtom).filter((node) => node.id.includes("task"));

  const { routineData, unclassifiedTasks } = setRoutineData(startDate, endDate, tasks);
  set(routineAtom, {
    ...get(routineAtom),
    data: routineData,
    unclassifiedTasks,
  });
})

// Routine 확장 관리
export const handleExpandedRoutineDataAtom = atom((get) => get(expandedRoutineDataAtom), (get, set, id: string) => {
  set(expandedRoutineDataAtom, (prev) => {
    if (prev.includes(id)) {
      return prev.filter((data) => data !== id);
    } else {
      return [...prev, id];
    }
  });
})
