import { MentorProps } from "@/Atoms/MentorAtom";
import { devConsoleError, devConsoleLog } from "@/Utils/ConsoleLogInDevelopment";
import { getAccessToken } from "@/Utils/CookieUtils";

export async function requestChatbot(
  access: string,
  input_message: string,
  user_id: string,
  flow_socket_name: string,
  character: string,
  handleStreamMessage: (
    message: string,
    isFirst: boolean,
    isNotStream: boolean,
    flow_socket_name: string
  ) => void,
  handleJson: (data: any) => void
): Promise<any> {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${access}`,
    },
    body: JSON.stringify({
      flow: flow_socket_name,
      message: input_message,
      user_id: user_id,
      character_name: character,
    }),
  };

  try {
    const response = await fetch(
      `${process.env.REACT_APP_DOKGABI_API_ADDRESS}/makeChatbotResponseWithCharacter`,
      requestOptions
    );

    if (response.status === 403) {
      return response;
    }

    const reader = response.body?.getReader();
    const decoder = new TextDecoder();
    let isFirst = true;
    let message = "";

    while (true) {
      const { done, value } = await reader!.read();

      if (done) {
        // 여기에 음성을 출력하는 기능 추가
        break;
      }

      const chunk = decoder.decode(value, { stream: true });

      if (chunk.startsWith("JSON:")) {
        // JSON 문자열 파싱 시도
        const json = JSON.parse(chunk.slice(5));
        handleJson(json);
      } else {
        message += chunk;
        handleStreamMessage(message, isFirst, false, flow_socket_name);

        isFirst = false;
      }
    }
  } catch (error) { }
}

export const getMentorList = async (accessToken: string): Promise<MentorProps[]> => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  const response = await fetch(
    `${process.env.REACT_APP_DOKGABI_API_ADDRESS}/getMentorFlow`,
    requestOptions
  );
  const data = await response.json();
  const mentorList: MentorProps[] = data.results.map(
    (data: any) => ({
      id: data.id,
      character_image: data.character_image,
      name: data.name,
      chatbot_socket_parameter: data.chatbot_socket_parameter,
      maker: data.maker,
    })
  );
  return mentorList;
}

export async function getJobInfo(accessToken: string, job: string): Promise<any | null> {
  const response = await fetch(
    'https://dashboard.careerplanner.dokgabi.ai/yback_plan/get-job-source',
    {
      method: "POST",
      headers: {
        'accept': "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        dream: "dream",
        goal: "goal",
        job: job,
      }),
    }
  );
  return response;
}

export async function requestJobWithChatbot(
  access: string,
  input_message: string,
  flow_socket_name: string,
  user_id: string,
): Promise<{
  usingDocs: string[];
  topic: string | null;
} | null> {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${access}`,
    },
    body: JSON.stringify({
      flow: flow_socket_name,
      message: input_message,
      user_id: user_id,
    }),
  };
  let parsedData;
  try {
    const response = await fetch(
      `${process.env.REACT_APP_DOKGABI_API_ADDRESS}/makeChatbotResposeWithDocs`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error("응답을 불러오지 못했습니다.");
    }
    const reader = response.body?.getReader();
    const decoder = new TextDecoder();

    while (true) {
      const { done, value } = await reader!.read();

      if (done) {
        break;
      }

      const chunk = decoder.decode(value, { stream: true });
      const lines = chunk.split("$$$DOK##G!A$$B!I##END$$$").filter(Boolean);

      for (const line of lines) {
        try {
          parsedData = JSON.parse(line);
        } catch (error) {
          devConsoleError("JSON 파싱 오류 발생한 데이터:", line);
          devConsoleError("JSON 파싱 오류:", error);
        }
      }
    }
  } catch (error) {
    devConsoleError("스트리밍 응답 처리 중 오류 발생:", error);
  } finally {
    if (parsedData.using_docs && parsedData.using_docs.length > 0) {
      return { usingDocs: parsedData.using_docs, topic: parsedData.topic };
    }
  }
  return null;
}
