import React, { useEffect, useRef, useState } from 'react';
import * as S from './styled';
import { Box, Button, Input, LinearProgress, Stack, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { Handle, HandleType, NodeToolbar, Position, useUpdateNodeInternals } from "@xyflow/react";
import { DatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import theme from '../../../../../../Styles/theme';
import { togetherDialogAtom } from '@/Atoms/Dialogs/Together';
import { editRoutineIntervalsDialogAtom } from '@/Atoms/Dialogs/Edit/RoutineOption';
import getRemainingDays from '@/Utils/GetRemainingDays';
import getProgressPercentage from '@/Utils/GetProgressPercentage';
import { careerPathAtom, selectedNodeAtom } from '@/Atoms/Plan/MindmapAtom';
import { detachNodeInGroupAtom } from '@/ViewModels/Plan/Mindmap/GroupViewModel';
import { handleClickDeleteNodeAtom, updateNodeAtom } from '@/ViewModels/Plan/Mindmap/FlowViewModel';
import inactivateDialogAtom from '@/Atoms/Dialogs/Inactivate';
import CustomColorButton from '@/Components/Common/Buttons/CustomColor';
import CustomBackgroundColorButton from '@/Components/Common/Buttons/CustomBackgroundColor';
import getRandomColorWithText from '@/Utils/GetRandomColor';
import { addHashtagNodeAtom, removeHashtagNodeAtom, updateTaskStatusNodeAtom } from '@/ViewModels/Plan/Mindmap/JobNodeViewModel';
import TextMarkdown from '@/Components/Common/Markdown';
import { languageAtom } from '@/Atoms/RootAtom';
import { textEditorOpenAtom } from '@/ViewModels/Dialogs/TextEditorViewModel';
import useIsMobile from '@/Hooks/useIsMobile';

const TaskNode = (data: any) => {
  const { isMobile } = useIsMobile();
  const [maxHeight, setMaxHeight] = useState('200px'); // 기본 축소 상태
  const contentRef = useRef<HTMLDivElement>(null); // 확장된 높이를 참조할 수 있는 useRef
  const updateNodeInternals = useUpdateNodeInternals();
  const detachNodeInGroup = useSetAtom(detachNodeInGroupAtom);
  const [, updateNode] = useAtom(updateNodeAtom);
  const language = useAtomValue(languageAtom);
  const selectedNode = useAtomValue(selectedNodeAtom);
  const careerPath = useAtomValue(careerPathAtom);
  const type = data.id.split('-')[0]
  const setEditRoutineDialog = useSetAtom(editRoutineIntervalsDialogAtom);
  const setTogetherDialog = useSetAtom(togetherDialogAtom);
  const setInactivateDialog = useSetAtom(inactivateDialogAtom);
  const openTextEditor = useSetAtom(textEditorOpenAtom);
  const [inputValue, setInputValue] = useState('');
  const addHashtag = useSetAtom(addHashtagNodeAtom);
  const removeHashtag = useSetAtom(removeHashtagNodeAtom);
  const updateStatus = useSetAtom(updateTaskStatusNodeAtom);
  const today = dayjs().format('YYYY-MM-DD');
  const isExistTodayStatus = data.data.taskStatus.length !== 0 && data.data.taskStatus.find((status: any) => status.date === today);
  const todayStatus = isExistTodayStatus ? data.data.taskStatus.find((status: any) => status.date === today) : null;
  const handleDeleteNode = useSetAtom(handleClickDeleteNodeAtom);

  const handlePositions = [
    { id: "left", position: Position.Left, additionalStyle: { top: "50%", transform: "translateY(-50%)", width: isMobile ? "25px" : "10px", height: isMobile ? "25px" : "10px" } },
    { id: "left-top", position: Position.Left, additionalStyle: { left: "0", top: "0", transform: "translate(0%, 0%)", width: isMobile ? "25px" : "10px", height: isMobile ? "25px" : "10px" } },
    { id: "left-bottom", position: Position.Left, additionalStyle: { left: "0", bottom: "0", transform: "translate(0%, 0%)", right: "auto", top: "auto", width: isMobile ? "25px" : "10px", height: isMobile ? "25px" : "10px" } },
    { id: "top", position: Position.Top, additionalStyle: { left: "50%", transform: "translateX(-50%)", width: isMobile ? "25px" : "10px", height: isMobile ? "25px" : "10px" } },
    { id: "right", position: Position.Right, additionalStyle: { top: "50%", transform: "translateY(-50%)", width: isMobile ? "25px" : "10px", height: isMobile ? "25px" : "10px" } },
    { id: "right-top", position: Position.Right, additionalStyle: { right: "0", top: "0", transform: "translate(0%, 0%)", width: isMobile ? "25px" : "10px", height: isMobile ? "25px" : "10px" } },
    { id: "right-bottom", position: Position.Right, additionalStyle: { right: "0", bottom: "0", transform: "translate(0%, 0%)", left: "auto", top: "auto", width: isMobile ? "25px" : "10px", height: isMobile ? "25px" : "10px" } },
    { id: "bottom", position: Position.Bottom, additionalStyle: { left: "50%", transform: "translateX(-50%)", width: isMobile ? "25px" : "10px", height: isMobile ? "25px" : "10px" } },
  ];

  // 엔터키 입력 시 태그 추가
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && inputValue.trim()) {
      event.preventDefault();
      const { backgroundColor, textColor } = getRandomColorWithText();
      if (!data.data.hashtags.find((tag: { backgroundColor: string, textColor: string, value: string }) => tag.value === inputValue.trim())) {
        addHashtag(data, {
          backgroundColor,
          textColor,
          value: inputValue.trim(),
        });
      }
      setInputValue(""); // 입력창 초기화
    } else if (event.key === 'Backspace' && inputValue === "") {
      if (data.data.hashtags.length > 0) {
        event.preventDefault();
        removeHashtag(data);
      }
    }
  };


  useEffect(() => {
    if (contentRef.current) {
      if (selectedNode?.id === data.id || data.data.isNewCreated) {
        setMaxHeight(`800px`); // 확장 시 실제 높이를 기준으로 설정
      } else {
        setMaxHeight('200px'); // 축소 시 고정된 높이로 설정
      }
    }

    // updateNodeInternals(data.id);
  }, [updateNodeInternals, data.id, selectedNode, data.data.isNewCreated]); // expanded 상태 변경 시마다 실행

  const handleUpdateNode = (key: keyof typeof data.data, value: any) => {
    const updatedNode = {
      ...data.data,
      [key]: value,
    };
    updateNode(updatedNode);
  }

  const handleDateUpdate = (date: Dayjs | null, type: 'start' | 'end') => {
    const updatedNode = {
      ...data.data,
      startDate: type === 'start' ? date?.toISOString() : data.data.startDate,
      endDate: type === 'end' ? date?.toISOString() : data.data.endDate,
    };
    updateNode(updatedNode);
  };

  const handleLocationUpdate = (address: string) => {
    const updatedNode = {
      ...data.data,
      location: {
        ...data.data.location,
        address: address,
      },
    };
    updateNode(updatedNode);
  };

  // 경로에 있는지 여부에 따른 활성/비활성 스타일 결정
  const isPathActive = careerPath?.path.includes(data.id);
  const isCareerPathNull = careerPath === null;

  const handleDetachNodeInGroup = (e: React.MouseEvent) => {
    e.stopPropagation();
    detachNodeInGroup(data.id);
  }

  const setRoutineOption = (key: number) => {
    let updatedNode;
    if (data.data.termType === key) {
      updatedNode = {
        ...data.data,
        termType: null,
        termData: null,
      };
    } else {
      updatedNode = {
        ...data.data,
        termType: key,
        termData: [],
      };
    }
    updateNode(updatedNode);
  }

  return (
    data.data.activate !== 2 &&
    <>
      <NodeToolbar
        isVisible={selectedNode?.id === data.id}
        position={Position.Top}
      >
        {
          data.parentId !== undefined &&
          <Button onClick={handleDetachNodeInGroup} style={{ fontSize: "0.8rem", fontWeight: "600", backgroundColor: theme.colors.primary, color: "white" }}>그룹해제</Button>
        }
        {
          isMobile &&
          <Button onClick={() => handleDeleteNode(data.id)} style={{ fontSize: "0.8rem", fontWeight: "600", backgroundColor: theme.colors.primary, color: "white" }}>삭제</Button>
        }
      </NodeToolbar>
      <NodeToolbar
        isVisible={isExistTodayStatus}
        position={Position.Right}
      >
        <Button style={{ fontSize: "0.8rem", fontWeight: "600", backgroundColor: theme.colors.primary, color: "white" }} onClick={() => updateStatus(data, todayStatus.taskStatusId as number, todayStatus.status as number === 1 ? 0 : 1)}>{todayStatus?.status === 1 ? "대기" : "완료"}</Button>
      </NodeToolbar>
      <Stack
        sx={{
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
          cursor: "pointer",
          transition: 'filter 0.5s ease', // 어두워지는 효과
          filter: isCareerPathNull || isPathActive ? 'none' : 'brightness(50%)', // 경로에 없으면 어둡게
        }}
        onClick={(e) => {
          if (selectedNode?.id === data.id) {
            e.stopPropagation();
          }
        }}
      >
        <S.Container
          id={`${data.id}`}
          $isActivate={data.data.activate === 1}
          $isSelecting={selectedNode?.id === data.id}
          $color={theme.colors[type]}
          style={{
            transition: 'max-height 0.5s ease', // 애니메이션 추가
            maxHeight: maxHeight, // 동적 max-height 설정
            overflow: 'hidden', // 넘치는 내용 숨기기
          }}
          ref={contentRef} // contentRef를 참조
        >
          {/* 핸들: source/target을 동적으로 설정 */}
          {
            handlePositions.map((handle) =>
              ["source", "target"].map((type) => (
                <Handle
                  key={`${data.id}-${handle.id}-${type}`}
                  type={type as HandleType}
                  position={handle.position}
                  id={`${data.id}-${handle.id}-${type}`}
                  className="handle"
                  style={{ ...handle.additionalStyle, backgroundColor: selectedNode && selectedNode.id === data.id ? "#d9d9d9" : "transparent" }}
                />
              ))
            )
          }
          {
            selectedNode?.id === data.id || data.data.isNewCreated ? (
              <>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                  <Input value={data.data.label} onChange={(e) => handleUpdateNode("label", e.target.value)} style={{ display: "flex", width: "100%", fontSize: "1.3rem" }} />
                </div>

                <div style={{ display: "flex", width: "100%", fontSize: "1rem", fontWeight: "bold" }}>진행 일자 설정</div>
                <DatePicker
                  label="시작일"
                  sx={{ width: "100%" }}
                  value={dayjs(data.data.startDate)}
                  onChange={(date) => handleDateUpdate(date, 'start')}
                  shouldDisableDate={data.data.endDate ? (day) => day.isAfter(data.data.endDate) : undefined}
                  format="YYYY.MM.DD"
                />
                <DatePicker
                  label="종료일"
                  sx={{ width: "100%" }}
                  value={dayjs(data.data.endDate)}
                  onChange={(date) => handleDateUpdate(date, 'end')}
                  shouldDisableDate={data.data.startDate ? (day) => day.isBefore(data.data.startDate) : undefined}
                  format="YYYY.MM.DD"
                />
                <S.ContentContainer onClick={() => openTextEditor(data.id, data.data.content as string)}>
                  {
                    data.data.content === "" ?
                      <Typography sx={{ fontSize: "0.8rem" }}>설명</Typography> :
                      <TextMarkdown text={data.data.content as string} />
                  }
                </S.ContentContainer>

                <Box sx={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", alignItems: "center" }}>
                  <Typography sx={{ width: "20%", fontSize: "1rem", fontWeight: "bold" }}>루틴 간격</Typography>
                  <ToggleButtonGroup
                    exclusive
                    value={data.data.termType}
                    onChange={(event, newViewType) => setRoutineOption(newViewType)}
                    color="primary"
                  >
                    <ToggleButton value={0} selected={data.data.termType === 0} onClick={() => setRoutineOption(0)}>매일</ToggleButton>
                    <ToggleButton value={1} selected={data.data.termType === 1} onClick={() => setRoutineOption(1)}>매주</ToggleButton>
                    <ToggleButton value={2} selected={data.data.termType === 2} onClick={() => setRoutineOption(2)}>매월</ToggleButton>
                  </ToggleButtonGroup>
                </Box>

                {
                  (data.data.termType === 1 || data.data.termType === 2) &&
                  <>
                    <Box sx={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", alignItems: "center" }}>
                      <Typography sx={{ fontSize: "1rem", fontWeight: "bold" }}>반복</Typography>
                      <CustomBackgroundColorButton text={language === "KR" ? "수정" : "edit"} onClick={() => setEditRoutineDialog({
                        open: data.id,
                        termType: data.data.termType,
                        termData: data.data.termData,
                      })} fontSize="1rem" />
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", alignItems: "center" }}>
                      <Typography sx={{ fontSize: "1rem", fontWeight: "bold" }}>{data.data.termType === 1 ? "매주" : "매월"}</Typography>
                      <Box sx={{ display: "grid", gridTemplateColumns: `repeat(${data.data.termData && data.data.termData.length < 7 ? data.data.termData.length : 7}, 1fr)`, gap: "5px" }}>
                        {data.data.termData?.map((interval: string) => (
                          <Typography key={interval} sx={{ fontSize: "1rem", fontWeight: "bold" }}>{interval}{data.data.termType === 1 ? "" : "일"}</Typography>
                        ))}
                      </Box>
                    </Box>
                  </>
                }

                <TextField id="outlined-basic" label="장소" variant="outlined" value={data.data.location.address || ""} onChange={(e) => handleLocationUpdate(e.target.value)} style={{ width: "100%" }} />

                <S.TagInputContainer>
                  {data.data.hashtags.map((tag: { backgroundColor: string, textColor: string, value: string }, idx: number) => {
                    return <S.TagItem key={idx} style={{ backgroundColor: tag.backgroundColor, color: tag.textColor }}>
                      <span>{tag.value}</span>
                    </S.TagItem>
                  })}
                  <S.TagInput
                    placeholder={data.data.hashtags.length === 0 ? "해시태그" : ""}
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyDown={handleKeyDown}
                  />
                </S.TagInputContainer>

                <div style={{ display: "flex", width: "100%", flexDirection: "row", justifyContent: "space-between" }}>
                  {
                    data.data.activate === 1 ?
                      <CustomColorButton text="비활성화 해제" onClick={() => setInactivateDialog({ open: true, node: data })} fontSize="1.2rem" /> :
                      <CustomColorButton text="비활성화" onClick={() => setInactivateDialog({ open: true, node: data })} fontSize="1.2rem" />
                  }
                  <CustomColorButton text="함께하기" onClick={() => setTogetherDialog({ node: data })} fontSize="1.2rem" />
                </div>
              </>
            ) :
              <>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                  {/* <Input value={data.data.label} onClick={(e) => e.stopPropagation()} onChange={(e) => handleUpdateNode("label", e.target.value)} style={{ display: "flex", width: "100%", fontSize: "1.3rem" }} /> */}
                  <Typography style={{ fontSize: "1.3rem" }}>{data.data.label}</Typography>
                </div>
                <div style={{ display: "flex", width: "100%", fontSize: "1rem", justifyContent: isExistTodayStatus ? "space-between" : "flex-end", color: `${(!data.data.startDate || !data.data.endDate ? "black" : theme.colors.primary)}`, fontWeight: "bold" }}>
                  <div style={{ color: "black" }}>
                    {isExistTodayStatus ? todayStatus?.status === 1 ? "완료" : "대기" : ""}
                  </div>
                  <div>
                    {(!data.data.startDate || !data.data.endDate) ? "일정 미설정" : getRemainingDays(data.data.startDate, data.data.endDate)}
                  </div>
                </div>
                <div style={{ marginTop: '10px', width: '100%' }}>
                  <LinearProgress variant="determinate" value={getProgressPercentage(data.data.startDate, data.data.endDate)} sx={{
                    height: "20px", borderRadius: "5px", backgroundColor: "#e0e0e0", '& .MuiLinearProgress-bar': {
                      backgroundColor: theme.colors.primary, // 커스텀 색상 적용
                    },
                  }} />
                </div>
              </>
          }
        </S.Container>
      </Stack>
    </>
  );
}

export default TaskNode;
