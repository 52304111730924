import { feedDetailUserSignatureDialogAtom } from "@/Atoms/Dialogs/FeedDetail/atom";
import SignatureCard from "@/Components/Signature/Contents/Card";
import { Dialog } from "@mui/material";
import { useAtom } from "jotai";
import React from "react";

const FeedDetailUserSignatureDialog = () => {
  const [dialog, setDialog] = useAtom(feedDetailUserSignatureDialogAtom);

  return (
    dialog &&
    <Dialog
      open={dialog !== null}
      onClose={() => setDialog(null)}
      maxWidth="md"
    >
      <SignatureCard signature={dialog} />
    </Dialog>
  )
}

export default FeedDetailUserSignatureDialog;
