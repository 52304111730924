import { crewLecturesAtom, Lecture } from '@/Atoms/Crew';
import applyLectureDialogAtom from '@/Atoms/Dialogs/ApplyLecture';
import GetIdFromQuerystring from '@/Utils/GetIdFromQuerystring';
import { atom } from 'jotai';
import { getUserId, getUserImage, getUserNickname } from '../UserViewModel';
import snackbarAtom from '@/Atoms/Snackbar';
import { applyLecture } from '@/Queries/LectureQueries';
import { handleReactQueryApiResponse } from '@/Utils/APIUtil';
import { error401ModalAtom } from '@/Atoms/Dialogs/Error/401Atom';

export const applyLectureDialogApplyAtom = atom(null, async (get, set) => {
  const dialog = get(applyLectureDialogAtom);
  if (!dialog) return;

  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  const crewLectures = get(crewLecturesAtom);
  const lectures = crewLectures.find((lecture) => lecture.innerTabId === innerTabId)?.lectures || [];
  const lecture = lectures.find((lecture) => lecture.id === dialog.id);
  if (!lecture) return;

  // Apply lecture
  const handleError401Modal = () => set(error401ModalAtom, true);
  const response = await handleReactQueryApiResponse(applyLecture, handleError401Modal, dialog.id, get(getUserId));
  if (!response) {
    set(snackbarAtom, { open: true, message: "강좌 신청에 실패했습니다.", severity: "error" });
    return;
  }

  const newLecture: Lecture = {
    ...lecture,
    members: [...lecture.members, {
      id: get(getUserId),
      name: get(getUserNickname),
      email: get(getUserNickname),
      profileImage: get(getUserImage),
    }]
  };
  const newLectures = lectures.map((lecture) => lecture.id === dialog.id ? newLecture : lecture);
  const newCrewLectures = crewLectures.map((crewLecture) => crewLecture.innerTabId === innerTabId ? { innerTabId, lectures: newLectures } : crewLecture);
  set(crewLecturesAtom, newCrewLectures);
  set(snackbarAtom, { open: true, message: "강좌 신청이 완료되었습니다.", severity: "success" });
  set(applyLectureDialogAtom, null);
})
