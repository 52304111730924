import React from "react";
import * as S from "./styled";
import { useAtomValue, useSetAtom } from "jotai";
import { userAtom } from "../../Atoms/UserAtom";
import MentorDashboard from "../../Components/Dashboard/Mentor";
import MenteeDashboard from "../../Components/Dashboard/Mentee";
import TeacherDashboard from "../../Components/Dashboard/Teacher";
import usePlanHip from "@/Hooks/usePlanHip";
import { waitingModalAtom } from "@/Atoms/RootAtom";
import DailyPlanDialog from "@/Components/Dashboard/Dialogs/DailyPlan";
import { getUserPosition } from "@/ViewModels/UserViewModel";
import useMenteeDashboard from "@/Hooks/useMenteeDashboard";
import useTeacherDashboard from "@/Hooks/useTeacherDashboard";

const Dialogs = () => {
  return <>
    <DailyPlanDialog />
  </>;
}

const Dashboard = () => {
  const user = useAtomValue(userAtom);
  const userPosition = useAtomValue(getUserPosition);
  const { isLoading } = usePlanHip();
  const setWaitingModal = useSetAtom(waitingModalAtom);
  const { isLoading: isMenteeDashboardLoading } = useMenteeDashboard();
  const { isLoading: isTeacherDashboardLoading } = useTeacherDashboard();

  React.useEffect(() => {
    setWaitingModal({
      state: isLoading && (
        userPosition === "교사" ?
          isTeacherDashboardLoading :
          userPosition === "멘티" ?
            isMenteeDashboardLoading :
            true
      ), text: "로딩중입니다."
    });
  }, [isLoading, isTeacherDashboardLoading, isMenteeDashboardLoading, userPosition, setWaitingModal]);

  return (
    <S.Container>
      {user?.membership === "mentor" && <MentorDashboard />}
      {user?.membership === "mentee" && <MenteeDashboard />}
      {user?.membership === "teacher" && <TeacherDashboard />}
      {user?.membership === "free" && <MentorDashboard />}
      <Dialogs />
    </S.Container>
  );
};

export default Dashboard;
