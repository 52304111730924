import theme from '@/Styles/theme';
import { atom } from 'jotai';

interface ColorPickerDialog {
  open: boolean;
  color: string;
}

const colorPickerDialogAtom = atom<ColorPickerDialog>({
  open: false,
  color: theme.colors.primary,
});

export default colorPickerDialogAtom;
