import { initCreateTaskDialogData } from "@/Atoms/Dialogs/Create/Task";
import { editTaskDialogAtom, editTaskDialogEditRoutineAtom } from "@/Atoms/Dialogs/Edit/Task";
import { edgesAtom, nodesAtom } from '@/Atoms/Plan/MindmapAtom';
import { LocationProps } from "@/Atoms/RootAtom";
import snackbarAtom from "@/Atoms/Snackbar";
import GetIdFromQuerystring from "@/Utils/GetIdFromQuerystring";
import { updatePlanDataMindmapAtom } from "@/ViewModels/Plan/InnerTabViewModel";
import { Node } from "@xyflow/react";
import dayjs from "dayjs";
import { atom } from "jotai";

// 수정하기 위한 데이터 세팅
export const editTaskDialogLoadDataAtom = atom(null, (get, set, node: Node) => {
  set(editTaskDialogAtom, {
    open: node.id,
    name: node.data.label as string,
    content: node.data.content as string,
    startDate: (node.data.startDate as string) ? dayjs(node.data.startDate as string) : null,
    endDate: (node.data.endDate as string) ? dayjs(node.data.endDate as string) : null,
    termType: node.data.termType as number,
    termData: node.data.termData as string[],
    location: node.data.location as LocationProps,
    hashtags: node.data.hashtags as { backgroundColor: string, textColor: string, value: string }[],
  });
})

// 상태 초기화
export const editTaskDialogClearStateAtom = atom(null, (get, set) => {
  set(editTaskDialogAtom, initCreateTaskDialogData());
})

// 할 일 수정
export const editTaskDialogEditTaskAtom = atom(null, (get, set) => {
  const task = get(editTaskDialogAtom);
  if (!task.open) return;
  if (task.name === "") {
    set(snackbarAtom, (prev) => ({ ...prev, open: true, message: "이름을 입력해주세요.", severity: "error" }));
    return;
  }
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  const updatedNode = get(nodesAtom).find((node) => node.id === task.open);
  if (!updatedNode) return;
  const newNode = {
    ...updatedNode,
    data: {
      ...updatedNode.data,
      label: task.name,
      content: task.content,
      startDate: task.startDate ? task.startDate.toISOString() : null,
      endDate: task.endDate ? task.endDate.toISOString() : null,
      termType: task.termType,
      termData: task.termData,
      location: task.location,
      hashtags: task.hashtags,
    },
  };
  const newNodes = get(nodesAtom).map((node) => node.id === task.open ? newNode : node);
  set(updatePlanDataMindmapAtom, innerTabId, newNodes, get(edgesAtom));
  set(editTaskDialogClearStateAtom);
});

// key에 따라 상태 변경
export const editTaskDialogUpdateStateAtom = atom((get) => get(editTaskDialogAtom), (get, set, key: string, value: any) => {
  if (key === "termData") {
    const weekDays = ["월", "화", "수", "목", "금", "토", "일"];
    get(editTaskDialogAtom).termType === 1 ?
      value.sort((a: string, b: string) => weekDays.indexOf(a) - weekDays.indexOf(b)) : value.sort((a: string, b: string) => parseInt(a) - parseInt(b));
  }
  set(editTaskDialogAtom, (prev) => ({
    ...prev,
    [key]: value,
  }));
});

// 장소 변경
export const editTaskDialogEditLocationAtom = atom(null, (get, set, address: string) => {
  set(editTaskDialogAtom, (prev) => ({
    ...prev,
    location: { address: address, latitude: prev.location.latitude, longitude: prev.location.longitude },
  }));
})

// 루틴 옵션 변경
export const editTaskDialogSetRoutineOptionAtom = atom(null, (get, set, key: number) => {
  set(editTaskDialogAtom, (prev) => ({
    ...prev,
    termType: prev.termType === key ? null : key,
    termData: prev.termType === key ? null : [],
  }));
});

// 루틴 일자 변경
export const editTaskDialogEditRoutineDataAtom = atom((get) => get(editTaskDialogEditRoutineAtom), (get, set, day: string) => {
  const editRoutineData = get(editTaskDialogEditRoutineAtom);
  if (!editRoutineData.termData) return;
  if (editRoutineData.termData.includes(day)) {
    set(editTaskDialogEditRoutineAtom, { termType: editRoutineData.termType, termData: editRoutineData.termData.filter((interval) => interval !== day) });
  } else {
    set(editTaskDialogEditRoutineAtom, { termType: editRoutineData.termType, termData: [...editRoutineData.termData, day] });
  }
});

// 루틴 일자 초기화
export const editTaskDialogEditRoutineClearStateAtom = atom(null, (get, set) => {
  set(editTaskDialogEditRoutineAtom, {
    termType: null,
    termData: null,
  });
});

// 루틴 변경 적용
export const editTaskDialogEditRoutineApplyAtom = atom(null, (get, set) => {
  const editRoutineData = get(editTaskDialogEditRoutineAtom);
  set(editTaskDialogAtom, (prev) => ({
    ...prev,
    termData: editRoutineData.termData,
  }));
  set(editTaskDialogEditRoutineClearStateAtom);
});

// 루틴 변경 시작
export const editTaskDialogEditRoutineStartAtom = atom(null, (get, set) => {
  const termData = get(editTaskDialogAtom).termData;
  set(editTaskDialogEditRoutineAtom, {
    termType: get(editTaskDialogAtom).termType,
    termData: termData ? [...termData] : [],
  });
});

// 해시태그 추가
export const editTaskDialogAddHashtagAtom = atom(null, (get, set, hashtag: { backgroundColor: string, textColor: string, value: string }) => {
  set(editTaskDialogAtom, (prev) => ({
    ...prev,
    hashtags: [...prev.hashtags, hashtag],
  }));
});

// 해시태그 삭제(pop)
export const editTaskDialogRemoveHashtagAtom = atom(null, (get, set) => {
  set(editTaskDialogAtom, (prev) => ({
    ...prev,
    hashtags: prev.hashtags.slice(0, -1),
  }));
});
