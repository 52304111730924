import { atom } from "jotai";

const deletePlanDialogAtom = atom<{
  open: boolean;
  complete: boolean;
}>({
  open: false,
  complete: false,
});

export default deletePlanDialogAtom;
