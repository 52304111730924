import { nodesAtom } from '@/Atoms/Plan/MindmapAtom';
import { atom } from "jotai";
import { routineSetDataAtom } from './RoutineViewModel';

// default routine에서의 업데이트 로직
export const updateTaskOnDefaultRoutineAtom = atom(null, (get, set, id: string, key: string, value: any) => {
  // 특정 id와 일치하는 task를 찾기
  const task = get(nodesAtom).find((task) => task.id === id);
  if (!task) return;

  // 해당 task에 업데이트된 값 설정
  const newUpdatedTaskData = {
    ...task.data,
    [key]: value,
  }

  const newNodes = get(nodesAtom).map((node) => (node.id === id) ? { ...node, data: newUpdatedTaskData } : node);

  // nodesAtom 업데이트
  set(nodesAtom, newNodes);
  set(routineSetDataAtom, newNodes);
});

// default routine에서의 termType 업데이트 로직
export const updateTaskTermTypeOnDefaultRoutineAtom = atom(null, (get, set, id: string, termType: number) => {
  // 특정 id와 일치하는 task를 찾기
  const task = get(nodesAtom).find((task) => task.id === id);
  if (!task) return;

  // 해당 task에 업데이트된 값 설정
  const newUpdatedTaskData = {
    ...task.data,
    termType: termType,
    termData: [],
  }

  const newNodes = get(nodesAtom).map((node) => (node.id === id) ? { ...node, data: newUpdatedTaskData } : node);

  // nodesAtom 업데이트
  set(nodesAtom, newNodes);
  set(routineSetDataAtom, newNodes);
});

// default routine에서의 location 업데이트 로직
export const updateTaskLocationOnDefaultRoutineAtom = atom(null, (get, set, id: string, address: string) => {
  // 특정 id와 일치하는 task를 찾기
  const task = get(nodesAtom).find((task) => task.id === id);
  if (!task) return;

  // 해당 task에 업데이트된 값 설정
  const newLocation = { address: address, latitude: null, longitude: null };

  const newNodes = get(nodesAtom).map((node) => (node.id === id) ? { ...node, data: { ...node.data, location: newLocation } } : node);

  // nodesAtom 업데이트
  set(nodesAtom, newNodes);
  set(routineSetDataAtom, newNodes);
});
