const getRandomDate = () => {
  const randomDate = new Date();
  randomDate.setDate(randomDate.getDate() - Math.floor(Math.random() * 100));
  return randomDate;
}

export const dummyNotificationData = () => ([
  {
    id: 1,
    title: "개발 전략 수립",
    content: "권순형님의 글 등록\nKT DB",
    date: getRandomDate(),
    profileImage: "https://via.placeholder.com/40", // 대체 이미지 URL
    profileNickName: "권순형",
    type: "미확인",
    unread: true,
  },
  {
    id: 2,
    title: "개발 전략 수립",
    content: "권순형님의 글 등록\n개발 서버",
    date: getRandomDate(),
    profileImage: "https://via.placeholder.com/40",
    profileNickName: "권순형",
    type: "미확인",
    unread: true,
  },
  {
    id: 3,
    title: "개발 전략 수립",
    content: "권순형님의 글 등록\n윈도우 Celery 동작시 command",
    date: getRandomDate(),
    profileImage: "https://via.placeholder.com/40",
    profileNickName: "권순형",
    type: "미확인",
    unread: true,
  },
  {
    id: 4,
    title: "개발 전략 수립",
    content: "권순형님의 글 등록\nDOKGABI 대표 이메일 백업 코드",
    date: getRandomDate(),
    profileImage: "https://via.placeholder.com/40",
    profileNickName: "권순형",
    type: "미확인",
    unread: true,
  },
  {
    id: 5,
    title: "용민",
    content: "백용민님의 글 등록\n세션, 쿠키, JWT, Access Token, Refresh Token, OAuth2.0",
    date: getRandomDate(),
    profileImage: "https://via.placeholder.com/40",
    profileNickName: "백용민",
    type: "확인",
    unread: false,
  },
  {
    id: 6,
    title: "시커리어플래너",
    content: "백용민님의 글 등록\n2024-10-28 (월) 일일 업무 일정 - 백문원",
    date: getRandomDate(),
    profileImage: "https://via.placeholder.com/40",
    profileNickName: "백용민",
    type: "확인",
    unread: false,
  },
  {
    id: 7,
    title: "개발 전략 수립",
    content: "권순형님의 글 등록\n아스프린 테이블 클라우드 교육",
    date: getRandomDate(),
    profileImage: "https://via.placeholder.com/40",
    profileNickName: "권순형",
    type: "미확인",
    unread: true,
  },
  {
    id: 8,
    title: "개발 전략 수립",
    content: "권순형님의 글 등록\n개발 서버 준비 작업",
    date: getRandomDate(),
    profileImage: "https://via.placeholder.com/40",
    profileNickName: "권순형",
    type: "확인",
    unread: false,
  },
  {
    id: 9,
    title: "용민",
    content: "백용민님의 글 등록\nOAuth2.0 인증 가이드",
    date: getRandomDate(),
    profileImage: "https://via.placeholder.com/40",
    profileNickName: "백용민",
    type: "확인",
    unread: false,
  },
  {
    id: 10,
    title: "시커리어플래너",
    content: "백용민님의 글 등록\n시커리어플래너 기능 설명서",
    date: getRandomDate(),
    profileImage: "https://via.placeholder.com/40",
    profileNickName: "백용민",
    type: "확인",
    unread: false,
  },
]);
