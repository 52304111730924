import { atom } from "jotai";

interface InnerTab {
  id: number;
  name: string | null;
  viewType: "mindmap" | "todo" | "routine" | "gantt";
}

export interface Plan {
  id: number;
  name: string | null;
  innerTabs: InnerTab[];
}

const planAtom = atom<Plan[]>([]);
export const planFitViewUpdatedAtom = atom<boolean>(false); // plan 페이지 입장 시 fitView를 업데이트하기 위한 atom

export const mobilePlanAtom = atom<string>(""); // 모바일에서 사용하는 제목 표시를 위한 atom
export const mobileEditPlanNameAtom = atom<string | null>(null); // 모바일에서 사용하는 제목 수정을 위한 atom

export default planAtom;
