import { useQuery } from 'react-query';
import { getPlanData } from '@/Queries/PlanQueries';
import GetIdFromQuerystring from '@/Utils/GetIdFromQuerystring';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import innerTabDataAtom from '@/Atoms/Plan/InnerTabDataAtom';
import planAtom, { mobilePlanAtom } from '@/Atoms/Plan';
import { editPlanNameDialogOpenAtom } from '@/ViewModels/Dialogs/Edit/PlanNameViewModel';
import { routineInitAtom } from '@/ViewModels/Plan/RoutineViewModel';
import { ganttChartUpdateAtom } from '@/ViewModels/Plan/GanttChartViewModel';
import { edgesAtom, nodesAtom } from '@/Atoms/Plan/MindmapAtom';
import { usePlanDataAtom } from '@/Atoms/HookControllerAtom';
import { handleReactQueryApiResponseWithJson } from '@/Utils/APIUtil';
import { Node } from '@xyflow/react';
import { error401ModalAtom } from '@/Atoms/Dialogs/Error/401Atom';
import { devConsoleLog } from '@/Utils/ConsoleLogInDevelopment';

export const usePlanData = () => {
  const plans = useAtomValue(planAtom);
  const planId = GetIdFromQuerystring("id");
  const plan = plans.find((plan) => plan.id === planId);
  const setInnerTabData = useSetAtom(innerTabDataAtom);
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  const editPlanNameDialogOpen = useSetAtom(editPlanNameDialogOpenAtom);
  const initializeRoutineData = useSetAtom(routineInitAtom);
  const initializeGanttChart = useSetAtom(ganttChartUpdateAtom);
  const setNodes = useSetAtom(nodesAtom);
  const setEdges = useSetAtom(edgesAtom);
  const [controller, setController] = useAtom(usePlanDataAtom);
  const setError401Modal = useSetAtom(error401ModalAtom);
  const setMobilePlanName = useSetAtom(mobilePlanAtom);

  return useQuery(['planData', innerTabId], () => handleReactQueryApiResponseWithJson(getPlanData, () => setError401Modal(true), innerTabId), {
    enabled: !!plan && controller,
    onSuccess: async (data) => {
      setController(false);
      setMobilePlanName(plan?.name || "-");
      if (!data) {
        setInnerTabData((prev) => ([...prev, { id: plan?.innerTabs[0].id || 0, nodes: [], edges: [] }]));
        setNodes([]);
        setEdges([]);
      } else {
        const groupNodes = data.nodes.filter((node: Node) => node.type === "group");
        const nonGroupNodes = data.nodes.filter((node: Node) => node.type !== "group");
        const nodes = [...groupNodes, ...nonGroupNodes].map((node: Node) => ({
          ...node,
          data: {
            ...node.data,
            hashtags: node.data.hashtags || [],
          }
        }));
        setInnerTabData((prev) => ([...prev, { id: plan?.innerTabs[0].id || 0, nodes: nodes, edges: data.edges }]));
        setNodes(nodes);
        setEdges(data.edges);
      }
      initializeRoutineData();
      initializeGanttChart();
      editPlanNameDialogOpen();
    },
  });
};
