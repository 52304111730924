import React from "react";
import { Box, Divider } from "@mui/material";
import * as S from "../styled";
import HabitTable from "./HabitTable";

// 추천 직업
export function ApiAdmin() {
  return (
    <S.CustomBox
      sx={{ height: "100%", display: "flex", flexDirection: "column" }}
    >
      <S.TitleTypo>API 연동 현황</S.TitleTypo>
      <Divider sx={{ margin: "10px 0" }} />
      <S.DescTypo sx={{ marginBottom: "10px" }}>
        타사 솔루션과 연동한 이력을 조회할 수 있습니다.
      </S.DescTypo>

      <HabitTable />
    </S.CustomBox>
  );
}
