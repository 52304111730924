import React from "react";
import * as S from "./styled";
import CreateCourseDialog from "@/Components/Crew/Dialogs/Create/Course";
import { useAtomValue, useSetAtom } from "jotai";
import afterSchoolAtom, { Course } from "@/Atoms/AfterSchool";
import CustomColorButton from "@/Components/Common/Buttons/CustomColor";
import { createCourseDialogOpenAtom } from "@/ViewModels/Dialogs/Create/CourseViewModel";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import convertDayToKorean from "@/Utils/ConvertDayToKorean";
import { useNavigate } from "react-router-dom";
import afterSchoolCourseAtom from "@/Atoms/AfterSchoolCourse";

const Dialogs = () => {
  return (
    <>
      <CreateCourseDialog />
    </>
  )
}

const AfterSchool = () => {
  const openCourseDialog = useSetAtom(createCourseDialogOpenAtom);
  const afterSchool = useAtomValue(afterSchoolAtom);
  const setAfterSchoolCourse = useSetAtom(afterSchoolCourseAtom);
  const navigate = useNavigate();
  const handleCourseClick = (course: Course) => {
    setAfterSchoolCourse(course);
    navigate("/after-school-course");
  }

  return (
    <S.Container>
      <S.ContentContainer>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
          <div style={{ fontSize: "1.2rem", fontWeight: "bold" }}>방과후학교</div>
          <CustomColorButton onClick={openCourseDialog} text="+ 과정 개설" />
        </div>
        <TableContainer component={Paper}>
          <Table sx={{ width: "100%" }}>
            <TableHead>
              <TableRow>
                <TableCell>과정 이름</TableCell>
                <TableCell>담당교사</TableCell>
                <TableCell>신청 방법</TableCell>
                <TableCell>운영시간</TableCell>
                <TableCell>선택군</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {afterSchool.courses.map((course, index) => (
                <TableRow key={index} sx={{ cursor: "pointer", "&:hover": { backgroundColor: "#e0e0e0" } }} onClick={() => handleCourseClick(course)}>
                  <TableCell>{course.courseName}</TableCell>
                  <TableCell>{course.teacher}</TableCell>
                  <TableCell>
                    {course.applicationMethod === 0 && "과목군별 택1 이내"}
                    {course.applicationMethod === 1 && "과목군별 택2 이내"}
                    {course.applicationMethod === 2 && "과목군별 택3 이내"}
                    {course.applicationMethod === 3 && "일괄신청 금지"}
                    {course.applicationMethod === 4 && "개별신청 금지"}
                  </TableCell>
                  <TableCell>
                    <ul style={{ paddingLeft: "15px", margin: 0 }}>
                      {Object.entries(course.operatingTimes).map(([day, times]) => (
                        <li key={day} style={{ listStyle: "none" }}>
                          {convertDayToKorean(day)}: {times.join(", ")} 교시
                        </li>
                      ))}
                    </ul>
                  </TableCell>
                  <TableCell>{course.selectionGroup}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div style={{ width: "100%", minHeight: "10px" }} />
      </S.ContentContainer>
      <Dialogs />
    </S.Container>
  );
};

export default AfterSchool;
