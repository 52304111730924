import { edgesAtom, nodesAtom } from "@/Atoms/Plan/MindmapAtom";
import createMindmapNodesAndEdges from "@/Utils/CreateMindmapNodesAndEdges";
import { atom } from "jotai";
import { setInnerTabDataAtom, updatePlanDataAtom } from "../../InnerTabViewModel";
import GetIdFromQuerystring from "@/Utils/GetIdFromQuerystring";

const testJob = {
  "job": "연주가",
  "tasks": {
    "categories": [
      {
        "name": "관현악과",
        "tasks": [
          {
            "name": "기술적 연주 능력",
            "tasks": [
              {
                "name": "악기 연습",
                "content": "매일 악기 연습 일정을 세우고 꾸준히 반복 연습을 통해 기술을 향상시키세요."
              },
              {
                "name": "연주회 참여",
                "content": "다양한 연주회와 마스터클래스에 참여하여 무대 경험을 쌓고 피드백을 받아들이세요."
              }
            ]
          },
          {
            "name": "예술적 표현 능력",
            "tasks": [
              {
                "name": "악보 분석",
                "content": "연주할 곡의 악보를 깊이 분석하고 작곡가의 의도를 파악하여 해석 능력을 기르세요."
              },
              {
                "name": "창의적 연주 기법 개발",
                "content": "다양한 스타일의 연주를 시도하여 자신만의 연주 기법을 개발하세요."
              }
            ]
          },
          {
            "name": "무대 매너",
            "tasks": [
              {
                "name": "무대 연출 연습",
                "content": "무대 공연 중 필요한 자세와 태도를 연습하여 관객과의 상호작용 능력을 향상시키세요."
              },
              {
                "name": "공연 후 피드백 분석",
                "content": "공연 후 피드백을 분석하여 꾸준히 개선할 부분을 찾아 발전시키세요."
              }
            ]
          },
          {
            "name": "음악적 소통 능력",
            "tasks": [
              {
                "name": "앙상블 활동",
                "content": "다양한 앙상블 활동에 참여하여 팀워크와 협업 능력을 기르세요."
              },
              {
                "name": "마스터클래스 참가",
                "content": "유명 연주자나 교수와의 마스터클래스를 통해 전문적 조언을 받으며 자신의 연주를 확인하세요."
              }
            ]
          }
        ]
      },
      {
        "name": "기악과",
        "tasks": [
          {
            "name": "높은 집중력",
            "tasks": [
              {
                "name": "지속적인 연습 시간 확보",
                "content": "지정된 시간 동안 방해 요소 없이 연습에 집중하여 집중력을 높이세요."
              },
              {
                "name": "마음챙김 명상",
                "content": "연주 전 마음을 안정시키고 집중력을 높이기 위해 명상을 실천하세요."
              }
            ]
          },
          {
            "name": "음악적 감수성",
            "tasks": [
              {
                "name": "다양한 음악 장르 탐색",
                "content": "다양한 장르의 음악을 듣고 분석하여 음악적 감수성을 넓히세요."
              },
              {
                "name": "작곡 경험 쌓기",
                "content": "자신만의 음악을 작곡하고 이를 통해 감정을 표현하는 연습을 하세요."
              }
            ]
          },
          {
            "name": "효율적 시간 관리",
            "tasks": [
              {
                "name": "연습 계획 작성",
                "content": "연습과 공연 일정을 체계적으로 관리하기 위한 계획을 작성하세요."
              },
              {
                "name": "우선순위 설정",
                "content": "필요한 작업을 목록화하고 중요도에 따라 우선순위를 정하세요."
              }
            ]
          },
          {
            "name": "교수 능력",
            "tasks": [
              {
                "name": "음악 교습 활동",
                "content": "학생 또는 취미 음악인에게 음악을 가르치며 교육 능력을 추구하세요."
              },
              {
                "name": "연주 피드백 제공",
                "content": "다른 사람의 연주를 듣고 건설적인 피드백을 제공하며 자신도 동시에 배우세요."
              }
            ]
          }
        ]
      },
      {
        "name": "성악과",
        "tasks": [
          {
            "name": "발성 기술",
            "tasks": [
              {
                "name": "발성 연습",
                "content": "매일 발성 연습을 통해 올바른 호흡과 음성을 유지하세요."
              },
              {
                "name": "화음 연습",
                "content": "다양한 화음 연습을 통해 음성 조화를 효과적으로 이룹니다."
              }
            ]
          },
          {
            "name": "무대 표현력",
            "tasks": [
              {
                "name": "연기 수업",
                "content": "감정 표현을 위해 기초 연기 수업을 수강하고 무대 연기를 강화하세요."
              },
              {
                "name": "표정 및 제스처 연습",
                "content": "거울 앞에서 표정과 제스처를 연습하여 무대 위에서 자신감을 높이세요."
              }
            ]
          },
          {
            "name": "언어 능력",
            "tasks": [
              {
                "name": "다양한 언어의 노래 연습",
                "content": "여러 언어로 된 노래를 연습하여 언어 구사력을 향상시키세요."
              },
              {
                "name": "발음 교정 훈련",
                "content": "정확한 발음을 위한 발음 교정과 훈련을 진행하세요."
              }
            ]
          },
          {
            "name": "음악 해석력",
            "tasks": [
              {
                "name": "곡 해석 워크숍 참여",
                "content": "음악의 역사와 배경을 배우고 곡 해석법을 연마하세요."
              },
              {
                "name": "가사 분석",
                "content": "노래의 가사를 분석하고 감정을 전달하는 방법을 연구하세요."
              }
            ]
          }
        ]
      },
      {
        "name": "음악과",
        "tasks": [
          {
            "name": "음악 이론 이해",
            "tasks": [
              {
                "name": "화성학 공부",
                "content": "화성학과 음악 이론을 체계적으로 공부하여 이론적 기반을 확립하세요."
              },
              {
                "name": "악보 읽기 연습",
                "content": "다양한 악보를 읽고 분석하는 연습을 통해 악보 이해력을 높이세요."
              }
            ]
          },
          {
            "name": "음악적 분석 능력",
            "tasks": [
              {
                "name": "음악 분석 과제 수행",
                "content": "한국 및 외국의 여러 음악 작품을 분석하는 과제를 수행하세요."
              },
              {
                "name": "청음 훈련",
                "content": "다양한 음악 조각을 듣고 구조와 요소를 파악하는 청음 훈련을 하세요."
              }
            ]
          },
          {
            "name": "음악 교육 능력",
            "tasks": [
              {
                "name": "교육 방법론 연구",
                "content": "다양한 음악 교육 방법을 탐색하고 적절한 교육 방식을 선택하세요."
              },
              {
                "name": "교수 경력 쌓기",
                "content": "음악 교육자로서 다양한 교육 환경에서 경력을 쌓으세요."
              }
            ]
          },
          {
            "name": "조직 및 계획 능력",
            "tasks": [
              {
                "name": "음악 행사 기획",
                "content": "음악회나 연주회 기획을 통해 조직 능력을 키우세요."
              },
              {
                "name": "시간표 관리 연습",
                "content": "연습 및 수업 시간표를 효과적으로 관리하는 연습을 하세요."
              }
            ]
          }
        ]
      },
      {
        "name": "음악학과",
        "tasks": [
          {
            "name": "연구 및 분석 능력",
            "tasks": [
              {
                "name": "음악사 연구",
                "content": "음악의 역사를 깊이 있게 연구하고 시대별 특징을 파악하세요."
              },
              {
                "name": "음악비평 작성",
                "content": "다양한 음악 작품에 대한 비평을 작성하며 분석 능력을 강화하세요."
              }
            ]
          },
          {
            "name": "자료 조사 능력",
            "tasks": [
              {
                "name": "문헌 탐구",
                "content": "다양한 음악 분야의 문헌을 탐구하여 자료 조사 기술을 연마하세요."
              },
              {
                "name": "음원 분석",
                "content": "음원을 탐색하여 음악의 구조와 특성을 분석하세요."
              }
            ]
          },
          {
            "name": "교육 기획 능력",
            "tasks": [
              {
                "name": "교육 커리큘럼 개발",
                "content": "효과적인 음악 교육 커리큘럼을 설계하고 이를 실현하세요."
              },
              {
                "name": "교육 리소스 제작",
                "content": "교육에 필요한 리소스를 제작하여 교육의 질을 높이세요."
              }
            ]
          },
          {
            "name": "연구 협업 능력",
            "tasks": [
              {
                "name": "공동 연구 프로젝트 참여",
                "content": "다양한 음악 분야의 공동 연구 프로젝트에 참여하여 협업 능력을 높이세요."
              },
              {
                "name": "세미나 및 워크숍 기획",
                "content": "음악 관련 세미나 및 워크숍을 기획하며 지적 교류를 촉진하세요."
              }
            ]
          }
        ]
      },
      {
        "name": "작곡과",
        "tasks": [
          {
            "name": "작곡 기술",
            "tasks": [
              {
                "name": "음악 작곡 연습",
                "content": "개인 프로젝트를 통해 다양한 장르의 음악을 작곡하면서 기술을 향상시키세요."
              },
              {
                "name": "작곡 레슨 참여",
                "content": "전문가의 멘토링을 받으며 자신의 작곡 스타일을 발전시키세요."
              }
            ]
          },
          {
            "name": "음악적 창의성",
            "tasks": [
              {
                "name": "신곡 제작 프로젝트",
                "content": "새로운 음악 작품을 창조하는 프로젝트에 참여하여 창의력을 발휘하세요."
              },
              {
                "name": "음악 재해석 시도",
                "content": "기존 음악을 재해석하여 새로운 느낌을 부여하는 연습을 하세요."
              }
            ]
          },
          {
            "name": "협업 작곡 능력",
            "tasks": [
              {
                "name": "팀 작곡 활동",
                "content": "협업 작곡 프로젝트에 참여하여 다양한 스타일과 시각을 경험하세요."
              },
              {
                "name": "워크샵에서의 협업 참여",
                "content": "작곡 워크샵에서 팀원과의 협업을 경험하며 새로운 기법을 배워보세요."
              }
            ]
          },
          {
            "name": "기술 활용 능력",
            "tasks": [
              {
                "name": "음악 소프트웨어 활용",
                "content": "최신 음악 소프트웨어를 익히고 활용하여 작업의 효율을 높이세요."
              },
              {
                "name": "디지털 장비 사용",
                "content": "디지털 장비를 활용하여 작곡 및 편곡을 실습하세요."
              }
            ]
          }
        ]
      },
      {
        "name": "피아노학과",
        "tasks": [
          {
            "name": "피아노 테크닉",
            "tasks": [
              {
                "name": "기본 테크닉 연습",
                "content": "스케일, 아르페지오 등 피아노의 기본 테크닉을 꾸준히 연습하세요."
              },
              {
                "name": "교향곡 및 협주곡 연습",
                "content": "고난도의 교향곡 및 협주곡을 연습하여 기교를 높이세요."
              }
            ]
          },
          {
            "name": "음악적 해석 능력",
            "tasks": [
              {
                "name": "피아노 문헌 연구",
                "content": "다양한 시대의 피아노 문헌을 연구하여 해석의 폭을 넓히세요."
              },
              {
                "name": "음악 감상 및 리뷰 작성",
                "content": "다양한 음악을 감상하고 리뷰를 작성하며 음악 이해도를 높이세요."
              }
            ]
          },
          {
            "name": "연주 지도 능력",
            "tasks": [
              {
                "name": "피아노 개인 교습",
                "content": "피아노 교습을 통해 교습 방법을 배우고 학생과의 소통 능력을 길러보세요."
              },
              {
                "name": "마스터 클래스 운영 참여",
                "content": "마스터 클래스 운영에 참여하여 교육 리더십을 발휘하세요."
              }
            ]
          },
          {
            "name": "공연 기획 능력",
            "tasks": [
              {
                "name": "독주회 기획",
                "content": "자신의 독주회를 기획하고 실행하며 기획 능력을 키우세요."
              },
              {
                "name": "음악축제 참여",
                "content": "지역 음악 축제에 참여하여 공연 기획 및 서비스 역량을 키우세요."
              }
            ]
          }
        ]
      }
    ]
  }
}


// 직업 마인드맵 생성 함수
export const testCreateMindmapAtom = atom(null, async (get, set) => {
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  if (!innerTabId) return;
  const newMap = {
    dream: "꿈을 입력해주세요",
    goal: "목표를 입력해주세요",
    job: "test",
    tasks: testJob,
  };
  const { nodes, edges } = createMindmapNodesAndEdges(newMap);
  set(nodesAtom, nodes);
  set(edgesAtom, edges);
  set(setInnerTabDataAtom, { id: innerTabId, nodes, edges });
  set(updatePlanDataAtom, innerTabId, nodes, edges);
});
