import React from "react";
import * as S from './styled';
import MailContents from "@/Components/Mail/Contents";
import MailSidebar from "@/Components/Mail/Sidebar";
import useMail from "@/Hooks/useMail";
import { useSetAtom } from "jotai";
import { waitingModalAtom } from "@/Atoms/RootAtom";
import AddressBookDialog from "@/Components/Mail/Dialogs/AddressBook";

const Dialogs = () => {
  return (
    <>
      <AddressBookDialog />
    </>
  )
}

const Mail = () => {
  const { isLoading } = useMail();
  const setWaitingModal = useSetAtom(waitingModalAtom);

  React.useEffect(() => {
    setWaitingModal({ state: isLoading, text: "로딩중입니다." });
  }, [isLoading, setWaitingModal]);

  return (
    <S.Container>
      <MailSidebar />
      <MailContents />
      <Dialogs />
    </S.Container>
  );
}

export default Mail;
