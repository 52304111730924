import { LocationProps } from '@/Atoms/RootAtom';
import dayjs, { Dayjs } from 'dayjs';
import { atom } from 'jotai';

export const initCreateTaskDialogData = () => {
  return ({
    open: null,
    isCrew: false,
    name: "",
    content: "",
    startDate: null,
    endDate: null,
    termType: null,
    termData: null,
    location: { address: "", latitude: null, longitude: null },
    hashtags: [],
  })
}

export const createTaskDialogAtom = atom<{
  open: string | null;
  isCrew: boolean;
  name: string;
  content: string;
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  termType: number | null;
  termData: string[] | null;
  location: LocationProps;
  hashtags: {
    backgroundColor: string;
    textColor: string;
    value: string;
  }[];
}>(initCreateTaskDialogData());

interface CreateTaskDialogEditRoutineProps {
  termType: number | null;
  termData: string[] | null;
}

export const createTaskDialogEditRoutineAtom = atom<CreateTaskDialogEditRoutineProps>({
  termType: null,
  termData: null,
});
