import { atom } from 'jotai';
import { getUserId } from '../UserViewModel';
import { settingsEditProfileDialogAtom } from '@/Atoms/Settings';
import { updateLanguage, updateNickname } from '@/Queries/UserQueries';
import { TFunction } from 'i18next';
import snackbarAtom from '@/Atoms/Snackbar';
import { userAtom } from '@/Atoms/UserAtom';
import i18n from '@/Locales/i18n';
import { getAliceByLanguageAtom } from '../ChatViewModel';
import { handleReactQueryApiResponse } from '@/Utils/APIUtil';
import { error401ModalAtom } from '@/Atoms/Dialogs/Error/401Atom';

// open settingsEditProfileDialogAtom
export const settingsEditProfileDialogOpenAtom = atom(null, (get, set, key: string,) => {
  const userId = get(getUserId);
  set(settingsEditProfileDialogAtom, { userId, key, value: "" });
})

// save settingsEditProfileDialogAtom
export const settingsEditProfileDialogSaveAtom = atom(null, async (get, set, t: TFunction) => {
  const dialog = get(settingsEditProfileDialogAtom);
  if (!dialog) return;

  const user = get(userAtom);
  if (!user) return;
  const { userId, key, value } = dialog;
  if (key === "nickname") {
    // save nickname
    const response = await handleReactQueryApiResponse(updateNickname, () => set(error401ModalAtom, true), userId, value);
    if (!response.ok) {
      set(snackbarAtom, (prev) => ({ ...prev, open: true, message: "닉네임 변경에 실패했습니다." }));
    } else {
      set(snackbarAtom, (prev) => ({ ...prev, open: true, message: "닉네임이 변경되었습니다." }));
      const newUser = { ...user, nickname: value };
      set(userAtom, newUser);
    }
  } else if (key === "language") {
    const response = await handleReactQueryApiResponse(updateLanguage, () => set(error401ModalAtom, true), userId, value);
    if (!response.ok) {
      set(snackbarAtom, (prev) => ({ ...prev, open: true, message: "언어 변경에 실패했습니다." }));
    } else {
      set(snackbarAtom, (prev) => ({ ...prev, open: true, message: "언어가 변경되었습니다." }));
      const newUser = { ...user, language: value };
      set(userAtom, newUser);
      if (value === "KR") {
        i18n.changeLanguage("ko");
      } else {
        i18n.changeLanguage("en");
      }
      set(getAliceByLanguageAtom, value);
    }
  }
  set(settingsEditProfileDialogAtom, null);
})

// change settingsEditProfileDialogAtom value
export const settingsEditProfileDialogChangeValueAtom = atom(null, (get, set, value: string) => {
  const dialog = get(settingsEditProfileDialogAtom);
  if (!dialog) return;
  set(settingsEditProfileDialogAtom, { ...dialog, value });
})
