import { nodesAtom, selectedNodeAtom } from '@/Atoms/Plan/MindmapAtom';
import { getNodesBounds, getViewportForBounds, NodeResizer, NodeToolbar } from '@xyflow/react';
import { useAtomValue, useSetAtom } from 'jotai';
import React, { memo, useRef } from 'react';
import theme from '@/Styles/theme';
import { TextField } from '@mui/material';
import { changeGroupLabelAtom, deleteGroupNodeAtom } from '@/ViewModels/Plan/Mindmap/GroupViewModel';
import { toPng } from 'html-to-image';
import CustomBackgroundColorButton from '@/Components/Common/Buttons/CustomBackgroundColor';
import { devConsoleError } from '@/Utils/ConsoleLogInDevelopment';
import useIsMobile from '@/Hooks/useIsMobile';

function downloadImage(dataUrl: string) {
  const a = document.createElement('a');

  a.setAttribute('download', 'reactflow.png');
  a.setAttribute('href', dataUrl);
  a.click();
}

const Group = (data: any) => {
  const { isMobile } = useIsMobile();
  const selectedNode = useAtomValue(selectedNodeAtom);
  const [isEdit, setIsEdit] = React.useState(false);
  const editLabel = useSetAtom(changeGroupLabelAtom);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const groupRef = useRef<HTMLDivElement>(null);  // 그룹 영역을 참조하는 ref
  const nodes = useAtomValue(nodesAtom);
  const handleDeleteGroup = useSetAtom(deleteGroupNodeAtom);
  const [groupName, setGroupName] = React.useState<string>(data.data.label);

  const handleLabelClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const handleLabelDoubleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsEdit(true);
    setGroupName(data.data.label);
  };

  const handlePrint = () => {
    const relatedNodes = nodes.filter((node) => node.id === data.id);
    const nodesBounds = getNodesBounds(relatedNodes);
    const viewport = getViewportForBounds(nodesBounds, data.width, data.height, 0.5, 2, 0.3);
    const viewportElement = document.querySelector('.react-flow__viewport');

    if (viewportElement) {
      toPng(viewportElement as HTMLElement, {
        backgroundColor: '#ececec',
        width: data.width,
        height: data.height,
        style: {
          width: `${data.width}px`,
          height: `${data.height}px`,
          transform: `translate(${viewport.x}px, ${viewport.y}px) scale(${viewport.zoom})`,
        },
      }).then((dataUrl) => {
        // 새 창에서 이미지를 열고 바로 인쇄
        const printWindow = window.open('', '_blank');
        if (printWindow) {
          printWindow.document.write(`
            <html>
              <head>
                <title>${data.data.label} 출력</title>
                <style>
                  body, html { margin: 0; padding: 0; display: flex; justify-content: center; align-items: center; height: 100%; }
                </style>
              </head>
              <body>
                <img src="${dataUrl}" style="max-width: 100%; max-height: 100%;" />
                <script>
                  window.onload = function() {
                    window.print();
                    window.onafterprint = function() {
                      window.close();
                    };
                  };
                </script>
              </body>
            </html>
          `);
          printWindow.document.close();
        }
      }).catch((error) => {
        devConsoleError("Error generating image for print:", error);
      });
    } else {
      devConsoleError("Viewport element not found");
    }
  };

  const handleDownloadImage = () => {
    const relatedNodes = nodes.filter((node) => node.id === data.id);
    const nodesBounds = getNodesBounds(relatedNodes);
    const viewport = getViewportForBounds(nodesBounds, data.width, data.height, 0.5, 2, 0.3);
    const viewportElement = document.querySelector('.react-flow__viewport');

    if (viewportElement) {
      // html-to-image를 사용하여 이미지를 생성
      toPng(viewportElement as HTMLElement, {
        backgroundColor: '#ececec',
        width: data.width,
        height: data.height,
        style: {
          width: data.width.toString() + 'px',
          height: data.height.toString() + 'px',
          transform: `translate(${viewport.x}px, ${viewport.y}px) scale(${viewport.zoom})`,
        },
      }).then(downloadImage);
    } else {
      devConsoleError("Viewport element not found");
    }
  };

  React.useEffect(() => {
    if (isEdit && inputRef.current) {
      inputRef.current?.focus();
      inputRef.current?.select();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

  return (
    <div ref={groupRef} id={data.id} style={{ width: data.width, height: data.height, display: "flex" }}>
      {/* NodeToolbar를 사용할 때 여기에 추가 */}
      <NodeToolbar isVisible={selectedNode?.id === data.id} style={{ display: "flex", gap: "10px" }}>
        {
          isMobile &&
          <CustomBackgroundColorButton onClick={() => handleDeleteGroup(data.id)} text='삭제' />
        }
        <CustomBackgroundColorButton onClick={handleDownloadImage} text="그룹 캡쳐" />
        <CustomBackgroundColorButton onClick={handlePrint} text="그룹 출력" />
        {/* <CustomBackgroundColorButton onClick={() => devConsoleLog("group delete")} text="그룹 삭제" /> */}
      </NodeToolbar>
      <NodeResizer
        isVisible={selectedNode?.id === data.id}
        minWidth={600}
        minHeight={400}
        handleStyle={{
          width: "16px",
          height: "16px",
          backgroundColor: theme.colors.primary,
        }}
      />
      {isEdit ? (
        <TextField
          value={groupName}
          // onChange={(e) => editLabel(data.id, e.target.value)}
          onChange={(e) => setGroupName(e.target.value)}
          onBlur={() => {
            editLabel(data.id, groupName);
            setIsEdit(false);
          }}
          inputRef={inputRef}
          variant="standard"
          InputProps={{
            style: {
              fontSize: "1.5rem",        // 폰트 크기 설정
              padding: "4px 10px",        // div와 일치하도록 padding 설정
              fontWeight: "bold",        // 폰트 굵기 설정
            },
          }}
          sx={{
            width: "fit-content",      // div와 동일한 너비
            fontSize: "1.5rem",          // 외부에서도 폰트 크기 설정
            fontWeight: "bold",        // 폰트 굵기 설정
          }}
        />
      ) : (
        <div>
          <div
            onClick={handleLabelClick}
            onDoubleClick={handleLabelDoubleClick}
            style={{
              fontSize: "1.5rem",
              padding: "4px 10px",
              backgroundColor: "white",
              border: "1px solid black",
              fontWeight: "bold",
              borderRadius: "5px",
            }}
          >
            {data.data.label}
          </div>
        </div>
      )}
    </div>
  );
}

export default memo(Group);
