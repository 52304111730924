import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import * as S from "./Components/Root/styled";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import {
  checkCookieAndAutoLoginAtom,
  initializeLandingAppAtom,
} from "./ViewModels/RootViewModel";
import { userAtom } from "./Atoms/UserAtom";
import { Error } from "./Pages/Error";
import SuccessModal from "./Components/Root/Modal/SuccessModal";
import ErrorModal from "./Components/Root/Modal/ErrorModal";
import WaitingModal from "./Components/Root/Modal/WaitingModal";
import NoticeModal from "./Components/Root/Modal/NoticeModal";
import Sidebar from "./Components/Root/Sidebar";
import useIsMobile from "./Hooks/useIsMobile";
import { FloatingChatbotButton } from "./Components/Root/FloatingChatbotButton";
import CustomSnackbar from "./Components/Common/Snackbar";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isBetween from "dayjs/plugin/isBetween";
import isoWeek from "dayjs/plugin/isoWeek";
import weekOfYear from "dayjs/plugin/weekOfYear";
import dayjs from "dayjs";
import Chat from "./Components/Chat";
import TogetherDialog from "./Components/Common/Dialogs/Together";
import ColorPickerDialog from "./Components/Common/Dialogs/ColorPicker";
import TextEditorDialog from "./Components/Common/Dialogs/TextEditor";
import SignatureDialog from "./Components/Signature/Dialogs";
import TextEditorForCreateAndEditDialog from "./Components/Common/Dialogs/TextEditor/createAndEditDialog";
import useRoot from "./Hooks/useRoot";
import { waitingModalAtom } from "./Atoms/RootAtom";
import { accordionSidebarAtom } from "./Atoms/SidebarAtom";
import NotificationPopover from "./Components/Root/Popover/Notification";
import useNotification from "./Hooks/useNotification";
import Loading from "./Pages/Loading";
import Error401Modal from "./Components/Root/Modal/Error401";
import DokgabiChat from "./Components/DokgabiChat";

// 하위 컴포넌트에서 사용할 수 있도록 전역으로 선언
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(isBetween);
dayjs.extend(weekOfYear);
dayjs.extend(isoWeek);

const Dialogs = () => {
  return (
    <>
      <SuccessModal />
      <ErrorModal />
      <Error401Modal />
      <WaitingModal />
      <NoticeModal />
      <TogetherDialog />
      <FloatingChatbotButton />
      <ColorPickerDialog />
      <Chat />
      <TextEditorDialog />
      <TextEditorForCreateAndEditDialog />
      <SignatureDialog />
      <NotificationPopover />
      <DokgabiChat />
    </>
  );
};

function Root() {
  const [user] = useAtom(userAtom);
  const { isMobile } = useIsMobile();
  const sidebar = useAtomValue(accordionSidebarAtom);
  const [, checkCookieAndAutoLogin] = useAtom(
    checkCookieAndAutoLoginAtom
  );
  const [, initializeLandingApp] = useAtom(initializeLandingAppAtom);
  const { isLoading: isRootLoading } = useRoot();
  const setWaitingModal = useSetAtom(waitingModalAtom);
  const { isLoading: isNotificationLoading } = useNotification();
  const [countOfLoginTrial, setCountOfLoginTrial] = React.useState(0);

  useEffect(() => {
    setWaitingModal({ state: isRootLoading || isNotificationLoading, text: "로딩중입니다." });
  }, [isRootLoading, isNotificationLoading, setWaitingModal]);

  // 첫 로딩 시 쿠키 확인 후 자동 로그인
  useEffect(() => {
    if (!user && countOfLoginTrial < 2) {
      const retryInterval = setInterval(() => {
        setCountOfLoginTrial((prev) => {
          if (prev >= 2) {
            clearInterval(retryInterval); // 최대 시도 횟수 도달 시 인터벌 종료
            return prev;
          }
          checkCookieAndAutoLogin();
          initializeLandingApp();
          return prev + 1;
        });
      }, 2000);

      return () => clearInterval(retryInterval); // 컴포넌트 언마운트 시 인터벌 정리
    }
  }, [user, countOfLoginTrial, checkCookieAndAutoLogin, initializeLandingApp]);

  return (
    <S.FullContainer>
      {/* <div id="google_translate_element" style={{ display: "none" }}></div> */}
      {user ? (
        <>
          {/* <Header /> */}
          <Sidebar />
          <CustomSnackbar />
          <Dialogs />
          <S.ContentContainer $isMobile={isMobile} $sidebar={sidebar}>
            <Outlet />
          </S.ContentContainer>
        </>
      ) : (
        countOfLoginTrial > 1 ? (
          <Error />
        ) : (
          <Loading />
        )
      )}
    </S.FullContainer>
  );
}

export default Root;
