import { Button, Switch } from '@mui/material';
import styled from 'styled-components';
import { alpha, styled as muiStyled } from '@mui/system';
import theme from '@/Styles/theme';

export const Container = styled.div`
  position: relative;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  gap: 10px;
  display: flex;
  padding: 20px;
  flex-direction: column;
  overflow: hidden;
`;

export const GanttTable = styled.div`
  display: table;
  border-bottom: #e6e4e4 1px solid;
  border-top: #e6e4e4 1px solid;
  border-left: #e6e4e4 1px solid;
`;

export const GanttTableHeader = styled.div`
  display: table-row;
  list-style: none;
`;

export const GanttTableHeaderItem = styled.div`
  display: table-cell;
  vertical-align: middle;
  font-size: 1rem;
  text-align: center;
`;

export const GanttTableHeaderSeparator = styled.div`
  border-right: 1px solid rgb(196, 196, 196);
  opacity: 1;
  margin-left: -2px;
`;

export const TaskListWrapper = styled.div`
  display: table;
  border-bottom: #e6e4e4 1px solid;
  border-left: #e6e4e4 1px solid;
`;

export const TaskListTableRow = styled.div`
  display: table-row;
  text-overflow: ellipsis;

  &:nth-of-type(even) {
    background-color: #f5f5f5;
  }
`;

export const TaskListCell = styled.div`
  display: table-cell;
  vertical-align: middle;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const TaskListNameWrapper = styled.div`
  display: flex;
`;

export const TaskListExpander = styled.div`
  color: rgb(86 86 86);
  font-size: 0.6rem;
  padding: 0.15rem 0.2rem 0rem 0.2rem;
  user-select: none;
  cursor: pointer;
`;

export const TaskListEmptyExpander = styled.div`
  font-size: 0.6rem;
  padding-left: 1rem;
  user-select: none;
`;

export const PrimaryColorSwitch = muiStyled(Switch)(() => {
  return {
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: theme.colors.primary,
      '&:hover': {
        backgroundColor: alpha(theme.colors.primary, 0.1),
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: theme.colors.primary,
    },
  };
});

export const PrimaryColorButton = muiStyled(Button)(() => {
  return {
    color: 'white',
    backgroundColor: theme.colors.primary,
    '&:hover': {
      backgroundColor: theme.colors.primary,
    },
  }
});

export const TooltipDefaultContainer = styled.div`
  background: #fff;
  padding: 12px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
`;

export const TooltipDefaultContainerParagraph = styled.p`
  font-size: 12px;
  margin-bottom: 6px;
  color: #666;
`;

export const TooltipDetailsContainer = styled.div`
  position: absolute;
  display: flex;
  flex-shrink: 0;
  pointer-events: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const TooltipDetailsContainerHidden = styled.div`
  visibility: hidden;
  position: absolute;
  display: flex;
  pointer-events: none;
`;

export const StoryItem = styled.div<{ $isMobile: boolean }>`
 width: ${({ $isMobile }) => ($isMobile ? "calc(95% - 40px)" : "calc(100% - 40px)")};
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: ${({ $isMobile }) => ($isMobile ? "6px" : "12px")};
  background-color: white;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const StoryTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #e0e0e0;
`;

export const StoryTitle = styled.div`
  width: 95%;
  font-weight: 600;
  font-size: 0.9rem;
`;

export const StoryBottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const TagInputContainer = styled.div`
  display: flex;
  width: 80%;
  flex-wrap: wrap;
  gap: 0.5em;
  padding: 0.4rem 1rem;
  border-bottom: 1px solid black;
`;

export const TagItem = styled.div`
  display: inline-block;
  padding: 0.2em 0.5em;
  border-radius: 20px;
  color: white;
`;

export const TagInput = styled.input`
  flex-grow: 1;
  min-width: 30px;
  max-width: 150px;
  padding: 0.5em 0;
  border: none;
  outline: none;
  font-family: "Noto Sans KR", sans-serif;
`;
