import { atom } from "jotai";

export const useRootAtom = atom(true);
export const usePlansAtom = atom(true);
export const usePlanDataAtom = atom(true);
export const useDefaultPlanDataAtom = atom(true);
export const usePlanHipAtom = atom(true);
export const useCrewAtom = atom(true);
export const useCrewLectureAtom = atom(true);
export const useMailAtom = atom(true);
export const useMenteeDashboardAtom = atom(true);
export const useTeacherDashboardAtom = atom(true);
export const useNotificationAtom = atom(true);
export const useHelperAtom = atom(true);
