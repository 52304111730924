import { drawerSidebarAtom, planToggleAtom } from '@/Atoms/SidebarAtom';
import { Drawer } from '@mui/material';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import React from 'react';
import * as S from './styled';
import { getUserImage, getUserMembershipAtom, getUserNickname } from '@/ViewModels/UserViewModel';
import useIsMobile from '@/Hooks/useIsMobile';
import planAtom from '@/Atoms/Plan';
import { useLocation, useNavigate } from 'react-router-dom';
import theme from '@/Styles/theme';
import { createNewPlanAtom } from '@/ViewModels/Plan/PlanViewModel';
import { editPlanNameDialogOpenAtom } from '@/ViewModels/Dialogs/Edit/PlanNameViewModel';
import { planHeaderChangeViewTypeAtom } from '@/ViewModels/Plan/HeaderViewModel';
import GetIdFromQuerystring from '@/Utils/GetIdFromQuerystring';
import TopSectionButtons from './topSectionButtons';
import BottomSectionButtons from './bottomSectionButtons';
import { languageAtom } from '@/Atoms/RootAtom';
import { useTranslation } from 'react-i18next';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import { openSignatureDialogAtom } from '@/ViewModels/Signature/ViewModel';

const MobileDrawer = () => {
  const { isMobile } = useIsMobile();
  const [drawerOpen, setDrawerOpen] = useAtom(drawerSidebarAtom);
  const userImage = useAtomValue(getUserImage);
  const userNickname = useAtomValue(getUserNickname);
  const plan = useAtomValue(planAtom);
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const createPlan = useSetAtom(createNewPlanAtom);
  const isOpenEditPlanNameDialog = useSetAtom(editPlanNameDialogOpenAtom);
  const changeViewType = useSetAtom(planHeaderChangeViewTypeAtom);
  const topSectionButtons = TopSectionButtons();
  const bottomSectionButtons = BottomSectionButtons();
  const planToggle = useAtomValue(planToggleAtom);
  const language = useAtomValue(languageAtom);
  const { t } = useTranslation();
  const userMembership = useAtomValue(getUserMembershipAtom);
  const openSignatureDialog = useSetAtom(openSignatureDialogAtom);

  const handleOpenSignatureDialog = () => {
    openSignatureDialog();
    setDrawerOpen(false);
  }

  return (
    <Drawer
      anchor="left"
      open={drawerOpen}
      onClose={() => setDrawerOpen(false)}
    >
      <S.MobileDrawer>
        <S.MobileTopContainer $membership={userMembership}>
          <S.MobileProfileContainer>
            <S.ProfileImage
              src={userImage}
              alt="profile"
              $isMobile={isMobile}
            />
            <S.MobileProfileInfo>
              <div style={{ fontSize: "1rem" }}>{userNickname}</div>
              <div style={{ fontSize: "0.8rem", color: "#555" }}>
                {t(`sidebar.elements.${userMembership}`)}
              </div>
            </S.MobileProfileInfo>
            <BadgeOutlinedIcon sx={{ position: "absolute", right: "20px" }} onClick={handleOpenSignatureDialog} />
          </S.MobileProfileContainer>
          <S.TabContainer style={{ height: "calc(100% - 51px)", overflowY: "auto" }}>
            {topSectionButtons.slice(1).map((tab, index) => (
              <S.MobileTab
                key={index}
                onClick={tab.onClick}
                $active={currentPath === tab.path}
              >
                {tab.name}
                {tab.dropdown &&
                  (planToggle
                    ? tab.dropdown.open
                    : tab.dropdown.close)}
              </S.MobileTab>
            ))}
            {planToggle && (
              <>
                <div
                  onClick={() => createPlan(navigate)}
                  style={{
                    display: "flex",
                    width: "90%",
                    minHeight: "40px",
                    fontSize: "0.9rem",
                    backgroundColor: theme.colors.primary,
                    color: "white",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                >
                  {t("sidebar.buttons.createPlan")}
                </div>
                {plan.map((tab, index) => (
                  <S.Tab
                    key={`plan-${index}`}
                    onClick={() => {
                      navigate(`/plan?id=${tab.id}&inner_tab_id=${tab.innerTabs[0].id}`);
                      changeViewType("mindmap");
                      isOpenEditPlanNameDialog();
                    }}
                    $active={GetIdFromQuerystring("id") === tab.id}
                    style={{ width: "calc(100% - 80px)", padding: "0px 40px", fontSize: "0.8rem" }}
                  >
                    {tab.name ? tab.name : language === "KR" ? "새 플랜" : "New Plan"}
                  </S.Tab>
                ))}
              </>
            )}
          </S.TabContainer>
        </S.MobileTopContainer>
        <S.TabContainer style={{ borderTop: "1px solid #d9d9d9" }}>
          {bottomSectionButtons.map((button, index) => (
            button &&
            <S.MobileTab key={index} onClick={button.onClick} $active={false}>
              {button.tooltipTitle}
            </S.MobileTab>
          ))}
        </S.TabContainer>
      </S.MobileDrawer>
    </Drawer>
  )
}

export default MobileDrawer;
