import { initialResetPasswordModalInfo, settingsResetPasswordModalAtom } from '@/Atoms/Settings';
import { atom } from 'jotai';
import { getUserEmailAtom } from '../UserViewModel';
import { login, resetPassword } from '@/Queries/AuthQueries';
import snackbarAtom from '@/Atoms/Snackbar';

const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+[\]{};':"\\|,.<>/?]).{8,}$/;

export const ResetPasswordModalOpenAtom = atom(null, (_get, set) => {
  set(settingsResetPasswordModalAtom, initialResetPasswordModalInfo);
});

export const ResetPasswordModalCurrentPasswordAuthAtom = atom(null, async (get, set) => {
  const modal = get(settingsResetPasswordModalAtom);
  if (!modal) return;
  const currentPassword = modal.currentPassword;
  const response = await login(get(getUserEmailAtom), currentPassword);
  if (response.data.results === 1) {
    set(settingsResetPasswordModalAtom, {
      ...modal,
      isCorrectPassword: true,
    });
    set(snackbarAtom, { open: true, message: "비밀번호가 확인되었습니다.", severity: "success" });
  } else {
    set(snackbarAtom, { open: true, message: "비밀번호가 일치하지 않습니다.", severity: "error" });
  }
});

export const ResetPasswordModalNewPasswordAuthAtom = atom((get) => {
  const modal = get(settingsResetPasswordModalAtom);
  if (!modal) return;
  const newPassword = modal.newPassword;
  return passwordRegex.test(newPassword) && newPassword !== modal.currentPassword;
});

export const ResetPasswordModalNewPasswordConfirmAuthAtom = atom((get) => {
  const modal = get(settingsResetPasswordModalAtom);
  if (!modal) return;
  const newPassword = modal.newPassword;
  const confirmPassword = modal.newPasswordConfirm;
  return get(ResetPasswordModalNewPasswordAuthAtom) && newPassword === confirmPassword;
});

export const ResetPasswordModalSubmitAtom = atom(null, async (get, set) => {
  const modal = get(settingsResetPasswordModalAtom);
  if (!modal) return;
  if (!get(ResetPasswordModalNewPasswordConfirmAuthAtom)) {
    set(snackbarAtom, { open: true, message: "비밀번호가 일치하지 않습니다.", severity: "error" });
    return;
  }
  const response = await resetPassword(get(getUserEmailAtom), modal.newPassword);
  if (response.status === 200) {
    set(settingsResetPasswordModalAtom, null);
    set(snackbarAtom, { open: true, message: "비밀번호가 성공적으로 변경되었습니다.", severity: "success" });
  } else {
    set(snackbarAtom, { open: true, message: "비밀번호 변경에 실패했습니다.", severity: "error" });
  }
});
