import { atom } from 'jotai';

interface ApplyLectureDialog {
  id: number;
  isAbleToCancel: boolean;
}

const applyLectureDialogAtom = atom<ApplyLectureDialog | null>(null);

export default applyLectureDialogAtom;
