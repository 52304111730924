// Parse Crew response to PlanHipCrew
export const parseCrewInPlanHip = (crews: any[]) => crews.map((crew: any) => ({
  crewId: crew.crew_id,
  innerTabId: crew.innerTabId,
  name: crew.name,
  content: crew.content,
  headCount: crew.headcount,
  current: crew.current,
  hashtags: crew.hashtags,
  imageUrl: crew.image_url,
  color: crew.color,
  category: crew.category,
}));

// 플랜힙에서 사용하는 크루 조회 api (criteria - 0: 최신순 1: 오래된 순 2: 현재 인원 많은 순 3: 현재 인원 적은 순)
export const getPlanHipCrews = async (access: string, userId: number, criteria: number) => {
  const response = await fetch(`${process.env.REACT_APP_CREW_ADDRESS}/index?user_id=${userId}&criteria=${criteria}`, {
    method: "GET",
    headers: {
      "accept": "application/json",
      "Authorization": `Bearer ${access}`,
    },
  });
  return response;
}
