import React from "react";
import { useAtomValue } from "jotai";
import * as S from "./styled";
import { GroupAdmin } from "./GroupAdmin";
import { ApiAdmin } from "./APIAdmin";
import { API } from "./API";
import { useNavigate } from "react-router-dom";
import useIsMobile from "@/Hooks/useIsMobile";
import TotalCareerProgress from "./TotalCareerProgress";
import { MentoringUsingProgress } from "./MentoringUsingProgress";
import CustomColorButton from "@/Components/Common/Buttons/CustomColor";
import { useTranslation } from "react-i18next";
import { getUserNickname } from "@/ViewModels/UserViewModel";
import DashboardSignatures from "./Signatures";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

const TeacherDashboard = () => {
  const { isMobile } = useIsMobile();
  const userNickname = useAtomValue(getUserNickname);
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    !isMobile ?
      <>
        <div style={{ display: "flex", flexDirection: "column", gap: "10px", position: "relative" }}>
          <S.WelcomeMessageTypo>
            {t("dashboard.teacher.header.greeting", { nickname: userNickname })}
          </S.WelcomeMessageTypo>
          <div style={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center" }}>
            <S.WelcomeMessageTypo>{t("dashboard.teacher.header.sentence")}</S.WelcomeMessageTypo>
          </div>
          {/* <CustomColorButton text={t("dashboard.teacher.header.sendMail")} onClick={() => navigate("/mail")} style={{ position: "absolute", right: "0px", top: "50%" }} /> */}
        </div>

        {/* Responsive Grid Section */}
        <Grid container spacing={2} sx={{ width: "100%", height: "auto" }}>
          {/* Left Column */}
          <Grid md={12} lg={6} sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
            <DashboardSignatures />
            <GroupAdmin />
          </Grid>

          {/* Right Column */}
          <Grid md={12} lg={6} sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
            <TotalCareerProgress />
            <MentoringUsingProgress />
          </Grid>
        </Grid>
      </> : <>
        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <S.WelcomeMessageTypo>
            {t("dashboard.teacher.header.greeting", { nickname: userNickname })}
          </S.WelcomeMessageTypo>
          <S.WelcomeMessageTypo>
            {t("dashboard.teacher.header.sentence")}
          </S.WelcomeMessageTypo>
          <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
            <CustomColorButton text={t("dashboard.teacher.header.sendMail")} onClick={() => navigate("/mail")} />
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "10px", height: "auto" }}>
          <DashboardSignatures />
          <API />
          <GroupAdmin />
          <ApiAdmin />
          <MentoringUsingProgress />
        </div>
      </>
  );
};

export default TeacherDashboard;
