import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";

export interface DataPoint {
  name: string;
  size: number;
  x: number;
  y: number;
}

export interface ScatterPlotProps {
  data: DataPoint[];
  color: string;
  xDesc: string;
  yDesc: string;
  xMin: number;
  xMax: number;
  yMin: number;
  yMax: number;
}

export function ScatterPlot({
  data,
  color,
  xDesc,
  yDesc,
  xMin,
  xMax,
  yMin,
  yMax,
}: ScatterPlotProps) {
  const svgRef = useRef<SVGSVGElement | null>(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const resizeObserverRef = useRef<ResizeObserver | null>(null);

  useEffect(() => {
    const updateDimensions = () => {
      if (svgRef.current && svgRef.current.parentElement) {
        const parent = svgRef.current.parentElement;
        const { width, height } = parent.getBoundingClientRect();
        setDimensions({ width, height });
      }
    };

    if (!resizeObserverRef.current && svgRef.current) {
      resizeObserverRef.current = new ResizeObserver(() => {
        updateDimensions();
      });
      resizeObserverRef.current.observe(svgRef.current.parentElement!);
    }

    return () => {
      if (resizeObserverRef.current) {
        resizeObserverRef.current.disconnect();
        resizeObserverRef.current = null;
      }
    };
  }, [svgRef]);

  useEffect(() => {
    if (svgRef.current && dimensions.width && dimensions.height) {
      const svgElement = svgRef.current;
      const width = dimensions.width;
      const height = dimensions.height;
      const margin = { top: 10, right: 50, bottom: 40, left: 50 };
      const padding = 0;

      const svg = d3.select(svgElement);
      svg.selectAll("*").remove();

      // X축과 Y축의 스케일 설정 (xMin, xMax, yMin, yMax 사용)
      const x = d3
        .scaleLinear()
        .domain([xMin, xMax])
        .range([margin.left, width - margin.right]);

      const y = d3
        .scaleLinear()
        .domain([yMin, yMax])
        .range([height - margin.bottom, margin.top]);

      // size의 최소값과 최대값 구하기
      const sizeMin = 0;
      const sizeMax = d3.max(data, (d) => d.size) ?? 1;

      // size를 정규화하는 스케일 (0~100%로 변환)
      const sizeScale = d3
        .scaleLinear()
        .domain([sizeMin, sizeMax])
        .range([0, 100]);

      // 툴팁 요소 생성
      const tooltip = d3
        .select(svgElement.parentElement)
        .append("div")
        .style("position", "absolute")
        .style("background-color", "white")
        .style("border", "1px solid #d3d3d3")
        .style("padding", "8px")
        .style("border-radius", "5px")
        .style("box-shadow", "0 2px 4px rgba(0,0,0,0.1)")
        .style("pointer-events", "none")
        .style("opacity", 0);

      // 그리드 설정
      const makeXGridlines = () => d3.axisBottom(x).ticks(5);
      const makeYGridlines = () => d3.axisLeft(y).ticks(5);

      // Y축 그리드 그리기
      svg
        .append("g")
        .attr("class", "grid")
        .attr("transform", `translate(${margin.left}, 0)`)
        .call(
          makeYGridlines()
            .tickSize(-width + margin.left + margin.right)
            .tickFormat(() => "")
        );

      // X축 그리기
      svg
        .append("g")
        .attr("transform", `translate(0, ${height - margin.bottom})`)
        .call(d3.axisBottom(x).ticks(5));

      // Y축 그리기
      svg
        .append("g")
        .attr("transform", `translate(${margin.left}, 0)`)
        .call(d3.axisLeft(y).ticks(5));

      // X축 설명 텍스트
      svg
        .append("text")
        .attr("text-anchor", "middle")
        .attr("x", width / 2)
        .attr("y", height - margin.bottom + 35)
        .attr("font-family", "NanumSquareNeoBrg")
        .attr("font-size", "14px")
        .text(xDesc);

      // Y축 설명 텍스트
      svg
        .append("text")
        .attr("text-anchor", "middle")
        .attr("x", -(height / 2))
        .attr("y", margin.left - 40)
        .attr("transform", "rotate(-90)")
        .attr("font-family", "NanumSquareNeoBrg")
        .attr("font-size", "14px")
        .text(yDesc);

      // 데이터 포인트 그리기 (이름 표시 및 패딩 적용)
      svg
        .selectAll("circle")
        .data(data)
        .enter()
        .append("circle")
        .attr("cx", (d) => x(d.x))
        .attr("cy", (d) => y(d.y))
        .attr("r", (d) => sizeScale(d.size) / 4)
        .attr("fill", color)
        .attr("stroke", "white")
      // .on("mouseover", (event, d) => {
      //   tooltip.transition().duration(200).style("opacity", 1);
      //   tooltip
      //     .html(`Name: ${d.name}<br>Size: ${d.size}<br>X: ${d.x}<br>Y: ${d.y}`)
      //     .style("left", event.clientX + "px") // 커서와 약간의 거리 유지
      //     .style("top", event.clientY + "px"); // 커서와 약간의 거리 유지
      // })
      // .on("mousemove", (event) => {
      //   tooltip
      //     .style("left", event.clientX - 50 + "px")
      //     .style("top", event.clientY - 50 + "px");
      // })
      // .on("mouseout", () => {
      //   tooltip.transition().duration(500).style("opacity", 0);
      // });

      // circle 안에 name 텍스트 넣기
      svg
        .selectAll("text.data-label")
        .data(data)
        .enter()
        .append("text")
        .attr("class", "data-label")
        .attr("x", (d) => x(d.x))
        .attr("y", (d) => y(d.y) + d.size / 4) // 텍스트 위치를 size에 맞춰 조정
        .attr("text-anchor", "middle")
        .attr("font-family", "NanumSquareNeoBrg")
        .attr("font-size", (d) => Math.min(d.size, 12)) // size에 따라 텍스트 크기 조정
        .attr("fill", "white") // 텍스트 색상
        .text((d) => d.name);

      // 그리드 스타일 설정
      svg
        .selectAll(".grid line")
        .style("stroke", "lightgrey")
        .style("stroke-dasharray", "3 3");
      svg.selectAll(".domain").style("display", "none");
    }
  }, [dimensions, data, xMin, xMax, yMin, yMax]);

  return <svg ref={svgRef} style={{ width: "100%", height: "100%" }}></svg>;
}
