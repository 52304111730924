import React, { useEffect } from 'react';
import { AppBar, Box, Button, Divider, IconButton, TextField, Tooltip, Typography } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CloseIcon from '@mui/icons-material/Close';
import { useAtomValue, useSetAtom } from 'jotai';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CancelIcon from '@mui/icons-material/Cancel';
import { chatDialogAtom } from '@/Atoms/ChatAtom';
import { getUserImage } from '@/ViewModels/UserViewModel';
import { mentorListAtom } from '@/Atoms/MentorAtom';
import theme from '@/Styles/theme';
import CustomBackgroundColorButton from '@/Components/Common/Buttons/CustomBackgroundColor';
import { devConsoleLog } from '@/Utils/ConsoleLogInDevelopment';

interface CreateChatRoomProps {
  isChatListOpen: boolean;
  setIsChatListOpen: React.Dispatch<React.SetStateAction<boolean>>;
  createChatRoom: (selectedMentorList: number[]) => void;
}

const CreateChatRoom = ({ isChatListOpen, setIsChatListOpen, createChatRoom }: CreateChatRoomProps) => {
  const setChatDialogOpen = useSetAtom(chatDialogAtom);
  const userImage = useAtomValue(getUserImage);
  const handleSidebarToggle = () => {
    setIsChatListOpen(!isChatListOpen); // 사이드바 열고/닫기
  };
  const mentorList = useAtomValue(mentorListAtom);
  const [selectedMentorList, setSelectedMentorList] = React.useState<number[]>([]);
  const [searchText, setSearchText] = React.useState<string>('');

  const handleSelectMentor = (mentorId: number) => {
    setSelectedMentorList([...selectedMentorList, mentorId]);
  }

  const handleRemoveMentor = (mentorId: number) => {
    setSelectedMentorList(selectedMentorList.filter(id => id !== mentorId));
  };

  return (
    <Box sx={{
      display: "flex",
      flexDirection: "column",
      width: `${isChatListOpen ? "calc(100% - 300px)" : "100%"}`,
      padding: "0",
    }}>
      {/* 채팅창 헤더 */}
      <AppBar
        position="static"
        sx={{ backgroundColor: `${theme.colors.primary}`, height: "60px", display: "flex", flexDirection: "row", alignItems: "center", width: "100%", padding: "0 20px" }}
      >
        <IconButton
          edge="start"
          color="inherit"
          aria-label="toggle sidebar"
          onClick={handleSidebarToggle}
        >
          {isChatListOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          채팅 생성
        </Typography>
        <CloseIcon sx={{ cursor: "pointer", color: "white" }} onClick={() => setChatDialogOpen(false)} />
      </AppBar>
      <Box
        flexGrow={1}
        sx={{
          display: "flex",
          width: "calc(100% - 40px)",
          flexDirection: "column",
          // height: "calc(100% - 100px)",
          justifyContent: "space-between",
          padding: "20px",
          overflowY: "auto",
        }}
      >
        <Typography variant="h6" sx={{ fontSize: "1.1rem", fontWeight: "bold" }}>
          대화하고 싶은 AI 멘토 챗봇을 선택해주세요.
        </Typography>
        <Box display="flex" flexDirection="column" width="100%" gap={2}>
          <Typography variant="h6" sx={{ fontSize: "1.1rem", fontWeight: "bold" }}>
            챗봇 목록
          </Typography>
          <TextField label="챗봇 검색" variant="outlined" fullWidth value={searchText} onChange={(e) => setSearchText(e.target.value)} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              minHeight: "100px",
              maxHeight: "300px",
              overflowY: "auto",
              border: "1px solid lightgray",
              padding: "10px",
              gap: "10px",
            }}
          >
            {mentorList.filter((mentor) => selectedMentorList.indexOf(mentor.id) === -1).filter((mentor) => mentor.name.includes(searchText)).map((mentor, index) => (
              <div key={`mentor-${index}`}>
                <Box key={index} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" minHeight="80px">
                  {
                    mentor.character_image.file_path &&
                    <img src={`${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${mentor.character_image.file_path}`} alt="mentor" style={{ width: "70px", height: "70px", borderRadius: "50%" }} key={`image-${index}`} />
                  }
                  <Typography variant="body1" sx={{ fontSize: "1rem" }}
                    key={`name-${index}`}
                  >
                    {mentor.name}
                  </Typography>
                  <CustomBackgroundColorButton key={`select-${index}`} onClick={() => handleSelectMentor(mentor.id)} text='선택' />
                </Box>
                {index !== mentorList.length - 1 && <Divider key={`divider-${index}`} />}
              </div>
            ))}
            {searchText !== "" &&
              <>
                <Divider />
                <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" minHeight="80px">
                  <Typography variant="body1" sx={{ fontSize: "1rem" }}>
                    "{searchText}"에 대한 챗봇 요청
                  </Typography>
                  <CustomBackgroundColorButton onClick={() => devConsoleLog(searchText, "요청보내기")} text='요청' />
                </Box>
              </>
            }
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" width="100%">
          <Typography variant="h6" sx={{ fontSize: "1.1rem", fontWeight: "bold" }}>
            참여 인원
          </Typography>
          <Box
            display="flex"
            flexDirection="row"
            maxWidth="100%"
            minHeight="100px"
            sx={{
              overflowX: "auto",
            }}
            alignItems="center"
            gap={2}
          >
            <Tooltip title={<div style={{ fontSize: "1.1rem" }}>나</div>} arrow>
              <img src={userImage} alt="user" style={{ width: "70px", height: "70px", borderRadius: "50%" }} />
            </Tooltip>
            {
              selectedMentorList.map((mentorId, index) => {
                const mentor = mentorList.find((mentor) => mentor.id === mentorId);
                return (
                  <Box key={index} sx={{ position: 'relative', display: 'inline-block' }}>
                    {
                      mentor?.character_image.file_path &&
                      <Tooltip key={index} title={<div style={{ fontSize: "1.1rem" }}>{mentor?.name}</div>} arrow>
                        <img src={`${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${mentor?.character_image.file_path}`} alt="mentor" style={{ width: "70px", height: "70px", borderRadius: "50%" }} />
                      </Tooltip>
                    }
                    <IconButton
                      size="small"
                      sx={{
                        position: 'absolute',
                        top: -10,
                        right: -10,
                        color: 'black',
                        '&:hover': {
                          color: 'gray',
                        },
                        zIndex: 1,
                      }}
                      onClick={() => handleRemoveMentor(mentorId)}
                    >
                      <CancelIcon fontSize="small" />
                    </IconButton>
                  </Box>
                );
              })
            }
          </Box>
        </Box>
        <Box display="flex" justifyContent="flex-end" mt={2}>
          <CustomBackgroundColorButton onClick={() => createChatRoom(selectedMentorList)} text='채팅 생성' fontSize='1rem' />
        </Box>
      </Box>
    </Box>
  );
}

export default CreateChatRoom;
