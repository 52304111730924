import { atom } from "jotai";
import {
  chatDialogAtom,
  chatRoomListAtom,
  ChatRoomProps,
  createChatRoomSectionAtom,
  currentChatbotTextProgressAtom,
  currentChatRoomAtom,
  isChatbotTypingAtom,
  MessageType,
} from "../Atoms/ChatAtom";
import { getUserEmailAtom } from "./UserViewModel";
import { mentorListAtom, MentorProps } from "../Atoms/MentorAtom";
import { getJobInfo, getMentorList, requestChatbot } from "@/Queries/ChatbotQueries";
import createMindmapNodesAndEdges from "@/Utils/CreateMindmapNodesAndEdges";
import GetIdFromQuerystring from "@/Utils/GetIdFromQuerystring";
import { setInnerTabDataAtom, updatePlanDataAtom } from "./Plan/InnerTabViewModel";
import { edgesAtom, nodesAtom } from "@/Atoms/Plan/MindmapAtom";
import { devConsoleError, devConsoleLog } from "@/Utils/ConsoleLogInDevelopment";
import { handleReactQueryApiResponse, handleReactQueryApiResponseWithJson } from "@/Utils/APIUtil";
import { error401ModalAtom } from "@/Atoms/Dialogs/Error/401Atom";
import { getChatbotHistory } from "@/Queries/HelperQueries";

// 직업 마인드맵 생성 함수
export const createMindmapAtom = atom(null, async (get, set, job: string) => {
  // const result = await getJobInfo(job);
  const handleError401Modal = () => set(error401ModalAtom, true);
  const result = await handleReactQueryApiResponseWithJson(getJobInfo, handleError401Modal, job);
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  if (innerTabId === 0) return;

  const newMap = {
    dream: "꿈을 입력해주세요",
    goal: "목표를 입력해주세요",
    job: job,
    tasks: result,
  };
  const { nodes, edges } = createMindmapNodesAndEdges(newMap);
  set(nodesAtom, nodes);
  set(edgesAtom, edges);
  set(setInnerTabDataAtom, { id: innerTabId, nodes, edges });
  set(updatePlanDataAtom, innerTabId, nodes, edges);
});

interface ChatbotHistoryProps {
  chattingUser: MessageType;
  date: string;
  image: string;
  name: string;
  text: string;
}

const parseChatHistory = (chatHistory: ChatbotHistoryProps[]) => {
  return chatHistory.map((chat, index) => {
    return {
      id: index,
      text: chat.text,
      type: chat.chattingUser,
      jobButtons: null,
      date: chat.date
    };
  });
}

// 언어 설정에 따라 다른 엘리스 챗봇을 불러오는 함수
export const getAliceByLanguageAtom = atom(null, async (get, set, language: string) => {
  const handleError401Modal = () => set(error401ModalAtom, true);
  try {
    const mentorList = await handleReactQueryApiResponse(getMentorList, handleError401Modal) as MentorProps[];
    set(mentorListAtom, mentorList);

    const chatRoomList = get(chatRoomListAtom);
    if (chatRoomList.length === 0) {
      const alice = mentorList.find((mentor) => mentor.chatbot_socket_parameter === (language === "KR" ?
        "5teaOUubVKvQpJXG5QnLptyozLbfDbzQmsYc" : "ONqsa1zpFSY1Xo7a6yWCUqXLSLLphoN2XmvF"));
      if (!alice) return;

      const result = await handleReactQueryApiResponse(
        getChatbotHistory,
        handleError401Modal,
        alice.id,
        get(getUserEmailAtom),
        1
      );
      devConsoleLog("챗봇 대화 내역", result);
      const parsedResult = parseChatHistory(result);

      const newChatRoom: ChatRoomProps = {
        id: 0,
        title: alice.name,
        chatbots: alice
          ? [
            {
              id: alice.id,
              name: alice.name,
              flow: alice.chatbot_socket_parameter,
              image: alice.character_image.file_path,
            },
          ]
          : [],
        messages: parsedResult || [],
        chatHistoryPageNum: 1,
      };

      set(currentChatbotTextProgressAtom, alice === undefined ? null : {
        id: alice.id,
        name: alice.name,
        flow: alice.chatbot_socket_parameter,
        image: alice.character_image.file_path,
      });

      set(chatRoomListAtom, [newChatRoom]);
      set(currentChatRoomAtom, newChatRoom);
    } else {
      set(chatRoomListAtom, [] as ChatRoomProps[]);
      const alice = mentorList.find((mentor) => mentor.chatbot_socket_parameter === (language === "KR" ? "ONqsa1zpFSY1Xo7a6yWCUqXLSLLphoN2XmvF" : "5teaOUubVKvQpJXG5QnLptyozLbfDbzQmsYc"));
      if (!alice) return;
      const result = await handleReactQueryApiResponse(
        getChatbotHistory,
        handleError401Modal,
        alice.id,
        get(getUserEmailAtom),
        1
      );

      const parsedResult = parseChatHistory(result);

      const newChatRoom: ChatRoomProps = {
        id: 0,
        title: alice.name,
        chatbots: alice
          ? [
            {
              id: alice.id,
              name: alice.name,
              flow: alice.chatbot_socket_parameter,
              image: alice.character_image.file_path,
            },
          ]
          : [],
        messages: parsedResult || [],
        chatHistoryPageNum: 1,
      };
      setTimeout(() => {
        set(chatRoomListAtom, [newChatRoom]);
        set(currentChatRoomAtom, newChatRoom);
      }, 10);
    }
  } catch (error) {
    devConsoleError("멘토 목록을 불러오지 못했습니다.", error);
    return [];
  }
});

// 챗봇 대화방을 생성하는 함수
export const createChatRoomAtom = atom(null, async (get, set, mentorIdList: number[]) => {
  const mentorList = get(mentorListAtom);
  const chatRoomList = get(chatRoomListAtom);
  const nextChatRoomId = chatRoomList.length;
  const selectedMentorList: MentorProps[] = mentorList.filter((mentor) =>
    mentorIdList.includes(mentor.id)
  );
  const newChatRoom: ChatRoomProps = {
    id: nextChatRoomId,
    title: selectedMentorList.map((mentor) => mentor.name).join(", "),
    chatbots: selectedMentorList.map((mentor) => ({
      id: mentor.id,
      name: mentor.name,
      flow: mentor.chatbot_socket_parameter,
      image: mentor.character_image.file_path,
    })),
    messages: [],
    chatHistoryPageNum: 1,
  };
  set(chatRoomListAtom, [...chatRoomList, newChatRoom]);
  set(currentChatRoomAtom, newChatRoom);
  set(createChatRoomSectionAtom, false);
});

// 챗봇 대화방 이동 함수
export const moveChatRoomAtom = atom(null, async (get, set, chatRoomId: number) => {
  const chatRoomList = get(chatRoomListAtom);
  const currentChatRoom = get(currentChatRoomAtom);
  if (currentChatRoom?.id === chatRoomId) return;
  const selectedChatRoom = chatRoomList.find((chatRoom) => chatRoom.id === chatRoomId);
  if (!selectedChatRoom) return;
  set(currentChatRoomAtom, selectedChatRoom);
  // 추후 여러 챗봇과 동시에 대화할 수 있도록 수정 필요
  set(currentChatbotTextProgressAtom, selectedChatRoom.chatbots[0]);
});

export const updateUserChatMessageAtom = atom(null, async (get, set, message: string) => {
  set(currentChatRoomAtom, (prev) => {
    if (!prev) return prev;
    const nextChatId = prev.messages.length;
    const userMessage = {
      id: nextChatId,
      text: message,
      type: "user" as const,
      jobButtons: null,
      date: new Date().toISOString(),
    };
    return { ...prev, messages: [...prev.messages, userMessage] };
  });
});

export const sendMessageAtom = atom(null, async (get, set, message: string) => {
  const currentChatRoom = get(currentChatRoomAtom);
  const chatRoomList = get(chatRoomListAtom);

  // 사용자 메시지 전송
  if (message !== "") set(updateUserChatMessageAtom, message);

  const firstChatBot =
    chatRoomList[currentChatRoom ? currentChatRoom.id : 0].chatbots[0];

  // 여기에서 backend랑 통신을 하도록 하자.
  const handleStreamMessage = (
    message: string,
    isFirst: boolean,
    character_image: string,
  ) => {
    if (isFirst)
      set(currentChatRoomAtom, (prev) => {
        if (!prev) return prev;
        return {
          ...prev,
          messages: [
            ...prev.messages,
            {
              id: prev.messages.length,
              text: message,
              type: "chatbot",
              jobButtons: null,
              date: new Date().toISOString(),
            },
          ],
        };
      });
    else {
      set(currentChatRoomAtom, (prev) => {
        if (!prev) return prev;

        const lastItem = prev.messages[prev.messages.length - 1]; // 마지막 메시지 가져오기

        if (lastItem && lastItem.type === "chatbot") {
          return {
            ...prev,
            messages: [
              ...prev.messages.slice(0, -1), // 마지막 메시지 이전의 메시지들은 그대로
              {
                ...lastItem,
                text: `${message}`, // 마지막 메시지의 텍스트에 새 메시지 추가
              },
            ],
          };
        }

        // lastItem이 없거나 type이 "chatbot"이 아닌 경우, 새 메시지
        return {
          ...prev,
          messages: [
            ...prev.messages,
            {
              id: prev.messages.length,
              text: message, // 새로운 메시지 텍스트
              type: "chatbot",
              jobButtons: null,
              date: new Date().toISOString(),
            },
          ],
        };
      });
    };
  };

  const handleUsingDocs = (data: { job: string }[]) => {
    if (data.length === 0) return;
    setTimeout(() => {
      set(currentChatRoomAtom, (prev) => {
        if (!prev) return prev;
        return {
          ...prev,
          messages: [
            ...prev.messages,
            {
              id: prev.messages.length,
              text: "아래 직업들을 추천해드릴게요! 눌러서 확인해보세요.",
              type: "system",
              jobButtons: data.map((job: { job: string }) => job.job),
              date: new Date().toISOString(),
            },
          ],
        };
      });
    }, 100);
  };

  const handleError401Modal = () => set(error401ModalAtom, true);
  set(isChatbotTypingAtom, true);

  await handleReactQueryApiResponse(
    requestChatbot,
    handleError401Modal,
    message,
    get(getUserEmailAtom),
    firstChatBot.flow,
    '',
    handleStreamMessage,
    handleUsingDocs
  );

  set(isChatbotTypingAtom, false);
});

// 현재 채팅방의 채팅 내역을 더 불러오는 함수
export const fetchMoreChattingListInCurrentChatRoomAtom = atom(null, async (get, set) => {
  const currentChatRoom = get(currentChatRoomAtom);
  const chatRoomList = get(chatRoomListAtom);
  if (!currentChatRoom) return;
  const currentChatbot = currentChatRoom.chatbots[0];
  if (!currentChatbot) return;
  const chatHistoryPageNum = currentChatRoom.chatHistoryPageNum + 1;
  const handleError401Modal = () => set(error401ModalAtom, true);
  const result = await handleReactQueryApiResponse(
    getChatbotHistory,
    handleError401Modal,
    currentChatbot.id,
    get(getUserEmailAtom),
    chatHistoryPageNum
  );
  const parsedResult = parseChatHistory(result);
  if (parsedResult.length === 0) return;
  set(currentChatRoomAtom, {
    ...currentChatRoom,
    messages: [...parsedResult, ...currentChatRoom.messages],
    chatHistoryPageNum,
  });
  set(chatRoomListAtom, chatRoomList.map((chatRoom) => {
    if (chatRoom.id === currentChatRoom.id) {
      return {
        ...chatRoom,
        messages: [...parsedResult, ...chatRoom.messages],
        chatHistoryPageNum,
      };
    }
    return chatRoom;
  }));
});

// 채팅방을 오픈하는 함수
export const openChatDialogAtom = atom(null, async (get, set) => {
  const chatRoomList = get(chatRoomListAtom);
  const createChatRoomSection = get(createChatRoomSectionAtom);
  if (chatRoomList.length !== 0 && !createChatRoomSection) {
    const currentChatRoom = get(currentChatRoomAtom);
    if (!currentChatRoom) return;
    if (currentChatRoom.messages.length === 0) {
      set(sendMessageAtom, "");
    }
    set(chatDialogAtom, true);
  }
});
