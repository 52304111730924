import editDefaultRoutineOptionAtom from "@/Atoms/Dialogs/Edit/DefaultRoutineOptionAtom";
import CustomBackgroundColorButton from "@/Components/Common/Buttons/CustomBackgroundColor";
import CustomColorButton from "@/Components/Common/Buttons/CustomColor";
import theme from "@/Styles/theme";
import { editDefaultRoutineChangeOptionAtom } from "@/ViewModels/Dialogs/Edit/DefaultRoutineOptionViewModel";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useAtom, useSetAtom } from "jotai";
import React from "react";

const weekDays = ["월", "화", "수", "목", "금", "토", "일"];

const EditDefaultRoutineIntervalsDialog = () => {
  const [editDefaultRoutine, setEditDefaultRoutine] = useAtom(editDefaultRoutineOptionAtom);
  const changeRoutineIntervals = useSetAtom(editDefaultRoutineChangeOptionAtom);

  const handleWeeklyRoutine = (day: string) => {
    if (!editDefaultRoutine.termData) return;
    if (editDefaultRoutine.termData.includes(day)) {
      setEditDefaultRoutine((prev) => ({ ...prev, termData: (prev.termData || []).filter((interval) => interval !== day) }));
    } else {
      setEditDefaultRoutine((prev) => ({ ...prev, termData: [...(prev.termData || []), day] }));
    }
  }

  return (
    <Dialog open={editDefaultRoutine.open !== null} onClose={() => setEditDefaultRoutine({ open: null, termType: null, termData: null, prevTermData: null, prevTermType: null })} fullWidth maxWidth="xs">
      <DialogTitle>반복 설정</DialogTitle>
      <DialogContent>
        {editDefaultRoutine.termType === 1 ? (
          <Box sx={{ display: 'flex', flexDirection: 'row', width: "100%", justifyContent: "space-between" }}>
            {weekDays.map((day) => (
              <div key={day} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: editDefaultRoutine.termData?.includes(day) ? theme.colors.primary : "white", borderRadius: "50%", color: editDefaultRoutine.termData?.includes(day) ? "white" : "black", fontSize: "0.9rem", fontWeight: "bold", width: "30px", height: "30px", cursor: "pointer", justifyContent: "center" }} onClick={() => handleWeeklyRoutine(day)}>
                {day}
              </div>
            ))}
          </Box>
        ) : (
          <Box sx={{ display: "grid", gridTemplateColumns: "repeat(7, 1fr)", gap: "5px" }}>
            {
              Array.from({ length: 31 }, (_, i) => i + 1).map((day) => (
                <div key={day} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: editDefaultRoutine.termData?.includes(day.toString()) ? theme.colors.primary : "white", borderRadius: "50%", color: editDefaultRoutine.termData?.includes(day.toString()) ? "white" : "black", fontSize: "0.9rem", fontWeight: "bold", width: "30px", height: "30px", cursor: "pointer", justifyContent: "center" }} onClick={() => handleWeeklyRoutine(day.toString())}>
                  {day}
                </div>
              ))
            }
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <CustomColorButton onClick={() => setEditDefaultRoutine({ open: null, termType: null, termData: null, prevTermData: null, prevTermType: null })} text="취소" fontSize="0.8rem" />
        <CustomBackgroundColorButton onClick={changeRoutineIntervals} text="확인" fontSize="0.8rem" />
      </DialogActions>
    </Dialog>
  )
}

export default EditDefaultRoutineIntervalsDialog;
