import CustomBackgroundColorButton from '@/Components/Common/Buttons/CustomBackgroundColor';
import CustomColorButton from '@/Components/Common/Buttons/CustomColor';
import theme from '@/Styles/theme';
import { createTaskDialogAddHashtagAtom, createTaskDialogAddTaskAtom, createTaskDialogAddTaskInCrewAtom, createTaskDialogClearStateAtom, createTaskDialogEditLocationAtom, createTaskDialogEditRoutineApplyAtom, createTaskDialogEditRoutineClearStateAtom, createTaskDialogEditRoutineDataAtom, createTaskDialogEditRoutineStartAtom, createTaskDialogRemoveHashtagAtom, createTaskDialogSetRoutineOptionAtom, createTaskDialogUpdateStateAtom } from '@/ViewModels/Dialogs/Create/TaskViewModel';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import * as S from './styled';
import React from 'react';
import getRandomColorWithText from '@/Utils/GetRandomColor';
import { textEditorForCreateAndEditDialogAtom } from '@/Atoms/Dialogs/TextEditor';
import TextMarkdown from '@/Components/Common/Markdown';
import { createTaskInGroupDialogAddHashtagAtom, createTaskInGroupDialogAddTaskAtom, createTaskInGroupDialogClearStateAtom, createTaskInGroupDialogEditLocationAtom, createTaskInGroupDialogEditRoutineApplyAtom, createTaskInGroupDialogEditRoutineClearStateAtom, createTaskInGroupDialogEditRoutineStartAtom, createTaskInGroupDialogRemoveHashtagAtom, createTaskInGroupDialogSetRoutineOptionAtom, createTaskInGroupDialogUpdateStateAtom } from '@/ViewModels/Dialogs/Create/TaskInGroupViewModel';
import { languageAtom } from '@/Atoms/RootAtom';

const weekDays = ["월", "화", "수", "목", "금", "토", "일"];

const CreateTaskInGroupDialog = () => {
  const [createTaskDialog, updateState] = useAtom(createTaskInGroupDialogUpdateStateAtom);
  const language = useAtomValue(languageAtom);
  const clearState = useSetAtom(createTaskInGroupDialogClearStateAtom);
  const setRoutineOption = useSetAtom(createTaskInGroupDialogSetRoutineOptionAtom);
  const addTask = useSetAtom(createTaskInGroupDialogAddTaskAtom);
  const [editRoutineData, setEditRoutineData] = useAtom(createTaskDialogEditRoutineDataAtom);
  const editRoutineStart = useSetAtom(createTaskInGroupDialogEditRoutineStartAtom);
  const editRoutineClearState = useSetAtom(createTaskInGroupDialogEditRoutineClearStateAtom);
  const applyRoutine = useSetAtom(createTaskInGroupDialogEditRoutineApplyAtom);
  const editLocation = useSetAtom(createTaskInGroupDialogEditLocationAtom);
  const [inputValue, setInputValue] = React.useState("");
  const addHashtag = useSetAtom(createTaskInGroupDialogAddHashtagAtom);
  const removeHashtag = useSetAtom(createTaskInGroupDialogRemoveHashtagAtom);
  const setTextEditorDialog = useSetAtom(textEditorForCreateAndEditDialogAtom);

  // 엔터키 입력 시 태그 추가
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && inputValue.trim()) {
      event.preventDefault();
      const { backgroundColor, textColor } = getRandomColorWithText();
      if (!createTaskDialog.hashtags.find((tag) => tag.value === inputValue.trim())) {
        addHashtag({
          backgroundColor,
          textColor,
          value: inputValue.trim(),
        });
      }
      setInputValue(""); // 입력창 초기화
    } else if (event.key === 'Backspace' && inputValue === "") {
      if (createTaskDialog.hashtags.length > 0) {
        event.preventDefault();
        removeHashtag();
      }
    }
  };

  return (
    <Dialog
      open={createTaskDialog.open !== null}
      onClose={clearState}
      maxWidth="sm"
      fullWidth
    >
      {
        editRoutineData.termType === null ?
          <>
            <DialogTitle sx={{ fontSize: "0.9rem", fontWeight: "bold" }}>할 일 생성</DialogTitle>
            <DialogContent sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
              <TextField
                label="이름"
                variant="outlined"
                fullWidth
                value={createTaskDialog.name}
                onChange={(e) => updateState("name", e.target.value)}
                sx={{ marginTop: "10px" }}
              />
              <S.ContentContainer onClick={() => setTextEditorDialog((prev) => ({ ...prev, open: true, content: createTaskDialog.content }))}>
                {
                  createTaskDialog.content === "" ?
                    <div style={{ fontSize: "1rem" }}>설명</div> :
                    <TextMarkdown text={createTaskDialog.content} />
                }
              </S.ContentContainer>
              <div style={{ display: "flex", width: "100%", fontSize: "0.8rem", fontWeight: "bold" }}>진행 일자 설정</div>
              <div style={{ display: "flex", width: "100%", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                <DatePicker
                  label="시작일"
                  sx={{ width: "47%" }}
                  value={dayjs(createTaskDialog.startDate)}
                  onChange={(date) => updateState('startDate', date)}
                  shouldDisableDate={createTaskDialog.endDate ? (day) => day.isAfter(createTaskDialog.endDate) : undefined}
                  format="YYYY.MM.DD"
                />
                <div style={{ fontSize: "1.2rem", fontWeight: "600" }}>~</div>
                <DatePicker
                  label="종료일"
                  sx={{ width: "47%" }}
                  value={dayjs(createTaskDialog.endDate)}
                  onChange={(date) => updateState('endDate', date)}
                  shouldDisableDate={createTaskDialog.startDate ? (day) => day.isBefore(createTaskDialog.startDate) : undefined}
                  format="YYYY.MM.DD"
                />
              </div>
              <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", alignItems: "center" }}>
                <div style={{ fontSize: "0.8rem", fontWeight: "bold" }}>루틴 간격</div>
                <ToggleButtonGroup
                  exclusive
                  value={createTaskDialog.termType}
                  onChange={(event, newViewType) => updateState("termType", newViewType)}
                  color="primary"
                >
                  <ToggleButton value={0} selected={createTaskDialog.termType === 0} onClick={() => setRoutineOption(0)}>매일</ToggleButton>
                  <ToggleButton value={1} selected={createTaskDialog.termType === 1} onClick={() => setRoutineOption(1)}>매주</ToggleButton>
                  <ToggleButton value={2} selected={createTaskDialog.termType === 2} onClick={() => setRoutineOption(2)}>매월</ToggleButton>
                </ToggleButtonGroup>
              </div>

              {
                (createTaskDialog.termType === 1 || createTaskDialog.termType === 2) &&
                <>
                  <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", alignItems: "center" }}>
                    <div style={{ fontSize: "1rem", fontWeight: "bold" }}>반복</div>
                    <CustomBackgroundColorButton onClick={editRoutineStart} text={language === "KR" ? "수정" : "edit"} />
                  </div>
                  <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", alignItems: "center" }}>
                    <div style={{ fontSize: "1rem", fontWeight: "bold" }}>{createTaskDialog.termType === 1 ? "매주" : "매월"}</div>
                    <div style={{
                      display: "grid",
                      gridTemplateColumns: `repeat(${createTaskDialog.termData && createTaskDialog.termData.length < 7 ? createTaskDialog.termData.length : 7}, 1fr)`,
                      gap: "5px",
                    }}>
                      {createTaskDialog.termData?.map((interval: string) => (
                        <div key={interval} style={{ fontSize: "1rem", fontWeight: "bold" }}>{interval}{createTaskDialog.termType === 1 ? "" : "일"}</div>
                      ))}
                    </div>
                  </div>
                </>
              }
              <TextField
                label="장소"
                variant="outlined"
                fullWidth
                value={createTaskDialog.location.address}
                onChange={(e) => editLocation(e.target.value)}
              />
              <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", alignItems: "center" }}>
                <div style={{ fontSize: "0.8rem", fontWeight: "bold" }}>해시태그</div>
                <S.TagInputContainer>
                  {createTaskDialog.hashtags.map((tag, idx) => {
                    return <S.TagItem key={idx} style={{ backgroundColor: tag.backgroundColor, color: tag.textColor }}>
                      <span>{tag.value}</span>
                    </S.TagItem>
                  })}
                  <S.TagInput
                    placeholder={createTaskDialog.hashtags.length === 0 ? "태그를 입력해보세요." : ""}
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyDown={handleKeyDown}
                  />
                </S.TagInputContainer>
              </div>
            </DialogContent>
            <DialogActions>
              <CustomColorButton onClick={clearState} text="취소" fontSize="0.8rem" />
              <CustomBackgroundColorButton onClick={addTask} text="확인" fontSize="0.8rem" />
            </DialogActions>
          </>
          :
          <>
            <DialogTitle>반복 설정</DialogTitle>
            <DialogContent>
              {createTaskDialog.termType === 1 ? (
                <div style={{ display: 'flex', flexDirection: 'row', width: "100%", justifyContent: "space-between" }}>
                  {weekDays.map((day) => (
                    <div key={day} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: editRoutineData.termData?.includes(day) ? theme.colors.primary : "white", borderRadius: "50%", color: editRoutineData.termData?.includes(day) ? "white" : "black", fontSize: "0.9rem", fontWeight: "bold", width: "30px", height: "30px", cursor: "pointer", justifyContent: "center" }} onClick={() => setEditRoutineData(day)}>
                      {day}
                    </div>
                  ))}
                </div>
              ) : (
                <div style={{ display: "grid", gridTemplateColumns: "repeat(7, 1fr)", gap: "5px" }}>
                  {
                    Array.from({ length: 31 }, (_, i) => i + 1).map((day) => (
                      <div key={day} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: editRoutineData.termData?.includes(day.toString()) ? theme.colors.primary : "white", borderRadius: "50%", color: editRoutineData.termData?.includes(day.toString()) ? "white" : "black", fontSize: "0.9rem", fontWeight: "bold", width: "30px", height: "30px", cursor: "pointer", justifyContent: "center" }} onClick={() => setEditRoutineData(day.toString())}>
                        {day}
                      </div>
                    ))
                  }
                </div>
              )}
            </DialogContent>
            <DialogActions>
              <CustomColorButton onClick={editRoutineClearState} text="취소" fontSize="0.8rem" />
              <CustomBackgroundColorButton onClick={applyRoutine} text="확인" fontSize="0.8rem" />
            </DialogActions>
          </>
      }
    </Dialog>
  )
}

export default CreateTaskInGroupDialog;
