import React from 'react';
import useIsMobile from '../../../../../Hooks/useIsMobile';
import StoryPosting from './posting';
import * as S from './styled';
import { Fab } from '@mui/material';
import theme from '../../../../../Styles/theme';
import EditIcon from '@mui/icons-material/Edit';
import StoryList from './list';

import EditStoryDialog from './editDialog';
import { RequestStoryProps, StoryProps } from '@/Atoms/StoryAtom';

interface IStoryProps {
  setTabValue: React.Dispatch<React.SetStateAction<number>>;
  storyProps: RequestStoryProps;
  setStoryProps: React.Dispatch<React.SetStateAction<RequestStoryProps>>;
  selectedSort: string;
  setSelectedSort: React.Dispatch<React.SetStateAction<string>>;
  startEditingStory: (story: StoryProps) => void;
  editingStory: StoryProps | null;
  tabValue: number;
  setEditingStory: React.Dispatch<React.SetStateAction<StoryProps | null>>;
}

export const Story = ({ setTabValue, storyProps, setStoryProps, startEditingStory, editingStory, setEditingStory, tabValue }: IStoryProps) => {
  const { isMobile, isTablet } = useIsMobile();
  const [mobilePost, setMobilePost] = React.useState(false);

  const getHeight = () => {
    if (isMobile) return isTablet ? "calc(100% - 112px)" : "calc(100% - 136px)";
    return "100%";
  }

  return (
    <>
      <S.StoryContainer $isMobile={isMobile || isTablet}>
        {isTablet || !isMobile ?
          <S.DesktopStoryTopContainer>
            <StoryPosting setTabValue={setTabValue} storyProps={storyProps} setStoryProps={setStoryProps} editingStory={editingStory} tabValue={tabValue} />

          </S.DesktopStoryTopContainer>
          :
          !mobilePost && <Fab
            style={{ position: 'fixed', bottom: '100px', right: '20px', backgroundColor: theme.colors.primary, color: 'white' }}
            onClick={() => setMobilePost(true)}
          >
            <EditIcon />
          </Fab>
        }

        {/* 스토리목록 */}
        {
          isMobile && mobilePost ?
            <></>
            :
            <StoryList startEditingStory={startEditingStory} />
        }
      </S.StoryContainer>
      <EditStoryDialog editingStory={editingStory} setEditingStory={setEditingStory} tabValue={tabValue} setTabValue={setTabValue} />
    </>
  );
}

export default Story;
