import { atom } from "jotai";

export const textEditorForCreateAndEditDialogAtom = atom<{
  open: boolean;
  content: string;
  imgSrcs: string[];
}>({
  open: false,
  content: "",
  imgSrcs: []
});

const textEditorDialogAtom = atom<{
  open: boolean;
  nodeId: string;
  content: string;
  imgSrcs: string[];
}>({
  open: false,
  nodeId: "",
  content: "",
  imgSrcs: []
});

export default textEditorDialogAtom;
