// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fc-day-sun a {
  /* 일요일 컬러 */
  color: red;
}

.fc-day-sat a {
  /* 토요일 컬러 */
  color: blue;
}

.fc .fc-toolbar.fc-header-toolbar {
  margin-top: 15px;
  padding: 10px 20px;

  font-size: 9px;
  background-color: #06c25e;
  color: white;
  border-radius: 30px;
}

.fc .fc-button {
  background-color: transparent;
  color: white;
  border: none;
}

.fc .fc-button:hover {
  background-color: transparent;
}

.fc .fc-button-primary:active:focus {
  border: none !important;
  box-shadow: none !important;
  background-color: transparent !important;
  color: white !important;
  border-color: transparent !important;
}

/* today 버튼 - 이번 달에 위치할 때 */
.fc .fc-today-button:disabled {
  background-color: transparent;
  color: #fff;
}

.fc-daygrid-day-frame {
  min-height: 100px; /* 원하는 높이로 조정 */
}
`, "",{"version":3,"sources":["webpack://./src/Components/Dashboard/Mentee/Calendar/styled.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,UAAU;AACZ;;AAEA;EACE,WAAW;EACX,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;;EAElB,cAAc;EACd,yBAAyB;EACzB,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,6BAA6B;EAC7B,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,uBAAuB;EACvB,2BAA2B;EAC3B,wCAAwC;EACxC,uBAAuB;EACvB,oCAAoC;AACtC;;AAEA,2BAA2B;AAC3B;EACE,6BAA6B;EAC7B,WAAW;AACb;;AAEA;EACE,iBAAiB,EAAE,eAAe;AACpC","sourcesContent":[".fc-day-sun a {\n  /* 일요일 컬러 */\n  color: red;\n}\n\n.fc-day-sat a {\n  /* 토요일 컬러 */\n  color: blue;\n}\n\n.fc .fc-toolbar.fc-header-toolbar {\n  margin-top: 15px;\n  padding: 10px 20px;\n\n  font-size: 9px;\n  background-color: #06c25e;\n  color: white;\n  border-radius: 30px;\n}\n\n.fc .fc-button {\n  background-color: transparent;\n  color: white;\n  border: none;\n}\n\n.fc .fc-button:hover {\n  background-color: transparent;\n}\n\n.fc .fc-button-primary:active:focus {\n  border: none !important;\n  box-shadow: none !important;\n  background-color: transparent !important;\n  color: white !important;\n  border-color: transparent !important;\n}\n\n/* today 버튼 - 이번 달에 위치할 때 */\n.fc .fc-today-button:disabled {\n  background-color: transparent;\n  color: #fff;\n}\n\n.fc-daygrid-day-frame {\n  min-height: 100px; /* 원하는 높이로 조정 */\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
