import { edgesAtom, isChangedMindmapAtom, nodesAtom } from "@/Atoms/Plan/MindmapAtom";
import theme from "@/Styles/theme";
import { atom } from "jotai";
import { updatePlanDataMindmapAtom } from "../../InnerTabViewModel";
import GetIdFromQuerystring from "@/Utils/GetIdFromQuerystring";
import { Node } from "@xyflow/react";
import { deleteNode, updatePlanData } from "@/Queries/PlanQueries";
import { getUserId } from "@/ViewModels/UserViewModel";
import { handleReactQueryApiResponse, handleReactQueryApiResponseWithJson } from "@/Utils/APIUtil";
import { error401ModalAtom } from "@/Atoms/Dialogs/Error/401Atom";

// 사이드바에서 플랜 이동 시
export const movePlanAtom = atom(null, async (get, set) => {
  if (get(isChangedMindmapAtom)) {
    const nodes = get(nodesAtom);
    const edges = get(edgesAtom).map((edge) => ({
      ...edge,
      style: {
        ...edge.style,
        stroke: theme.colors.primary,
        strokeWidth: 3,
        strokeDasharray: undefined,
      },
      animated: false,
    }))
    const innerTabId = GetIdFromQuerystring("inner_tab_id");
    await handleReactQueryApiResponseWithJson(updatePlanData, () => set(error401ModalAtom, true), get(getUserId), innerTabId, nodes, edges);
    set(isChangedMindmapAtom, false);
  }
});

// ERD 재설계에 따라 추후 수정 예정
export const updateMindmapAtom = atom(null, async (get, set) => {
  const nodes = get(nodesAtom);
  const edges = get(edgesAtom).map((edge) => ({
    ...edge,
    style: {
      ...edge.style,
      stroke: theme.colors.primary,
      strokeWidth: 3,
      strokeDasharray: undefined,
    },
    animated: false,
  }))
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  set(updatePlanDataMindmapAtom, innerTabId, nodes, edges);
  set(isChangedMindmapAtom, false);
});

// delete task
export const deleteTaskAtom = atom(null, async (get, set, node: Node) => {
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  const response = await handleReactQueryApiResponse(deleteNode, () => set(error401ModalAtom, true), [node.data.backendId as number], innerTabId, get(getUserId));
  if (response.ok) {
    set(nodesAtom, get(nodesAtom).filter((n) => n.id !== node.id));
  }
});
