import { LocationProps } from '@/Atoms/RootAtom';
import { Dayjs } from 'dayjs';
import { atom } from 'jotai';

export const createTaskInGroupDialogAtom = atom<{
  open: string | null;
  groupId: string | null;
  isCrew: boolean;
  name: string;
  content: string;
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  termType: number | null;
  termData: string[] | null;
  location: LocationProps;
  hashtags: {
    backgroundColor: string;
    textColor: string;
    value: string;
  }[];
}>({
  open: null,
  groupId: null,
  isCrew: false,
  name: "",
  content: "",
  startDate: null,
  endDate: null,
  termType: null,
  termData: null,
  location: { address: "", latitude: null, longitude: null },
  hashtags: [],
});
