import { atom } from "jotai";

export interface StoryDataProps {
  background_color: string;
  text_color: string;
  value: string;
}

export interface RequestStoryProps {
  user_id: number;
  title: string;
  content: string;
  date: StoryDataProps | null;
  hashtags: StoryDataProps[];
  image_file?: File;
}

export type StoryProps = RequestStoryProps & {
  id: number | string;
  created_at: string;
  image_url: string;
}

export const filteredStoriesAtom = atom<StoryProps[]>([]);
export const storySortAtom = atom("era");
export const waitingDeleteStoryAtom = atom<StoryProps | null>(null);

const storiesAtom = atom<StoryProps[]>([]);
export default storiesAtom;
