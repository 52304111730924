import { Edge, MarkerType, Node } from "@xyflow/react";
import theme from "../Styles/theme";
import { v4 as uuidv4 } from "uuid";
import { LocationProps } from "@/Atoms/RootAtom";

const NODE_HEIGHT = 176;

interface Task {
  name: string;
  content: string;
  tasks?: Task[]; // 자기 자신을 재참조하여 재귀적인 구조 표현
}

interface Category {
  name: string;
  tasks: Task[]; // 각 Category는 Task 배열을 포함
}

interface Job {
  job: string;
  tasks: {
    categories: Category[];
  };
}

const calculateTaskCountsByDepthForCategories = (
  categories: Category[]
): { [categoryName: string]: number[] } => {
  const result: { [categoryName: string]: number[] } = {};

  categories.forEach(category => {
    const depthCounts: number[] = [];

    const countTasks = (tasks: Task[], depth: number) => {
      if (depth > depthCounts.length) {
        depthCounts.push(0); // 새로운 depth에 대해 초기값 추가
      }

      depthCounts[depth - 1] += tasks.length;

      tasks.forEach(task => {
        if (task.tasks) {
          countTasks(task.tasks, depth + 1); // 재귀적으로 하위 tasks를 계산
        }
      });
    };

    countTasks(category.tasks, 1);
    result[category.name] = depthCounts; // 카테고리 이름을 키로 결과 저장
  });

  return result;
};

const initialData = {
  id: "",
  backendId: 0,
  label: "",
  startDate: null,
  endDate: null,
  content: "",
  isNewCreated: false,
  termType: null,
  termData: null,
  location: {
    address: "",
    latitude: null,
    longitude: null,
  } as LocationProps,
  taskStatus: [],
  hashtags: [],
}

const createTreeNodesAndEdges = (
  parentId: string,
  children: any[],
  depth: number,
  xGap: number,
  yGap: number,
  xBase: number,
  yRange: [number, number],
  categoryIndex: number,
  customEdgeStyle: Partial<Edge>,
  currentTime: number
): { nodes: Node[]; edges: Edge[]; centerY: number; totalHeight: number } => {
  const nodes: Node[] = [];
  const edges: Edge[] = [];
  const adjustedYGap = 0; // 추가 간격

  const childCount = children.length;
  const childYStep = (yRange[1] - yRange[0]) / childCount; // 각 자식 노드 간 Y 간격

  let childCenters: number[] = []; // 자식 노드들의 중심 Y 좌표를 저장
  let totalHeight = 0; // 현재 레벨에서 사용된 Y 높이 누적

  children.forEach((child, index) => {
    const childId = `task-${categoryIndex}-${depth}-${index}-${uuidv4()}`;
    const childX = xBase + xGap; // 현재 뎁스에서 X 위치
    const childY = yRange[0] + index * childYStep + childYStep / 2; // Y 위치는 범위 내 균등 분포

    // 현재 노드 추가
    nodes.push({
      id: childId,
      position: { x: childX, y: childY },
      data: {
        ...initialData,
        id: childId,
        label: child.name,
        content: child.content || "",
      },
      type: "task",
      measured: {
        width: 410,
        height: 176,
      },
    });

    // 부모-자식 간 엣지 추가
    edges.push({
      id: `${parentId}-${childId}-${uuidv4()}`,
      source: parentId,
      sourceHandle: `${parentId}-right-source`,
      target: childId,
      targetHandle: `${childId}-left-target`,
      type: "smoothstep",
      ...customEdgeStyle,
    });

    // 자식 노드의 하위 tasks 처리
    if (child.tasks && child.tasks.length > 0) {
      const { nodes: childNodes, edges: childEdges, centerY, totalHeight: childHeight } = createTreeNodesAndEdges(
        childId,
        child.tasks,
        depth + 1,
        xGap,
        adjustedYGap,
        childX,
        [childY - childYStep / 2, childY + childYStep / 2],
        categoryIndex,
        customEdgeStyle,
        currentTime
      );

      nodes.push(...childNodes);
      edges.push(...childEdges);

      childCenters.push(centerY);
      totalHeight += childHeight;
    } else {
      // 리프 노드의 중심 좌표 저장
      childCenters.push(childY);
      totalHeight += NODE_HEIGHT + adjustedYGap;
    }
  });

  // 현재 노드 중심은 모든 자식 노드 중심의 평균
  const centerY = childCenters.length > 0 ? childCenters.reduce((sum, y) => sum + y, 0) / childCenters.length : yRange[0];

  return { nodes, edges, centerY, totalHeight };
};


const createMindmapNodesAndEdges = (mindmap1: any) => {
  const nodes: Node[] = [];
  const edges: Edge[] = [];
  const baseX = 100; // X 좌표 시작 위치
  const baseY = 50; // Y 좌표 시작 위치
  const xGap = 500; // X 간격
  const yGap = 130; // Y 간격
  const currentTime = new Date().getTime();

  const mindmap = mindmap1.tasks as Job;
  const taskCountsByDepth = calculateTaskCountsByDepthForCategories(mindmap.tasks.categories); // 각 깊이별 task 수 계산

  const customEdgeStyle: Partial<Edge> = {
    data: {
      backendId: 0,
    },
    style: {
      strokeWidth: 3,
      stroke: theme.colors.primary,
    },
    markerEnd: {
      type: MarkerType.ArrowClosed,
      width: 10,
      height: 10,
      color: process.env.REACT_APP_MAIN_COLOR,
    },
  };

  // Dream Node
  const dreamId = `dream-${uuidv4()}`;
  nodes.push({
    id: dreamId,
    position: { x: baseX, y: baseY },
    data: {
      ...initialData,
      id: dreamId,
      label: `꿈을 입력해주세요.`,
    },
    type: "dream",
    measured: {
      width: 410,
      height: 176,
    },
  });

  // Goal Node
  const goalId = `goal-${uuidv4()}`;
  const goalX = baseX + xGap;
  nodes.push({
    id: goalId,
    position: { x: goalX, y: baseY },
    data: {
      ...initialData,
      id: goalId,
      label: `목표를 입력해주세요.`,
    },
    type: "task",
    measured: {
      width: 410,
      height: 176,
    },
  });

  // Job Node
  const jobId = `job-${uuidv4()}`;
  const jobX = baseX + 2 * xGap;
  nodes.push({
    id: jobId,
    position: { x: jobX, y: baseY },
    data: {
      ...initialData,
      id: jobId,
      label: mindmap.job,
    },
    type: "job",
    measured: {
      width: 410,
      height: 176,
    },
  });

  // Connect dream -> goal -> job
  edges.push({
    id: `goal-to-dream-${uuidv4()}`,
    source: `${dreamId}`,
    target: `${goalId}`,
    sourceHandle: `${dreamId}-right-source`,
    targetHandle: `${goalId}-left-target`,
    type: "smoothstep",
    ...customEdgeStyle,
  });
  edges.push({
    id: `job-to-goal-${uuidv4()}`,
    source: `${goalId}`,
    sourceHandle: `${goalId}-right-source`,
    target: `${jobId}`,
    targetHandle: `${jobId}-left-target`,
    type: "smoothstep",
    ...customEdgeStyle,
  });

  // 첫 카테고리의 y축 위치 계산
  // 1. 각 카테고리별 최하위 Depth의 Task 수 계산
  const lowestDepthCounts = Object.entries(taskCountsByDepth).map(([category, counts]) => ({
    category,
    lowestDepthCount: counts[counts.length - 1],
  }));

  // 2. 각 카테고리별 높이 계산
  const categoryHeights = lowestDepthCounts.map(({ category, lowestDepthCount }) => ({
    category,
    height: lowestDepthCount * (NODE_HEIGHT + (yGap / 2)), // 각 Task가 차지하는 높이 계산
  }));

  // 3. 각 카테고리의 높이 합을 계산
  const totalCategoryHeight = categoryHeights.reduce((sum, { height }) => sum + height, 0);

  // 4. 첫 카테고리의 y축 위치 계산
  // 4.1 카테고리의 갯수가 1개일 경우 중앙에 위치
  let currentCategoryY: number = baseY;
  if (categoryHeights.length === 1) {
    currentCategoryY = baseY;
  } else if (categoryHeights.length % 2 === 0) {
    // 4.2 카테고리의 갯수가 짝수일 경우
    const halfCategoryCount = categoryHeights.length / 2;
    const halfCategoryHeight = categoryHeights.slice(0, halfCategoryCount).reduce((sum, { height }) => sum + height, 0);
    currentCategoryY = baseY - (halfCategoryHeight / 2);
  } else {
    // 4.3 카테고리의 갯수가 홀수일 경우
    const halfCategoryCount = Math.floor(categoryHeights.length / 2);
    const halfCategoryHeight = categoryHeights.slice(0, halfCategoryCount).reduce((sum, { height }) => sum + height, 0);
    currentCategoryY = baseY - (halfCategoryHeight / 2);
  }

  Object.entries(taskCountsByDepth).forEach(([categoryName, counts], index) => {
    const categoryId = `category-${index}-${uuidv4()}`;
    const categoryX = jobX + xGap;
    const categoryHeight = categoryHeights.find(({ category }) => category === categoryName)?.height || 0;

    const categoryYRange: [number, number] = [
      currentCategoryY - categoryHeight / 2,
      currentCategoryY + categoryHeight / 2,
    ];

    nodes.push({
      id: categoryId,
      position: { x: categoryX, y: (categoryYRange[0] + categoryYRange[1]) / 2 },
      data: {
        ...initialData,
        id: categoryId,
        label: categoryName,
      },
      type: "task",
      measured: {
        width: 410,
        height: 176,
      },
    });

    edges.push({
      id: `job-to-category-${uuidv4()}`,
      source: jobId,
      sourceHandle: `${jobId}-right-source`,
      target: categoryId,
      targetHandle: `${categoryId}-left-target`,
      type: "smoothstep",
      ...customEdgeStyle,
    });

    const { nodes: childNodes, edges: childEdges } = createTreeNodesAndEdges(
      categoryId,
      mindmap.tasks.categories[index].tasks,
      1,
      xGap,
      yGap,
      categoryX,
      categoryYRange,
      index,
      customEdgeStyle,
      currentTime
    );

    nodes.push(...childNodes);
    edges.push(...childEdges);

    // 다음 카테고리는 이전 카테고리의 높이만큼 더해진 위치에서 시작
    currentCategoryY += categoryHeight + yGap;
  });

  return { nodes, edges };
};

export default createMindmapNodesAndEdges;
