import { LocationProps } from '@/Atoms/RootAtom';
import { Dayjs } from 'dayjs';
import { atom } from 'jotai';
import { initCreateTaskDialogData } from '../../Create/Task';

export const editTaskDialogAtom = atom<{
  open: string | null;
  name: string;
  content: string;
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  termType: number | null;
  termData: string[] | null;
  location: LocationProps;
  hashtags: {
    backgroundColor: string;
    textColor: string;
    value: string;
  }[];
}>(initCreateTaskDialogData());

interface EditTaskDialogEditRoutineProps {
  termType: number | null;
  termData: string[] | null;
}

export const editTaskDialogEditRoutineAtom = atom<EditTaskDialogEditRoutineProps>({
  termType: null,
  termData: null,
});
