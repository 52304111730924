import React from "react";
import * as S from "./styled";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import useIsMobile from "../../../../../Hooks/useIsMobile";
import { Divider, IconButton, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import { useAtomValue, useSetAtom } from "jotai";
import { StoryProps, waitingDeleteStoryAtom } from "@/Atoms/StoryAtom";
import { languageAtom } from "@/Atoms/RootAtom";

interface StoryItemProps {
  item: StoryProps;
  startEditingStory: (story: StoryProps) => void;
}

const formatRelativeTime = (date: Date) => {
  const now = new Date();
  const diff = now.getTime() - date.getTime();

  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);
  const years = Math.floor(months / 12);

  if (seconds < 60) {
    return `방금 전`;
  } else if (minutes < 60) {
    return `${minutes}분 전`;
  } else if (hours < 24) {
    return `${hours}시간 전`;
  } else if (days < 30) {
    return `${days}일 전`;
  } else if (months < 12) {
    return `${months}달 전`;
  } else {
    return `${years}년 전`;
  }
};

const StoryItem = ({ item, startEditingStory }: StoryItemProps) => {
  const { isMobile } = useIsMobile();
  const language = useAtomValue(languageAtom);
  const [isStoryDialogOpen, setIsStoryDialogOpen] = React.useState<"template" | "story" | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const setWaitingDeleteStoryId = useSetAtom(waitingDeleteStoryAtom);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (value: string) => {
    if (value === "edit") {
      setIsStoryDialogOpen("story");
      startEditingStory(item);
    } else if (value === "delete") {
      setWaitingDeleteStoryId(item);
    }
    handleClose();
  };

  // Helper function to break title into spans
  const formatTitle = (title: string) => {
    return title.split(/(?<=[?!\.])\s+/g).map((part, index) => (
      <span key={index} style={{ display: 'block', marginBottom: '2px' }}>
        {part}
      </span>
    ));
  };

  const groupItems = [
    { name: language === "KR" ? "수정" : "edit", value: "edit" },
    { name: "삭제", value: "delete" },
  ];

  return (
    <>
      <S.StoryItem $isMobile={isMobile}>
        <S.StoryTitleContainer>
          <S.StoryTitle>
            {formatTitle(item.title)}
          </S.StoryTitle>
          <Tooltip title={item.created_at.toLocaleString()} arrow placement="top">
            <div style={{ fontSize: "0.8rem", fontWeight: 600, whiteSpace: "nowrap", display: "flex", alignItems: "center" }}>
              {formatRelativeTime(new Date(item.created_at))}
            </div>
          </Tooltip>
          <IconButton
            sx={{
              color: "black",
              fontWeight: "bold",
              padding: 0,
              textTransform: "none",
              fontSize: "0rem",
            }}
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            <MoreVertIcon sx={{ fontSize: "1.5rem" }} />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            {groupItems.map((item, index) => (
              <MenuItem key={index} onClick={() => handleMenuItemClick(item.value)}>
                {item.name}
              </MenuItem>
            ))}
          </Menu>
        </S.StoryTitleContainer>
        <Divider style={{ margin: "10px 0" }} />
        <div style={{ display: "flex", alignItems: "flex-start", fontSize: "0.8rem", lineHeight: "1.4", flexWrap: "wrap", gap: "20px" }}>
          {item.image_url && (
            <img
              src={`${process.env.REACT_APP_FEED_MEDIA_ADDRESS}${item.image_url.replace("/var/www/career_planner_feed/media", "")}`}
              alt="Story"
              style={{
                width: '150px',
                height: 'auto',
                objectFit: 'cover',
                borderRadius: '8px',
                marginBottom: '10px',
                cursor: 'pointer',
              }}
              onClick={() => window.open(`${process.env.REACT_APP_FEED_MEDIA_ADDRESS}${item.image_url.replace("/var/www/career_planner_feed/media", "")}`, "_blank")}
            />
          )}
          <div style={{ flex: 1 }}>
            {item.content}
          </div>
        </div>
        <div style={{ height: "30px" }} /> {/* Spacer */}
        <S.StoryBottomContainer>
          <div style={{ display: "flex", width: "70%", flexWrap: "wrap", gap: "10px", justifyContent: "flex-start" }}>
            <div style={{ fontWeight: 600, backgroundColor: item.date?.background_color, padding: "7px 10px", borderRadius: "7px", color: item.date?.text_color }}>
              {item.date ? item.date.value : "날짜 미정"}
            </div>
          </div>
          <div style={{ display: "flex", width: "100%", flexWrap: "wrap", gap: "10px", justifyContent: "flex-end" }}>
            {item.hashtags && item.hashtags.map((tag, index) => (
              tag.value && <Typography
                key={index}
                variant="body2"
                style={{
                  fontWeight: 600,
                  backgroundColor: tag.background_color,
                  padding: "7px 10px",
                  borderRadius: "7px",
                  color: tag.text_color,
                  whiteSpace: "nowrap"
                }}
              >
                #{tag.value}
              </Typography>
            ))}
          </div>
        </S.StoryBottomContainer>
      </S.StoryItem>
    </>
  )
}

export default StoryItem;
