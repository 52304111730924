import { togetherDialogAtom, togetherDialogSelectedCrewIdAtom } from "@/Atoms/Dialogs/Together";
import planHipAtom from "@/Atoms/PlanHip/Atom";
import { waitingModalAtom } from "@/Atoms/RootAtom";
import usePlanHip from "@/Hooks/usePlanHip";
import { togetherDialogWithCrewAtom } from "@/ViewModels/Dialogs/TogetherDialogViewModel";
import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import React from "react";
import { useNavigate } from "react-router-dom";
import CustomColorButton from "../../Buttons/CustomColor";
import CustomBackgroundColorButton from "../../Buttons/CustomBackgroundColor";
import { useTranslation } from "react-i18next";

const TogetherDialog = () => {
  const [dialog, setDialog] = useAtom(togetherDialogAtom);
  const planHip = useAtomValue(planHipAtom);
  const setSelectedCrewId = useSetAtom(togetherDialogSelectedCrewIdAtom);
  const togetherWithCrew = useSetAtom(togetherDialogWithCrewAtom);
  const navigate = useNavigate();
  const { isLoading } = usePlanHip();
  const setWaitingModal = useSetAtom(waitingModalAtom);
  const { t } = useTranslation();

  React.useEffect(() => {
    setWaitingModal({ state: isLoading, text: "로딩중입니다." });
  }, [isLoading, setWaitingModal]);

  return (
    dialog.node !== null &&
    <Dialog
      open={dialog.node !== null}
      onClose={() => setDialog({ node: null })}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>{t("dialogs.together.title", { title: dialog.node?.data.label as string })}</DialogTitle>
      <DialogContent>
        <Autocomplete
          options={planHip.myCrews}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => <TextField {...params} label={t("dialogs.together.selectCrew")} />}
          sx={{ paddingTop: "20px" }}
          onChange={(event, value) => setSelectedCrewId({
            crewId: value?.crewId as number,
            innerTabId: value?.innerTabId as number,
          })}
        />
      </DialogContent>
      <DialogActions>
        <CustomColorButton text={t("dialogs.together.close")} onClick={() => setDialog({ node: null })} fontSize="0.8rem" />
        <CustomBackgroundColorButton text={t("dialogs.together.together")} onClick={() => togetherWithCrew(navigate, t)} fontSize="0.8rem" />
      </DialogActions>
    </Dialog>
  )
}

export default TogetherDialog;
