import { atom } from "jotai";
import { StoryProps } from "./StoryAtom";


export interface CareerPlannerTabProps {
  id: number;
  name: string;
}

export interface CareerPlannerProps {
  currentTab: number;
  tabs: CareerPlannerTabProps[];
}

const careerPlannerTabs: CareerPlannerTabProps[] = [];

const careerPlannerAtom = atom<CareerPlannerProps>({
  currentTab: 0,
  tabs: careerPlannerTabs,
});

export interface MindmapStoriesProps extends StoryProps {
  mindmapId: number;
}

export interface MindmapDefaultRoutineProps {
  id: number; // 웬만하면 마인드맵 아이디랑 같게하면 겹칠일이 없을듯
  name: string;
  description: string;
  isActivated: boolean; // 활성화 여부
}

export const mindmapDefaultRoutinesAtom = atom([]);

export default careerPlannerAtom;
