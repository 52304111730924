import React from 'react';
import * as S from './styled';
import * as C from './containers';
import { useAtomValue, useSetAtom } from 'jotai';
import { nodesAtom } from '@/Atoms/Plan/MindmapAtom';
import { dateSelectorInitialSettingAtom } from '@/ViewModels/Components/DateSelectorViewModel';
import theme from '@/Styles/theme';
import { createTaskDialogAtom } from '@/Atoms/Dialogs/Create/Task';
import { v4 as uuidv4 } from 'uuid';
import DateSelector from './DateSelector';
import routineAtom from '@/Atoms/Plan/RoutineAtom';
import { routineUpdateAtom } from '@/ViewModels/Plan/RoutineViewModel';
import { useTranslation } from 'react-i18next';

const Routine = () => {
  const { t } = useTranslation();
  const taskNodes = useAtomValue(nodesAtom).filter((node) => node.id.includes("task"));
  const dateSelector = useAtomValue(dateSelectorInitialSettingAtom);
  const routine = useAtomValue(routineAtom);
  const updateRoutine = useSetAtom(routineUpdateAtom);
  const setCreateTaskDialog = useSetAtom(createTaskDialogAtom);
  const [maxHeight, setMaxHeight] = React.useState((window.innerHeight - 120) * 0.8);

  // x축으로 스크롤 가능하게 만들기 위한 코드
  const [isYScrollActive, setIsYScrollActive] = React.useState(false);

  const handleWheel = (e: React.WheelEvent<HTMLDivElement>) => {
    if (isYScrollActive) {
      e.currentTarget.scrollTop += e.deltaY;
    } else {
      e.currentTarget.scrollLeft += e.deltaY;
    }
  };

  React.useEffect(() => {
    window.addEventListener('resize', () => {
      setMaxHeight((window.innerHeight - 120) * 0.8);
    });
  }, []);

  React.useEffect(() => {
    updateRoutine();
    // eslint-disable-next-line
  }, [dateSelector.currentDate, dateSelector.weekOrMonth]);

  return (
    <S.Container>
      <DateSelector tasks={taskNodes} />
      <S.InnerContainer
        style={{ width: "calc(100% - 40px)" }}
        onWheel={handleWheel}
      >
        <S.KanbanList
          $backgroundColor={theme.colors.customPurple + "CC"}
          style={{ color: "white", maxHeight: maxHeight }}
        >
          <S.KanbanInnerList
            onMouseEnter={() => setIsYScrollActive(true)}
            onMouseLeave={() => setIsYScrollActive(false)}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: "row", fontWeight: "bold" }}>
              {t("plan.contents.routine.unclassified")}
            </div>
            <S.AddTaskButton
              onClick={() => setCreateTaskDialog((prev) => ({ ...prev, open: `task-${uuidv4()}` }))}
            >
              {t("plan.contents.routine.addTodo")}
            </S.AddTaskButton>
            {routine.unclassifiedTasks.map((task) => (
              <C.Item key={task.id} task={task} setIsYScrollActive={setIsYScrollActive} date={null} />
            ))}
          </S.KanbanInnerList>
        </S.KanbanList>
        {routine.data.map((routine, index) =>
          <S.KanbanList
            key={`${routine.id}-${index}`}
            $backgroundColor={theme.colors.primary + "CC"}
            style={{ color: "white", maxHeight: maxHeight }}
          >
            <S.KanbanInnerList
              id={routine.id}
              onMouseEnter={() => setIsYScrollActive(true)}
              onMouseLeave={() => setIsYScrollActive(false)}
            >
              <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: "row", fontWeight: "bold" }}>
                {routine.date.format("YYYY-MM-DD")}
              </div>
              {routine.tasks.map((task, index) => (
                <C.Item key={`${routine.id}-${task.id}-${index}`} task={task} setIsYScrollActive={setIsYScrollActive} date={routine.date.format("YYYY-MM-DD")} />
              ))}
            </S.KanbanInnerList>
          </S.KanbanList>
        )}
      </S.InnerContainer>
    </S.Container>
  );
}

export default Routine;
