import React, { useCallback } from 'react';
import * as S from './styled';
import { createLectureDialogGradesAtom, createLectureDialogHeadcountAtom, createLectureDialogClassRoomAtom, createLectureDialogOperatingTimesAtom, createLectureDialogOperatingTimesDayAtom, createLectureDialogOperatingTimesTimeAtom, createLectureDialogPeriodAtom, createLectureDialogTeacherAtom, createLectureDialogTextbookAtom, createLectureDialogNameAtom, createLectureDialogCoursePeriodAtom, createLectureDialogApplicationPeriodAtom, createLectureDialogApplicationPeriodDateAtom, createLectureDialogApplicationPeriodTimeAtom, createLectureDialogAddHashtagAtom, createLectureDialogRemoveHashtagAtom, createLectureDialogIsVisibleMemberAtom, createLectureDialogIsAbleToCancelAtom, createLectureDialogOperatingPlanAtom, createLectureDialogUploadFileAtom, createLectureDialogSelectedMultipleLectureAtom, createLectureDialogEditMultipleLectureAtom, createLectureDialogAllMultipleLectureAtom, createLectureDialogEditMultipleLectureRemoveHashtagAtom, createLectureDialogEditMultipleLectureAddHashtagAtom, createLectureDialogEditMultipleLectureIsAbleToCancelAtom, createLectureDialogEditMultipleLectureIsVisibleMemberAtom, createLectureDialogEditMultipleLectureCoursePeriodAtom, createLectureDialogEditMultipleLectureApplicationPeriodTimeAtom, createLectureDialogEditMultipleLectureApplicationPeriodDateAtom, createLectureDialogEditMultipleLectureNameAtom, createLectureDialogEditMultipleLectureTextbookAtom, createLectureDialogEditMultipleLectureClassRoomAtom, createLectureDialogEditMultipleLectureOperatingTimesTimeAtom, createLectureDialogEditMultipleLectureOperatingTimesDayAtom, createLectureDialogEditMultipleLectureOperatingTimesAtom, createLectureDialogEditMultipleLectureOperatingPlanAtom, createLectureDialogEditMultipleLecturePeriodAtom, createLectureDialogEditMultipleLectureHeadcountAtom, createLectureDialogEditMultipleLectureGradesAtom, createLectureDialogEditMultipleLectureTeacherAtom } from '@/ViewModels/Dialogs/Create/LectureViewModel';
import { Autocomplete, Checkbox, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import convertDayToKorean from '@/Utils/ConvertDayToKorean';
import getRandomColorWithText from '@/Utils/GetRandomColor';
import createLectureDialogAtom, { MultipleLectureFormat } from '@/Atoms/Dialogs/Create/Lecture';
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import theme from "@/Styles/theme";
import { lighten } from "polished";
import { useDropzone } from "react-dropzone";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import snackbarAtom from '@/Atoms/Snackbar';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { languageAtom } from '@/Atoms/RootAtom';
import convertToEnglishNumbering from '@/Utils/ConvertToEnglishNumbering';

export const CreateSingleLecture = () => {
  const { t } = useTranslation();
  const langauge = useAtomValue(languageAtom);
  const dialog = useAtomValue(createLectureDialogAtom);
  const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
  const times = Array.from({ length: 14 }, (_, i) => i); // 0 to 13 time slots
  const [teacher, editTeacher] = useAtom(createLectureDialogTeacherAtom);
  const [grades, editGrades] = useAtom(createLectureDialogGradesAtom);
  const [headcount, editHeadcount] = useAtom(createLectureDialogHeadcountAtom);
  const [period, editPeriod] = useAtom(createLectureDialogPeriodAtom);
  const [operatingPlan, editOperatingPlan] = useAtom(createLectureDialogOperatingPlanAtom);
  const [operatingTimes, editOperatingTimes] = useAtom(createLectureDialogOperatingTimesAtom);
  const editOperatingTimesDay = useSetAtom(createLectureDialogOperatingTimesDayAtom);
  const editOperatingTimesTime = useSetAtom(createLectureDialogOperatingTimesTimeAtom);
  const [classRoom, editClassRoom] = useAtom(createLectureDialogClassRoomAtom);
  const [textbook, editTextbook] = useAtom(createLectureDialogTextbookAtom);
  const [name, editName] = useAtom(createLectureDialogNameAtom);
  const applicationPeriod = useAtomValue(createLectureDialogApplicationPeriodAtom);
  const editApplicationPeriodDate = useSetAtom(createLectureDialogApplicationPeriodDateAtom);
  const editApplicationPeriodTime = useSetAtom(createLectureDialogApplicationPeriodTimeAtom);
  const [coursePeriod, editCoursePeriod] = useAtom(createLectureDialogCoursePeriodAtom);
  const [isVisibleMember, editIsVisibleMember] = useAtom(createLectureDialogIsVisibleMemberAtom);
  const [isAbleToCancel, editIsAbleToCancel] = useAtom(createLectureDialogIsAbleToCancelAtom);
  const [inputValue, setInputValue] = React.useState("");
  const addHashtag = useSetAtom(createLectureDialogAddHashtagAtom);
  const removeHashtag = useSetAtom(createLectureDialogRemoveHashtagAtom);
  const teacherOptions = ["김교사", "이교사", "박교사", "최교사", "정교사"];

  // 엔터키 입력 시 태그 추가
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && inputValue.trim()) {
      event.preventDefault();
      const { backgroundColor, textColor } = getRandomColorWithText();
      if (!dialog.hashtags.find((tag) => tag.value === inputValue.trim())) {
        addHashtag({
          backgroundColor,
          textColor,
          value: inputValue.trim(),
        });
      }
      setInputValue(""); // 입력창 초기화
    } else if (event.key === 'Backspace' && inputValue === "") {
      if (dialog.hashtags.length > 0) {
        event.preventDefault();
        removeHashtag();
      }
    }
  };

  return (
    <>
      <S.ContentContentRow>
        <S.ContentTitle>{t("dialogs.lecture.single.teacher")}</S.ContentTitle>
        <Autocomplete
          options={teacherOptions}
          value={teacher}
          onChange={(event, newValue) => editTeacher(newValue || "")}
          freeSolo // Allow custom input
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={t("dialogs.lecture.single.teacherName")}
              size="small"
              variant="outlined"
              sx={{
                width: "300px",
                borderRadius: "5px",
                '& .MuiOutlinedInput-root': {
                  display: "flex",
                  alignItems: "center",
                  height: "50px",
                  '& fieldset': {
                    borderColor: '#c0c0c0',
                  },
                  '&:hover fieldset': {
                    borderColor: '#a0a0a0',
                  },
                },
              }}
            />
          )}
          onInputChange={(event, newInputValue) => editTeacher(newInputValue)}
        />
      </S.ContentContentRow>
      <S.ContentContentRow>
        <S.ContentTitle>{t("dialogs.lecture.single.name")}</S.ContentTitle>
        <input type="text" style={S.ContentInputStyle(300)} placeholder={t("dialogs.lecture.single.namePlaceholder")} value={name} onChange={(e) => editName(e.target.value)} />
      </S.ContentContentRow>
      <S.ContentContentRow>
        <S.ContentTitle>{t("dialogs.lecture.single.grade.title")}</S.ContentTitle>
        <S.ContentContentRow style={{ gap: "5px", cursor: "pointer" }} onClick={() => editGrades(0)}>
          <Checkbox checked={grades.includes(0)} sx={{ width: "16px", height: "16px", transform: "scale(1)", '& .MuiSvgIcon-root': { fontSize: 16 } }} />
          <div>{t("dialogs.lecture.single.grade.all")}</div>
        </S.ContentContentRow>
        {[1, 2, 3].map((grade, idx) => (
          <S.ContentContentRow style={{ gap: "5px", cursor: "pointer" }} onClick={() => editGrades(grade)} key={idx}>
            <Checkbox checked={grades.includes(grade)} sx={{ width: "16px", height: "16px", transform: "scale(1)", '& .MuiSvgIcon-root': { fontSize: 16 } }} />
            <div>{t(`dialogs.lecture.single.grade.${grade}`)}</div>
          </S.ContentContentRow>
        ))}
      </S.ContentContentRow>
      <S.ContentContentRow>
        <S.ContentTitle>{t("dialogs.lecture.single.counts")}</S.ContentTitle>
        <S.ContentContentRow style={{ gap: "5px" }}>
          <input type="number" style={S.ContentInputStyle(80)} min={1} value={headcount} onChange={(e) => editHeadcount(parseInt(e.target.value))} />{t("dialogs.lecture.single.countsDescription")}
        </S.ContentContentRow>
      </S.ContentContentRow>
      <S.ContentContentRow>
        <S.ContentTitle>{t("dialogs.lecture.single.period")}</S.ContentTitle>
        <S.ContentContentRow style={{ gap: "5px" }}>
          <input type="number" style={S.ContentInputStyle(80)} min={1} value={period} onChange={(e) => editPeriod(parseInt(e.target.value))} />{t("dialogs.lecture.single.periodDescription")}
        </S.ContentContentRow>
      </S.ContentContentRow>
      <S.ContentContentRow>
        <S.ContentTitle>{t("dialogs.lecture.single.classroom")}</S.ContentTitle>
        <input type="text" style={S.ContentInputStyle(300)} value={classRoom} onChange={(e) => editClassRoom(e.target.value)} placeholder={t("dialogs.lecture.single.classroomPlaceholder")} />
      </S.ContentContentRow>
      <S.ContentContentRow>
        <S.ContentTitle>{t("dialogs.lecture.single.textbook")}</S.ContentTitle>
        <input type="text" style={S.ContentInputStyle(400)} placeholder={t("dialogs.lecture.single.textbookPlaceholder")} value={textbook} onChange={(e) => editTextbook(e.target.value)} />
      </S.ContentContentRow>
      <S.ContentContentRow>
        <S.ContentTitle>{t("dialogs.lecture.single.application.title")}</S.ContentTitle>
        <S.ContentContentColumn>
          <S.ContentContentRow style={{ gap: "5px" }}>
            <input
              type="date"
              style={S.ContentInputStyle(120)}
              value={applicationPeriod.start.date}
              onChange={(e) => editApplicationPeriodDate(new Date(e.target.value), "start")}
            />{t("dialogs.lecture.single.application.day")}
            <input
              type="number"
              style={S.ContentInputStyle(50)}
              value={applicationPeriod.start.hour}
              min="0"
              max="23" // Restricts hours from 0 to 23
              onChange={(e) => editApplicationPeriodTime(parseInt(e.target.value), "start", "hour")}
            />{t("dialogs.lecture.single.application.hour")}
            <input
              type="number"
              style={S.ContentInputStyle(50)}
              value={applicationPeriod.start.minute}
              min="0"
              max="59" // Restricts minutes from 0 to 59
              onChange={(e) => editApplicationPeriodTime(parseInt(e.target.value), "start", "minute")}
            />{t("dialogs.lecture.single.application.minuteFrom")}
          </S.ContentContentRow>
          <S.ContentContentRow style={{ gap: "5px" }}>
            <input
              type="date"
              style={S.ContentInputStyle(120)}
              value={applicationPeriod.end.date}
              onChange={(e) => editApplicationPeriodDate(new Date(e.target.value), "end")}
            />{t("dialogs.lecture.single.application.day")}
            <input
              type="number"
              style={S.ContentInputStyle(50)}
              value={applicationPeriod.end.hour}
              min="0"
              max="23" // Restricts hours from 0 to 23
              onChange={(e) => editApplicationPeriodTime(parseInt(e.target.value), "end", "hour")}
            />{t("dialogs.lecture.single.application.hour")}
            <input
              type="number"
              style={S.ContentInputStyle(50)}
              value={applicationPeriod.end.minute}
              min="0"
              max="59" // Restricts minutes from 0 to 59
              onChange={(e) => editApplicationPeriodTime(parseInt(e.target.value), "end", "minute")}
            />{t("dialogs.lecture.single.application.minuteUntil")}
          </S.ContentContentRow>
        </S.ContentContentColumn>
      </S.ContentContentRow>
      <S.ContentContentRow>
        <S.ContentTitle>{t("dialogs.lecture.single.coursePeriod.title")}</S.ContentTitle>
        <S.ContentContentRow style={{ gap: "5px" }}>
          <input type="date" style={S.ContentInputStyle(120)}
            value={coursePeriod.startDate} onChange={(e) => editCoursePeriod(new Date(e.target.value), "startDate")}
          />{t("dialogs.lecture.single.coursePeriod.from")}
          <input type="date" style={S.ContentInputStyle(120)}
            value={coursePeriod.endDate} onChange={(e) => editCoursePeriod(new Date(e.target.value), "endDate")}
          />{t("dialogs.lecture.single.coursePeriod.until")}
        </S.ContentContentRow>
      </S.ContentContentRow>
      <S.ContentContentRow>
        <S.ContentTitle>{t("dialogs.lecture.single.visible.title")}</S.ContentTitle>
        <S.ContentContentRow style={{ gap: "5px" }}>
          <Checkbox checked={isVisibleMember} onChange={() => editIsVisibleMember(!isVisibleMember)} sx={{ width: "16px", height: "16px", transform: "scale(1)", '& .MuiSvgIcon-root': { fontSize: 16 } }} />
          <div>{isVisibleMember ? t("dialogs.lecture.single.visible.y") : t("dialogs.lecture.single.visible.n")}</div>
        </S.ContentContentRow>
      </S.ContentContentRow>
      <S.ContentContentRow>
        <S.ContentTitle>{t("dialogs.lecture.single.cancel.title")}</S.ContentTitle>
        <S.ContentContentRow style={{ gap: "5px" }}>
          <Checkbox checked={isAbleToCancel} onChange={() => editIsAbleToCancel(!isAbleToCancel)} sx={{ width: "16px", height: "16px", transform: "scale(1)", '& .MuiSvgIcon-root': { fontSize: 16 } }} />
          <div>{isAbleToCancel ? t("dialogs.lecture.single.cancel.y") : t("dialogs.lecture.single.cancel.n")}</div>
        </S.ContentContentRow>
      </S.ContentContentRow>
      <S.ContentContentColumn style={{ gap: "0px" }}>
        <S.ContentTitle>{t("dialogs.lecture.single.operationTime.title")}</S.ContentTitle>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center" sx={{ fontSize: "0.8rem", padding: "8px" }}>{t("dialogs.lecture.single.operationTime.periodTitle")}</TableCell>
                {daysOfWeek.map((day) => (
                  <TableCell key={day} align="center" sx={{ fontSize: "0.8rem", padding: "8px" }} >
                    <S.ContentContentRow style={{ gap: "5px" }}>
                      <KeyboardDoubleArrowDownIcon
                        onClick={() => editOperatingTimesDay(day)}
                        sx={{ cursor: "pointer", border: "1px solid #c0c0c0", borderRadius: "5px", width: "0.8rem", height: "0.8rem" }} />
                      {t(`dialogs.lecture.single.operationTime.${convertDayToKorean(day)}`)}
                    </S.ContentContentRow>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {times.map((time) => (
                <TableRow key={time}>
                  <TableCell align="center" sx={{ padding: "8px" }}>
                    <S.ContentContentRow style={{ gap: "5px" }}>
                      {/* {time}교시 */}
                      {t("dialogs.lecture.single.operationTime.period", { period: convertToEnglishNumbering(time, langauge) })}
                      <KeyboardDoubleArrowRightIcon
                        onClick={() => editOperatingTimesTime(time)}
                        sx={{ cursor: "pointer", border: "1px solid #c0c0c0", borderRadius: "5px", width: "0.8rem", height: "0.8rem" }} />
                    </S.ContentContentRow>
                  </TableCell>
                  {daysOfWeek.map((day, index) => (
                    <TableCell key={day + time} align="center" sx={{ padding: "8px" }}>
                      <Checkbox checked={operatingTimes[day] && operatingTimes[day].includes(time)} onChange={() => editOperatingTimes(day, time)} sx={{ width: "16px", height: "16px", transform: "scale(1)", '& .MuiSvgIcon-root': { fontSize: 16 } }} />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </S.ContentContentColumn>
      <S.ContentContentRow>
        <S.ContentTitle>{t("dialogs.lecture.single.operationPlan.title")}</S.ContentTitle>
        <TextField
          value={operatingPlan}
          onChange={(e) => editOperatingPlan(e.target.value)}
          multiline
          rows={4}
          placeholder={t("dialogs.lecture.single.operationPlan.description")}
          variant="outlined"
          sx={{
            width: "calc(100% - 100px)",
            '& .MuiOutlinedInput-root': {
              borderRadius: "5px",
              '& fieldset': {
                borderColor: '#c0c0c0',
              },
              '&:hover fieldset': {
                borderColor: '#a0a0a0',
              },
            },
          }}
        />
      </S.ContentContentRow>
      <S.ContentContentRow>
        <S.ContentTitle>{t("dialogs.lecture.single.hashtag.title")}</S.ContentTitle>
        <S.TagInputContainer>
          {dialog.hashtags.map((tag, idx) => {
            return <S.TagItem key={idx} style={{ backgroundColor: tag.backgroundColor, color: tag.textColor }}>
              <span>{tag.value}</span>
            </S.TagItem>
          })}
          <S.TagInput
            placeholder={dialog.hashtags.length === 0 ? t("dialogs.lecture.single.hashtag.description") : ""}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={handleKeyDown}
          />
        </S.TagInputContainer>
      </S.ContentContentRow>
    </>
  )
}

const generateExcel = () => {
  // Define the data
  const data = [
    ["담당교사", "강좌명", "학년", "정원", "총시수", "강의실", "교재", "신청기간(시작)", "신청기간(종료)", "수강기간(시작)", "수강기간(종료)", "수강생 표시(Y/N)", "수강 취소 여부(Y/N)", "운영계획", "운영시간(0-13교시)"],
    ["김교사", "국어: 소설특강", "1,2,3", 30, 20, "101", "EBS 수능특강 국어", "2024-11-01 09:00", "2024-11-10 18:00", "2024-12-01", "2024-12-31", "Y", "N", "이 강좌는 국어 영역에서 문학 부문에 대한 문제 해결력을 높이기 위한 강좌입니다.", "월7,수7,금7"],
  ];

  // Create a worksheet
  const worksheet = XLSX.utils.aoa_to_sheet(data);

  // Create a workbook and add the worksheet
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  // Convert workbook to binary and save
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
  saveAs(blob, "커리어플래너_강좌일괄개설양식.xlsx");
};

const DownloadExcelButton = () => {
  const [isHovered, setIsHovered] = React.useState(false);
  const { t } = useTranslation();

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);
  return (
    <div
      style={{
        color: isHovered ? lighten(0.1, theme.colors.primary) : theme.colors.primary,
        marginLeft: "5px",
        cursor: "pointer"
      }}
      onClick={generateExcel}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {t("dialogs.lecture.multi.excel")}
    </div>
  );
};

export const CreateMultiLecture = () => {
  const [file, setFile] = useAtom(createLectureDialogUploadFileAtom);
  const setSnackbar = useSetAtom(snackbarAtom);
  const { t } = useTranslation();

  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      setFile(acceptedFiles[0]);
    }
  }, [setFile]);

  const onDropRejected = useCallback(() => {
    setSnackbar({ open: true, message: t("dialogs.common.snackbar.notSupportedFile"), severity: "error" });
  }, [setSnackbar]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected,
    accept: {
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
      "application/vnd.ms-excel": [".xls"],
    },
    multiple: false,
  });

  return (
    <>
      <div style={{ display: "flex", flexDirection: "column", width: "calc(100% - 3px)", height: "50px", justifyContent: "space-around", alignItems: "center" }}>
        <div style={{ display: "flex", flexDirection: "row", fontSize: "0.9rem" }}>{t("dialogs.lecture.multi.description1")} <DownloadExcelButton />{t("dialogs.lecture.multi.description2")}</div>
      </div>
      <S.DropZoneContainer {...getRootProps()} $isDragActive={isDragActive}>
        <input {...getInputProps()} />
        <CloudUploadIcon sx={{ fontSize: "2.5rem", color: "#999" }} />
        <Typography variant="body1" sx={{ fontSize: "0.9rem", color: "#666" }}>
          {isDragActive ? t("dialogs.lecture.multi.fileDrop") : t("dialogs.lecture.multi.fileDrag")}
        </Typography>
        {file && <S.FileInfo>{t("dialogs.lecture.multi.selectedFile")} {file.name}</S.FileInfo>}
      </S.DropZoneContainer>
    </>
  )
}

const parseMultipleLectureData = (data: string[][]): MultipleLectureFormat[] => {
  const multipleLectures: MultipleLectureFormat[] = [];
  const daysOfWeekMap: { [key: string]: string } = {
    "월": "Monday",
    "화": "Tuesday",
    "수": "Wednesday",
    "목": "Thursday",
    "금": "Friday",
    "토": "Saturday",
    "일": "Sunday",
  };

  data.forEach((row, index) => {
    const [
      teacher,
      name,
      gradesStr,
      headcount,
      period,
      classRoom,
      textbook,
      applicationStart,
      applicationEnd,
      courseStart,
      courseEnd,
      isVisibleMember,
      isAbleToCancel,
      operatingPlan,
      operatingTimesStr,
    ] = row;

    const grades = gradesStr.split(',').map(Number);
    const applicationStartDateTime = applicationStart.split(' ');
    const applicationEndDateTime = applicationEnd.split(' ');

    const multipleLecture: MultipleLectureFormat = {
      index,
      name,
      teacher,
      grades,
      headcount: Number(headcount),
      period: Number(period),
      operatingTimes: {
        Monday: [],
        Tuesday: [],
        Wednesday: [],
        Thursday: [],
        Friday: [],
        Saturday: [],
        Sunday: [],
      },
      operatingPlan,
      classRoom,
      textbook,
      isVisibleMember: isVisibleMember === 'Y',
      isAbleToCancel: isAbleToCancel === 'Y',
      applicationPeriod: {
        start: {
          date: applicationStartDateTime[0],
          hour: Number(applicationStartDateTime[1].split(':')[0]),
          minute: Number(applicationStartDateTime[1].split(':')[1]),
        },
        end: {
          date: applicationEndDateTime[0],
          hour: Number(applicationEndDateTime[1].split(':')[0]),
          minute: Number(applicationEndDateTime[1].split(':')[1]),
        },
        detail: {
          option1: false,
          option2: false,
          option3: false,
          option4: false,
          option5: false,
        },
      },
      coursePeriod: {
        startDate: courseStart,
        endDate: courseEnd,
      },
      hashtags: [],
    };

    operatingTimesStr.split(',').forEach(timeEntry => {
      const dayKorean = timeEntry.charAt(0);
      const dayEnglish = daysOfWeekMap[dayKorean];
      const time = parseInt(timeEntry.slice(1), 10);

      if (dayEnglish && !isNaN(time)) {
        multipleLecture.operatingTimes[dayEnglish].push(time);
      }
    });
    multipleLectures.push(multipleLecture);
  });

  return multipleLectures;
};

export const ConfirmMultiLecture = () => {
  const file = useAtomValue(createLectureDialogUploadFileAtom);
  const editLecture = useSetAtom(createLectureDialogEditMultipleLectureAtom);
  const [multipleLectures, setMultipleLectures] = useAtom(createLectureDialogSelectedMultipleLectureAtom);
  const [tableData, setTableData] = useAtom(createLectureDialogAllMultipleLectureAtom);
  const { t } = useTranslation();

  const fileColumns: { key: string, value: string, minWidth: string }[] = [
    { key: "teacher", value: t("dialogs.lecture.confirm.teacher"), minWidth: "100px" },
    { key: "name", value: t("dialogs.lecture.confirm.name"), minWidth: "150px" },
    { key: "grades", value: t("dialogs.lecture.confirm.grade"), minWidth: "100px" },
    { key: "headcount", value: t("dialogs.lecture.confirm.headcount"), minWidth: "50px" },
    { key: "period", value: t("dialogs.lecture.confirm.period"), minWidth: "50px" },
    { key: "classRoom", value: t("dialogs.lecture.confirm.classroom"), minWidth: "100px" },
    { key: "textbook", value: t("dialogs.lecture.confirm.textbook"), minWidth: "150px" },
    { key: "applicationPeriod.start.date", value: t("dialogs.lecture.confirm.applicationPeriodStart"), minWidth: "150px" },
    { key: "applicationPeriod.end.date", value: t("dialogs.lecture.confirm.applicationPeriodEnd"), minWidth: "150px" },
    { key: "coursePeriod.startDate", value: t("dialogs.lecture.confirm.coursePeriodStart"), minWidth: "150px" },
    { key: "coursePeriod.endDate", value: t("dialogs.lecture.confirm.coursePeriodEnd"), minWidth: "150px" },
    { key: "isVisibleMember", value: t("dialogs.lecture.confirm.visible"), minWidth: "150px" },
    { key: "isAbleToCancel", value: t("dialogs.lecture.confirm.cancel"), minWidth: "150px" },
    { key: "operatingPlan", value: t("dialogs.lecture.confirm.operationPlan"), minWidth: "150px" },
    { key: "operatingTimes", value: t("dialogs.lecture.confirm.operationTime"), minWidth: "150px" },
  ]

  const handleSelectAll = () => {
    setMultipleLectures(tableData.map((lecture, index) => index));
  };

  const handleSelect = (index: number) => {
    const lectureIndex = multipleLectures.findIndex(lecture => lecture === index);
    if (lectureIndex === -1) {
      setMultipleLectures([...multipleLectures, index]);
    } else {
      setMultipleLectures(multipleLectures.filter(lecture => lecture !== index));
    } // Toggle the selection
  };

  React.useEffect(() => {
    const readFile = async () => {
      if (file && tableData.length === 0) {
        const reader = new FileReader();
        reader.onload = (event) => {
          const data = new Uint8Array(event.target?.result as ArrayBuffer);
          const workbook = XLSX.read(data, { type: 'array' });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const jsonData: string[][] = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

          // Exclude the header row and set the rest as table data
          setTableData(parseMultipleLectureData(jsonData.slice(1)));
        };
        reader.readAsArrayBuffer(file);
      }
    };

    readFile();
  }, [file, setTableData, tableData]);

  return (
    <>
      <S.ContentContentColumn>
        <S.ContentTitle style={{ fontSize: "1rem", width: "100%" }}>{t("dialogs.lecture.confirm.title")}</S.ContentTitle>
        <S.CustomSelectButton onClick={handleSelectAll}>
          {t("dialogs.lecture.confirm.selectAll")}
        </S.CustomSelectButton>
      </S.ContentContentColumn>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center" sx={{ minWidth: "50px", margin: "4px", padding: "4px" }}>{t("dialogs.lecture.confirm.select")}</TableCell>
              {fileColumns.map((header, index) => (
                <TableCell key={header.key} align="center" sx={{ minWidth: header.minWidth, margin: "4px", padding: "4px", maxWidth: "150px" }}>{header.value}</TableCell>
              ))}
              <TableCell align="center" sx={{ minWidth: "150px", margin: "4px", padding: "4px" }}>{t("dialogs.lecture.buttons.edit")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                <S.CustomTableCell align="center">
                  <Checkbox sx={{ width: "16px", height: "16px", transform: "scale(1)", '& .MuiSvgIcon-root': { fontSize: 16 } }} checked={multipleLectures.includes(row.index)} onChange={() => handleSelect(rowIndex)} />
                </S.CustomTableCell>
                <S.CustomTableCell align="center">{row.teacher}</S.CustomTableCell>
                <S.CustomTableCell align="center">{row.name}</S.CustomTableCell>
                <S.CustomTableCell align="center">{row.grades.join(', ')}</S.CustomTableCell>
                <S.CustomTableCell align="center">{row.headcount}</S.CustomTableCell>
                <S.CustomTableCell align="center">{row.period}</S.CustomTableCell>
                <S.CustomTableCell align="center">{row.classRoom}</S.CustomTableCell>
                <S.CustomTableCell align="center">{row.textbook}</S.CustomTableCell>
                <S.CustomTableCell align="center">{dayjs(row.applicationPeriod.start.date).format("YYYY-MM-DD HH:mm")}</S.CustomTableCell>
                <S.CustomTableCell align="center">{dayjs(row.applicationPeriod.end.date).format("YYYY-MM-DD HH:mm")}</S.CustomTableCell>
                <S.CustomTableCell align="center">{row.coursePeriod.startDate}</S.CustomTableCell>
                <S.CustomTableCell align="center">{row.coursePeriod.endDate}</S.CustomTableCell>
                <S.CustomTableCell align="center">{row.isVisibleMember ? "Y" : "N"}</S.CustomTableCell>
                <S.CustomTableCell align="center">{row.isAbleToCancel ? "Y" : "N"}</S.CustomTableCell>
                <S.CustomTableCell align="center" sx={{ maxWidth: "150px" }}>{row.operatingPlan}</S.CustomTableCell>
                <S.CustomTableCell align="center">
                  {Object.entries(row.operatingTimes)
                    .filter(([, times]) => times.length > 0)
                    .map(([day, times]) => times.map(time => `${convertDayToKorean(day)}${time}`).join(','))
                    .join(',')}
                </S.CustomTableCell>
                <S.CustomTableCell align="center">
                  <S.CustomSelectButton onClick={() => editLecture(row.index)}>
                    {t("dialogs.lecture.buttons.edit")}
                  </S.CustomSelectButton>
                </S.CustomTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export const EditMultiLecture = () => {
  const { t } = useTranslation();
  const langauge = useAtomValue(languageAtom);
  const editLecture = useAtomValue(createLectureDialogAtom).editLecture;
  const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
  const times = Array.from({ length: 14 }, (_, i) => i); // 0 to 13 time slots
  const editTeacher = useSetAtom(createLectureDialogEditMultipleLectureTeacherAtom);
  const editGrades = useSetAtom(createLectureDialogEditMultipleLectureGradesAtom);
  const editHeadcount = useSetAtom(createLectureDialogEditMultipleLectureHeadcountAtom);
  const editPeriod = useSetAtom(createLectureDialogEditMultipleLecturePeriodAtom);
  const editOperatingPlan = useSetAtom(createLectureDialogEditMultipleLectureOperatingPlanAtom);
  const editOperatingTimes = useSetAtom(createLectureDialogEditMultipleLectureOperatingTimesAtom);
  const editOperatingTimesDay = useSetAtom(createLectureDialogEditMultipleLectureOperatingTimesDayAtom);
  const editOperatingTimesTime = useSetAtom(createLectureDialogEditMultipleLectureOperatingTimesTimeAtom);
  const editClassRoom = useSetAtom(createLectureDialogEditMultipleLectureClassRoomAtom);
  const editTextbook = useSetAtom(createLectureDialogEditMultipleLectureTextbookAtom);
  const editName = useSetAtom(createLectureDialogEditMultipleLectureNameAtom);
  const editApplicationPeriodDate = useSetAtom(createLectureDialogEditMultipleLectureApplicationPeriodDateAtom);
  const editApplicationPeriodTime = useSetAtom(createLectureDialogEditMultipleLectureApplicationPeriodTimeAtom);
  const editCoursePeriod = useSetAtom(createLectureDialogEditMultipleLectureCoursePeriodAtom);
  const editIsVisibleMember = useSetAtom(createLectureDialogEditMultipleLectureIsVisibleMemberAtom);
  const editIsAbleToCancel = useSetAtom(createLectureDialogEditMultipleLectureIsAbleToCancelAtom);
  const [inputValue, setInputValue] = React.useState("");
  const addHashtag = useSetAtom(createLectureDialogEditMultipleLectureAddHashtagAtom);
  const removeHashtag = useSetAtom(createLectureDialogEditMultipleLectureRemoveHashtagAtom);
  const teacherOptions = ["김교사", "이교사", "박교사", "최교사", "정교사"];

  // 엔터키 입력 시 태그 추가
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && inputValue.trim()) {
      event.preventDefault();
      const { backgroundColor, textColor } = getRandomColorWithText();
      if (!editLecture?.hashtags.find((tag) => tag.value === inputValue.trim())) {
        addHashtag({
          backgroundColor,
          textColor,
          value: inputValue.trim(),
        });
      }
      setInputValue(""); // 입력창 초기화
    } else if (event.key === 'Backspace' && inputValue === "") {
      if (editLecture && editLecture.hashtags.length > 0) {
        event.preventDefault();
        removeHashtag();
      }
    }
  };

  return (
    editLecture && <>
      <S.ContentContentRow>
        <S.ContentTitle>{t("dialogs.lecture.single.teacher")}</S.ContentTitle>
        <Autocomplete
          options={teacherOptions}
          value={editLecture.teacher}
          onChange={(event, newValue) => editTeacher(newValue || "")}
          freeSolo // Allow custom input
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={t("dialogs.lecture.single.teacherName")}
              size="small"
              variant="outlined"
              sx={{
                width: "300px",
                borderRadius: "5px",
                '& .MuiOutlinedInput-root': {
                  display: "flex",
                  alignItems: "center",
                  height: "50px",
                  '& fieldset': {
                    borderColor: '#c0c0c0',
                  },
                  '&:hover fieldset': {
                    borderColor: '#a0a0a0',
                  },
                },
              }}
            />
          )}
          onInputChange={(event, newInputValue) => editTeacher(newInputValue)}
        />
      </S.ContentContentRow>
      <S.ContentContentRow>
        <S.ContentTitle>{t("dialogs.lecture.single.name")}</S.ContentTitle>
        <input type="text" style={S.ContentInputStyle(300)} placeholder={t("dialogs.lecture.single.namePlaceholder")} value={editLecture.name} onChange={(e) => editName(e.target.value)} />
      </S.ContentContentRow>
      <S.ContentContentRow>
        <S.ContentTitle>{t("dialogs.lecture.single.grade.title")}</S.ContentTitle>
        <S.ContentContentRow style={{ gap: "5px", cursor: "pointer" }} onClick={() => editGrades(0)}>
          <Checkbox checked={editLecture.grades.includes(0)} sx={{ width: "16px", height: "16px", transform: "scale(1)", '& .MuiSvgIcon-root': { fontSize: 16 } }} />
          <div>{t("dialogs.lecture.single.grade.all")}</div>
        </S.ContentContentRow>
        {[1, 2, 3].map((grade, idx) => (
          <S.ContentContentRow style={{ gap: "5px", cursor: "pointer" }} onClick={() => editGrades(grade)} key={idx}>
            <Checkbox checked={editLecture.grades.includes(grade)} sx={{ width: "16px", height: "16px", transform: "scale(1)", '& .MuiSvgIcon-root': { fontSize: 16 } }} />
            <div>{t(`dialogs.lecture.single.grade.${grade}`)}</div>
          </S.ContentContentRow>
        ))}
      </S.ContentContentRow>
      <S.ContentContentRow>
        <S.ContentTitle>{t("dialogs.lecture.single.counts")}</S.ContentTitle>
        <S.ContentContentRow style={{ gap: "5px" }}>
          <input type="number" style={S.ContentInputStyle(80)} min={1} value={editLecture.headcount} onChange={(e) => editHeadcount(parseInt(e.target.value))} />{t("dialogs.lecture.single.countsDescription")}
        </S.ContentContentRow>
      </S.ContentContentRow>
      <S.ContentContentRow>
        <S.ContentTitle>{t("dialogs.lecture.single.period")}</S.ContentTitle>
        <S.ContentContentRow style={{ gap: "5px" }}>
          <input type="number" style={S.ContentInputStyle(80)} min={1} value={editLecture.period} onChange={(e) => editPeriod(parseInt(e.target.value))} />{t("dialogs.lecture.single.periodDescription")}
        </S.ContentContentRow>
      </S.ContentContentRow>
      <S.ContentContentRow>
        <S.ContentTitle>{t("dialogs.lecture.single.classroom")}</S.ContentTitle>
        <input type="text" style={S.ContentInputStyle(300)} value={editLecture.classRoom} onChange={(e) => editClassRoom(e.target.value)} placeholder={t("dialogs.lecture.single.classroomPlaceholder")} />
      </S.ContentContentRow>
      <S.ContentContentRow>
        <S.ContentTitle>{t("dialogs.lecture.single.textbook")}</S.ContentTitle>
        <input type="text" style={S.ContentInputStyle(400)} placeholder={t("dialogs.lecture.single.textbookPlaceholder")} value={editLecture.textbook} onChange={(e) => editTextbook(e.target.value)} />
      </S.ContentContentRow>
      <S.ContentContentRow>
        <S.ContentTitle>{t("dialogs.lecture.single.application.title")}</S.ContentTitle>
        <S.ContentContentColumn>
          <S.ContentContentRow style={{ gap: "5px" }}>
            <input
              type="date"
              style={S.ContentInputStyle(120)}
              value={editLecture.applicationPeriod.start.date}
              onChange={(e) => editApplicationPeriodDate(new Date(e.target.value), "start")}
            />{t("dialogs.lecture.single.application.day")}
            <input
              type="number"
              style={S.ContentInputStyle(50)}
              value={editLecture.applicationPeriod.start.hour}
              min="0"
              max="23" // Restricts hours from 0 to 23
              onChange={(e) => editApplicationPeriodTime(parseInt(e.target.value), "start", "hour")}
            />{t("dialogs.lecture.single.application.hour")}
            <input
              type="number"
              style={S.ContentInputStyle(50)}
              value={editLecture.applicationPeriod.start.minute}
              min="0"
              max="59" // Restricts minutes from 0 to 59
              onChange={(e) => editApplicationPeriodTime(parseInt(e.target.value), "start", "minute")}
            />{t("dialogs.lecture.single.application.minuteFrom")}
          </S.ContentContentRow>
          <S.ContentContentRow style={{ gap: "5px" }}>
            <input
              type="date"
              style={S.ContentInputStyle(120)}
              value={editLecture.applicationPeriod.end.date}
              onChange={(e) => editApplicationPeriodDate(new Date(e.target.value), "end")}
            />{t("dialogs.lecture.single.application.day")}
            <input
              type="number"
              style={S.ContentInputStyle(50)}
              value={editLecture.applicationPeriod.end.hour}
              min="0"
              max="23" // Restricts hours from 0 to 23
              onChange={(e) => editApplicationPeriodTime(parseInt(e.target.value), "end", "hour")}
            />{t("dialogs.lecture.single.application.hour")}
            <input
              type="number"
              style={S.ContentInputStyle(50)}
              value={editLecture.applicationPeriod.end.minute}
              min="0"
              max="59" // Restricts minutes from 0 to 59
              onChange={(e) => editApplicationPeriodTime(parseInt(e.target.value), "end", "minute")}
            />{t("dialogs.lecture.single.application.minuteUntil")}
          </S.ContentContentRow>
        </S.ContentContentColumn>
      </S.ContentContentRow>
      <S.ContentContentRow>
        <S.ContentTitle>{t("dialogs.lecture.single.coursePeriod.title")}</S.ContentTitle>
        <S.ContentContentRow style={{ gap: "5px" }}>
          <input type="date" style={S.ContentInputStyle(120)}
            value={editLecture.coursePeriod.startDate} onChange={(e) => editCoursePeriod(new Date(e.target.value), "startDate")}
          />{t("dialogs.lecture.single.coursePeriod.from")}
          <input type="date" style={S.ContentInputStyle(120)}
            value={editLecture.coursePeriod.endDate} onChange={(e) => editCoursePeriod(new Date(e.target.value), "endDate")}
          />{t("dialogs.lecture.single.coursePeriod.until")}
        </S.ContentContentRow>
      </S.ContentContentRow>
      <S.ContentContentRow>
        <S.ContentTitle>{t("dialogs.lecture.single.visible.title")}</S.ContentTitle>
        <S.ContentContentRow style={{ gap: "5px" }}>
          <Checkbox checked={editLecture.isVisibleMember} onChange={() => editIsVisibleMember(!editLecture.isVisibleMember)} sx={{ width: "16px", height: "16px", transform: "scale(1)", '& .MuiSvgIcon-root': { fontSize: 16 } }} />
          <div>{editLecture.isVisibleMember ? "표시" : "표시 안함"}</div>
        </S.ContentContentRow>
      </S.ContentContentRow>
      <S.ContentContentRow>
        <S.ContentTitle>{t("dialogs.lecture.single.cancel.title")}</S.ContentTitle>
        <S.ContentContentRow style={{ gap: "5px" }}>
          <Checkbox checked={editLecture.isAbleToCancel} onChange={() => editIsAbleToCancel(!editLecture.isAbleToCancel)} sx={{ width: "16px", height: "16px", transform: "scale(1)", '& .MuiSvgIcon-root': { fontSize: 16 } }} />
          <div>{editLecture.isAbleToCancel ? t("dialogs.lecture.single.cancel.y") : t("dialogs.lecture.single.cancel.n")}</div>
        </S.ContentContentRow>
      </S.ContentContentRow>
      <S.ContentContentRow>
        <S.ContentTitle>{t("dialogs.lecture.single.operationPlan.title")}</S.ContentTitle>
        <TextField
          value={editLecture.operatingPlan}
          onChange={(e) => editOperatingPlan(e.target.value)}
          multiline
          rows={4}
          placeholder={t("dialogs.lecture.single.operationPlan.description")}
          variant="outlined"
          sx={{
            width: "calc(100% - 100px)",
            '& .MuiOutlinedInput-root': {
              borderRadius: "5px",
              '& fieldset': {
                borderColor: '#c0c0c0',
              },
              '&:hover fieldset': {
                borderColor: '#a0a0a0',
              },
            },
          }}
        />
      </S.ContentContentRow>
      <S.ContentContentColumn style={{ gap: "0px" }}>
        <S.ContentTitle>{t("dialogs.lecture.single.operationTime.title")}</S.ContentTitle>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center" sx={{ fontSize: "0.8rem", padding: "8px" }}>{t("dialogs.lecture.single.operationTime.periodTitle")}</TableCell>
                {daysOfWeek.map((day) => (
                  <TableCell key={day} align="center" sx={{ fontSize: "0.8rem", padding: "8px" }} >
                    <S.ContentContentRow style={{ gap: "5px" }}>
                      <KeyboardDoubleArrowDownIcon
                        onClick={() => editOperatingTimesDay(day)}
                        sx={{ cursor: "pointer", border: "1px solid #c0c0c0", borderRadius: "5px", width: "0.8rem", height: "0.8rem" }} />
                      {t(`dialogs.lecture.single.operationTime.${convertDayToKorean(day)}`)}
                    </S.ContentContentRow>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {times.map((time) => (
                <TableRow key={time}>
                  <TableCell align="center" sx={{ padding: "8px" }}>
                    <S.ContentContentRow style={{ gap: "5px" }}>
                      {t("dialogs.lecture.single.operationTime.period", { period: convertToEnglishNumbering(time, langauge) })}
                      <KeyboardDoubleArrowRightIcon
                        onClick={() => editOperatingTimesTime(time)}
                        sx={{ cursor: "pointer", border: "1px solid #c0c0c0", borderRadius: "5px", width: "0.8rem", height: "0.8rem" }} />
                    </S.ContentContentRow>
                  </TableCell>
                  {daysOfWeek.map((day, index) => (
                    <TableCell key={day + time} align="center" sx={{ padding: "8px" }}>
                      <Checkbox checked={editLecture.operatingTimes[day] && editLecture.operatingTimes[day].includes(time)} onChange={() => editOperatingTimes(day, time)} sx={{ width: "16px", height: "16px", transform: "scale(1)", '& .MuiSvgIcon-root': { fontSize: 16 } }} />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </S.ContentContentColumn>
      <S.ContentContentRow>
        <S.ContentTitle>{t("dialogs.lecture.single.hashtag.title")}</S.ContentTitle>
        <S.TagInputContainer>
          {editLecture.hashtags.map((tag, idx) => {
            return <S.TagItem key={idx} style={{ backgroundColor: tag.backgroundColor, color: tag.textColor }}>
              <span>{tag.value}</span>
            </S.TagItem>
          })}
          <S.TagInput
            placeholder={editLecture.hashtags.length === 0 ? t("dialogs.lecture.single.hashtag.description") : ""}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={handleKeyDown}
          />
        </S.TagInputContainer>
      </S.ContentContentRow>
    </>
  )
}
