import React from "react";
import { Box, Divider } from "@mui/material";
import * as S from "../styled";
import HabitTable from "./HabitTable";
import { useTranslation } from "react-i18next";

// 추천 직업
export function MentoringUsingProgress() {
  const { t } = useTranslation();
  return (
    <S.CustomBox
      sx={{ height: "100%", display: "flex", flexDirection: "column" }}
    >
      <S.TitleTypo>{t("dashboard.mentor.careerPlanning.title")}</S.TitleTypo>
      <Divider sx={{ margin: "10px 0" }} />
      <S.DescTypo sx={{ marginBottom: "10px" }}>
        {t("dashboard.mentor.careerPlanning.description")}
      </S.DescTypo>

      <HabitTable />
    </S.CustomBox>
  );
}
