import createJobMindmapDialogAtom from '@/Atoms/Dialogs/CreateJobMindmap';
import CustomBackgroundColorButton from '@/Components/Common/Buttons/CustomBackgroundColor';
import CustomColorButton from '@/Components/Common/Buttons/CustomColor';
import theme from '@/Styles/theme';
import { createJobNodeDataAtom } from '@/ViewModels/Plan/Mindmap/JobNodeViewModel';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useAtom, useSetAtom } from 'jotai';
import React from 'react';

const CreateJobMindmapDialog = () => {
  const [dialog, setDialog] = useAtom(createJobMindmapDialogAtom);
  const createMindmap = useSetAtom(createJobNodeDataAtom);
  return (dialog &&
    <Dialog open={dialog.open} onClose={() => setDialog(null)}>
      <DialogTitle sx={{ fontSize: "0.9rem", fontWeight: "bold" }}>마인드맵 생성</DialogTitle>
      <DialogContent>
        <div style={{ fontSize: "0.8rem" }}>
          {dialog.job} 직업에 대한 마인드맵을 생성하시겠습니까?
        </div>
        <div style={{ fontSize: "0.8rem" }}>
          생성하면 기존 연결된 하위 요소들이 모두 삭제됩니다.
        </div>
      </DialogContent>
      <DialogActions>
        <CustomColorButton text="취소" onClick={() => setDialog(null)} fontSize="0.8rem" />
        <CustomBackgroundColorButton text="생성" onClick={() => createMindmap(dialog.node, dialog.job)} fontSize="0.8rem" />
      </DialogActions>
    </Dialog>
  )
}

export default CreateJobMindmapDialog;
