import React from 'react';
import * as S from './styled';
import { Button, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { drawerSidebarAtom } from '@/Atoms/SidebarAtom';
import { useLocation, useNavigate } from 'react-router-dom';
import { sendMailAtom } from '@/ViewModels/Mail/ViewModel';
import { createCrewDialogOpenAtom } from '@/ViewModels/Dialogs/Create/CrewViewModel';
import crewAtom from '@/Atoms/CrewAtom';
import { crewJoinAtom, crewLeaveAtom } from '@/ViewModels/Crew/ViewModel';
import { getUserId } from '@/ViewModels/UserViewModel';
import useIsMobile from '@/Hooks/useIsMobile';
import { useTranslation } from 'react-i18next';
import PlanMobileHeaderContent from '@/Components/Plan/Contents/Mobile/HeaderContent';

const MobileSidebar = () => {
  const { isMobile } = useIsMobile();
  const [drawerOpen, setDrawerOpen] = useAtom(drawerSidebarAtom);
  const location = useLocation();
  const navigate = useNavigate();
  const sendMail = useSetAtom(sendMailAtom);
  const createCrewDialogOpen = useSetAtom(createCrewDialogOpenAtom);
  const crew = useAtomValue(crewAtom);
  const joinCrew = useSetAtom(crewJoinAtom);
  const leaveCrew = useSetAtom(crewLeaveAtom);
  const userId = useAtomValue(getUserId);
  const { t } = useTranslation();

  React.useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) setDrawerOpen(false);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
    // eslint-disable-next-line
  }, []);

  const renderMobileButton = () => {
    if (!location) return null;

    switch (location.pathname) {
      case "/mail":
        return <Button onClick={sendMail} sx={{ color: "black", fontWeight: "bold", padding: 0 }}>{t("sidebar.mobile.send")}</Button>;

      case "/plan-hip":
        return <Button onClick={createCrewDialogOpen} sx={{ color: "black", fontWeight: "bold", padding: 0 }}>{t("sidebar.mobile.createCrew")}</Button>;

      case "/crew":
        if (crew && crew.members.map((member) => member.userId).includes(userId)) {
          return <Button onClick={() => leaveCrew(navigate)} sx={{ color: "black", fontWeight: "bold", padding: 0 }}>{t("sidebar.mobile.leaveCrew")}</Button>;
        }
        if (crew && crew.crewInfo.headCount !== crew.crewInfo.current) {
          return <Button onClick={joinCrew} sx={{ color: "black", fontWeight: "bold", padding: 0 }}>{t("sidebar.mobile.joinCrew")}</Button>;
        }
        return null;

      case "/plan":
        return <PlanMobileHeaderContent />;

      default:
        return null;
    }
  };

  return (
    isMobile &&
    <S.MobileHeaderContainer>
      <IconButton
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={() => setDrawerOpen(!drawerOpen)}
      >
        <MenuIcon />
      </IconButton>
      {renderMobileButton()}
    </S.MobileHeaderContainer>
  )
}

export default MobileSidebar;
