import mailSidebarAtom from "@/Atoms/Mail/SidebarAtom";
import { useAtomValue } from "jotai";
import React from "react";
import * as S from './styled';

const MailSidebar = () => {
  const mailSidebar = useAtomValue(mailSidebarAtom);

  return (
    mailSidebar &&
    <S.Container>
      sidebar
    </S.Container>
  )
}

export default MailSidebar;
