import React from "react";
import { useAtom, useAtomValue } from "jotai";
import { Stack } from "@mui/material";
import * as S from "./styled";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { TotalCareerProgress } from "./TotalCareerProgress";
import { SupplyMenteeList } from "./SupplyMenteeList";
import { MentoringUsingProgress } from "./MentoringUsingProgress";
import { MenteeStudyParticipation } from "./MenteeStudyParticipation";
import { MenteeStudyState } from "./MenteeStudyState";
import { PopularPlan } from "./PopularPlan";
import CustomColorButton from "@/Components/Common/Buttons/CustomColor";
import { useTranslation } from "react-i18next";
import { getUserNickname } from "@/ViewModels/UserViewModel";
import { devConsoleLog } from "@/Utils/ConsoleLogInDevelopment";

const MentorDashboard = () => {
  const userNickname = useAtomValue(getUserNickname);
  const { t } = useTranslation();

  return (
    <>
      <S.WelcomeMessageTypo>
        {t("dashboard.mentor.header.greeting", { nickname: userNickname })}
      </S.WelcomeMessageTypo>
      <Stack
        direction={"row"}
        gap="10px"
        alignItems={"center"}
        sx={{ marginBottom: "20px" }}
      >
        <S.WelcomeMessageTypo>{t("dashboard.mentor.header.sentence")}</S.WelcomeMessageTypo>
        <CustomColorButton text={t("dashboard.mentor.header.goTo")} onClick={() => devConsoleLog("멘티 확인하러가기 버튼 클릭")} fontSize="0.8rem" icon={<ArrowRightAltIcon />} />
      </Stack>

      <div style={{ display: "flex", flexDirection: "row", gap: "10px", height: "auto" }}>
        <div style={{ display: "flex", flexDirection: "column", gap: "10px", height: "100%", flex: 1 }}>
          <TotalCareerProgress />
          <MentoringUsingProgress />
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "10px", height: "100%", flex: 1 }}>
          <SupplyMenteeList />
          <MenteeStudyParticipation />
          <PopularPlan />
          <MenteeStudyState />
        </div>
      </div>
    </>
  );
};

export default MentorDashboard;
