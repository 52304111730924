import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: calc(100% - 40px);
  border-radius: 20px;
  border: 1px solid #d9d9d9;
  padding: 20px;
  gap: 20px;
  position: relative;
  align-items: center;
`;

export const Title = styled.div`
  font-size: 0.8rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primary};
  width: 100%;
`;

export const CrewsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr); // 5개씩 그리드로 배치
  gap: 20px; // 그리드 아이템 간격
  width: 100%;

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr); // 1개씩 그리드로 배치
  }
`;

export const CrewContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #999;
  justify-content: center;
  cursor: pointer;
  width: calc(100% - 40px); // 그리드 아이템 너비를 100%로 설정
  gap: 10px;
`;

export const CrewLogo = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
`;

export const HashTags = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  flex-wrap: wrap;
  width: 100%;
`;

export const HashTag = styled.div<{ $color: string }>`
  padding: 5px;
  border-radius: 10px;
  background-color: ${({ $color }) => $color};
  color: white;
  font-size: 0.7rem;
  font-weight: bold;
`;

export const NumberOfPagesContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 5px;
  margin-top: 10px;
`;

export const PageNavigator = styled.div<{ $active: boolean }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${({ $active, theme }) => $active ? theme.colors.primary : "#999"};
  cursor: pointer;
`;
