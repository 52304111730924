import { Button, IconButton, Typography } from '@mui/material';
import React from 'react';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import theme from '@/Styles/theme';
import { useAtomValue, useSetAtom } from 'jotai';
import { getUserNickname } from '@/ViewModels/UserViewModel';
import { createCrewDialogOpenAtom } from '@/ViewModels/Dialogs/Create/CrewViewModel';
import useIsMobile from '@/Hooks/useIsMobile';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { findCrewDialogOpenAtom } from '@/ViewModels/Dialogs/FindCrewViewModel';
import CustomColorButton from '@/Components/Common/Buttons/CustomColor';
import { useTranslation } from 'react-i18next';

const PlanHipTopSection = () => {
  const { isMobile } = useIsMobile();
  const userNickname = useAtomValue(getUserNickname);
  const createCrewDialogOpen = useSetAtom(createCrewDialogOpenAtom);
  const findCrewDialogOpen = useSetAtom(findCrewDialogOpenAtom);
  const { t } = useTranslation();

  return (isMobile ?
    <div style={{ display: "flex", width: "100%", flexDirection: "column", gap: "10px" }}>
      <Typography sx={{ fontSize: "1rem", fontWeight: "500" }}>
        {t("planHip.header.greeting", { nickname: userNickname })}
      </Typography>
      <Typography sx={{ fontSize: "0.9rem" }}>
        {t("planHip.header.sentence")}
      </Typography>
      {/* <CustomColorButton fontSize="0.9rem" text="크루 찾기" onClick={findCrewDialogOpen} /> */}
    </div> :
    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Typography sx={{ fontSize: "1.2rem", fontWeight: "500" }}>
          {t("planHip.header.greeting", { nickname: userNickname })}
        </Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Typography sx={{ fontSize: "0.9rem" }}>
            {t("planHip.header.sentence")}
          </Typography>
          {/* <IconButton
            onClick={findCrewDialogOpen}
            sx={{
              fontSize: "0.9rem",
              fontWeight: "bold",
              color: theme.colors.primary,
              display: "flex",
              gap: "5px",
              alignItems: "center",
            }}
          >
            <ArrowForwardIcon />
            {t("planHip.header.findCrew")}
          </IconButton> */}
        </div>
      </div>
      <IconButton
        onClick={createCrewDialogOpen}
        sx={{
          fontSize: "1.1rem",
          fontWeight: "bold",
          color: theme.colors.primary,
          display: "flex",
          gap: "5px",
          alignItems: "center",
        }}
      >
        <AddOutlinedIcon />
        {t("planHip.header.createCrew")}
      </IconButton>
    </div>
  )
}

export default PlanHipTopSection;
