import React from 'react';
import * as S from './styled';
import { useAtomValue, useSetAtom } from 'jotai';
import { getUserId } from '@/ViewModels/UserViewModel';
import crewAtom from '@/Atoms/CrewAtom';
import { crewJoinAtom, crewLeaveAtom } from '@/ViewModels/Crew/ViewModel';
import { useNavigate } from 'react-router-dom';
import useIsMobile from '@/Hooks/useIsMobile';
import IeumLogo from '@assets/Images/ieum_logo.png';
import CustomBackgroundColorButton from '@/Components/Common/Buttons/CustomBackgroundColor';
import { useTranslation } from 'react-i18next';

const CrewTopSection = () => {
  const { isMobile } = useIsMobile();
  const crew = useAtomValue(crewAtom);
  const userId = useAtomValue(getUserId);
  const joinCrew = useSetAtom(crewJoinAtom);
  const leaveCrew = useSetAtom(crewLeaveAtom);
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (crew &&
    <S.Container>
      <S.Title>
        <S.CrewThumbnail src={crew.crewInfo.imageUrl ? `${process.env.REACT_APP_CAREER_PLANNER_PLAN_MEDIA_ADDRESS}${crew.crewInfo.imageUrl.split("/media")[1]}` : IeumLogo} alt="logo" />
        {crew.crewInfo.name}
      </S.Title>
      {!isMobile &&
        (crew.members.map((member) => member.userId).includes(userId) ?
          <CustomBackgroundColorButton text={t("crew.header.leaveCrew")} onClick={() => leaveCrew(navigate)} fontSize="0.9rem" style={{ padding: "5px 10px", width: "140px" }} backgroundColor='#D32F2F' /> :
          crew.crewInfo.headCount !== crew.crewInfo.current ?
            <CustomBackgroundColorButton text={t("crew.header.joinCrew")} onClick={joinCrew} fontSize="0.9rem" style={{ padding: "5px 10px", width: "140px" }} /> : null)
      }
    </S.Container>
  );
}

export default CrewTopSection;
