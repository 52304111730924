import { CreateSignature, createSignatureAtom, createSignatureMobileModeAtom, initialCreateSignature, notSavedSignatureDialogAtom, signatureAtom, signatureCurrentIndexAtom, signatureDialogAtom, SignatureInfo } from '@/Atoms/Signature/Atom';
import { atom } from 'jotai';
import { getUserEmailAtom, getUserNickname } from '../UserViewModel';
import { v4 as uuidv4 } from 'uuid';
import i18n from '@/Locales/i18n';
import { TFunction } from 'i18next';
import snackbarAtom from '@/Atoms/Snackbar';
import { deleteSignature, setDefaultSignature } from '@/Queries/SignatureQueries';
import { handleReactQueryApiResponse, handleReactQueryApiResponseWithJson } from '@/Utils/APIUtil';
import { error401ModalAtom } from '@/Atoms/Dialogs/Error/401Atom';

// ViewModel
// 시그니쳐 가져오기
export const getSignatureAtom = atom((get) => {
  const signatures = get(signatureAtom);
  const representativeSignature = signatures.find((signature) => signature.representative);
  if (!representativeSignature) {
    return get(signatureAtom);
  }
  const notRepresentativeSignatures = get(signatureAtom).filter((signature) => !signature.representative);
  return [representativeSignature, ...notRepresentativeSignatures];
});

// 시그니쳐 다이얼로그 열기
export const openSignatureDialogAtom = atom(null, (get, set) => {
  set(signatureDialogAtom, {
    open: true,
    mode: "list",
  })
});

// 시그니쳐 다이얼로그 닫기
export const closeSignatureDialogAtom = atom(null, (get, set) => {
  set(signatureDialogAtom, {
    open: false,
    mode: "list",
  })
});

// 시그니쳐 다이얼로그 모드 변경
export const changeModeSignatureDialogAtom = atom((get) => get(signatureDialogAtom).mode, (get, set, mode: "list" | "create" | "edit" | "style") => {
  set(signatureDialogAtom, (prev) => ({
    ...prev,
    mode
  }));
});

// 이전 버튼을 눌렀을 때 수정 여부에 따라 저장 여부를 묻는 다이얼로그 열기
export const openNotSavedSignatureDialogAtom = atom(null, (get, set, isMobile: boolean) => {
  const mode = get(changeModeSignatureDialogAtom);
  const mobileCreateMode = get(createSignatureMobileModeAtom);
  if (isMobile && mobileCreateMode === "style") {
    set(createSignatureMobileModeAtom, "edit");
    return;
  }
  if (mode === "style") {
    set(changeModeSignatureDialogAtom, "create");
    return;
  }
  const createSignature = get(createSignatureAtom);
  if (!createSignature) {
    return;
  }

  if (
    createSignature.representative ||
    !createSignature.careerAdvancement ||
    !["새 시그니쳐", "New Signature"].includes(createSignature.name) ||
    createSignature.nickname !== get(getUserNickname) ||
    createSignature.info.filter((info) => info.value !== "").length > 0 ||
    createSignature.borderColor !== "#c0c0c0" ||
    createSignature.backgroundColor !== "#FFFFFF" ||
    createSignature.fontColor !== "#000000"
  ) {
    set(notSavedSignatureDialogAtom, true);
    return;
  }

  set(createSignatureAtom, null);
  set(changeModeSignatureDialogAtom, "list");
});

// 저장하지 않음을 선택했을 때 다이얼로그 닫기
export const closeNotSavedSignatureDialogAtom = atom(null, (get, set) => {
  set(createSignatureAtom, null);
  set(changeModeSignatureDialogAtom, "list");
  set(notSavedSignatureDialogAtom, false);
});

const createSignatureInfo = (label: string): SignatureInfo => {
  return {
    key: uuidv4(),
    label,
    value: "",
    orderBy: 0,
    visible: true,
  }
}

// 시그니쳐 생성 모드 진입
export const createSignatureModeAtom = atom(null, (get, set) => {
  const currentLanguage = i18n.language;
  const language = currentLanguage === "ko" ? "KR" : "EN";
  set(createSignatureAtom, initialCreateSignature(get(getUserNickname), get(getUserEmailAtom), language));
  set(changeModeSignatureDialogAtom, "create");
});

// 시그니쳐 수정 모드 진입
export const editSignatureModeAtom = atom(null, (get, set, id: number) => {
  const signature = get(signatureAtom).find((signature) => signature.id === id);
  if (!signature) {
    return;
  }
  const currentLanguage = i18n.language;
  const language = currentLanguage === "ko" ? "KR" : "EN";
  const signatureCareerInfos: SignatureInfo[] = [
    createSignatureInfo(language === "KR" ? "목표성적" : "Target Grade"),
    createSignatureInfo(language === "KR" ? "선호직업" : "Preferred Job"),
    createSignatureInfo(language === "KR" ? "관심사" : "Interest"),
  ].map((info, index) => ({
    ...info,
    orderBy: index,
  }));
  const editSignature: CreateSignature = {
    id: signature.id,
    representative: signature.representative,
    careerAdvancement: signature.careerAdvancement,
    name: signature.name,
    nickname: signature.nickname,
    tag: signature.tag || "",
    email: signature.email,
    isUserProfile: signature.isUserProfile,
    previewImage: signature.image,
    isUploadImage: false,
    image: null,
    info: [...signature.info],
    orderList: [...signature.info],
    borderColor: signature.borderColor,
    backgroundColor: signature.backgroundColor,
    fontColor: signature.fontColor,
  }
  set(createSignatureAtom, editSignature);
  set(changeModeSignatureDialogAtom, "edit");
});

// 시그니쳐 생성 모드에 필요한 atom 초기화
export const initializeCreateSignatureStateAtom = atom((get) => get(createSignatureAtom), (get, set) => {
  const currentLanguage = i18n.language;
  const language = currentLanguage === "ko" ? "KR" : "EN";
  set(createSignatureAtom, initialCreateSignature(get(getUserNickname), get(getUserEmailAtom), language));
})

// 시그니쳐 삭제 atom
export const deleteSignatureAtom = atom(null, async (get, set, id: number, t: TFunction) => {
  const signatures = get(signatureAtom);
  if (signatures.length === 1) {
    set(snackbarAtom, (prev) => ({ ...prev, open: true, message: t("signature.list.deleteLastSignature"), severity: "error" }));
    return;
  }
  const handleError401Modal = () => set(error401ModalAtom, true);

  const res = await handleReactQueryApiResponseWithJson(deleteSignature, handleError401Modal, id);
  if (!res) {
    return;
  }
  const response = res.signatures;
  set(signatureCurrentIndexAtom, (prev) => prev - 1);
  set(snackbarAtom, (prev) => ({ ...prev, open: true, message: t("signature.list.deleteSignature"), severity: "success" }));
  set(signatureAtom, response);
});

// 대표 시그니쳐 변경
export const changeRepresentativeSignatureAtom = atom(null, async (get, set, id: number, t: TFunction) => {
  const signatures = get(signatureAtom);
  const index = signatures.findIndex((signature) => signature.id === id);
  if (index === -1) {
    return;
  }
  const handleError401Modal = () => set(error401ModalAtom, true);
  const response = await handleReactQueryApiResponse(setDefaultSignature, handleError401Modal, id);
  if (response.ok) {
    const representativeSignature = {
      ...signatures[index],
      representative: true,
    }
    const notRepresentativeSignatures = signatures.filter((signature) => signature.id !== id).map((signature) => ({
      ...signature,
      representative: false,
    }));
    set(signatureAtom, [representativeSignature, ...notRepresentativeSignatures]);
    set(snackbarAtom, (prev) => ({ ...prev, open: true, message: t("signature.list.changeRepresentativeSuccess"), severity: "success" }));
  }
});
