// Plan에서 사용하는 Atom들을 정의합니다.

import planAtom, { mobilePlanAtom } from "@/Atoms/Plan";
import { deletePlan, getPlans, parseGetPlansResponse, updatePlanName } from "@/Queries/PlanQueries";
import { atom } from "jotai";
import { getUserId } from "../UserViewModel";
import { createPlan } from "@/Queries/PlanQueries";
import snackbarAtom from "@/Atoms/Snackbar";
import { NavigateFunction } from "react-router-dom";
import GetIdFromQuerystring from "@/Utils/GetIdFromQuerystring";
import { getPlanDataGanttAtom, getPlanDataMindmapAtom, getPlanDataRoutineAtom, getPlanDataTodoAtom } from "./InnerTabViewModel";
import deletePlanDialogAtom from "@/Atoms/Dialogs/Delete/PlanAtom";
import { handleReactQueryApiResponse, handleReactQueryApiResponseWithJson } from "@/Utils/APIUtil";
import { error401ModalAtom } from "@/Atoms/Dialogs/Error/401Atom";

// 직접 만들기 눌렀을 때
export const createNewPlanAtom = atom(null, async (get, set, navigate: NavigateFunction) => {
  const userId = get(getUserId);
  if (userId) {
    const response = await handleReactQueryApiResponse(createPlan, () => set(error401ModalAtom, true), userId);
    if (!response) {
      set(snackbarAtom, (prev) => ({ ...prev, open: true, message: "플랜 생성에 실패하였습니다. 잠시 후 다시 시도해주세요." }));
      return;
    }
    const { plan_id: planId, inner_tab_id: innerTabId } = await response.json();
    const newPath = `/plan?id=${planId}&inner_tab_id=${innerTabId}&new_plan=1`;
    navigate(newPath);
    set(getPlansAtom);
  }
});

// Plan 불러오기
export const getPlansAtom = atom((get) => get(planAtom), async (get, set) => {
  // API 호출
  const response = await handleReactQueryApiResponseWithJson(getPlans, () => set(error401ModalAtom, true), get(getUserId));
  if (!response) return;
  const planData = parseGetPlansResponse(response);
  set(planAtom, planData);
});

// Plan 이름 변경
export const updatePlanNameAtom = atom(null, async (get, set, name: string) => {
  if (!name) {
    set(snackbarAtom, (prev) => ({ ...prev, open: true, message: "플랜 이름을 입력해주세요." }));
    return;
  }
  const planId = GetIdFromQuerystring("id");
  if (!planId) return;
  const response = await handleReactQueryApiResponse(updatePlanName, () => set(error401ModalAtom, true), planId, name);
  if (!response.ok) {
    set(snackbarAtom, (prev) => ({ ...prev, open: true, message: "플랜 이름 변경에 실패하였습니다. 잠시 후 다시 시도해주세요." }));
    return;
  }
  set(snackbarAtom, (prev) => ({ ...prev, open: true, message: "플랜 이름이 변경되었습니다." }));
  set(mobilePlanAtom, name);
  set(getPlansAtom);
});

// 현재 상태에 대한 데이터 동기화
export const syncPlanAtom = atom(null, (get, set, viewType: "mindmap" | "gantt" | "routine" | "todo") => {
  // Plans 불러오기
  set(getPlansAtom);
  // // 현재 innerTabId에 대한 데이터 불러오기
  // set(getPlanDataAtom);
  switch (viewType) {
    case "mindmap":
      set(syncMindmapAtom);
      break;
    case "gantt":
      set(syncGanttAtom);
      break;
    case "routine":
      set(syncRoutineAtom);
      break;
    case "todo":
      set(syncTodoAtom);
      break;
    default:
      set(syncMindmapAtom);
  }
});

// viewType === "mindmap"일 때, 현재 데이터 동기화
const syncMindmapAtom = atom(null, (get, set) => set(getPlanDataMindmapAtom));

// viewType === "todo"일 때, 현재 데이터 동기화
const syncTodoAtom = atom(null, (get, set) => set(getPlanDataTodoAtom));

// viewType === "routine"일 때, 현재 데이터 동기화
const syncRoutineAtom = atom(null, (get, set) => set(getPlanDataRoutineAtom));

// viewType === "gantt"일 때, 현재 데이터 동기화
const syncGanttAtom = atom(null, (get, set) => set(getPlanDataGanttAtom));

// Plan 삭제
export const deletePlanAtom = atom(null, async (get, set) => {
  const planId = GetIdFromQuerystring("id");
  if (!planId) return;
  const response = await handleReactQueryApiResponse(deletePlan, () => set(error401ModalAtom, true), planId);
  if (!response.ok) {
    set(snackbarAtom, (prev) => ({ ...prev, open: true, message: "플랜 삭제에 실패하였습니다. 잠시 후 다시 시도해주세요." }));
    return;
  }
  set(deletePlanDialogAtom, { open: true, complete: true });
  set(getPlansAtom);
});
