import createCourseDialogAtom from "@/Atoms/Dialogs/Create/Course";
import { Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import * as S from "./styled";
import { createCourseDialogAnnouncementAtom, createCourseDialogApplicationMethodAtom, createCourseDialogApplicationPeriodAtom, createCourseDialogApplicationPeriodDateAtom, createCourseDialogApplicationPeriodDetailAtom, createCourseDialogApplicationPeriodTimeAtom, createCourseDialogCloseAtom, createCourseDialogCostDisclosureAtom, createCourseDialogCourseEvaluationAddQuestionAtom, createCourseDialogCourseEvaluationAtom, createCourseDialogCourseNameAtom, createCourseDialogCoursePeriodAtom, createCourseDialogOperatingTimesAtom, createCourseDialogOperatingTimesDayAtom, createCourseDialogOperatingTimesTimeAtom, createCourseDialogSaveAtom, createCourseDialogSelectionGroupAtom, createCourseDialogSubsidyContentAtom, createCourseDialogTeacherAtom, createCourseDialogViewOptionGradeAtom, createCourseDialogViewOptionIsVisibleAtom } from "@/ViewModels/Dialogs/Create/CourseViewModel";
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import theme from "@/Styles/theme";
import CustomColorButton from "@/Components/Common/Buttons/CustomColor";
import CustomBackgroundColorButton from "@/Components/Common/Buttons/CustomBackgroundColor";
import DownloadExcelButton from "@/Components/AfterSchool/Contents/DownloadExcelButton";
import AddIcon from '@mui/icons-material/Add';

const CreateCourseDialog = () => {
  const dialog = useAtomValue(createCourseDialogAtom);
  const closeDialog = useSetAtom(createCourseDialogCloseAtom);
  const saveDialog = useSetAtom(createCourseDialogSaveAtom);
  const [teacher, editTeacher] = useAtom(createCourseDialogTeacherAtom);
  const applicationPeriod = useAtomValue(createCourseDialogApplicationPeriodAtom);
  const editApplicationPeriodDate = useSetAtom(createCourseDialogApplicationPeriodDateAtom);
  const editApplicationPeriodTime = useSetAtom(createCourseDialogApplicationPeriodTimeAtom);
  const editApplicationPeriodDetail = useSetAtom(createCourseDialogApplicationPeriodDetailAtom);
  const [applicationMethod, editApplicationMethod] = useAtom(createCourseDialogApplicationMethodAtom);
  const [coursePeriod, editCoursePeriod] = useAtom(createCourseDialogCoursePeriodAtom);
  const [courseName, editCourseName] = useAtom(createCourseDialogCourseNameAtom);
  const [viewOptionIsVisible, editViewOptionIsVisible] = useAtom(createCourseDialogViewOptionIsVisibleAtom);
  const [viewOptionGrade, editViewOptionGrade] = useAtom(createCourseDialogViewOptionGradeAtom);
  const [selectionGroup, editSelectionGroup] = useAtom(createCourseDialogSelectionGroupAtom);
  const [operatingTimes, editOperatingTimes] = useAtom(createCourseDialogOperatingTimesAtom);
  const editOperatingTimesDay = useSetAtom(createCourseDialogOperatingTimesDayAtom);
  const editOperatingTimesTime = useSetAtom(createCourseDialogOperatingTimesTimeAtom);
  const [isCostDisclosured, editIsCostDisclosured] = useAtom(createCourseDialogCostDisclosureAtom);
  const [announcement, editAnnouncement] = useAtom(createCourseDialogAnnouncementAtom);
  const [subsidyContent, editSubsidyContent] = useAtom(createCourseDialogSubsidyContentAtom);
  const [courseEvaluation, editCourseEvaluation] = useAtom(createCourseDialogCourseEvaluationAtom);
  const addQuestion = useSetAtom(createCourseDialogCourseEvaluationAddQuestionAtom)
  const daysOfWeek = ["월", "화", "수", "목", "금", "토", "일"];
  const times = Array.from({ length: 14 }, (_, i) => i); // 0 to 13 time slots

  return (
    <Dialog open={dialog.open} fullScreen fullWidth>
      <DialogTitle sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", fontSize: "0.9rem", fontWeight: "bold", backgroundColor: theme.colors.primary, color: "white" }}>
        <div>과정 개설하기</div>
        <CloseIcon onClick={closeDialog} sx={{ color: "white", cursor: "pointer" }} />
      </DialogTitle>
      <DialogContent sx={{ paddingTop: "20px !important" }}>

        {/* 과정개설 1 */}
        <S.ContentContainer>
          <S.ContentTitle>담당교사</S.ContentTitle>
          <S.ContentContent>
            <input type="text" style={S.ContentInputStyle(100)} value={teacher} onChange={(e) => editTeacher(e.target.value)} />
          </S.ContentContent>
        </S.ContentContainer>
        <S.ContentContainer>
          <S.ContentTitle>신청기간</S.ContentTitle>
          <S.ContentContent>
            <S.ContentContentRow>
              <input
                type="date"
                style={S.ContentInputStyle(120)}
                value={applicationPeriod.start.date}
                onChange={(e) => editApplicationPeriodDate(new Date(e.target.value), "start")}
              />일

              <input
                type="number"
                style={S.ContentInputStyle(30)}
                value={applicationPeriod.start.hour}
                min="0"
                max="23" // Restricts hours from 0 to 23
                onChange={(e) => editApplicationPeriodTime(parseInt(e.target.value), "start", "hour")}
              />시

              <input
                type="number"
                style={S.ContentInputStyle(30)}
                value={applicationPeriod.start.minute}
                min="0"
                max="59" // Restricts minutes from 0 to 59
                onChange={(e) => editApplicationPeriodTime(parseInt(e.target.value), "start", "minute")}
              />분 부터

              <input
                type="date"
                style={S.ContentInputStyle(120)}
                value={applicationPeriod.end.date}
                onChange={(e) => editApplicationPeriodDate(new Date(e.target.value), "end")}
              />일

              <input
                type="number"
                style={S.ContentInputStyle(30)}
                value={applicationPeriod.end.hour}
                min="0"
                max="23" // Restricts hours from 0 to 23
                onChange={(e) => editApplicationPeriodTime(parseInt(e.target.value), "end", "hour")}
              />시

              <input
                type="number"
                style={S.ContentInputStyle(30)}
                value={applicationPeriod.end.minute}
                min="0"
                max="59" // Restricts minutes from 0 to 59
                onChange={(e) => editApplicationPeriodTime(parseInt(e.target.value), "end", "minute")}
              />분 까지
            </S.ContentContentRow>
            <S.ContentContentRow>
              <Checkbox checked={applicationPeriod.detail.option1} onChange={() => editApplicationPeriodDetail(1)} />
              대기 상태일 때 지도교사 비공개
            </S.ContentContentRow>
            <S.ContentContentRow>
              <Checkbox checked={applicationPeriod.detail.option2} onChange={() => editApplicationPeriodDetail(2)} />
              방과후 담당자 외 강좌수정 금지
            </S.ContentContentRow>
            <S.ContentContentRow>
              <Checkbox checked={applicationPeriod.detail.option3} onChange={() => editApplicationPeriodDetail(3)} />
              방과후 담당자 외 수강신청 금지
            </S.ContentContentRow>
            <S.ContentContentRow>
              <Checkbox checked={applicationPeriod.detail.option4} onChange={() => editApplicationPeriodDetail(4)} />
              강좌마다 신청기간 설정 허용(강좌별로 따로 신청받을 경우에만 사용)
            </S.ContentContentRow>
            <S.ContentContentRow>
              <Checkbox checked={applicationPeriod.detail.option5} onChange={() => editApplicationPeriodDetail(5)} />
              강좌 운영시간(강좌 개설 시 체크한 시간) 중복 시 수강신청 금지
            </S.ContentContentRow>
          </S.ContentContent>
        </S.ContentContainer>
        <S.ContentContainer>
          <S.ContentTitle>신청 방법</S.ContentTitle>
          <S.ContentContent>
            <select style={S.ContentInputStyle(150)} value={applicationMethod} onChange={(e) => editApplicationMethod(parseInt(e.target.value) as 0 | 1 | 2 | 3 | 4 | 5)}>
              <option value={0}>-제한 조건 선택-</option>
              <option value={1}>과목군별 택1 이내</option>
              <option value={2}>과목군별 택2 이내</option>
              <option value={3}>과목군별 택3 이내</option>
              <option value={4}>일괄신청 금지</option>
              <option value={5}>개별신청 금지</option>
            </select>
          </S.ContentContent>
        </S.ContentContainer>
        <S.ContentContainer>
          <S.ContentTitle>수강기간</S.ContentTitle>
          <S.ContentContent>
            <S.ContentContentRow>
              <input type="date" style={S.ContentInputStyle(120)} value={coursePeriod.startDate} onChange={(e) => editCoursePeriod(new Date(e.target.value), "startDate")} />일 부터
              <input type="date" style={S.ContentInputStyle(120)} value={coursePeriod.endDate} onChange={(e) => editCoursePeriod(new Date(e.target.value), "endDate")} />일 까지
            </S.ContentContentRow>
          </S.ContentContent>
        </S.ContentContainer>
        <S.ContentContainer>
          <S.ContentTitle>과정 이름</S.ContentTitle>
          <S.ContentContent>
            <input type="text" style={S.ContentInputStyle(400)} value={courseName} onChange={(e) => editCourseName(e.target.value)} />
            <div style={{ fontSize: "0.8rem", fontWeight: "bold" }}>*예시: 2022년 1학기 1학년 방과후학교</div>
          </S.ContentContent>
        </S.ContentContainer>

        {/* 과정개설 2 */}
        <S.ContentContainer>
          <S.ContentTitle>보기 옵션</S.ContentTitle>
          <S.ContentContent>
            <S.ContentContentRow>
              <Checkbox checked={viewOptionIsVisible} onChange={() => editViewOptionIsVisible(!viewOptionIsVisible)} />
              과정목록에서 이 과정 숨기기(담당자에게만 보임)
            </S.ContentContentRow>
            <S.ContentContentRow>
              강좌목록에서
              <select style={S.ContentInputStyle(120)} value={viewOptionGrade} onChange={(e) => editViewOptionGrade(parseInt(e.target.value) as 0 | 1 | 2 | 3)}>
                <option value={0}>재학생</option>
                <option value={1}>외부강사</option>
                <option value={2}>선생님</option>
                <option value={3}>방과후담당</option>
              </select>
              등급 이상에게만 수강신청인원 보이기
            </S.ContentContentRow>
          </S.ContentContent>
        </S.ContentContainer>
        <S.ContentContainer>
          <S.ContentTitle>선택군</S.ContentTitle>
          <S.ContentContent>
            <input style={S.ContentInputStyle(300)} placeholder="월;화;수;목;금" value={selectionGroup} onChange={(e) => editSelectionGroup(e.target.value)} />
            <div style={{ fontSize: "0.8rem", fontWeight: "bold" }}>
              *세미콜론(;)으로 구분하여 선택군 종류 입력(예시1: "8교시;9교시;10교시" 예시2: "월수8;화목8")
            </div>
            <div style={{ fontSize: "0.8rem", fontWeight: "bold" }}>
              *선택군마다 한 강좌만(선택군이 없거나 '미지정'이면 무제한) 신청 가능
            </div>
          </S.ContentContent>
        </S.ContentContainer>
        <S.ContentContainer>
          <S.ContentTitle>운영시간</S.ContentTitle>
          <S.ContentContent>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">교시</TableCell>
                    {daysOfWeek.map((day) => (
                      <TableCell key={day} align="center" >
                        <S.ContentContentRow>
                          <KeyboardDoubleArrowDownIcon onClick={() => editOperatingTimesDay(day)} sx={{ cursor: "pointer", border: "1px solid #c0c0c0", borderRadius: "5px" }} />
                          {day}
                        </S.ContentContentRow>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {times.map((time) => (
                    <TableRow key={time}>
                      <TableCell align="center">
                        <S.ContentContentRow>
                          {time}교시
                          <KeyboardDoubleArrowRightIcon onClick={() => editOperatingTimesTime(time)} sx={{ cursor: "pointer", border: "1px solid #c0c0c0", borderRadius: "5px" }} />
                        </S.ContentContentRow>
                      </TableCell>
                      {daysOfWeek.map((day, index) => (
                        <TableCell key={day + time} align="center">
                          <Checkbox checked={operatingTimes[day].includes(time)} onChange={() => editOperatingTimes(day, time)} />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </S.ContentContent>
        </S.ContentContainer>
        <S.ContentContainer>
          <S.ContentTitle>
            <div style={{ display: "flex", flexDirection: "column", gap: "5px", justifyContent: "center", alignItems: "center" }}>
              <div>
                운영 계획
              </div>
              <div>
                (공지사항)
              </div>
            </div>
          </S.ContentTitle>
          <S.ContentContent>
            <TextField multiline fullWidth placeholder="방과후학교 공지사항 입력" minRows={5} maxRows={20} value={announcement} onChange={(e) => editAnnouncement(e.target.value)} />
            <div style={{ fontSize: "0.8rem", fontWeight: "bold", display: "flex", flexDirection: "row" }}>
              현재 글자수: <div style={{ color: theme.colors.primary, marginLeft: "5px" }}>{announcement.length}</div>자
            </div>
          </S.ContentContent>
        </S.ContentContainer>

        {/* 과정개설 3 */}
        <S.ContentContainer>
          <S.ContentTitle>수강료 내역</S.ContentTitle>
          <S.ContentContent>
            <S.ContentContentRow>
              <select style={S.ContentInputStyle(100)} value={isCostDisclosured ? "공개" : "비공개"} onChange={(e) => editIsCostDisclosured(e.target.value === "공개")}>
                <option value={"공개"}>공개</option>
                <option value={"비공개"}>비공개</option>
              </select>
              <div style={{ display: "flex", flexDirection: "column", gap: "10px", fontSize: "0.8rem", fontWeight: "bold" }}>
                <div>*비공개로 설정하면 학생이나 일반 교사는 수강료를 볼 수 없습니다.</div>
              </div>
            </S.ContentContentRow>
          </S.ContentContent>
        </S.ContentContainer>
        <S.ContentContainer>
          <S.ContentTitle>
            <div style={{ display: "flex", flexDirection: "column", gap: "5px", justifyContent: "center", alignItems: "center" }}>
              <div>
                지원금
              </div>
              <div>
                (옵션)
              </div>
            </div>
          </S.ContentTitle>
          <S.ContentContent>
            <TextField multiline fullWidth placeholder="지원금 양식 입력" minRows={5} maxRows={20} value={subsidyContent} onChange={(e) => editSubsidyContent(e.target.value)} />
            <div style={{ fontSize: "0.8rem", fontWeight: "bold", display: "flex", flexDirection: "row" }}>
              *'학번/이름/자유수강권' 형식으로 된 <DownloadExcelButton />의 셀내용을 복사하여 붙여넣어주세요.
            </div>
            <div style={{ fontSize: "0.8rem", fontWeight: "bold" }}>
              *자유수강권 잔액이 없거나 미확정인 학생은 금액을 '0'으로 입력하세요.
            </div>
            <div style={{ fontSize: "0.8rem", fontWeight: "bold" }}>
              *무료 수강생은 '자유수강권'란에 '무료'라고 입력하세요.
            </div>
            <div style={{ fontSize: "0.8rem", fontWeight: "bold" }}>
              *신청자 수에 따라 강사료를 책정하려면 수강권 엑셀 양식 하단에 있는 학생수/강사료를 수정 후 붙여넣어주세요.
            </div>
            <div style={{ fontSize: "0.8rem", fontWeight: "bold" }}>
              *'학생수/강사비' 형식의 셀내용은 '학번/이름/자유수강권' 형식의 셀내용의 끝에 한 줄 띄운 형태로 붙여넣어주세요.
            </div>
          </S.ContentContent>
        </S.ContentContainer>
        <S.ContentContainer style={{ borderBottom: "1px solid #c0c0c0" }}>
          <S.ContentTitle>강의평가</S.ContentTitle>
          <S.ContentContent>
            <S.ContentContentRow>
              기간:
              <input type="date" style={{ width: "100px" }} />일 부터
              <input type="date" style={{ width: "100px" }} />일 까지
            </S.ContentContentRow>
            <TextField multiline fullWidth placeholder="1. (필수)(학생)이 강좌에 대해 만족하십니까? ①: 매우 그렇다. ②: 그렇다. ③: 보통이다. ④: 그렇지 않다. ⑤: 전혀 그렇지 않다." minRows={5} maxRows={20} value={courseEvaluation} onChange={(e) => editCourseEvaluation(e.target.value)} />
            <S.ContentContentRow>
              <div style={{ fontSize: "0.8rem", fontWeight: "bold" }}>
                문항 추가
              </div>
              <AddIcon sx={{ cursor: "pointer", border: "1px solid #c0c0c0", color: "#777", borderRadius: "5px", "&:hover": { border: "1px solid black", color: "black" } }} onClick={addQuestion} />
            </S.ContentContentRow>
            <div style={{ fontSize: "0.8rem", fontWeight: "bold" }}>
              *문항은 최대 30개까지만 가능합니다.
            </div>
            <div style={{ fontSize: "0.8rem" }}>
              [강의평가 입력 방법]
            </div>
            <div style={{ fontSize: "0.8rem" }}>
              1. 각 문항마다 새 줄에 입력하세요.
            </div>
            <div style={{ fontSize: "0.8rem" }}>
              2. 답지(①②③④⑤)가 없으면 서술형 문항, 문제번호 뒤에 "(필수)"가 있으면 필수 문항으로 인식됩니다.
            </div>
            <div style={{ fontSize: "0.8rem" }}>
              3. 통계 산출 시 문항별 5점 만점(①5점, ②4점, ③3점, ④2점, ⑤1점)으로 계산합니다.
            </div>
          </S.ContentContent>
        </S.ContentContainer>
      </DialogContent>
      <DialogActions>
        <CustomColorButton text="취소" onClick={closeDialog} />
        <CustomBackgroundColorButton text="저장" onClick={saveDialog} />
      </DialogActions>
    </Dialog>
  )
}

export default CreateCourseDialog;
