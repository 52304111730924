import React from "react";
import * as S from "./styled";
import useIsMobile from "../../../../../Hooks/useIsMobile";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { deleteStoryAtom, getStoriesAtom } from "../../../../../ViewModels/StoryViewModel";
import StoryGrouping from "./grouping";
import storiesAtom, { StoryProps, storySortAtom, waitingDeleteStoryAtom } from "@/Atoms/StoryAtom";
import StoryItem from "./item";
import { Button, IconButton, Snackbar, SnackbarCloseReason } from "@mui/material";
import theme from "@/Styles/theme";
import CloseIcon from '@mui/icons-material/Close';

interface StoryListProps {
  startEditingStory: (story: StoryProps) => void
}

const StoryList = ({ startEditingStory }: StoryListProps) => {
  const { isMobile, isTablet } = useIsMobile();
  const [stories, setStories] = useAtom(storiesAtom);
  const storySort = useAtomValue(storySortAtom);
  const getStories = useSetAtom(getStoriesAtom);
  const [waitingDeleteStory, setWaitingDeleteStory] = useAtom(waitingDeleteStoryAtom);
  const deleteStory = useSetAtom(deleteStoryAtom);

  const getHeight = () => {
    if (isMobile) {
      return "100%";
    } else if (isTablet) {
      return "calc(100vh - 272px)";
    } else {
      return "calc(100vh - 216px)";
    }
  }

  const handleSnackbarClose = (
    event: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason,
  ) => {
    if (reason === 'clickaway' || waitingDeleteStory === null) {
      return;
    }
    deleteStory(waitingDeleteStory.id as number);
    setWaitingDeleteStory(null);
  };

  const action = (
    <React.Fragment>
      <Button size="small" onClick={() => setWaitingDeleteStory(null)} sx={{ color: theme.colors.primary }}>
        실행 취소
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleSnackbarClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment >
  );

  React.useEffect(() => {
    const fetchStories = async () => {
      await getStories();
    }
    setStories([]);
    fetchStories();
    // eslint-disable-next-line
  }, [storySort, getStories]);

  return (
    <>
      <StoryGrouping />
      <S.StoryList $height={getHeight()}>
        {stories.map((story, index) => (
          waitingDeleteStory && waitingDeleteStory.id === story.id ? null :
            <StoryItem key={index} item={story} startEditingStory={startEditingStory} />
        ))}
      </S.StoryList>
      <Snackbar
        open={waitingDeleteStory !== null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message="스토리가 삭제되었습니다."
        action={action}
      />
    </>
  )
}

export default StoryList;
