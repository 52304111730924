import { Node } from '@xyflow/react';
import { atom } from 'jotai';

interface InactivateDialogAtom {
  open: boolean;
  node: Node;
}

const inactivateDialogAtom = atom<InactivateDialogAtom | null>(null);

export default inactivateDialogAtom;
