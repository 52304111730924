import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Tooltip,
} from "@mui/material";
import theme from "../../../../../Styles/theme";
import { getUserNickname } from "@/ViewModels/UserViewModel";
import { useAtomValue } from "jotai";
import { LinearProgress } from "@mui/material";
import teacherDashboardAtom from "@/Atoms/Dashboard/TeacherAtom";
import convertRelativeTime from "@/Utils/ConvertRelativeTime";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

const HabitTable: React.FC = () => {
  const userNickname = useAtomValue(getUserNickname);
  const careerPlanningData = useAtomValue(teacherDashboardAtom).careerPlanning;
  const { t } = useTranslation();
  // Color mapping for activities
  const colorMap = {
    study: "#4caf50", // green
    rest: "#ffeb3b", // yellow
    hobbies: "#2196f3", // blue
    career: "#f44336", // red
  };

  return (
    <TableContainer sx={{ flexGrow: 1 }}>
      <Table stickyHeader aria-label="habit table">
        <TableHead>
          <TableRow >
            <TableCell
              style={{
                backgroundColor: theme.colors.primary,
                color: "white",
              }}
            >
              {t("dashboard.teacher.careerPlanning.crewName")}
            </TableCell>
            <TableCell
              style={{
                backgroundColor: theme.colors.primary,
                color: "white",
              }}
            >
              {t("dashboard.teacher.careerPlanning.menteeName")}
            </TableCell>
            <TableCell
              style={{
                backgroundColor: theme.colors.primary,
                color: "white",
              }}
            >
              {t("dashboard.teacher.careerPlanning.contentName")}
            </TableCell>
            <TableCell
              style={{
                backgroundColor: theme.colors.primary,
                color: "white",
              }}
            >
              {t("dashboard.teacher.careerPlanning.recentlyEntered")}
            </TableCell>
            <TableCell
              style={{
                backgroundColor: theme.colors.primary,
                color: "white",
              }}
            >
              {t("dashboard.teacher.careerPlanning.numberOfUltimateSkills")}
            </TableCell>
            <TableCell
              style={{
                backgroundColor: theme.colors.primary,
                color: "white",
              }}
            >
              {t("dashboard.teacher.careerPlanning.numberOfTotalTodos")}
            </TableCell>
            <TableCell
              style={{
                backgroundColor: theme.colors.primary,
                color: "white",
              }}
            >
              {t("dashboard.teacher.careerPlanning.careerGrowthRate")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {careerPlanningData.map((row) => ({ ...row, mentor: userNickname })).map((row, index) => {
            return (
              <TableRow key={row.mentor + row.mentee + index} sx={{ fontSize: "0.8rem" }}>
                <TableCell>{row.crew}</TableCell>
                <TableCell>{row.mentee}</TableCell>
                <TableCell>{row.content}</TableCell>
                <Tooltip title={dayjs(row.lastStudyDate).format("YYYY년 MM월 DD일 HH시 mm분")}>
                  <TableCell>{convertRelativeTime(new Date(row.lastStudyDate))}</TableCell>
                </Tooltip>
                <TableCell>{row.numberOfUltimateSkills}</TableCell>
                <TableCell>{row.numberOfTodos}</TableCell>
                <TableCell sx={{
                  width: "100px"
                }}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box sx={{ width: '100%', mr: 1 }}>
                      <LinearProgress
                        variant="determinate"
                        value={row.careerAchievement}
                        sx={{
                          height: 10,
                          borderRadius: 1,
                          backgroundColor: "gray",
                          "& .MuiLinearProgress-bar": {
                            borderRadius: 1,
                            backgroundColor: colorMap.study,
                          },
                        }}
                      />
                    </Box>
                    <Box sx={{ minWidth: 35 }}>
                      <span>{`${row.careerAchievement}%`}</span>
                    </Box>
                  </Box>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default HabitTable;
