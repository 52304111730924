import { useQuery } from 'react-query';
import { useAtom, useSetAtom } from 'jotai';
import { useCrewAtom } from '@/Atoms/HookControllerAtom';
import GetIdFromQuerystring from '@/Utils/GetIdFromQuerystring';
import { useNavigate } from 'react-router-dom';
import { getCrew, parseCrew } from '@/Queries/CrewQueries';
import crewAtom from '@/Atoms/CrewAtom';
import { edgesAtom, nodesAtom } from '@/Atoms/Plan/MindmapAtom';
import { devConsoleError } from '@/Utils/ConsoleLogInDevelopment';
import { handleReactQueryApiResponse } from '@/Utils/APIUtil';
import { Node } from '@xyflow/react';
import { error401ModalAtom } from '@/Atoms/Dialogs/Error/401Atom';

const useCrew = () => {
  const crewId = GetIdFromQuerystring("crew_id");
  const [controller, setController] = useAtom(useCrewAtom);
  const navigate = useNavigate();
  const setCrew = useSetAtom(crewAtom);
  const setNodes = useSetAtom(nodesAtom);
  const setEdges = useSetAtom(edgesAtom);
  const setError401Modal = useSetAtom(error401ModalAtom);

  return useQuery(['crews'], () => handleReactQueryApiResponse(getCrew, () => setError401Modal(true), crewId), {
    enabled: controller,
    onSuccess: async (data) => {
      if (!data) return;
      const jsonedData = await data.json();
      const crewData = parseCrew(jsonedData);
      const nodes = jsonedData.nodes_and_edges.nodes.map((node: Node) => ({
        ...node,
        data: {
          ...node.data,
          comments: node.data.comments ? node.data.comments : [],
        }
      }));
      const edges = jsonedData.nodes_and_edges.edges;
      setController(false);
      setCrew(crewData);
      setNodes(nodes);
      setEdges(edges);
    },
    onError: (error) => {
      devConsoleError(error);
      navigate('/plan-hip');
    }
  });
};

export default useCrew;
