import { Node } from '@xyflow/react';
import dayjs, { Dayjs } from 'dayjs';
import { atom } from 'jotai';

export interface RoutineData {
  id: string;
  date: Dayjs;
  tasks: Node[];
}

interface Routine {
  isInit: boolean;
  startDate: Dayjs;
  endDate: Dayjs;
  data: RoutineData[];
  unclassifiedTasks: Node[];
};

const routineAtom = atom<Routine>({
  isInit: false,
  startDate: dayjs(),
  endDate: dayjs(),
  data: [],
  unclassifiedTasks: [],
});

export const expandedRoutineDataAtom = atom<string[]>([]);

export default routineAtom;
