import { SvgIconProps, Tooltip } from "@mui/material";
import React from "react";
import * as S from "./styled";
import TopSectionButtons from "./topSectionButtons";
import BottomSectionButtons from "./bottomSectionButtons";

interface SidebarToggleButtonProps {
  onClick: () => void;
  tooltipTitle: string;
  icon: React.ElementType<SvgIconProps>;
  iconSize: number;
  color: string;
}

const SidebarToggleButton: React.FC<SidebarToggleButtonProps> = ({
  onClick, tooltipTitle, icon: Icon, iconSize, color
}) => {
  return (
    <Tooltip title={tooltipTitle} placement="right" arrow>
      <Icon
        onClick={onClick}
        style={{
          width: iconSize,
          height: iconSize,
          display: "flex",
          alignItems: "center",
          color: color,
          cursor: "pointer",
        }}
      />
    </Tooltip>
  );
}

interface SidebarButtonProps {
  buttons: SidebarToggleButtonProps[];
}

export const SidebarButtons: React.FC<SidebarButtonProps> = ({ buttons }) => {
  return (
    <>
      {buttons.map((button, index) => (
        <SidebarToggleButton
          key={index}
          onClick={button.onClick}
          tooltipTitle={button.tooltipTitle}
          icon={button.icon}
          iconSize={button.iconSize}
          color={button.color}
        />
      ))}
    </>
  );
}

export const AccordionCloseSidebar = () => {
  const topSectionButtons = TopSectionButtons();
  const bottomSectionButtons = BottomSectionButtons();
  return (
    <>
      <S.AccordionCloseSection>
        <SidebarButtons buttons={topSectionButtons} />
      </S.AccordionCloseSection>
      <S.AccordionCloseSection>
        <SidebarButtons buttons={bottomSectionButtons} />
      </S.AccordionCloseSection>
    </>
  )
}
