import React, { useState } from "react";
import theme from "@/Styles/theme";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';

interface TableInfoRow {
  cells: React.ReactNode[];
}

interface CommonTableProps {
  headers: string[];
  rows: TableInfoRow[];
  extendContents?: React.ReactNode[];
}

const CommonTable = ({ headers, rows, extendContents }: CommonTableProps) => {
  // 각 row의 extend 상태를 관리하는 상태
  const [extendedRows, setExtendedRows] = useState<boolean[]>(rows.map(() => false));
  const tableRef = React.useRef<HTMLTableElement>(null);
  const [cellWidth, setCellWidth] = useState<number>(0);

  const toggleExtend = (index: number) => {
    const newExtendedRows = extendedRows.map((extend, i) => (i === index ? !extend : extend));
    setExtendedRows(newExtendedRows);
  };

  const calculateCellWidth = () => {
    if (tableRef.current) {
      const tableWidth = tableRef.current.offsetWidth - (extendContents ? 30 : 0); // Subtract padding or margin if any
      const cellCount = headers.length;
      const calculatedWidth = tableWidth / cellCount;
      setCellWidth(calculatedWidth);
    }
  };

  // UseLayoutEffect to measure the DOM after rendering
  React.useLayoutEffect(() => {
    calculateCellWidth();
    window.addEventListener("resize", calculateCellWidth);
    return () => {
      window.removeEventListener("resize", calculateCellWidth);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headers.length]); // Dependency on headers length

  return (
    <TableContainer
      sx={{ width: "100%", flexGrow: 1, overflowX: "hidden" }}
      ref={tableRef}
    >
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {
              extendContents &&
              <TableCell
                style={{
                  backgroundColor: theme.colors.primary,
                  color: "white",
                  fontSize: "0.9rem",
                  fontWeight: "bold",
                  padding: "4px",
                  textAlign: "center",
                  minWidth: "22px",
                  maxWidth: "22px",
                }}
              />
            }
            {headers.map((name, index) => (
              <TableCell
                key={`header-${index}`}
                style={{
                  backgroundColor: theme.colors.primary,
                  color: "white",
                  fontSize: "0.9rem",
                  fontWeight: "bold",
                  padding: "4px",
                  textAlign: "center",
                  minWidth: `${cellWidth - 8}px`,
                  maxWidth: `${cellWidth - 8}px`,
                }}
              >
                {name}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <React.Fragment key={`row-${index}`}>
              <TableRow
                sx={{
                  "&:hover": {
                    backgroundColor: "#f5f5f5",
                  },
                }}
              >
                {
                  extendContents &&
                  <TableCell
                    style={{
                      padding: "4px",
                      textAlign: "center",
                      minWidth: "22px",
                      maxWidth: "22px",
                      cursor: "pointer",
                    }}
                    onClick={() => toggleExtend(index)}
                  >
                    {extendedRows[index] ?
                      <KeyboardArrowUpOutlinedIcon /> :
                      <KeyboardArrowDownOutlinedIcon />
                    }
                  </TableCell>
                }
                {row.cells.map((cell, cellIndex) => (
                  <TableCell
                    key={`cell-${cellIndex}`}
                    sx={{
                      fontSize: "0.8rem",
                      padding: "4px",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      minWidth: `${cellWidth - 8}px`,
                      maxWidth: `${cellWidth - 8}px`,
                    }}
                  >
                    {cell}
                  </TableCell>
                ))}
              </TableRow>
              {extendContents && extendedRows[index] && (
                <TableRow sx={{
                  backgroundColor: "#f5f5f5",
                }}>
                  <TableCell colSpan={headers.length + 1}>
                    {extendContents[index]}
                  </TableCell>
                </TableRow>
              )}
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CommonTable;
