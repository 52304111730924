import editDefaultRoutineOptionAtom from '@/Atoms/Dialogs/Edit/DefaultRoutineOptionAtom';
import { nodesAtom } from '@/Atoms/Plan/MindmapAtom';
import { atom } from 'jotai';

// 모달 열기
export const editDefaultRoutineOptionOpenAtom = atom((get) => get(editDefaultRoutineOptionAtom), (get, set, id: string, change: number) => {
  const task = get(nodesAtom).find((node) => node.id === id);
  if (!task || !task.data) return;

  if (task.data.termType !== change) {
    if (change === 0) {
      const newUpdatedTaskData = {
        ...task.data,
        termType: change,
        termData: [],
      }
      set(nodesAtom, (prev) => prev.map((node) => (node.id === id) ? { ...node, data: newUpdatedTaskData } : node));
      return;
    }
    set(editDefaultRoutineOptionAtom, {
      open: id,
      prevTermType: task.data.termType as number,
      prevTermData: task.data.termData as string[],
      termType: change,
      termData: [],
    });
  } else {
    set(nodesAtom, (prev) => prev.map((node) => node.id === id ? { ...node, data: { ...node.data, termType: null, termData: null } } : node));
  }
});

// 루틴 옵션 변경
export const editDefaultRoutineChangeOptionAtom = atom(null, (get, set) => {
  const { open: taskId, termType, termData } = get(editDefaultRoutineOptionAtom);
  if (!taskId) return;
  const task = get(nodesAtom).find((node) => node.id === taskId);
  if (!task) return;

  const newUpdatedTaskData = {
    ...task.data,
    termType: termType,
    termData: termData,
  }

  set(nodesAtom, (prev) => prev.map((node) => (node.id === taskId) ? { ...node, data: newUpdatedTaskData } : node));
  set(editDefaultRoutineOptionAtom, { open: null, termType: null, termData: null, prevTermData: null, prevTermType: null });
});
