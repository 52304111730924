import React, { SyntheticEvent, useState } from "react";
import theme from "@/Styles/theme";
import * as S from './styled';
import { addressBookDialogAddRecipientsAtom, addressBookDialogClearAtom, addressBookDialogDeleteRecipientAtom, addressBookDialogRefreshAtom, addressBookDialogSaveAtom, addressBookDialogSearchContactAtom, addressBookDialogSearchGroupAtom, addressBookDialogSelectAllEmailsAtom, addressBookDialogSelectEmailAtom, addressBookDialogSetSelectedGroupAtom } from "@/ViewModels/Dialogs/AddressBook/ViewModel";
import { Button, Dialog, DialogActions, DialogTitle, Tab, TextField, Checkbox } from "@mui/material";
import { useAtom, useSetAtom } from "jotai";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import RefreshIcon from '@mui/icons-material/Refresh';
import CustomColorButton from "@/Components/Common/Buttons/CustomColor";
import CustomBackgroundColorButton from "@/Components/Common/Buttons/CustomBackgroundColor";

const tabLabels = [
  // "개인",
  // "공용",
  "크루",
  // "대표 메일",
  // "메일링",
  // "공용 그룹",
  // "중요 연락처 ★"
];

const AddressBookDialog = () => {
  const [addressBookDialog, closeDialog] = useAtom(addressBookDialogClearAtom);
  const [selectedTab, setSelectedTab] = useState(0); // 탭 상태 관리
  const setSelectedGroup = useSetAtom(addressBookDialogSetSelectedGroupAtom); // 선택된 그룹 상태
  const [searchedGroups, setSearchGroupQuery] = useAtom(addressBookDialogSearchGroupAtom); // 그룹 검색 쿼리
  const [searchedContacts, setSearchContactQuery] = useAtom(addressBookDialogSearchContactAtom); // 연락처 검색 쿼리
  const setSelectEmail = useSetAtom(addressBookDialogSelectEmailAtom);
  const setSelectAllEmails = useSetAtom(addressBookDialogSelectAllEmailsAtom);
  const addRecipients = useSetAtom(addressBookDialogAddRecipientsAtom);
  const deleteRecipient = useSetAtom(addressBookDialogDeleteRecipientAtom);
  const refresh = useSetAtom(addressBookDialogRefreshAtom);
  const save = useSetAtom(addressBookDialogSaveAtom);

  const handleTabChange = (event: SyntheticEvent, newValue: number): void => {
    setSelectedTab(newValue);
  };

  const recipientData = [
    {
      title: "받는 사람",
      recipientType: "recipients",
      recipients: addressBookDialog.recipients,
    },
    {
      title: "참조",
      recipientType: "copyRecipients",
      recipients: addressBookDialog.copyRecipients,
    },
    {
      title: "숨은 참조",
      recipientType: "hiddenRecipients",
      recipients: addressBookDialog.hiddenRecipients,
    },
  ];

  return (
    <Dialog open={addressBookDialog.open} onClose={closeDialog} fullWidth maxWidth="lg">
      <DialogTitle sx={{ fontSize: "1rem", fontWeight: "bold" }}>주소록</DialogTitle>

      {/* 탭 */}
      <S.CustomTabs value={selectedTab} onChange={handleTabChange} variant="standard" >
        {tabLabels.map((label, index) => (
          <Tab
            key={label}
            label={label}
            sx={{
              color: selectedTab === index ? theme.colors.primary : 'gray',
              '&.Mui-selected': {
                color: theme.colors.primary,
                fontWeight: 'bold',
              }
            }}
          />
        ))}
        <S.RefreshContainer>
          {`선택 ${addressBookDialog.recipients.length + addressBookDialog.copyRecipients.length + addressBookDialog.hiddenRecipients.length}`}
          <RefreshIcon sx={{ cursor: "pointer" }} onClick={refresh} />
        </S.RefreshContainer>
      </S.CustomTabs>
      <S.CustomDialogContent>
        <S.LeftContainer>
          {/* 그룹 입력 */}
          <S.GroupContainer>
            {/* 검색 */}
            <TextField label="크루 검색" variant="outlined" size="small" value={addressBookDialog.groupQuery} onChange={(e) => setSearchGroupQuery(e.target.value)} />
            <SearchIcon style={{ position: "absolute", right: "10px", top: "8px" }} />
            {/* 그룹 목록 */}
            <S.GroupList>
              {
                searchedGroups.map((contact, index) => (
                  <S.GroupItem key={`group-item-${index}`} $isSelected={addressBookDialog.selectedGroup === contact.name} onClick={() => setSelectedGroup(contact.name)}>
                    &nbsp;&nbsp;&nbsp;&nbsp;{`${contact.name}`}
                  </S.GroupItem>
                ))
              }
            </S.GroupList>
          </S.GroupContainer>
          {/* 연락처 목록 */}
          <S.GroupContainer>
            <TextField label="이름, 메일 주소 검색" variant="outlined" size="small" value={addressBookDialog.contactQuery} onChange={(e) => setSearchContactQuery(e.target.value)} />
            <SearchIcon style={{ position: "absolute", right: "10px", top: "8px" }} />
            <S.GroupList>
              {
                searchedContacts.length > 0 &&
                <S.GroupItem $isSelected={false} onClick={setSelectAllEmails}>&nbsp;&nbsp;&nbsp;&nbsp;전체 선택</S.GroupItem >
              }
              {
                searchedContacts.map((member, index) => (
                  <S.StyledFormControlLabel
                    key={`contact-item-${index}`}
                    control={<Checkbox checked={addressBookDialog.selectedEmails.includes(member.email)} onChange={() => setSelectEmail(member.email)} />}
                    label={`${member.name} (${member.email})`}
                  />
                ))
              }
            </S.GroupList>
          </S.GroupContainer>
        </S.LeftContainer>
        {/* 선택된 연락처 */}
        <S.RightContainer>
          {recipientData.map((data, index) => (
            <S.RecipientContainer key={`recipient-container-${index}`}>
              <Button endIcon={<KeyboardArrowRightIcon />} variant="contained" onClick={() => addRecipients(data.recipientType)} sx={S.AddRecipientButton}>
                {data.title}
              </Button>
              <S.RecipientList>
                {
                  data.recipients.map((email, index) => (
                    <S.RecipientItem key={`recipient-${index}`} >
                      {email}
                      <CloseIcon sx={{ position: "absolute", right: "5px", cursor: "pointer" }} onClick={() => deleteRecipient(data.recipientType, email)} />
                    </S.RecipientItem>
                  ))
                }
              </S.RecipientList>
            </S.RecipientContainer>
          ))}
        </S.RightContainer>
      </S.CustomDialogContent>
      <DialogActions sx={{ padding: "0px 24px 16px 24px" }}>
        <CustomColorButton text="취소" onClick={closeDialog} />
        <CustomBackgroundColorButton text="저장" onClick={save} />
      </DialogActions>
    </Dialog>
  );
};

export default AddressBookDialog;
