import snackbarAtom from "@/Atoms/Snackbar";
import { IconButton, Snackbar, SnackbarCloseReason } from "@mui/material";
import { useAtom } from "jotai";
import React from "react";
import CloseIcon from '@mui/icons-material/Close';

const CustomSnackbar = () => {
  const [snackbar, setSnackbar] = useAtom(snackbarAtom);

  const handleClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleSnackbarClose = (
    event: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };


  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleSnackbarClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <Snackbar
      open={snackbar.open}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      autoHideDuration={6000}
      onClose={handleClose}
      message={snackbar.message}
      action={action}
      color={snackbar.severity}
    />
  )
}

export default CustomSnackbar;
