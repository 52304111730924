import MentorProfile from "@assets/Images/TestMentor/mentor-profile.png";
import MenteeProfile from "@assets/Images/TestMentee/mentee-profile.png";
// import ParentsProfile from "@assets/Images/TestParents/parents-profile.png"; // Parents 프로필 추가 가정

export interface UserProps {
  id: number;
  membership: string;
  image: string;
  membership_end_date: Date;
  birth_date?: Date;
  name?: string;
}

const koreanNames = [
  "김민준", "이서준", "박서연", "최지우", "정하윤", "윤서현", "문채원", "강준혁", "오유리", "장예준",
  "한지호", "백서윤", "홍지민", "전도현", "고다연", "김다희", "이수민", "신현서", "정민재", "박주원",
  "채소윤", "윤지우", "송하은", "조민성", "안소율", "유서윤", "권지민", "이유진", "백민호", "장태희",
  "박하늘", "최현우", "이하람", "강예림", "김현아", "손다인", "신예지", "정도현", "박서율", "류지호",
  "장민혁", "강민재", "오지우", "한준희", "권채원", "이재원", "홍수진", "최태양", "정지훈", "김하은"
];

export const getRandomName = () => {
  return koreanNames[Math.floor(Math.random() * koreanNames.length)];
};

const emailDomains = [
  "gmail.com", "naver.com", "daum.net", "kakao.com", "yahoo.com", "hotmail.com"
];

// 무작위 이메일 생성 함수
export const getRandomEmail = (): { name: string, email: string } => {
  const randomName = koreanNames[Math.floor(Math.random() * koreanNames.length)];
  const randomDomain = emailDomains[Math.floor(Math.random() * emailDomains.length)];
  const randomNumber = Math.floor(Math.random() * 100); // 0부터 99까지 무작위 숫자
  const formattedName = randomName.replace(/ /g, "").toLowerCase(); // 이름에서 공백 제거 및 소문자로 변환
  return ({
    name: randomName,
    email: `${formattedName}${randomNumber}@${randomDomain}`
  });
};

// 멘토, 멘티, 부모 더미 데이터 생성
const generateUsers = (): UserProps[] => {
  const users: UserProps[] = [];

  // mentor: id 1 - 10
  for (let i = 1; i <= 10; i++) {
    users.push({
      id: i,
      membership: "mentor",
      image: MentorProfile,
      membership_end_date: new Date("2024-12-31"), // 멘토 만료 날짜 예시
      birth_date: new Date(`1985-${i < 10 ? `0${i}` : i}-23`), // 1985년을 기준으로 생일 예시
      name: getRandomName(),
    });
  }

  // parents: id 11 - 20
  for (let i = 11; i <= 20; i++) {
    users.push({
      id: i,
      membership: "teacher",
      image: MentorProfile,
      membership_end_date: new Date("2024-12-31"),
      birth_date: new Date(`1975-${i - 10 < 10 ? `0${i - 10}` : i - 10}-23`), // 부모 생일 예시
      name: getRandomName(),
    });
  }

  // mentee: id 21 - 100
  for (let i = 21; i <= 100; i++) {
    users.push({
      id: i,
      membership: "mentee",
      image: MenteeProfile,
      membership_end_date: new Date("2024-12-31"),
      birth_date: new Date(`2008-${i - 20 < 10 ? `0${i - 20}` : i - 20}-23`), // 멘티 생일 예시
      name: i === 30 ? "김민영" : getRandomName(),
    });
  }

  users[30].name = "정장훈";
  users[30].membership = "mentor";
  users[30].image = MentorProfile;
  users[30].membership_end_date = new Date("2024-12-31");
  users[30].birth_date = new Date("1985-01-23");

  users[31].name = "Erin";
  users[31].membership = "teacher";
  users[31].image = MentorProfile;
  users[31].membership_end_date = new Date("2024-12-31");
  users[31].birth_date = new Date("1975-01-23");

  users[32].name = "하이디";

  return users;
};

export const userData = generateUsers();
