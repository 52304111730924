import { atom } from 'jotai';

export interface TabGroupInfo {
  id: number;
  name: string;
  members: {
    id: number;
    name: string;
    email: string;
  }[]
}

const addressBookDialogAtom = atom<{
  open: boolean;
  currentTabGroups: TabGroupInfo[];
  selectedGroup: string | null;
  selectedEmails: string[];
  groupQuery: string;
  contactQuery: string;
  recipients: string[];
  copyRecipients: string[];
  hiddenRecipients: string[];
}>({
  open: false,
  currentTabGroups: [],
  selectedGroup: null,
  selectedEmails: [],
  groupQuery: '',
  contactQuery: '',
  recipients: [],
  copyRecipients: [],
  hiddenRecipients: [],
})

export default addressBookDialogAtom;
