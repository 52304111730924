import { togetherDialogAtom, togetherDialogSelectedCrewIdAtom } from "@/Atoms/Dialogs/Together";
import snackbarAtom from "@/Atoms/Snackbar";
import { shareWithCrew } from "@/Queries/PlanQueries";
import { Node } from "@xyflow/react";
import { atom } from "jotai";
import { useNavigate } from "react-router-dom";
import { getUserId } from "../UserViewModel";
import { TFunction } from 'i18next';
import { handleReactQueryApiResponse } from "@/Utils/APIUtil";
import { error401ModalAtom } from "@/Atoms/Dialogs/Error/401Atom";

// 함께하기 모달 열기
export const togetherDialogOpenAtom = atom(null, (get, set, node: Node) => {
  set(togetherDialogAtom, { node });
})

// 크루와 함께하기
export const togetherDialogWithCrewAtom = atom(null, async (get, set, navigate: ReturnType<typeof useNavigate>, t: TFunction<"translation", undefined>) => {
  const crewId = get(togetherDialogSelectedCrewIdAtom);
  if (crewId === null) {
    set(snackbarAtom, { open: true, message: t("dialogs.common.snackbar.together.selectCrew"), severity: "error" });
    return;
  }
  const task = get(togetherDialogAtom).node;
  if (!task) {
    return;
  }
  // api 호출
  const handleError401Modal = () => set(error401ModalAtom, true);
  const response = await handleReactQueryApiResponse(shareWithCrew, handleError401Modal, task.data?.backendId as number, get(getUserId), crewId.innerTabId);
  if (!response.ok) {
    set(snackbarAtom, { open: true, message: t("dialogs.common.snackbar.together.fail"), severity: "error" });
    return;
  }
  set(togetherDialogAtom, { node: null });
  set(snackbarAtom, { open: true, message: t("dialogs.common.snackbar.together.success"), severity: "success" });
  navigate(`/crew?crew_id=${crewId.crewId}&inner_tab_id=${crewId.innerTabId}`);
  set(togetherDialogSelectedCrewIdAtom, null);
});
