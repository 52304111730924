import { useQuery } from 'react-query';
import { useAtom, useSetAtom } from 'jotai';
import { useCrewLectureAtom } from '@/Atoms/HookControllerAtom';
import GetIdFromQuerystring from '@/Utils/GetIdFromQuerystring';
import { useNavigate } from 'react-router-dom';
import { crewLecturesAtom } from '@/Atoms/Crew';
import { getLectures } from '@/Queries/LectureQueries';
import { devConsoleError } from '@/Utils/ConsoleLogInDevelopment';
import { handleReactQueryApiResponse } from '@/Utils/APIUtil';
import { error401ModalAtom } from '@/Atoms/Dialogs/Error/401Atom';

const useLecture = () => {
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  const [controller, setController] = useAtom(useCrewLectureAtom);
  const navigate = useNavigate();
  const [lectures, setLectures] = useAtom(crewLecturesAtom);
  const setError401Modal = useSetAtom(error401ModalAtom);

  return useQuery(['crewLectures'], () => handleReactQueryApiResponse(getLectures, () => setError401Modal(true), innerTabId), {
    enabled: controller,
    onSuccess: async (data) => {
      setController(false);
      if (data === null) {
        const lecture = lectures.find((lecture) => lecture.innerTabId === innerTabId);
        if (!lecture) {
          setLectures((prev) => [...prev, { innerTabId, lectures: [] }]);
        } else {
          setLectures((prev) => prev.map((prevLecture) => prevLecture.innerTabId === innerTabId ? { innerTabId, lectures: [] } : prevLecture));
        }
      }
      const jsonedData = await data.json();
      const dataLectures = jsonedData.lectures || [];
      const lecture = lectures.find((lecture) => lecture.innerTabId === innerTabId);
      if (!lecture) {
        setLectures((prev) => [...prev, { innerTabId, lectures: dataLectures }]);
      } else {
        setLectures((prev) => prev.map((prevLecture) => prevLecture.innerTabId === innerTabId ? { innerTabId, lectures: dataLectures } : prevLecture));
      }
    },
    onError: (error) => {
      devConsoleError(error);
      navigate('/plan-hip');
    }
  });
};

export default useLecture;
