import addressBookDialogAtom, { TabGroupInfo } from '@/Atoms/Dialogs/AddressBook';
import { error401ModalAtom } from '@/Atoms/Dialogs/Error/401Atom';
import mailAtom from '@/Atoms/Mail/Atom';
import snackbarAtom from '@/Atoms/Snackbar';
import { getRandomEmail } from '@/Metadata/userData';
import { getCrewMembers } from '@/Queries/CrewQueries';
import { getPlanHipCrews } from '@/Queries/PlanHipQueries';
import { handleReactQueryApiResponse } from '@/Utils/APIUtil';
import { getUserId } from '@/ViewModels/UserViewModel';
import { atom } from 'jotai';

export const addressBookDialogOpenAtom = atom(null, async (get, set) => {
  // 주소록에 필요한 정보들을 가져오는 로직
  const userId = get(getUserId);
  const handleError401Modal = () => set(error401ModalAtom, true);
  const response = await handleReactQueryApiResponse(getPlanHipCrews, handleError401Modal, userId, 0);
  const jsonedData = await response.json();
  const myCrews = jsonedData.user_active_crews;
  const tabGroups = [];
  for (const crew of myCrews) {
    const response = await handleReactQueryApiResponse(getCrewMembers, handleError401Modal, crew.crewId);
    if (response === null) return;
    const crewMemberList = await response.json();
    const tabGroupInfo = {
      id: crew.crewId,
      name: crew.name,
      members: crewMemberList.map((member: any) => {
        const { name, email } = getRandomEmail();
        return {
          id: member.userId,
          name: name,
          email: email,
        }
      }),
    };
    tabGroups.push(tabGroupInfo);
  }
  const { recipients, copyRecipients, hiddenRecipients } = get(mailAtom);

  set(addressBookDialogAtom, ({
    open: true,
    currentTabGroups: tabGroups,
    selectedGroup: null,
    selectedEmails: [],
    groupQuery: '',
    contactQuery: '',
    recipients: recipients,
    copyRecipients: copyRecipients,
    hiddenRecipients: hiddenRecipients,
  }));
});

export const addressBookDialogClearAtom = atom((get) => get(addressBookDialogAtom), (get, set) => {
  set(addressBookDialogAtom, {
    open: false,
    currentTabGroups: [],
    selectedGroup: null,
    selectedEmails: [],
    groupQuery: '',
    contactQuery: '',
    recipients: [],
    copyRecipients: [],
    hiddenRecipients: [],
  });
});

export const addressBookDialogRefreshAtom = atom(null, async (get, set) => {
  set(addressBookDialogAtom, (prev) => ({
    ...prev,
    selectedGroup: null,
    selectedEmails: [],
    groupQuery: '',
    contactQuery: '',
    recipients: [],
    copyRecipients: [],
    hiddenRecipients: [],
  }));
});

export const addressBookDialogSaveAtom = atom(null, (get, set) => {
  const { recipients, copyRecipients, hiddenRecipients } = get(addressBookDialogAtom);
  set(mailAtom, (prev) => ({
    ...prev,
    recipients: recipients,
    copyRecipients: copyRecipients,
    hiddenRecipients: hiddenRecipients,
  }));
  set(addressBookDialogClearAtom);
});

const filterGroups = (groups: TabGroupInfo[], query: string): TabGroupInfo[] => {
  return query === "" ? groups : groups.filter((group) => group.name.includes(query));
}

export const addressBookDialogSearchGroupAtom = atom((get) => filterGroups(get(addressBookDialogAtom).currentTabGroups, get(addressBookDialogAtom).groupQuery), (get, set, query: string) => {
  set(addressBookDialogAtom, (prev) => ({
    ...prev,
    groupQuery: query,
  }));
});

const filterContacts = (contacts: TabGroupInfo["members"] | undefined, query: string): TabGroupInfo["members"] => {
  if (!contacts) return [];
  return query === "" ? contacts : contacts.filter((member) => member.name.includes(query) || member.email.includes(query));
}

export const addressBookDialogSearchContactAtom = atom((get) => filterContacts(get(addressBookDialogAtom).currentTabGroups.find((group) => group.name === get(addressBookDialogAtom).selectedGroup)?.members, get(addressBookDialogAtom).contactQuery), (get, set, query: string) => {
  set(addressBookDialogAtom, (prev) => ({
    ...prev,
    contactQuery: query,
  }));
});

export const addressBookDialogSetSelectedGroupAtom = atom(null, async (get, set, selectedGroup: string) => {
  set(addressBookDialogAtom, (prev) => ({
    ...prev,
    selectedGroup: selectedGroup,
  }));
});

export const addressBookDialogSelectAllEmailsAtom = atom(null, (get, set) => {
  const { currentTabGroups, selectedGroup } = get(addressBookDialogAtom);
  const selectedEmailsInGroup = currentTabGroups.find((group) => group.name === selectedGroup)?.members.map((member) => member.email) || [];
  set(addressBookDialogAtom, (prev) => ({
    ...prev,
    selectedEmails: selectedEmailsInGroup,
  }));
});

export const addressBookDialogSelectEmailAtom = atom(null, (get, set, selectedEmail: string) => {
  set(addressBookDialogAtom, (prev) => ({
    ...prev,
    selectedEmails: prev.selectedEmails.includes(selectedEmail) ? prev.selectedEmails.filter((email) => email !== selectedEmail) : [...prev.selectedEmails, selectedEmail],
  }));
});

export const addressBookDialogAddRecipientsAtom = atom(null, (get, set, type: string) => {
  const { selectedEmails, recipients, copyRecipients, hiddenRecipients } = get(addressBookDialogAtom);
  switch (type) {
    case "recipients":
      for (const email of selectedEmails) {
        if (recipients.includes(email)) {
          set(snackbarAtom, (prev) => ({ ...prev, open: true, message: "중복된 연락처는 한 건만 등록됩니다.", severity: "error" }));
          break;
        }
      }
      set(addressBookDialogAtom, (prev) => ({
        ...prev,
        recipients: [...recipients, ...selectedEmails.filter((email) => !recipients.includes(email))],
      }));
      break;
    case "copyRecipients":
      for (const email of selectedEmails) {
        if (copyRecipients.includes(email)) {
          set(snackbarAtom, (prev) => ({ ...prev, open: true, message: "중복된 연락처는 한 건만 등록됩니다.", severity: "error" }));
          break;
        }
      }
      set(addressBookDialogAtom, (prev) => ({
        ...prev,
        copyRecipients: [...copyRecipients, ...selectedEmails.filter((email) => !copyRecipients.includes(email))],
      }));
      break;
    case "hiddenRecipients":
      for (const email of selectedEmails) {
        if (hiddenRecipients.includes(email)) {
          set(snackbarAtom, (prev) => ({ ...prev, open: true, message: "중복된 연락처는 한 건만 등록됩니다.", severity: "error" }));
          break;
        }
      }
      set(addressBookDialogAtom, (prev) => ({
        ...prev,
        hiddenRecipients: [...hiddenRecipients, ...selectedEmails.filter((email) => !hiddenRecipients.includes(email))],
      }));
      break;
    default:
      break;
  }
  set(addressBookDialogAtom, (prev) => ({
    ...prev,
    selectedEmails: [],
  }));
});

export const addressBookDialogDeleteRecipientAtom = atom(null, (get, set, type: string, email: string) => {
  const { recipients, copyRecipients, hiddenRecipients } = get(addressBookDialogAtom);
  switch (type) {
    case "recipients":
      set(addressBookDialogAtom, (prev) => ({
        ...prev,
        recipients: recipients.filter((recipient) => recipient !== email),
      }));
      break;
    case "copyRecipients":
      set(addressBookDialogAtom, (prev) => ({
        ...prev,
        copyRecipients: copyRecipients.filter((recipient) => recipient !== email),
      }));
      break;
    case "hiddenRecipients":
      set(addressBookDialogAtom, (prev) => ({
        ...prev,
        hiddenRecipients: hiddenRecipients.filter((recipient) => recipient !== email),
      }));
      break;
    default:
      break;
  }
});
