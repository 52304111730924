import React from "react";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import * as S from "../styled";
import { useAtomValue } from "jotai";
import { Settings } from "@mui/icons-material";
import theme from "@/Styles/theme";
import { useNavigate } from "react-router-dom";
import teacherDashboardAtom from "@/Atoms/Dashboard/TeacherAtom";
import { useTranslation } from "react-i18next";
import { devConsoleLog } from "@/Utils/ConsoleLogInDevelopment";

export function GroupAdmin() {
  const crewData = useAtomValue(teacherDashboardAtom).crews;
  const { t } = useTranslation();

  const navigate = useNavigate();

  return (
    <S.CustomBox>
      <Stack
        direction={"row"}
        sx={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <S.TitleTypo>{t("dashboard.teacher.crewManagement.title")}</S.TitleTypo>
        <IconButton onClick={() => devConsoleLog("그룹 관리 페이지로의 이동 필요")}>
          <Settings></Settings>
        </IconButton>
      </Stack>

      <Divider sx={{ margin: "10px 0" }} />
      <S.DescTypo>{t("dashboard.teacher.crewManagement.description")}</S.DescTypo>
      <Box sx={{ flexGrow: 1, overflow: "auto" }}>
        <Grid container spacing={2}>
          {crewData.map((crew, index) => (
            <Grid item xs={12} sm={12} md={12} lg={12} key={index}>
              <Box
                sx={{
                  background: "#c0c0c0",
                  borderRadius: "5px",
                  padding: "5px",
                  boxSizing: "border-box",
                  cursor: "pointer",
                }}
                onClick={() => navigate(`/crew?crew_id=${crew.crewId}&inner_tab_id=${crew.innerTabId}`)}
              >
                <Box
                  sx={{
                    background: "white",
                    borderRadius: "5px",
                    padding: "5px",
                    height: "100%",
                  }}
                >
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    sx={{ height: "100%", gap: "20px" }}
                  >
                    <img
                      src={`${process.env.REACT_APP_CAREER_PLANNER_PLAN_MEDIA_ADDRESS}${crew.imageUrl.split("/media")[1]}`}
                      alt="crew"
                      style={{ width: "100px", height: "100px", borderRadius: "50%" }}
                    />
                    <Box sx={{ flex: 5 }}>
                      <Typography
                        sx={{
                          fontSize: "19px",
                          fontWeight: "bold",
                          marginBottom: "15px",
                        }}
                      >
                        {crew.name as string}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          color: theme.colors.primary,
                          marginBottom: "5px",
                        }}
                      >
                        {crew.content}
                      </Typography>
                      {/* <Typography
                        sx={{
                          fontSize: "12px",
                          color: theme.colors.primary,
                          marginBottom: "5px",
                          cursor: "pointer",
                          borderRadius: "5px",
                          border: "1px solid #06C25E",
                          padding: "0 5px",
                          display: "inline-block",
                        }}
                      >
                        공유설정
                      </Typography> */}
                    </Box>
                    <Box sx={{ flex: 1 }}>
                      <Typography>{`${crew.current} / ${crew.headCount}명`}</Typography>
                      {/* <Typography
                        sx={{
                          fontSize: "12px",
                          color: theme.colors.primary,
                          marginBottom: "5px",
                          cursor: "pointer",
                          borderRadius: "5px",
                          border: "1px solid #06C25E",
                          padding: "0 5px",
                          display: "inline-block",
                          marginRight: "10px",
                        }}
                      >
                        수정
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          color: theme.colors.primary,
                          marginBottom: "5px",
                          cursor: "pointer",
                          borderRadius: "5px",
                          border: "1px solid #06C25E",
                          padding: "0 5px",
                          display: "inline-block",
                        }}
                      >
                        삭제
                      </Typography> */}
                    </Box>
                  </Stack>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </S.CustomBox>
  );
}
