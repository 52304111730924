import { atom } from 'jotai';
import { Course } from '../AfterSchool';

const dummyClass: Course = {
  teacher: "Kim Minho",
  applicationPeriod: {
    start: {
      date: "2024-09-01",
      hour: 9,
      minute: 0,
    },
    end: {
      date: "2024-09-10",
      hour: 17,
      minute: 0,
    },
    detail: {
      option1: true,
      option2: false,
      option3: false,
      option4: true,
      option5: false,
    },
  },
  applicationMethod: 1,
  coursePeriod: {
    startDate: "2024-09-15",
    endDate: "2024-12-20",
  },
  courseName: "Advanced Mathematics",
  viewOption: {
    isVisible: true,
    grade: 2,
  },
  operatingTimes: {
    Monday: [1, 2],
    Wednesday: [3, 4],
    Friday: [5, 6],
  },
  isCostDisclosured: true,
  selectionGroup: "Science",
  announcement: "Please bring your own calculator.",
  subsidy: {
    "students": [
      {
        "studentId": "10101",
        "name": "홍길동",
        "freePass": "500000"
      },
      {
        "studentId": "10201",
        "name": "홍길서",
        "freePass": "350000"
      },
      {
        "studentId": "10301",
        "name": "홍길남",
        "freePass": "면제"
      }
    ],
    fees: [
      { "studentsCount": 5, "fee": 60000 },
      { "studentsCount": 6, "fee": 62000 },
      { "studentsCount": 7, "fee": 64000 },
      { "studentsCount": 8, "fee": 66000 },
      { "studentsCount": 9, "fee": 68000 },
      { "studentsCount": 10, "fee": 70000 },
      { "studentsCount": 11, "fee": 72000 },
      { "studentsCount": 12, "fee": 74000 },
      { "studentsCount": 13, "fee": 76000 },
      { "studentsCount": 14, "fee": 78000 },
      { "studentsCount": 15, "fee": 80000 },
      { "studentsCount": 16, "fee": 82000 },
      { "studentsCount": 17, "fee": 84000 },
      { "studentsCount": 18, "fee": 86000 },
      { "studentsCount": 19, "fee": 88000 },
      { "studentsCount": 20, "fee": 90000 }
    ]
  },
  courseEvaluation: "This course is challenging but rewarding.",
  classes: [],
};

const afterSchoolCourseAtom = atom<Course | null>(null);

export default afterSchoolCourseAtom;
