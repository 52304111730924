import React from 'react';
import * as S from './styled';
import ReactPlayer from 'react-player';
import useIsMobile from '../../../../../Hooks/useIsMobile';
import { getUserId } from '@/ViewModels/UserViewModel';
import { useAtomValue } from 'jotai';

const TimelapseVideo = () => {
  const { isMobile } = useIsMobile();
  // const width = isMobile ? "90%" : "350px";
  const width = "80%";
  const userId = useAtomValue(getUserId);

  return (
    <div style={{ padding: "0 10px", width: `${width}` }}>
      <S.VideoContainer $aspectRatio="9:16">
        <ReactPlayer
          // url={TimeplapseVideo}
          url={`${process.env.REACT_APP_FEED_MEDIA_ADDRESS}/videos/${userId}.mp4`}
          width="100%"
          height="100%" // ReactPlayer 높이를 VideoContainer 높이에 맞춤
          controls={true}
          playing={false}
          debounce={200}
        />
      </S.VideoContainer>
    </div>
  );
}

export default TimelapseVideo;
