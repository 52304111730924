import { useQuery } from 'react-query';
import { getPlans, parseGetPlansResponse } from '@/Queries/PlanQueries';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import planAtom, { Plan } from '@/Atoms/Plan';
import { getUserId } from '@/ViewModels/UserViewModel';
import GetIdFromQuerystring from '@/Utils/GetIdFromQuerystring';
import { planHeaderChangeViewTypeAtom } from '@/ViewModels/Plan/HeaderViewModel';
import { usePlansAtom } from '@/Atoms/HookControllerAtom';
import { handleReactQueryApiResponseWithJson } from '@/Utils/APIUtil';
import { error401ModalAtom } from '@/Atoms/Dialogs/Error/401Atom';

export const usePlans = () => {
  const setPlan = useSetAtom(planAtom);
  const userId = useAtomValue(getUserId);
  const changeViewType = useSetAtom(planHeaderChangeViewTypeAtom);
  const [controller, setController] = useAtom(usePlansAtom);
  const setError401Modal = useSetAtom(error401ModalAtom);

  return useQuery(['plans', userId], () => handleReactQueryApiResponseWithJson(getPlans, () => setError401Modal(true), userId), {
    enabled: controller,
    onSuccess: (data) => {
      if (!data) return;
      setController(false);
      const planData = parseGetPlansResponse(data);
      setPlan(planData);

      // 새로고침 시 innerTabId에 따라 viewType 변경
      const planId = GetIdFromQuerystring("id");
      const innerTabId = GetIdFromQuerystring("inner_tab_id");
      if (planId && innerTabId) {
        const plan = planData.find((plan) => plan.id === planId);
        if (!plan) return;
        const innerTabs = plan.innerTabs;
        if (innerTabs.length === 0) return;
        const innerTab = innerTabs.find((innerTab) => innerTab.id === innerTabId);
        if (!innerTab) return;
        changeViewType(innerTab.viewType);
      }
    },
  });
};
