import { atom } from 'jotai';
import snackbarAtom from '@/Atoms/Snackbar';
import findCrewDialogAtom from '@/Atoms/Dialogs/FindCrew';
import { waitingModalAtom } from '@/Atoms/RootAtom';
import { signatureAtom } from '@/Atoms/Signature/Atom';

export const findCrewDialogOpenAtom = atom(null, async (get, set) => {
  const signatures = get(signatureAtom);
  const representativeSignature = signatures.find((signature) => signature.representative);
  if (!representativeSignature) {
    set(snackbarAtom, (prev) => ({ ...prev, open: true, message: "대표 서명을 설정해주세요." }));
    return;
  }
  set(findCrewDialogAtom, representativeSignature);
});

export const findCrewDialogRecommendAtom = atom(null, async (get, set) => {
  const infomation = get(findCrewDialogAtom);
  if (!infomation) return;
  set(waitingModalAtom, (prev) => ({ state: true, text: "크루를 추천받고 있습니다. 잠시만 기다려주세요." }))
});
