import React from "react";
import * as S from "./styled";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import crewAtom from "@/Atoms/CrewAtom";
import { getUserId, getUserPosition } from "@/ViewModels/UserViewModel";
import { createTaskDialogAtom } from "@/Atoms/Dialogs/Create/Task";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import AssistantIcon from '@mui/icons-material/Assistant';
import SchoolIcon from '@mui/icons-material/School';
import { v4 as uuidv4 } from "uuid";
import { useLocation, useNavigate } from "react-router-dom";
import { setPreviousCrewAtom } from "@/ViewModels/HelperViewModel";
import { languageAtom } from "@/Atoms/RootAtom";
import { createLectureDialogOpenAtom } from "@/ViewModels/Dialogs/Create/LectureViewModel";
import { Divider } from "@mui/material";
import { useTranslation } from "react-i18next";
import GetIdFromQuerystring from "@/Utils/GetIdFromQuerystring";

const TaskPosting = () => {
  const crew = useAtomValue(crewAtom);
  const userId = useAtomValue(getUserId);
  const userPosition = useAtomValue(getUserPosition);
  const [, setPreviousCrew] = useAtom(setPreviousCrewAtom);
  const setCreateTaskDialog = useSetAtom(createTaskDialogAtom);
  const language = useAtomValue(languageAtom);
  const navigate = useNavigate();
  const location = useLocation();
  const openCreateLectureDialog = useSetAtom(createLectureDialogOpenAtom);
  const { t } = useTranslation();

  return (
    crew &&
    crew.members.map((member) => member.userId).includes(userId) && (
      <S.Container
        onClick={() =>
          setCreateTaskDialog((prev) => ({
            ...prev,
            open: `task-${uuidv4()}`,
            isCrew: true,
          }))
        }
      >
        <S.TitleContainer>
          <S.TitleButton>
            <ArticleOutlinedIcon sx={{ width: "20px", height: "20px" }} />{t("crew.taskPosting.feed")}
          </S.TitleButton>
          <Divider orientation="vertical" flexItem />
          {
            userPosition === "교사" &&
            <S.TitleButton onClick={(e) => {
              e.stopPropagation();
              openCreateLectureDialog();
            }}>
              <SchoolIcon sx={{ width: "20px", height: "20px" }} />
              {t("crew.taskPosting.createLecture")}
            </S.TitleButton>
          }
          {/* <Divider orientation="vertical" flexItem />
          <S.TitleButton
            onClick={(e) => {
              e.stopPropagation();

              // Extract crew_id and inner_tab_id from queryString
              const crewId = GetIdFromQuerystring("crew_id");
              const innerTabId = GetIdFromQuerystring("inner_tab_id");
              setPreviousCrew(crewId, innerTabId);
              navigate(`/crew-helper?crew_id=${crewId}&inner_tab_id=${innerTabId}`);
            }}
          >
            <AssistantIcon sx={{ width: "20px", height: "20px" }} />
            {t("crew.taskPosting.aiHelper")}
          </S.TitleButton> */}
        </S.TitleContainer>
        <S.DescriptionContainer>{t("crew.taskPosting.description")}</S.DescriptionContainer>
      </S.Container>
    )
  );
};

export default TaskPosting;
