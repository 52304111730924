import { atom } from 'jotai';

interface CreateClassDialog {
  open: boolean;
  teacher: string; // 담당교사
  grade: 0 | 1 | 2 | 3; // 학년 - 0: 전체, 1: 1학년, 2: 2학년, 3: 3학년
  headcount: number; // 정원
  period: number; // 차시
  operatingTimes: { [key: string]: number[] }; // 운영시간
  classRoom: string; // 강의실
  name: string; // 강좌명
  textbook: string; // 교재
}

export const createClassDialogInitializeState = (open: boolean): CreateClassDialog => ({
  open: open,
  teacher: '',
  grade: 0,
  headcount: 1,
  period: 1,
  operatingTimes: {},
  classRoom: '',
  name: '',
  textbook: '',
});

const createClassDialogAtom = atom<CreateClassDialog>(createClassDialogInitializeState(false));

export default createClassDialogAtom;
