import React from "react";
import { Box, Divider, Stack, Typography } from "@mui/material";
import * as S from "../styled";
import theme from "../../../../Styles/theme";
import ManImage1 from "@assets/Images/MenteeImages/man1.png";
import ManImage2 from "@assets/Images/MenteeImages/man2.png";
import ManImage3 from "@assets/Images/MenteeImages/man3.png";
import ManImage4 from "@assets/Images/MenteeImages/man4.png";
import ManImage5 from "@assets/Images/MenteeImages/man5.png";
import WomanImage1 from "@assets/Images/MenteeImages/woman1.png";
import WomanImage2 from "@assets/Images/MenteeImages/woman2.png";
import WomanImage3 from "@assets/Images/MenteeImages/woman3.png";
import WomanImage4 from "@assets/Images/MenteeImages/woman4.png";
import WomanImage5 from "@assets/Images/MenteeImages/woman5.png";
import WomanImage6 from "@assets/Images/MenteeImages/woman6.png";
import { useTranslation } from "react-i18next";

const studyCompleteLessData = [
  {
    image: ManImage1,
    name: "김민준",
    num: 2,
  },
  {
    image: WomanImage1,
    name: "이서연",
    num: 3,
  },
  {
    image: ManImage2,
    name: "박지훈",
    num: 1,
  },
  {
    image: WomanImage2,
    name: "최유리",
    num: 4,
  },
  {
    image: ManImage3,
    name: "정우성",
    num: 2,
  },
  {
    image: ManImage4,
    name: "한서준",
    num: 1,
  },
  {
    image: WomanImage3,
    name: "강예빈",
    num: 2,
  },
  {
    image: WomanImage4,
    name: "문지영",
    num: 1,
  },
  {
    image: ManImage5,
    name: "이재혁",
    num: 2,
  },
  {
    image: WomanImage5,
    name: "박수현",
    num: 1,
  },
  {
    image: WomanImage6,
    name: "김수빈",
    num: 4,
  },
].sort((a, b) => b.num - a.num);

const studyProgressLessData = [
  {
    image: ManImage4,
    name: "한서준",
    num: 1,
  },
  {
    image: WomanImage3,
    name: "강예빈",
    num: 2,
  },
  {
    image: WomanImage4,
    name: "문지영",
    num: 1,
  },
  {
    image: ManImage5,
    name: "이재혁",
    num: 2,
  },
  {
    image: WomanImage5,
    name: "박수현",
    num: 1,
  },
  {
    image: WomanImage6,
    name: "김수빈",
    num: 4,
  },
].sort((a, b) => b.num - a.num);

export function SupplyMenteeList() {
  const { t } = useTranslation();

  return (
    <S.CustomBox sx={{ minHeight: "600px", maxHeight: "600px" }}>
      <S.TitleTypo>{t("dashboard.mentor.adviceMentee.title")}</S.TitleTypo>
      <Divider sx={{ margin: "10px 0" }} />
      <S.DescTypo sx={{ marginBottom: "10px" }}>
        {t("dashboard.mentor.adviceMentee.description")}
      </S.DescTypo>

      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "row",
          height: "70%",
        }}
      >
        <Stack
          direction={"column"}
          sx={{
            flexGrow: 1,
            flex: 1,
            alignItems: "center",
            padding: "10px",
            gap: "10px",
            height: "100%",
          }}
        >
          <Box
            sx={{
              background: theme.colors.primary,
              textAlign: "center",
              padding: "5px",
              borderRadius: "20px",
              color: "white",
              width: "60%",
            }}
          >
            <Typography>{t("dashboard.mentor.adviceMentee.notCompleted")}</Typography>
          </Box>

          <Stack
            sx={{
              flexGrow: 1,
              padding: "10px",
              overflowY: "auto",
              width: "100%",
              gap: "10px",
            }}
          >
            {studyCompleteLessData.map((data, index) => (
              <Stack
                key={index} // map 함수에 key 추가
                sx={{
                  width: "100%",
                  justifyContent: "space-between",
                }}
                direction={"row"}
              >
                <Stack
                  direction={"row"}
                  sx={{ alignItems: "center", gap: "10px" }}
                >
                  <Box
                    component="img"
                    src={data.image}
                    alt="Profile Image"
                    sx={{
                      width: 30,
                      height: 30,
                      borderRadius: "50%",
                      objectFit: "cover",
                    }}
                  />
                  <Typography>{data.name}</Typography>
                </Stack>

                <Typography sx={{ color: "red" }}>{data.num}개</Typography>
              </Stack>
            ))}
          </Stack>
        </Stack>

        <Stack
          direction={"column"}
          sx={{ flex: 1, alignItems: "center", padding: "10px" }}
        >
          <Box
            sx={{
              background: theme.colors.primary,
              textAlign: "center",
              padding: "5px",
              borderRadius: "20px",
              color: "white",
              width: "60%",
            }}
          >
            <Typography>{t("dashboard.mentor.adviceMentee.waitingList")}</Typography>
          </Box>
          <Stack
            sx={{
              flexGrow: 1,
              padding: "10px",
              overflowY: "auto",
              width: "100%",
              gap: "10px",
            }}
          >
            {studyProgressLessData.map((data, index) => (
              <Stack
                key={index} // map 함수에 key 추가
                sx={{
                  width: "100%",
                  justifyContent: "space-between",
                }}
                direction={"row"}
              >
                <Stack
                  direction={"row"}
                  sx={{ alignItems: "center", gap: "10px" }}
                >
                  <Box
                    component="img"
                    src={data.image}
                    alt="Profile Image"
                    sx={{
                      width: 30,
                      height: 30,
                      borderRadius: "50%",
                      objectFit: "cover",
                    }}
                  />
                  <Typography>{data.name}</Typography>
                </Stack>

                <Typography sx={{ color: "red" }}>{data.num}개</Typography>
              </Stack>
            ))}
          </Stack>
        </Stack>
      </Box>
    </S.CustomBox>
  );
}
