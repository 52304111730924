import { edgesAtom, isChangedMindmapAtom, nodesAtom } from "@/Atoms/Plan/MindmapAtom";
import { useAtomValue, useSetAtom } from "jotai";
import { useNavigate } from "react-router-dom";

const useNavigateWithWarning = () => {
  const navigate = useNavigate();
  const isChangedMindmap = useAtomValue(isChangedMindmapAtom);
  const setNodes = useSetAtom(nodesAtom);
  const setEdges = useSetAtom(edgesAtom);

  const navigateWithWarning = (path: string) => {
    if (isChangedMindmap) {
      const confirmLeaving = window.confirm("변경사항이 저장되지 않을 수 있습니다. 정말 이동하시겠습니까?");
      if (!confirmLeaving) {
        return; // 사용자가 취소를 눌렀다면 이동하지 않음
      }
    }
    setNodes([]);
    setEdges([]);
    navigate(path); // 페이지 이동
  };

  return navigateWithWarning;
};

export default useNavigateWithWarning;
