import React from "react";
import theme from "@/Styles/theme";
import { Box } from "@mui/material";
import { currentChatbotTextProgressAtom } from "@/Atoms/ChatAtom";
import { useAtom, useSetAtom } from "jotai";
import useIsMobile from "@/Hooks/useIsMobile";
import { useLocation } from "react-router-dom";
import { dokgabiChatModalAtom } from "@/Atoms/DokgabiChat/Atom";
import { openChatDialogAtom } from "@/ViewModels/ChatViewModel";
import { openDokgabiChatModalAtom } from "@/ViewModels/Chat/ModalViewModel";

const sharedAnimation = {
  "@keyframes bounceShared": {
    "0%": {
      transform: "translateY(0)",
    },
    "10%": {
      transform: "translateY(-50px)",
    },
    "20%": {
      transform: "translateY(0)",
    },
    "30%": {
      transform: "translateY(-30px)",
    },
    "40%": {
      transform: "translateY(0)",
    },
    "80%": {
      transform: "translateY(0)",
    },
  },
};

export function FloatingChatbotButton() {
  const location = useLocation();
  const currentPath = location.pathname;
  const { isMobile } = useIsMobile();
  const setChatDialogOpen = useSetAtom(openChatDialogAtom);
  const [currentChatbotTextProgress] = useAtom(currentChatbotTextProgressAtom);
  // 초기 실행 시점 동기화: DOM 로드 후 동일한 타이밍 시작
  const [animationStartTime, setAnimationStartTime] = React.useState(false);
  const setDokgabiChatOpen = useSetAtom(openDokgabiChatModalAtom);

  React.useEffect(() => {
    const timeout = setTimeout(() => setAnimationStartTime(true), 100); // 100ms 지연 후 애니메이션 동기화
    return () => clearTimeout(timeout);
  }, []);

  return (
    (!currentPath.includes("/crew-helper") && !currentPath.includes("/settings")) && animationStartTime &&
    <>
      {/* Speech Bubble */}
      <Box
        sx={{
          position: "absolute",
          bottom: isMobile ? "100px" : "130px",
          right: "20px",
          padding: isMobile ? "6px 8px" : "8px 12px",
          backgroundColor: theme.colors.primary,
          color: "#fff",
          borderRadius: "12px",
          fontSize: isMobile ? "12px" : "14px",
          fontWeight: "bold",
          textAlign: "center",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          zIndex: 100,
          animation: "bounceShared 5s ease-in-out infinite", // Sync with the button's bounce
          ...sharedAnimation,
          "&::after": {
            content: '""',
            position: "absolute",
            bottom: "-10px",
            left: "20px", // Position the tail correctly
            transform: "translateX(-50%)",
            width: 0,
            height: 0,
            borderStyle: "solid",
            borderWidth: "10px 10px 0 10px",
            borderColor: `${theme.colors.primary} transparent transparent transparent`,
          },
        }}
      >
        Talk to me!
      </Box>

      {/* Floating Chatbot Button */}
      {
        currentChatbotTextProgress?.image &&
        <Box
          component="img"
          src={`${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${currentChatbotTextProgress?.image}`}
          alt="Profile Image"
          sx={{
            width: isMobile ? "60px" : "100px",
            height: isMobile ? "60px" : "100px",
            position: "fixed",
            right: "20px",
            bottom: "20px",
            borderRadius: "50%",
            background: `radial-gradient(circle at 30% 30%, #ffffff, ${theme.colors.primary})`,
            boxShadow: `0 8px 12px rgba(0, 0, 0, 0.3),
                    inset -5px -5px 15px rgba(0, 0, 0, 0.2),
                    inset 5px 5px 15px rgba(255, 255, 255, 0.3)`,
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 100,
            animation: "bounceShared 5s ease-in-out infinite",
            ...sharedAnimation,
          }}
          onClick={setDokgabiChatOpen}
        // onClick={setChatDialogOpen}
        />
      }
    </>
  );
}
