import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material';
import React from 'react';
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import theme from '@/Styles/theme';
import { lighten } from 'polished';
import * as S from './styled';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import createCrewDialogAtom, { createCrewDialogCheckNameStatusAtom, createCrewDialogImagePreviewAtom } from '@/Atoms/Dialogs/Create/Crew';
import { createCrewDialogCheckDuplicationAtom, createCrewDialogCloseAtom, createCrewDialogCreateAtom, createCrewDialogUpdateStateAtom } from '@/ViewModels/Dialogs/Create/CrewViewModel';
import Lottie from 'react-lottie';
import LottieData from './Animation/Animation - 1723387593175.json';
import colorPickerDialogAtom from '@/Atoms/Dialogs/ColorPicker';
import { useNavigate } from 'react-router-dom';
import CustomColorButton from '@/Components/Common/Buttons/CustomColor';
import CustomBackgroundColorButton from '@/Components/Common/Buttons/CustomBackgroundColor';
import { useTranslation } from 'react-i18next';

const CreateCrewDialog = () => {
  const { t } = useTranslation();
  const createCrewDialog = useAtomValue(createCrewDialogAtom)
  const updateState = useSetAtom(createCrewDialogUpdateStateAtom);
  const createCrew = useSetAtom(createCrewDialogCreateAtom);
  const [inputTagValue, setInputTagValue] = React.useState("");
  const checkNameStatus = useAtomValue(createCrewDialogCheckNameStatusAtom);
  const checkCrewName = useSetAtom(createCrewDialogCheckDuplicationAtom);
  const [colorPicker, setColorPicker] = useAtom(colorPickerDialogAtom);
  const [imagePreview, setImagePreview] = useAtom(createCrewDialogImagePreviewAtom);
  const navigate = useNavigate();
  const close = useSetAtom(createCrewDialogCloseAtom);

  const handleTagKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && inputTagValue.trim()) {
      event.preventDefault();
      if (!createCrewDialog?.hashtags.find((tag) => tag === inputTagValue.trim())) {
        updateState("hashtags", [...(createCrewDialog?.hashtags || []), inputTagValue.trim()]);
      }
      setInputTagValue(""); // 입력창 초기화
    } else if (event.key === 'Backspace' && inputTagValue === "") {
      if (createCrewDialog && createCrewDialog.hashtags.length > 0) {
        event.preventDefault();
        const updatedTags = [...(createCrewDialog?.hashtags || [])];
        updatedTags.pop();
        updateState("hashtags", updatedTags);
      }
    }
  };

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      updateState("imageUrl", file);
      setImagePreview(URL.createObjectURL(file));
    }
  }

  return (
    <Dialog
      open={createCrewDialog !== null}
      onClose={close}
      sx={{ display: "flex", flexDirection: "column", gap: "20px" }}
      fullWidth
      maxWidth="sm"
    >
      {
        createCrewDialog &&
        <>
          <DialogTitle sx={{ fontSize: "1rem", fontWeight: "bold" }}>{t("dialogs.createCrew.title")}</DialogTitle>
          <DialogContent sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            <S.ElementContainer>
              <S.Typography>{t("dialogs.createCrew.name")}</S.Typography>
              <S.NameFieldContainer>
                <TextField variant="standard" size="small" placeholder={t("dialogs.createCrew.namePlaceholder")} sx={{ width: "80%" }} value={createCrewDialog.name} onChange={(e) => updateState("name", e.target.value)}
                  helperText={
                    checkNameStatus === "success"
                      ? t("dialogs.createCrew.nameSuccess")
                      : checkNameStatus === "fail"
                        ? t("dialogs.createCrew.nameFail")
                        : ""
                  }
                  FormHelperTextProps={{
                    sx: {
                      color:
                        checkNameStatus === "success"
                          ? "green" // 성공일 때 녹색
                          : checkNameStatus === "fail"
                            ? "red" // 실패일 때 빨간색
                            : "inherit", // 기본 색상
                    },
                  }}
                />
                <CustomBackgroundColorButton onClick={checkCrewName} text="확인" fontSize="0.8rem" disabled={createCrewDialog.name === "" || (checkNameStatus === "loading" || checkNameStatus === "fail")} />
                {/* {
                  checkNameStatus === "loading" &&
                  <Lottie
                    options={{
                      loop: true,
                      autoplay: true,
                      animationData: LottieData,
                      rendererSettings: {
                        preserveAspectRatio: "xMidYMid slice",
                      },
                    }}
                    height={40}
                    width={40}
                  />
                } */}
              </S.NameFieldContainer>
            </S.ElementContainer>
            {
              checkNameStatus === "success" &&
              <>
                <S.ImageContainer>
                  <S.ImageInputContainer>
                    <input
                      accept="image/*"
                      id="image-upload"
                      type="file"
                      style={{ display: 'none' }} // input을 숨김
                      onChange={handleImageUpload} // 파일 선택 시 updateState 함수 호출
                    />
                    <label htmlFor="image-upload">
                      <Button
                        variant="contained"
                        component="span" // span을 사용하여 클릭 이벤트만 처리
                        startIcon={<PhotoCamera />} // 원하는 아이콘 추가
                        sx={{
                          fontSize: "0.8rem",
                          fontWeight: "bold",
                          backgroundColor: theme.colors.primary, // 원하는 스타일 추가
                          color: '#fff',
                          '&:hover': {
                            backgroundColor: lighten(0.05, theme.colors.primary), // hover시 원하는 스타일 추가
                          },
                        }}
                      >
                        {t("dialogs.createCrew.upload")}
                      </Button>
                    </label>
                  </S.ImageInputContainer>
                  {createCrewDialog.imageUrl && (
                    <div style={{ display: "flex", padding: 2, justifyContent: "center" }}>
                      {imagePreview && <img src={imagePreview} alt="preview" style={{ maxWidth: '150px', maxHeight: '150px' }} />
                      }
                    </div>
                  )}
                </S.ImageContainer>
                <Typography sx={{ fontSize: "0.9rem", fontWeight: "bold" }}>{t("dialogs.createCrew.description")}</Typography>
                <TextField
                  variant="standard"
                  size="small"
                  placeholder={t("dialogs.createCrew.descriptionPlaceholder")}
                  multiline
                  rows={2}
                  fullWidth
                  sx={{ marginTop: "-15px" }}
                  value={createCrewDialog.content}
                  onChange={(e) => updateState("content", e.target.value)}
                />
                <S.ElementContainer>
                  <S.Typography>{t("dialogs.createCrew.headcount")}</S.Typography>
                  <TextField variant="standard" size="small" type="number" inputProps={{ min: 1, style: { textAlign: "center" } }} value={createCrewDialog.headCount} onChange={(e) => updateState("headCount", parseInt(e.target.value))} />
                </S.ElementContainer>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="body2" style={{ fontSize: "0.9rem", fontWeight: "bold" }}>
                    {t("dialogs.createCrew.category")}
                  </Typography>
                  <TextField variant="standard" size="small" placeholder={t("dialogs.createCrew.categoryPlaceholder")} sx={{ width: "50%" }} value={createCrewDialog.category} onChange={(e) => updateState("category", e.target.value)} />
                </Box>
                <S.ElementContainer>
                  <Typography variant="body2" style={{ fontSize: "0.9rem", fontWeight: "bold" }}>
                    {t("dialogs.createCrew.hashtag")}
                  </Typography>
                  <S.TagInputContainer>
                    {createCrewDialog.hashtags.map((tag, idx) => {
                      return <S.TagItem key={idx} style={{ backgroundColor: theme.colors.primary, color: "white" }}>
                        <span>{tag}</span>
                      </S.TagItem>
                    })}
                    <S.TagInput
                      placeholder={createCrewDialog.hashtags.length === 0 ? t("dialogs.createCrew.hashtagPlaceholder") : ""}
                      value={inputTagValue}
                      onChange={(e) => setInputTagValue(e.target.value)}
                      onKeyDown={handleTagKeyDown}
                    />
                  </S.TagInputContainer>
                </S.ElementContainer>
                <S.ElementContainer>
                  <S.Typography>{t("dialogs.createCrew.color")}</S.Typography>
                  <S.ColorPickerContainer>
                    <S.SelectedColor $color={colorPicker.color} />
                    <CustomColorButton text={t("dialogs.createCrew.changeColor")} onClick={() => setColorPicker((prev) => ({ ...prev, open: true }))} fontSize="0.8rem" />
                  </S.ColorPickerContainer>
                </S.ElementContainer>
              </>
            }
          </DialogContent>
          <DialogActions>
            <CustomColorButton onClick={close} text={t("dialogs.createCrew.cancel")} fontSize="0.8rem" color="black" />
            {
              checkNameStatus === "success" &&
              <CustomBackgroundColorButton onClick={() => createCrew(navigate)} text={t("dialogs.createCrew.create")} fontSize="0.8rem" />
            }
          </DialogActions>
        </>
      }
    </Dialog >
  )
}

export default CreateCrewDialog;
