import React from "react";
import {
  Box,
  Divider
} from "@mui/material";
import * as S from "../styled";
import theme from "../../../../Styles/theme";
import { DataPoint, ScatterPlot } from "../../Charts/Scatter";
import { useTranslation } from "react-i18next";

// 추천 직업
const scatterPlotData: DataPoint[] = [
  { name: "박지훈", size: 8, x: 10, y: 20 },
  { name: "김민수", size: 12, x: 15, y: 30 },
  { name: "이서연", size: 6, x: 5, y: 35 },
  { name: "정우성", size: 10, x: 12, y: 50 },
  { name: "한유정", size: 14, x: 17, y: 55 },
  { name: "김영민", size: 7, x: 12, y: 60 },
  { name: "최수현", size: 9, x: 17, y: 65 },
  { name: "김도현", size: 11, x: 18, y: 70 },
  { name: "이하나", size: 8, x: 5, y: 75 },
  { name: "이지은", size: 12, x: 20, y: 80 },
];

export function TotalCareerProgress() {
  const { t } = useTranslation();

  return (
    <S.CustomBox
      sx={{ minHeight: "600px", maxHeight: "600px", display: "flex", flexDirection: "column" }}
    >
      <S.TitleTypo>{t("dashboard.mentor.careerProgress.title")}</S.TitleTypo>
      <Divider sx={{ margin: "10px 0" }} />
      <S.DescTypo sx={{ marginBottom: "10px" }}>
        {t("dashboard.mentor.careerProgress.description")}
      </S.DescTypo>

      <Box sx={{ flexGrow: 1 }}>
        <ScatterPlot
          data={scatterPlotData}
          color={theme.colors.primary}
          xDesc={t("dashboard.mentor.careerProgress.numberOfUltimateSkills")}
          yDesc={t("dashboard.mentor.careerProgress.numberOfTodos")}
          xMax={24}
          yMax={100}
          xMin={0}
          yMin={0}
        />
      </Box>
    </S.CustomBox>
  );
}
