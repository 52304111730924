import { atom } from 'jotai';

const createCrewDialogAtom = atom<{
  name: string;
  content: string;
  headCount: number;
  hashtags: never[];
  imageUrl: string | null;
  color: string;
  category: string;
} | null>(null);
export const createCrewDialogCheckNameStatusAtom = atom("init" as "init" | "loading" | "success" | "fail");
export const createCrewDialogImagePreviewAtom = atom<string | null>(null);

export default createCrewDialogAtom;
