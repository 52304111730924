import { useAtomValue } from 'jotai';
import React from 'react';
import * as C from './containers';
import { createSignatureAtom, createSignatureMobileModeAtom } from '@/Atoms/Signature/Atom';
import useIsMobile from '@/Hooks/useIsMobile';

const CreateSignature = () => {
  const { isMobile } = useIsMobile();
  const createSignature = useAtomValue(createSignatureAtom);
  const mobileMode = useAtomValue(createSignatureMobileModeAtom);

  return (
    createSignature && (!isMobile ?
      <C.DesktopContents /> :
      mobileMode === 'edit' ?
        <C.MobileEditContents /> :
        <C.MobileStyleContents />
    )
  )
}

export default CreateSignature;
