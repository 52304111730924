import React from 'react';
import * as S from './styled';
import * as C from './components';
import { Gantt, Task, ViewMode } from 'gantt-task-react';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { ganttChartChangeExpandListAtom, ganttChartChangeTaskPositionAtom, ganttChartChangeViewModeAtom, ganttChartDoubleClickAtom, ganttChartExpanderClickAtom } from '@/ViewModels/Plan/GanttChartViewModel';
import ganttChartAtom from '@/Atoms/Components/GanttChartAtom';
import "gantt-task-react/dist/index.css";
import { useTranslation } from 'react-i18next';
import { devConsoleLog } from '@/Utils/ConsoleLogInDevelopment';

const GanttChart = () => {
  const [height, setHeight] = React.useState<number>(window.innerHeight - 210);
  const ganttChart = useAtomValue(ganttChartAtom);
  const changeViewMode = useSetAtom(ganttChartChangeViewModeAtom);
  const [expandList, setExpandList] = useAtom(ganttChartChangeExpandListAtom);
  const taskChange = useSetAtom(ganttChartChangeTaskPositionAtom);
  const expanderClick = useSetAtom(ganttChartExpanderClickAtom);
  const doubleClick = useSetAtom(ganttChartDoubleClickAtom);
  const { t } = useTranslation();

  const viewModes = [{ value: ViewMode.Day, label: t("plan.contents.gantt.day") }, { value: ViewMode.Week, label: t("plan.contents.gantt.week") }, { value: ViewMode.Month, label: t("plan.contents.gantt.month") }];

  const handleTaskDelete = (task: Task) => {
    const conf = window.confirm("Are you sure about " + task.name + " ?");
    // if (conf) {
    //   setTasks(tasks.filter(t => t.id !== task.id));
    // }
    return conf;
  };

  const handleProgressChange = async (task: Task) => {
    // setTasks(tasks.map(t => (t.id === task.id ? task : t)));
    devConsoleLog("On progress change Id:" + task.id);
  };

  const handleClick = (task: Task) => {
    devConsoleLog("On Click event Id:" + task.id);
  };

  const handleSelect = (task: Task, isSelected: boolean) => {
    devConsoleLog(task.name + " has " + (isSelected ? "selected" : "unselected"));
  };

  React.useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight - 210);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <S.Container>
      <div style={{ display: "flex", gap: "10px", alignItems: "center", justifyContent: "flex-end" }}>
        <div style={{ fontSize: "0.8rem", fontWeight: "bold" }}>{t("plan.contents.gantt.viewMode")}</div>
        {viewModes.map((mode) => (<S.PrimaryColorButton key={mode.value} onClick={() => changeViewMode(mode.value)}>{mode.label}</S.PrimaryColorButton>))}
        <S.PrimaryColorSwitch checked={expandList} onChange={() => setExpandList(!expandList)} />
        <div style={{ fontSize: "0.8rem", fontWeight: "bold" }}>{expandList ? t("plan.contents.gantt.hideList") : t("plan.contents.gantt.showList")}</div>
      </div>
      {
        ganttChart.tasks.length === 0 ?
          <div>{t("plan.contents.gantt.noData")}</div> :
          <Gantt
            tasks={ganttChart.tasks}
            viewMode={ganttChart.viewMode}
            onDateChange={taskChange}
            onDelete={handleTaskDelete}
            onProgressChange={handleProgressChange}
            onDoubleClick={doubleClick}
            onClick={handleClick}
            onSelect={handleSelect}
            onExpanderClick={expanderClick}
            TaskListHeader={C.TaskListHeader}
            TaskListTable={C.CustomListTable}
            TooltipContent={C.StandardTooltipContent}
            listCellWidth={expandList ? "100px" : ""}
            columnWidth={ganttChart.columnWidth}
            handleWidth={10}
            ganttHeight={height}
          />
      }
    </S.Container>
  )
}

export default GanttChart;
