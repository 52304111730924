import deletePlanDialogAtom from '@/Atoms/Dialogs/Delete/PlanAtom';
import { mobileEditPlanNameAtom, mobilePlanAtom } from '@/Atoms/Plan';
import CustomBackgroundColorButton from '@/Components/Common/Buttons/CustomBackgroundColor';
import CustomColorButton from '@/Components/Common/Buttons/CustomColor';
import { updatePlanNameAtom } from '@/ViewModels/Plan/PlanViewModel';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Popover, TextField } from '@mui/material';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import React from 'react';
import { useTranslation } from 'react-i18next';

const PlanMobileHeaderContent = () => {
  const mobilePlanName = useAtomValue(mobilePlanAtom);
  const [planAnchorEl, setPlanAnchorEl] = React.useState<null | HTMLElement>(null);
  const setDeletePlanDialog = useSetAtom(deletePlanDialogAtom);
  const [mobileEditPlanNameModal, setMobileEditPlanNameModal] = useAtom(mobileEditPlanNameAtom);
  const updatePlanName = useSetAtom(updatePlanNameAtom);
  const { t } = useTranslation();

  const planGroupItems = [
    { name: t("plan.contents.todo.edit"), value: "edit" },
    { name: t("plan.contents.todo.delete"), value: "delete" },
  ];

  const handleMenuItemClick = (value: string) => {
    switch (value) {
      case "edit":
        setMobileEditPlanNameModal(mobilePlanName);
        break;

      case "delete":
        setDeletePlanDialog({ open: true, complete: false });
        break;

      default:
        break;
    }
  };

  const handleEditPlanName = (mode: "cancel" | "save") => {
    mode === "save" && mobileEditPlanNameModal && updatePlanName(mobileEditPlanNameModal);
    setMobileEditPlanNameModal(null);
    setPlanAnchorEl(null);
  };

  return (
    <>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={(e) => setPlanAnchorEl(e.currentTarget)}
        sx={{
          color: "black",
          fontWeight: "bold",
          padding: 0,
          position: "absolute",
          left: "50%",
          transform: "translateX(-50%)",
        }}>
        {mobilePlanName}⏷
      </Button>
      <Popover
        anchorEl={planAnchorEl}
        open={Boolean(planAnchorEl)}
        onClose={() => setPlanAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {planGroupItems.map((item, index) =>
          item && <MenuItem key={index} onClick={() => handleMenuItemClick(item.value)}>
            {item.name}
          </MenuItem>
        )}
      </Popover>
      <Dialog
        open={mobileEditPlanNameModal !== null}
        onClose={() => setMobileEditPlanNameModal(null)}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle sx={{ fontSize: "0.9rem", fontWeight: "bold" }}>플랜명 수정</DialogTitle>
        <DialogContent sx={{ fontSize: "0.8rem" }}>
          <TextField
            sx={{ marginTop: "10px" }}
            label="플랜명"
            fullWidth
            variant="outlined"
            value={mobileEditPlanNameModal}
            onChange={(e) => setMobileEditPlanNameModal(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <CustomColorButton onClick={() => handleEditPlanName("cancel")} color="gray" text="취소" fontSize='0.8rem' />
          <CustomBackgroundColorButton onClick={() => handleEditPlanName("save")} text="저장" fontSize='0.8rem' />
        </DialogActions>
      </Dialog>
    </>
  )
}

export default PlanMobileHeaderContent;
