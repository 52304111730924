import { atom } from 'jotai';

interface JobNodeProps {
  id: string;
  status: number; // 0: not started, 1: in progress, 2: done
  jobList: string[];
}

const jobNodeAtom = atom<JobNodeProps | null>(null);

export default jobNodeAtom;
