import { settingsResetPasswordModalAtom } from "@/Atoms/Settings";
import useIsMobile from "@/Hooks/useIsMobile";
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Tooltip } from "@mui/material";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import CustomBackgroundColorButton from "@/Components/Common/Buttons/CustomBackgroundColor";
import { ResetPasswordModalCurrentPasswordAuthAtom, ResetPasswordModalNewPasswordAuthAtom, ResetPasswordModalNewPasswordConfirmAuthAtom, ResetPasswordModalSubmitAtom } from "@/ViewModels/Settings/ResetPasswordViewModel";
import CheckCircleSharpIcon from "@mui/icons-material/CheckCircleSharp";
import WarningIcon from "@mui/icons-material/Warning";
import * as S from "./styled";

const ResetPasswordModal = () => {
  const { isMobile } = useIsMobile();
  const [modal, setModal] = useAtom(settingsResetPasswordModalAtom);
  const authCurrentPassword = useSetAtom(ResetPasswordModalCurrentPasswordAuthAtom);
  const isValidNewPassword = useAtomValue(ResetPasswordModalNewPasswordAuthAtom);
  const isValidNewPasswordConfirm = useAtomValue(ResetPasswordModalNewPasswordConfirmAuthAtom);
  const resetPassword = useSetAtom(ResetPasswordModalSubmitAtom);

  return (
    modal &&
    <Dialog open={modal !== null} onClose={() => setModal(null)} fullScreen={isMobile} fullWidth maxWidth="sm">
      <DialogTitle sx={{ fontSize: "0.9rem", fontWeight: "bold", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
        비밀번호 재설정
        <CloseIcon sx={{ cursor: "pointer" }} onClick={() => setModal(null)} />
      </DialogTitle>
      <DialogContent sx={{ fontSize: "0.8rem", display: "flex", flexDirection: "column", gap: "20px", justifyContent: "center", alignItems: "center" }}>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: "10px" }}>
          <div style={{ width: "150px" }}>현재 비밀번호</div>
          <TextField variant="outlined" size="small" type="password" value={modal.currentPassword} onChange={(e) => setModal({ ...modal, currentPassword: e.target.value })} onKeyDown={(e) => {
            if (e.key === "Enter") {
              authCurrentPassword();
            }
          }} />
          <CustomBackgroundColorButton text="확인" fontSize="0.8rem" onClick={authCurrentPassword} />
        </div>

        {
          modal.isCorrectPassword &&
          <>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: "10px" }}>
              <div style={{ width: "150px" }}>새 비밀번호</div>
              <TextField variant="outlined" size="small" type="password" value={modal.newPassword} onChange={(e) => setModal({ ...modal, newPassword: e.target.value })} />
              <S.CustomIconButton tabIndex={-1}>
                {isValidNewPassword ? (
                  <CheckCircleSharpIcon
                    sx={{ color: process.env.REACT_APP_MAIN_COLOR }}
                  />
                ) : (
                  <Tooltip title="비밀번호는 최소 8자 이상이어야 하며, 대문자, 숫자, 특수문자를 포함해야 합니다.">
                    <WarningIcon sx={{ color: "red" }} />
                  </Tooltip>
                )}
              </S.CustomIconButton>
            </div>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: "10px" }}>
              <div style={{ width: "150px" }}>비밀번호 확인</div>
              <TextField variant="outlined" size="small" type="password" value={modal.newPasswordConfirm} onChange={(e) => setModal({ ...modal, newPasswordConfirm: e.target.value })} />
              <S.CustomIconButton tabIndex={-1}>
                {isValidNewPasswordConfirm ? (
                  <CheckCircleSharpIcon
                    sx={{ color: process.env.REACT_APP_MAIN_COLOR }}
                  />
                ) : (
                  <Tooltip title="비밀번호와 동일해야 합니다.">
                    <WarningIcon sx={{ color: "red" }} />
                  </Tooltip>
                )}
              </S.CustomIconButton>
            </div>
          </>
        }
      </DialogContent>
      {
        modal.isCorrectPassword &&
        <DialogActions>
          <CustomBackgroundColorButton text="비밀번호 재설정" fontSize="0.8rem" onClick={resetPassword} />
        </DialogActions>
      }
    </Dialog>
  )
}

export default ResetPasswordModal;
