import styled from 'styled-components';

export const SignatureContainer = styled.div<{ $borderColor: string | undefined, $backgroundColor: string | undefined, $fontColor: string | undefined }>`
  width: 200px;
  height: ${200 * 5 / 9}px;
  border: ${({ $borderColor }) => $borderColor ? `3px solid ${$borderColor}` : "1px solid lightgray"};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  padding: 6px;
  position: relative;
  background-color: ${({ $backgroundColor }) => $backgroundColor || "white"};
  color: ${({ $fontColor }) => $fontColor || "black"};
`

export const LoopIconStyle = {
  position: "absolute",
  top: "10px",
  right: "10px",
  fontSize: "24px"
};

export const SignatureProfileContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 40%;
  justify-content: flex-start;
`;

export const SignatureImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding: 0;
  margin: 0;
  object-fit: cover;
`;

export const SignatureProfileInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  padding: 5px;
`;

export const SignatureGrid = styled.div<{ $height: string }>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 1fr;
  height: ${({ $height }) => $height};
  justify-content: flex-start;
`;

export const GridItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
`;

export const ItemTitle = styled.div`
  font-size: 0.5rem;
  font-weight: bold;
`;

export const ItemValue = styled.div`
  font-size: 0.5rem;
`;

export const HoverOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* 반투명 검정 배경 */
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  border-radius: 10px;
  z-index: 10;
`;
