import { selfJoinCrew, selfLeaveCrew } from "@/Queries/CrewQueries";
import GetIdFromQuerystring from "@/Utils/GetIdFromQuerystring";
import { atom } from "jotai";
import { getUserId } from "../UserViewModel";
import snackbarAtom from "@/Atoms/Snackbar";
import { useCrewAtom, usePlanHipAtom } from "@/Atoms/HookControllerAtom";
import { NavigateFunction } from "react-router-dom";
import { handleReactQueryApiResponse } from "@/Utils/APIUtil";
import { error401ModalAtom } from "@/Atoms/Dialogs/Error/401Atom";

// 크루 입장
export const crewJoinAtom = atom(null, async (get, set) => {
  const crewId = GetIdFromQuerystring("crew_id");
  const userId = get(getUserId);
  const handleError401Modal = () => set(error401ModalAtom, true);
  const response = await handleReactQueryApiResponse(selfJoinCrew, handleError401Modal, crewId, userId);
  if (response.ok) {
    set(snackbarAtom, { open: true, message: "크루에 가입되었습니다.", severity: "success" });
    set(usePlanHipAtom, true);
    set(useCrewAtom, true);
  }
});

// 크루 퇴장
export const crewLeaveAtom = atom(null, async (get, set, navigate: NavigateFunction) => {
  const crewId = GetIdFromQuerystring("crew_id");
  const userId = get(getUserId);
  const handleError401Modal = () => set(error401ModalAtom, true);
  const response = await handleReactQueryApiResponse(selfLeaveCrew, handleError401Modal, crewId, userId);
  if (response.ok) {
    set(snackbarAtom, { open: true, message: "크루에서 퇴장했습니다.", severity: "success" });
    set(usePlanHipAtom, true);
    navigate("/plan-hip");
  }
});
