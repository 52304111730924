import dayjs, { Dayjs } from "dayjs";
import { atom } from "jotai";

export interface DateSelectorAtomProps {
  isInit: boolean;
  currentDate: Dayjs;
  startDate: Dayjs;
  endDate: Dayjs;
  changedStartDate: Dayjs;
  changedEndDate: Dayjs;
  weekOrMonth: "week" | "month";
  isPrevDisabled: boolean;
  isNextDisabled: boolean;
  renderedDate: string;
}

const dateSelectorAtom = atom<DateSelectorAtomProps>({
  isInit: false,
  currentDate: dayjs(),
  startDate: dayjs(),
  endDate: dayjs(),
  changedStartDate: dayjs(),
  changedEndDate: dayjs(),
  weekOrMonth: "week",
  isPrevDisabled: false,
  isNextDisabled: false,
  renderedDate: `${dayjs().year()}년 ${dayjs().month() + 1}월`,
});

export default dateSelectorAtom;
