import { crewLecturesAtom, Lecture } from '@/Atoms/Crew';
import editLectureDialogAtom, { editLectureDialogInitializeState } from '@/Atoms/Dialogs/Edit/Lecture';
import { error401ModalAtom } from '@/Atoms/Dialogs/Error/401Atom';
import snackbarAtom from '@/Atoms/Snackbar';
import { CreateLectureInfo, updateLecture } from '@/Queries/LectureQueries';
import { handleReactQueryApiResponse } from '@/Utils/APIUtil';
import GetIdFromQuerystring from '@/Utils/GetIdFromQuerystring';
import { getUserId } from '@/ViewModels/UserViewModel';
import dayjs from 'dayjs';
import { TFunction } from 'i18next';
import { atom } from 'jotai';

// Edit Lecture Dialog
export const editLectureDialogOpenAtom = atom(null, (get, set, id: number) => {
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  const lectures = get(crewLecturesAtom);
  const lecture = lectures.find((lecture) => lecture.innerTabId === innerTabId)?.lectures.find((lecture) => lecture.id === id);

  if (!lecture) {
    return;
  }

  set(editLectureDialogAtom, (prev) => ({
    ...prev,
    open: true,
    id: lecture.id,
    name: lecture.name,
    teacher: lecture.teacher,
    grades: lecture.grades,
    headcount: lecture.headcount,
    period: lecture.period,
    operatingTimes: lecture.operatingTimes,
    operatingPlan: lecture.operatingPlan,
    classRoom: lecture.classRoom,
    textbook: lecture.textbook,
    isVisibleMember: lecture.isVisibleMember,
    isAbleToCancel: lecture.isAbleToCancel,
    applicationPeriod: {
      start: {
        date: dayjs(lecture.applicationPeriod.start.date).format('YYYY-MM-DD'),
        hour: lecture.applicationPeriod.start.hour,
        minute: lecture.applicationPeriod.start.minute,
      },
      end: {
        date: dayjs(lecture.applicationPeriod.end.date).format('YYYY-MM-DD'),
        hour: lecture.applicationPeriod.end.hour,
        minute: lecture.applicationPeriod.end.minute,
      },
      detail: lecture.applicationPeriod.detail,
    },
    coursePeriod: {
      startDate: dayjs(lecture.coursePeriod.startDate).format('YYYY-MM-DD'),
      endDate: dayjs(lecture.coursePeriod.endDate).format('YYYY-MM-DD'),
    },
    hashtags: lecture.hashtags,
    members: lecture.members,
    management: lecture.management,
  }))
});

// close Edit Lecture Dialog
export const editLectureDialogCloseAtom = atom(null, (get, set) => {
  set(editLectureDialogAtom, editLectureDialogInitializeState(false, 0));
});

// save dialog
export const editLectureDialogSaveAtom = atom(null, async (get, set, t: TFunction<"translation", undefined>) => {
  const dialog = get(editLectureDialogAtom);

  // Validation
  if (!dialog.teacher.trim()) {
    set(snackbarAtom, { open: true, message: t("dialogs.common.snackbar.lecture.enterTeacher"), severity: 'error' });
    return;
  }

  if (!dialog.name.trim()) {
    set(snackbarAtom, { open: true, message: t("dialogs.common.snackbar.lecture.enterLectureName"), severity: 'error' });
    return;
  }

  if (!dialog.grades || dialog.grades.length === 0) {
    set(snackbarAtom, { open: true, message: t("dialogs.common.snackbar.lecture.enterGrade"), severity: 'error' });
    return;
  }

  if (!dialog.headcount || dialog.headcount <= 0) {
    set(snackbarAtom, { open: true, message: t("dialogs.common.snackbar.lecture.headcount"), severity: 'error' });
    return;
  }

  if (!dialog.period || dialog.period <= 0) {
    set(snackbarAtom, { open: true, message: t("dialogs.common.snackbar.lecture.period"), severity: 'error' });
    return;
  }

  if (Object.keys(dialog.operatingTimes).length === 0 ||
    !Object.values(dialog.operatingTimes).some(times => times.length > 0)) {
    set(snackbarAtom, { open: true, message: t("dialogs.common.snackbar.lecture.operationTime"), severity: 'error' });
    return;
  }

  // Date validations
  const { applicationPeriod, coursePeriod } = dialog;

  const applicationStart = new Date(`${applicationPeriod.start.date}T${applicationPeriod.start.hour}:${applicationPeriod.start.minute}`);
  const applicationEnd = new Date(`${applicationPeriod.end.date}T${applicationPeriod.end.hour}:${applicationPeriod.end.minute}`);
  const courseStart = new Date(coursePeriod.startDate);
  const courseEnd = new Date(coursePeriod.endDate);

  if (applicationStart >= applicationEnd) {
    set(snackbarAtom, { open: true, message: t("dialogs.common.snackbar.lecture.applicationStart"), severity: 'error' });
    return;
  }

  if (courseStart >= courseEnd) {
    set(snackbarAtom, { open: true, message: t("dialogs.common.snackbar.lecture.courseStart"), severity: 'error' });
    return;
  }

  if (applicationEnd >= courseStart) {
    set(snackbarAtom, { open: true, message: t("dialogs.common.snackbar.lecture.applicationCourse"), severity: 'error' });
    return;
  }

  const innerTabId = GetIdFromQuerystring('inner_tab_id');
  const lectures = get(crewLecturesAtom).find((l) => l.innerTabId === innerTabId)?.lectures || [];

  // If all validations pass, create a new lecture object
  const newLecture: Lecture = {
    id: dialog.id,
    name: dialog.name,
    teacher: dialog.teacher,
    author_id: dialog.author_id,
    grades: dialog.grades,
    headcount: dialog.headcount,
    period: dialog.period,
    operatingTimes: dialog.operatingTimes,
    operatingPlan: dialog.operatingPlan,
    classRoom: dialog.classRoom,
    textbook: dialog.textbook,
    isVisibleMember: dialog.isVisibleMember,
    isAbleToCancel: dialog.isAbleToCancel,
    applicationPeriod: dialog.applicationPeriod,
    coursePeriod: dialog.coursePeriod,
    hashtags: dialog.hashtags,
    members: dialog.members,
    management: dialog.management,
  };

  const newUpdateLecture: CreateLectureInfo = {
    name: dialog.name,
    teacher: dialog.teacher,
    teacherId: dialog.management.id,
    grades: dialog.grades,
    headcount: dialog.headcount,
    period: dialog.period,
    operatingTimes: dialog.operatingTimes,
    operatingPlan: dialog.operatingPlan,
    classRoom: dialog.classRoom,
    textbook: dialog.textbook,
    isVisibleMember: dialog.isVisibleMember,
    isAbleToCancel: dialog.isAbleToCancel,
    applicationPeriod: dialog.applicationPeriod,
    coursePeriod: dialog.coursePeriod,
    hashtags: dialog.hashtags
  };

  // Update the lecture
  const newLectures = lectures.map((lecture) => (lecture.id === dialog.id) ? newLecture : lecture);

  const handleError401Modal = () => set(error401ModalAtom, true);
  await handleReactQueryApiResponse(updateLecture, handleError401Modal, dialog.id, newUpdateLecture, get(getUserId)).then((result) => {
    if (!result.ok) {
      set(snackbarAtom, { open: true, message: t("dialogs.common.snackbar.lecture.editLectureFail"), severity: 'error' });
      return;
    } else {
      set(crewLecturesAtom, (prev) => prev.map((l) => (l.innerTabId === innerTabId) ? { ...l, lectures: newLectures } : l));

      set(snackbarAtom, { open: true, message: t("dialogs.common.snackbar.lecture.editLectureComplete"), severity: 'success' });
    }
    // Close the dialog
    set(editLectureDialogAtom, editLectureDialogInitializeState(false, 0));
  });

});

// edit teacher
export const editLectureDialogTeacherAtom = atom((get) => get(editLectureDialogAtom).teacher, (get, set, teacher: string) => {
  set(editLectureDialogAtom, (prev) => ({
    ...prev,
    teacher,
  }));
});

// edit headcount
export const editLectureDialogHeadcountAtom = atom((get) => get(editLectureDialogAtom).headcount, (get, set, headcount: number) => {
  set(editLectureDialogAtom, (prev) => ({
    ...prev,
    headcount,
  }));
});

// edit period
export const editLectureDialogPeriodAtom = atom((get) => get(editLectureDialogAtom).period, (get, set, period: number) => {
  set(editLectureDialogAtom, (prev) => ({
    ...prev,
    period,
  }));
});

// edit grade
export const editLectureDialogGradesAtom = atom(
  (get) => get(editLectureDialogAtom).grades,
  (get, set, grade: number) => {
    set(editLectureDialogAtom, (prev) => {
      let updatedGrades: number[] = [];

      if (grade === 0) {
        // If all grades [0, 1, 2, 3] are already selected, clear the array
        if (prev.grades.length === 4 && prev.grades.every((g) => [0, 1, 2, 3].includes(g))) {
          updatedGrades = [];
        } else {
          // Otherwise, set to [0, 1, 2, 3]
          updatedGrades = [0, 1, 2, 3];
        }
      } else {
        // If the grade already exists, remove it and remove 0 if present
        if (prev.grades.includes(grade)) {
          updatedGrades = prev.grades.filter((g) => g !== grade && g !== 0);
        } else {
          // Add the grade
          updatedGrades = [...prev.grades, grade];

          // Check if we now have [1, 2, 3] and need to add 0
          if ([1, 2, 3].every((g) => updatedGrades.includes(g))) {
            updatedGrades = Array.from(new Set([...updatedGrades, 0]));
          }
        }
      }

      return { ...prev, grades: updatedGrades };
    });
  }
);

// edit operating plan
export const editLectureDialogOperatingPlanAtom = atom((get) => get(editLectureDialogAtom).operatingPlan, (get, set, operatingPlan: string) => {
  set(editLectureDialogAtom, (prev) => ({
    ...prev,
    operatingPlan,
  }));
});

// edit operating times
export const editLectureDialogOperatingTimesAtom = atom((get) => get(editLectureDialogAtom).operatingTimes, (get, set, day: string, time: number) => {
  set(editLectureDialogAtom, (prev) => ({
    ...prev,
    operatingTimes: {
      ...prev.operatingTimes,
      [day]: prev.operatingTimes[day].includes(time)
        ? prev.operatingTimes[day].filter((t) => t !== time)
        : [...prev.operatingTimes[day], time],
    },
  }));
});

// edit operating times (specific day)
export const editLectureDialogOperatingTimesDayAtom = atom(null, (get, set, day: string) => {
  set(editLectureDialogAtom, (prev) => ({
    ...prev,
    operatingTimes: {
      ...prev.operatingTimes,
      [day]: Array.from({ length: 14 }, (_, i) => i),
    },
  }));
});

// edit operating times (specific time)
export const editLectureDialogOperatingTimesTimeAtom = atom(null, (get, set, time: number) => {
  set(editLectureDialogAtom, (prev) => ({
    ...prev,
    operatingTimes: Object.fromEntries(
      Object.entries(prev.operatingTimes).map(([day, times]) => [
        day,
        times.includes(time) ? times : [...times, time],
      ])
    ),
  }));
});

// edit class room
export const editLectureDialogClassRoomAtom = atom((get) => get(editLectureDialogAtom).classRoom, (get, set, LectureRoom: string) => {
  set(editLectureDialogAtom, (prev) => ({
    ...prev,
    classRoom: LectureRoom,
  }));
});

// edit name
export const editLectureDialogNameAtom = atom((get) => get(editLectureDialogAtom).name, (get, set, name: string) => {
  set(editLectureDialogAtom, (prev) => ({
    ...prev,
    name,
  }));
});

// edit textbook
export const editLectureDialogTextbookAtom = atom((get) => get(editLectureDialogAtom).textbook, (get, set, textbook: string) => {
  set(editLectureDialogAtom, (prev) => ({
    ...prev,
    textbook,
  }));
});

// application period
export const editLectureDialogApplicationPeriodAtom = atom((get) => get(editLectureDialogAtom).applicationPeriod);

// edit application period (start, end date)
export const editLectureDialogApplicationPeriodDateAtom = atom(null, (get, set, date: Date, type: "start" | "end") => {
  set(editLectureDialogAtom, (prev) => ({
    ...prev,
    applicationPeriod: {
      ...prev.applicationPeriod,
      [type]: {
        ...prev.applicationPeriod[type],
        date: dayjs(date).format('YYYY-MM-DD'),
      },
    },
  }));
});

// edit application period (start, end hour and minute)
export const editLectureDialogApplicationPeriodTimeAtom = atom(null, (get, set, time: number, type: "start" | "end", option: "hour" | "minute") => {
  const newTime = option === "hour" ? time > 23 ? 23 : time : time > 59 ? 59 : time;
  set(editLectureDialogAtom, (prev) => ({
    ...prev,
    applicationPeriod: {
      ...prev.applicationPeriod,
      [type]: {
        ...prev.applicationPeriod[type],
        [option]: newTime,
      },
    },
  }));
});

// edit course period
export const editLectureDialogCoursePeriodAtom = atom((get) => get(editLectureDialogAtom).coursePeriod, (get, set, date: Date, type: "startDate" | "endDate") => {
  set(editLectureDialogAtom, (prev) => ({
    ...prev,
    coursePeriod: {
      ...prev.coursePeriod,
      [type]: dayjs(date).format('YYYY-MM-DD'),
    },
  }));
});

// 해시태그 추가
export const editLectureDialogAddHashtagAtom = atom(null, (get, set, hashtag: { backgroundColor: string, textColor: string, value: string }) => {
  set(editLectureDialogAtom, (prev) => ({
    ...prev,
    hashtags: [...prev.hashtags, hashtag],
  }));
});

// 해시태그 삭제(pop)
export const editLectureDialogRemoveHashtagAtom = atom(null, (get, set) => {
  set(editLectureDialogAtom, (prev) => ({
    ...prev,
    hashtags: prev.hashtags.slice(0, -1),
  }));
});

// 수강생 표시 여부
export const editLectureDialogIsVisibleMemberAtom = atom((get) => get(editLectureDialogAtom).isVisibleMember, (get, set, isVisibleMember: boolean) => {
  set(editLectureDialogAtom, (prev) => ({
    ...prev,
    isVisibleMember,
  }));
});

// 수강 취소 가능 여부
export const editLectureDialogIsAbleToCancelAtom = atom((get) => get(editLectureDialogAtom).isAbleToCancel, (get, set, isAbleToCancel: boolean) => {
  set(editLectureDialogAtom, (prev) => ({
    ...prev,
    isAbleToCancel,
  }));
});
