import inactivateDialogAtom from '@/Atoms/Dialogs/Inactivate';
import CustomBackgroundColorButton from '@/Components/Common/Buttons/CustomBackgroundColor';
import CustomColorButton from '@/Components/Common/Buttons/CustomColor';
import { inactivateDialogClearAtom, inactivateDialogConfirmAtom } from '@/ViewModels/Dialogs/InactivateViewModel';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useAtom, useSetAtom } from 'jotai';
import React from 'react';

const InactivateDialog = () => {
  const [dialog, setDialog] = useAtom(inactivateDialogAtom);
  const confirm = useSetAtom(inactivateDialogConfirmAtom);
  const clear = useSetAtom(inactivateDialogClearAtom);
  return (
    dialog &&
    <Dialog open={dialog.open} onClose={() => setDialog(null)}>
      <DialogTitle sx={{ fontSize: "0.9rem", fontWeight: "bold" }}>{dialog.node.data.activate === 1 ? "비활성화 해제" : "비활성화"}</DialogTitle>
      <DialogContent sx={{ fontSize: "0.8rem" }}>
        <div>
          {dialog.node.data.activate === 1 ? "비활성화를 해제 하시겠습니까?" : "비활성화 하시겠습니까?"}
        </div>
        <div>
          {dialog.node.data.activate === 1 ? "하위 노드가 다시 표시됩니다." : "비활성화된 노드의 하위 노드는 숨김 처리됩니다."}
        </div>
      </DialogContent>
      <DialogActions>
        <CustomColorButton onClick={() => setDialog(null)} text="취소" fontSize="0.8rem" color="black" />
        <CustomBackgroundColorButton onClick={() => dialog.node.data.activate === 1 ? clear() : confirm()} text="확인" fontSize="0.8rem" />
      </DialogActions>
    </Dialog>
  )
}

export default InactivateDialog;
