import React from "react";
import { useAtomValue, useSetAtom } from "jotai";
import { useLocation, useNavigate } from "react-router-dom";
import SmartToyOutlinedIcon from "@mui/icons-material/SmartToyOutlined";
import CropSquareOutlinedIcon from "@mui/icons-material/CropSquareOutlined";
import LocalFireDepartmentOutlinedIcon from "@mui/icons-material/LocalFireDepartmentOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import { deleteAuthCookies, deleteCookie } from "@/Utils/CookieUtils";
import { userAtom } from "@/Atoms/UserAtom";
import { useTranslation } from "react-i18next";
import { devConsoleLog } from "@/Utils/ConsoleLogInDevelopment";
import { getUserMembershipAtom } from "@/ViewModels/UserViewModel";

const BottomSectionButtons = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const navigate = useNavigate();
  const setUser = useSetAtom(userAtom);
  const { t } = useTranslation();
  const membership = useAtomValue(getUserMembershipAtom);

  const bottomSectionButtons = [
    membership !== "mentee" ? {
      onClick: () => window.open("https://dashboard.dokgabi.ai", "_blank"),
      tooltipTitle: t("sidebar.buttons.mentorChatbotBuilder"),
      icon: SmartToyOutlinedIcon,
      iconSize: currentPath === "/mentor-chatbot" ? 35 : 25,
      color: "white",
      path: "/mentor-chatbot",
    } : null,
    {
      onClick: () => (window.location.href = "https://pay.dokgabi.ai"),
      tooltipTitle: t("sidebar.buttons.pay"),
      icon: CropSquareOutlinedIcon,
      iconSize: currentPath === "/ai-studio" ? 35 : 25,
      color: "white",
      path: "/ai-studio",
    },
    // {
    //   onClick: () => window.open("https://dashboard.dokgabi.ai", "_blank"),
    //   tooltipTitle: t("sidebar.buttons.booster"),
    //   icon: LocalFireDepartmentOutlinedIcon,
    //   iconSize: currentPath === "/booster" ? 35 : 25,
    //   color: "white",
    //   path: "/booster",
    // },
    {
      onClick: () => navigate("/settings"),
      tooltipTitle: t("sidebar.buttons.settings"),
      icon: SettingsIcon,
      iconSize: 25,
      color: "white",
      path: "/settings",
    },
    {
      onClick: () => {
        deleteAuthCookies();

        setUser(null);
        window.location.href = process.env.REACT_APP_PARENT_LOGIN_ADDRESS;
      },
      tooltipTitle: t("sidebar.buttons.logout"),
      icon: LogoutIcon,
      iconSize: 25,
      color: "white",
      path: "/logout",
    },
  ].filter(Boolean);
  return bottomSectionButtons;
};

export default BottomSectionButtons;
