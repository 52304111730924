import { Box, InputBase, styled, Typography } from "@mui/material";

export const CustomBox = styled(Box)(({ theme }) => ({
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[24],
  padding: theme.spacing(4),
  outline: "none",
  textAlign: "center",

  "@media (max-width: 768px)": {
    width: "90%", // 모바일에서는 너비를 90%로 설정
    padding: theme.spacing(2), // 패딩을 줄임
    boxShadow: theme.shadows[12], // 모바일에서 그림자 크기를 줄임
  },
}));

export const InterestBox = styled(Box)(({ theme }) => ({
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[24],
  padding: theme.spacing(4),
  outline: "none",
  textAlign: "center",
  borderRadius: "5px",
  border: "1px solid #c0c0c0",
  width: "50vw",
  maxHeight: "80vh",
  overflowY: "auto",
  "@media (max-width: 768px)": {
    width: "90%", // 모바일에서는 너비를 90%로 설정
    padding: theme.spacing(2), // 패딩을 줄임
    boxShadow: theme.shadows[12], // 모바일에서 그림자 크기를 줄임
  },
}));

export const leftTypo = styled(Typography)({
  color: "#8f8f8f",
  fontWeight: "bold",
  flex: 1,
  textAlign: "start",
});

export const InputContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  backgroundColor: "#e5e8eb",
  borderRadius: "30px",
  padding: "0px 5px",
  height: "35px",
  flex: 4,
}));

export const CustomInputBase = styled(InputBase)(({ theme }) => ({
  flex: 1,
  marginLeft: "15px",
  fontSize: "0.8rem"
}));
