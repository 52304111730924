import { DefaultTheme } from "styled-components";
import { lighten, darken } from "polished";

const theme: DefaultTheme = {
  colors: {
    primary: "#06C25E",
    secondary: "#00371C",
    customPink: "#ff00e4",
    customYellow: "#faff20",
    customSky: "#09bffd",
    customPurple: "#4c24e3",
    customDarkGreen: "#00371C",
    customPaleBlue: "#d2f4ff",
    background: "#d9d9d9",
    dream: "#06C25E",
    goal: "#007ACC",
    job: "#F39C12",
    task: "#E74C3C",
    ultimate: "#9B59B6",
    major: "#3498DB",
    curriculum: "#F1C40F",
    darkPrimary: "#059C4B",
    lightBackground: lighten(0.1, "#d9d9d9"),
    darkBackground: darken(0.1, "#d9d9d9"),
  },
};

export default theme;
