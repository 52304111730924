import React from "react";
import EditPlanNameDialogAtom from "@/Atoms/Dialogs/Edit/PlanNameAtom";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { useAtom, useSetAtom } from "jotai"
import { editPlanNameDialogUpdateAtom } from "@/ViewModels/Dialogs/Edit/PlanNameViewModel";
import theme from "@/Styles/theme";
import CustomColorButton from "@/Components/Common/Buttons/CustomColor";

const EditPlanNameDialog = () => {
  const [editPlanName, setEditPlanName] = useAtom(EditPlanNameDialogAtom);
  const updateName = useSetAtom(editPlanNameDialogUpdateAtom);

  return (
    <Dialog open={editPlanName.open} fullWidth maxWidth="xs">
      <DialogTitle sx={{ fontSize: "1rem", fontWeight: "bold" }}>플랜 이름 설정</DialogTitle>
      <DialogContent>
        <TextField
          label="플랜 이름"
          variant="standard"
          value={editPlanName.name}
          onChange={(e) => setEditPlanName((prev) => ({ ...prev, name: e.target.value }))}
          fullWidth
          sx={{ fontSize: "0.8rem" }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              updateName();
            }
          }}
        />
      </DialogContent>
      <DialogActions>
        <CustomColorButton onClick={updateName} text="다음" fontSize="0.8rem" />
      </DialogActions>
    </Dialog>
  )
}

export default EditPlanNameDialog;
