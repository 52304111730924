import { createTaskDialogEditRoutineAtom } from '@/Atoms/Dialogs/Create/Task';
import { createTaskInGroupDialogAtom } from '@/Atoms/Dialogs/Create/TaskInGroup';
import { edgesAtom, nodesAtom } from '@/Atoms/Plan/MindmapAtom';
import snackbarAtom from '@/Atoms/Snackbar';
import GetIdFromQuerystring from '@/Utils/GetIdFromQuerystring';
import { updatePlanDataMindmapAtom } from '@/ViewModels/Plan/InnerTabViewModel';
import { atom } from 'jotai';

// 상태 초기화
export const createTaskInGroupDialogClearStateAtom = atom(null, (get, set) => {
  set(createTaskInGroupDialogAtom, {
    open: null,
    groupId: null,
    isCrew: false,
    name: "",
    content: "",
    startDate: null,
    endDate: null,
    termType: null,
    termData: null,
    location: { address: "", latitude: null, longitude: null },
    hashtags: [],
  });
})

// 루틴 옵션 변경
export const createTaskInGroupDialogSetRoutineOptionAtom = atom(null, (get, set, key: number) => {
  set(createTaskInGroupDialogAtom, (prev) => ({
    ...prev,
    termType: prev.termType === key ? null : key,
    termData: prev.termType === key ? null : [],
  }));
});

// key에 따라 상태 변경
export const createTaskInGroupDialogUpdateStateAtom = atom((get) => get(createTaskInGroupDialogAtom), (get, set, key: string, value: any) => {
  if (key === "termData") {
    const weekDays = ["월", "화", "수", "목", "금", "토", "일"];
    get(createTaskInGroupDialogAtom).termType === 1 ?
      value.sort((a: string, b: string) => weekDays.indexOf(a) - weekDays.indexOf(b)) : value.sort((a: string, b: string) => parseInt(a) - parseInt(b));
  }
  set(createTaskInGroupDialogAtom, (prev) => ({
    ...prev,
    [key]: value,
  }));
});

// 할 일 생성
export const createTaskInGroupDialogAddTaskAtom = atom(null, (get, set) => {
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  const task = get(createTaskInGroupDialogAtom);
  if (!task.open || task.groupId === null) return;
  if (task.name === "") {
    set(snackbarAtom, (prev) => ({ ...prev, open: true, message: "이름을 입력해주세요.", severity: "error" }));
    return;
  }
  const firstNode = get(nodesAtom)[0] ? get(nodesAtom)[0] : {
    position: { x: 0, y: 0 },
  };
  const newNode = {
    id: task.open,
    parentId: task.groupId,
    position: { x: firstNode.position.x, y: firstNode.position.y + 200 },
    data: {
      id: task.open,
      backendId: 0,
      label: task.name,
      startDate: task.startDate ? task.startDate.toISOString() : null,
      endDate: task.endDate ? task.endDate.toISOString() : null,
      content: task.content,
      isNewCreated: true,
      termType: task.termType,
      termData: task.termData,
      location: task.location,
      taskStatus: [],
      hashtags: task.hashtags,
    },
    type: "task",
    measured: {
      width: 410,
      height: 176
    },
  }
  const newNodes = [...get(nodesAtom), newNode];
  set(nodesAtom, [newNode, ...get(nodesAtom)]);
  set(createTaskInGroupDialogClearStateAtom);
  set(updatePlanDataMindmapAtom, innerTabId, newNodes, get(edgesAtom));
});

// 장소 변경
export const createTaskInGroupDialogEditLocationAtom = atom(null, (get, set, address: string) => {
  set(createTaskInGroupDialogAtom, (prev) => ({
    ...prev,
    location: { address: address, latitude: prev.location.latitude, longitude: prev.location.longitude },
  }));
})

// 루틴 일자 변경
export const createTaskInGroupDialogEditRoutineDataAtom = atom((get) => get(createTaskDialogEditRoutineAtom), (get, set, day: string) => {
  const editRoutineData = get(createTaskDialogEditRoutineAtom);
  if (!editRoutineData.termData) return;
  if (editRoutineData.termData.includes(day)) {
    set(createTaskDialogEditRoutineAtom, { termType: editRoutineData.termType, termData: editRoutineData.termData.filter((interval) => interval !== day) });
  } else {
    set(createTaskDialogEditRoutineAtom, { termType: editRoutineData.termType, termData: [...editRoutineData.termData, day] });
  }
});


// 루틴 일자 초기화
export const createTaskInGroupDialogEditRoutineClearStateAtom = atom(null, (get, set) => {
  set(createTaskDialogEditRoutineAtom, {
    termType: null,
    termData: null,
  });
});

// 루틴 변경 적용
export const createTaskInGroupDialogEditRoutineApplyAtom = atom(null, (get, set) => {
  const editRoutineData = get(createTaskDialogEditRoutineAtom);
  set(createTaskInGroupDialogAtom, (prev) => ({
    ...prev,
    termData: editRoutineData.termData,
  }));
  set(createTaskInGroupDialogEditRoutineClearStateAtom);
});

// 루틴 변경 시작
export const createTaskInGroupDialogEditRoutineStartAtom = atom(null, (get, set) => {
  const termData = get(createTaskInGroupDialogAtom).termData;
  set(createTaskDialogEditRoutineAtom, {
    termType: get(createTaskInGroupDialogAtom).termType,
    termData: termData ? [...termData] : [],
  });
});

// 해시태그 추가
export const createTaskInGroupDialogAddHashtagAtom = atom(null, (get, set, hashtag: { backgroundColor: string, textColor: string, value: string }) => {
  set(createTaskInGroupDialogAtom, (prev) => ({
    ...prev,
    hashtags: [...prev.hashtags, hashtag],
  }));
});

// 해시태그 삭제(pop)
export const createTaskInGroupDialogRemoveHashtagAtom = atom(null, (get, set) => {
  set(createTaskInGroupDialogAtom, (prev) => ({
    ...prev,
    hashtags: prev.hashtags.slice(0, -1),
  }));
});
