import { error401ModalAtom } from '@/Atoms/Dialogs/Error/401Atom';
import CustomBackgroundColorButton from '@/Components/Common/Buttons/CustomBackgroundColor';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useAtom } from 'jotai';
import React from 'react';

const Error401Modal = () => {
  const [modal, setModal] = useAtom(error401ModalAtom)
  const handleRedirect = () => {
    setModal(false);
    window.location.href = process.env.REACT_APP_PARENT_LOGIN_ADDRESS;
  }

  return (
    <Dialog open={modal} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ fontSize: "0.9rem", fontWeight: "bold" }}>세션 만료</DialogTitle>
      <DialogContent sx={{ fontSize: "0.8rem" }}>
        세션이 만료되었습니다. 다시 로그인 해주세요.
      </DialogContent>
      <DialogActions>
        <CustomBackgroundColorButton onClick={handleRedirect} text="확인" fontSize="0.8rem" />
      </DialogActions>
    </Dialog>
  )
}

export default Error401Modal;
