import theme from "@/Styles/theme";
import styled from "styled-components";

export const CreateSignatureContainer = styled.div`
  width: 600px;
  height: ${600 * 5 / 9}px;
  border: 3px dashed #c0c0c0;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  gap: 20px;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    width: 300px;
    max-width: 90%;
    height: ${300 * 5 / 9}px;
    gap: 10px;
    padding: 10px;
  }

  &:hover {
    border: 3px dashed black;

    /* 하위 아이콘과 텍스트 스타일 변경 */
    svg {
      color: black;
    }

    div {
      color: black; /* 텍스트 색상 변경 */
      font-size: 0.85rem;
    }
  }
`;

export const CircleContainer = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const EachCircle = styled.div<{ $selected: boolean, $index: number }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${({ $index }) => $index === 0 ? theme.colors.primary : "#c0c0c0"};
  border: ${({ $selected }) => $selected ? "1px solid black" : "none"};
  cursor: pointer;
`;

export const ArrowIconStyle = (position: "left" | "right", currentIndex: number, maxLength: number, isMobile: boolean) => {
  const isDisabled = position === "left" ? currentIndex === -1 : currentIndex === maxLength - 1;

  return {
    position: "absolute",
    [position]: "0",
    top: "50%",
    transform: "translateY(-50%)",
    cursor: isDisabled ? "default" : "pointer",
    width: isMobile ? "30px" : "50px",
    height: isMobile ? "30px" : "50px",
    color: isDisabled ? "#c0c0c0" : "black",
  }
}

export const CreateText = styled.div`
  font-size: 0.8rem;
  font-weight: bold;
  color: #c0c0c0;
`;
