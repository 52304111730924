import { atom } from "jotai";
import { ApplicationMethod, ApplicationPeriodInfo } from "../Dialogs/Create/Course";
import { v4 as uuidv4 } from "uuid";

export interface Student {
  studentId: string; // 학번
  name: string; // 이름
  freePass: string; // 자유수강권
}

export interface Fee {
  studentsCount: number; // 학생 수
  fee: number; // 수강료
}

export interface Subsidy {
  students: Student[];
  fees: Fee[];
}

export interface Class {
  id: string; // 강좌 ID
  teacher: string; // 담당교사
  grade: 0 | 1 | 2 | 3; // 학년 - 0: 전체, 1: 1학년, 2: 2학년, 3: 3학년
  headcount: number; // 정원
  period: number; // 차시
  operatingTimes: { [key: string]: number[] }; // 운영시간
  classRoom: string; // 강의실
  name: string; // 강좌명
  textbook: string; // 교재
  students: Student[]; // 수강생 목록
}

export interface Course {
  id: string; // 강좌 ID
  teacher: string; // 담당교사
  applicationPeriod: ApplicationPeriodInfo; // 신청기간
  applicationMethod: ApplicationMethod; // 신청방법
  coursePeriod: { // 수강기간
    startDate: string;  // yyyy-MM-dd
    endDate: string;  // yyyy-MM-dd
  };
  courseName: string; // 과정 이름
  viewOption: { // 보기 옵션
    isVisible: boolean; // 과정 보기 여부
    grade: 0 | 1 | 2 | 3 // 특정 등급 이상에게만 보이기 (0: 재학생, 1: 외부강사, 2: 선생님, 3: 방과후담당)
  };
  operatingTimes: { [day: string]: number[] }; // 운영시간
  isCostDisclosured: boolean; // 비용 공개 여부
  selectionGroup: string; // 선택군
  announcement: string; // 공지사항
  subsidy: Subsidy;   // 지원금(옵션)
  courseEvaluation: string; // 강의평가
  classes: Class[]; // 강좌 목록
}

interface AfterSchool {
  courses: Course[];
}

const dummyClasses: Class[] = [
  {
    id: uuidv4(),
    teacher: 'Kim Minho',
    grade: 1,
    headcount: 30,
    period: 12,
    operatingTimes: {
      Monday: [2, 3],
      Wednesday: [2, 3],
      Friday: [6, 7],
    },
    classRoom: '101',
    name: 'Advanced Mathematics',
    textbook: 'Mathematics for High School',
    students: [],
  },
  {
    id: uuidv4(),
    teacher: 'Lee Jieun',
    grade: 2,
    headcount: 25,
    period: 15,
    operatingTimes: {
      Monday: [2, 3],
      Friday: [6, 7],
    },
    classRoom: '202',
    name: 'Physics Principles',
    textbook: 'Understanding Physics',
    students: [],
  },
  {
    id: uuidv4(),
    teacher: 'Park Jisoo',
    grade: 3,
    headcount: 20,
    period: 10,
    operatingTimes: {
      Wednesday: [2, 3],
      Friday: [6, 7],
    },
    classRoom: '303',
    name: 'Chemistry Basics',
    textbook: 'Chemistry Essentials',
    students: [],
  },
];

const dummyCourse = (): Course[] => [
  {
    id: uuidv4(),
    teacher: "Kim Minho",
    applicationPeriod: {
      start: {
        date: "2024-09-01",
        hour: 9,
        minute: 0,
      },
      end: {
        date: "2024-09-10",
        hour: 17,
        minute: 0,
      },
      detail: {
        option1: true,
        option2: false,
        option3: false,
        option4: true,
        option5: false,
      },
    },
    applicationMethod: 1,
    coursePeriod: {
      startDate: "2024-09-15",
      endDate: "2024-12-20",
    },
    courseName: "Advanced Mathematics",
    viewOption: {
      isVisible: true,
      grade: 2,
    },
    operatingTimes: {
      Monday: [2, 3],
      Wednesday: [2, 3],
      Friday: [6, 7],
    },
    isCostDisclosured: true,
    selectionGroup: "Science",
    announcement: "Please bring your own calculator.",
    subsidy: {
      "students": [
        {
          "studentId": "10101",
          "name": "홍길동",
          "freePass": "500000"
        },
        {
          "studentId": "10201",
          "name": "홍길서",
          "freePass": "350000"
        },
        {
          "studentId": "10301",
          "name": "홍길남",
          "freePass": "면제"
        }
      ],
      fees: [
        { "studentsCount": 5, "fee": 60000 },
        { "studentsCount": 6, "fee": 62000 },
        { "studentsCount": 7, "fee": 64000 },
        { "studentsCount": 8, "fee": 66000 },
        { "studentsCount": 9, "fee": 68000 },
        { "studentsCount": 10, "fee": 70000 },
        { "studentsCount": 11, "fee": 72000 },
        { "studentsCount": 12, "fee": 74000 },
        { "studentsCount": 13, "fee": 76000 },
        { "studentsCount": 14, "fee": 78000 },
        { "studentsCount": 15, "fee": 80000 },
        { "studentsCount": 16, "fee": 82000 },
        { "studentsCount": 17, "fee": 84000 },
        { "studentsCount": 18, "fee": 86000 },
        { "studentsCount": 19, "fee": 88000 },
        { "studentsCount": 20, "fee": 90000 }
      ]
    },
    courseEvaluation: "This course is challenging but rewarding.",
    classes: dummyClasses,
  },
  {
    id: uuidv4(),
    teacher: "Park Jisoo",
    applicationPeriod: {
      start: {
        date: "2024-09-05",
        hour: 10,
        minute: 0,
      },
      end: {
        date: "2024-09-15",
        hour: 16,
        minute: 0,
      },
      detail: {
        option1: false,
        option2: true,
        option3: true,
        option4: false,
        option5: true,
      },
    },
    applicationMethod: 0,
    coursePeriod: {
      startDate: "2024-10-01",
      endDate: "2024-12-31",
    },
    courseName: "English Literature",
    viewOption: {
      isVisible: true,
      grade: 1,
    },
    operatingTimes: {
      Tuesday: [2, 3],
      Thursday: [4, 5],
    },
    isCostDisclosured: false,
    selectionGroup: "Humanities",
    announcement: "All readings will be provided digitally.",
    subsidy: {
      "students": [
        {
          "studentId": "10101",
          "name": "홍길동",
          "freePass": "500000"
        },
        {
          "studentId": "10201",
          "name": "홍길서",
          "freePass": "350000"
        },
        {
          "studentId": "10301",
          "name": "홍길남",
          "freePass": "면제"
        }
      ],
      fees: [
        { "studentsCount": 5, "fee": 60000 },
        { "studentsCount": 6, "fee": 62000 },
        { "studentsCount": 7, "fee": 64000 },
        { "studentsCount": 8, "fee": 66000 },
        { "studentsCount": 9, "fee": 68000 },
        { "studentsCount": 10, "fee": 70000 },
        { "studentsCount": 11, "fee": 72000 },
        { "studentsCount": 12, "fee": 74000 },
        { "studentsCount": 13, "fee": 76000 },
        { "studentsCount": 14, "fee": 78000 },
        { "studentsCount": 15, "fee": 80000 },
        { "studentsCount": 16, "fee": 82000 },
        { "studentsCount": 17, "fee": 84000 },
        { "studentsCount": 18, "fee": 86000 },
        { "studentsCount": 19, "fee": 88000 },
        { "studentsCount": 20, "fee": 90000 }
      ]
    },
    courseEvaluation: "Students have enjoyed the diverse reading list.",
    classes: [],
  },
  {
    id: uuidv4(),
    teacher: "Lee Jinhyuk",
    applicationPeriod: {
      start: {
        date: "2024-08-15",
        hour: 8,
        minute: 0,
      },
      end: {
        date: "2024-08-25",
        hour: 18,
        minute: 0,
      },
      detail: {
        option1: true,
        option2: false,
        option3: true,
        option4: true,
        option5: false,
      },
    },
    applicationMethod: 2,
    coursePeriod: {
      startDate: "2024-09-01",
      endDate: "2024-11-30",
    },
    courseName: "Physics",
    viewOption: {
      isVisible: true,
      grade: 3,
    },
    operatingTimes: {
      Monday: [2, 3],
      Wednesday: [2, 3],
      Friday: [6, 7],
    },
    isCostDisclosured: true,
    selectionGroup: "Science",
    announcement: "Lab coats are required.",
    subsidy: {
      "students": [
        {
          "studentId": "10101",
          "name": "홍길동",
          "freePass": "500000"
        },
        {
          "studentId": "10201",
          "name": "홍길서",
          "freePass": "350000"
        },
        {
          "studentId": "10301",
          "name": "홍길남",
          "freePass": "면제"
        }
      ],
      fees: [
        { "studentsCount": 5, "fee": 60000 },
        { "studentsCount": 6, "fee": 62000 },
        { "studentsCount": 7, "fee": 64000 },
        { "studentsCount": 8, "fee": 66000 },
        { "studentsCount": 9, "fee": 68000 },
        { "studentsCount": 10, "fee": 70000 },
        { "studentsCount": 11, "fee": 72000 },
        { "studentsCount": 12, "fee": 74000 },
        { "studentsCount": 13, "fee": 76000 },
        { "studentsCount": 14, "fee": 78000 },
        { "studentsCount": 15, "fee": 80000 },
        { "studentsCount": 16, "fee": 82000 },
        { "studentsCount": 17, "fee": 84000 },
        { "studentsCount": 18, "fee": 86000 },
        { "studentsCount": 19, "fee": 88000 },
        { "studentsCount": 20, "fee": 90000 }
      ]
    },
    courseEvaluation: "The lab sessions are very hands-on and interactive.",
    classes: [],
  },
  {
    id: uuidv4(),
    teacher: "Choi Eunji",
    applicationPeriod: {
      start: {
        date: "2024-09-10",
        hour: 12,
        minute: 0,
      },
      end: {
        date: "2024-09-20",
        hour: 15,
        minute: 0,
      },
      detail: {
        option1: false,
        option2: true,
        option3: false,
        option4: false,
        option5: true,
      },
    },
    applicationMethod: 4,
    coursePeriod: {
      startDate: "2024-09-25",
      endDate: "2024-12-15",
    },
    courseName: "Introduction to Coding",
    viewOption: {
      isVisible: true,
      grade: 0,
    },
    operatingTimes: {
      Tuesday: [4, 5],
      Thursday: [4, 5],
    },
    isCostDisclosured: false,
    selectionGroup: "Technology",
    announcement: "Bring your own laptop.",
    subsidy: {
      "students": [
        {
          "studentId": "10101",
          "name": "홍길동",
          "freePass": "500000"
        },
        {
          "studentId": "10201",
          "name": "홍길서",
          "freePass": "350000"
        },
        {
          "studentId": "10301",
          "name": "홍길남",
          "freePass": "면제"
        }
      ],
      fees: [
        { "studentsCount": 5, "fee": 60000 },
        { "studentsCount": 6, "fee": 62000 },
        { "studentsCount": 7, "fee": 64000 },
        { "studentsCount": 8, "fee": 66000 },
        { "studentsCount": 9, "fee": 68000 },
        { "studentsCount": 10, "fee": 70000 },
        { "studentsCount": 11, "fee": 72000 },
        { "studentsCount": 12, "fee": 74000 },
        { "studentsCount": 13, "fee": 76000 },
        { "studentsCount": 14, "fee": 78000 },
        { "studentsCount": 15, "fee": 80000 },
        { "studentsCount": 16, "fee": 82000 },
        { "studentsCount": 17, "fee": 84000 },
        { "studentsCount": 18, "fee": 86000 },
        { "studentsCount": 19, "fee": 88000 },
        { "studentsCount": 20, "fee": 90000 }
      ]
    },
    courseEvaluation: "The course offers great introductory programming skills.",
    classes: [],
  },
  {
    id: uuidv4(),
    teacher: "Yoon Daeho",
    applicationPeriod: {
      start: {
        date: "2024-08-20",
        hour: 9,
        minute: 30,
      },
      end: {
        date: "2024-09-01",
        hour: 17,
        minute: 45,
      },
      detail: {
        option1: true,
        option2: false,
        option3: false,
        option4: true,
        option5: false,
      },
    },
    applicationMethod: 3,
    coursePeriod: {
      startDate: "2024-09-10",
      endDate: "2024-12-20",
    },
    courseName: "Art and Creativity",
    viewOption: {
      isVisible: true,
      grade: 1,
    },
    operatingTimes: {
      Monday: [1, 2],
      Wednesday: [1, 2],
      Friday: [3, 4],
    },
    isCostDisclosured: true,
    selectionGroup: "Arts",
    announcement: "Materials will be provided.",
    subsidy: {
      "students": [
        {
          "studentId": "10101",
          "name": "홍길동",
          "freePass": "500000"
        },
        {
          "studentId": "10201",
          "name": "홍길서",
          "freePass": "350000"
        },
        {
          "studentId": "10301",
          "name": "홍길남",
          "freePass": "면제"
        }
      ],
      fees: [
        { "studentsCount": 5, "fee": 60000 },
        { "studentsCount": 6, "fee": 62000 },
        { "studentsCount": 7, "fee": 64000 },
        { "studentsCount": 8, "fee": 66000 },
        { "studentsCount": 9, "fee": 68000 },
        { "studentsCount": 10, "fee": 70000 },
        { "studentsCount": 11, "fee": 72000 },
        { "studentsCount": 12, "fee": 74000 },
        { "studentsCount": 13, "fee": 76000 },
        { "studentsCount": 14, "fee": 78000 },
        { "studentsCount": 15, "fee": 80000 },
        { "studentsCount": 16, "fee": 82000 },
        { "studentsCount": 17, "fee": 84000 },
        { "studentsCount": 18, "fee": 86000 },
        { "studentsCount": 19, "fee": 88000 },
        { "studentsCount": 20, "fee": 90000 }
      ]
    },
    courseEvaluation: "The course encourages creative expression.",
    classes: [],
  },
];

const afterSchoolAtom = atom<AfterSchool>({
  courses: dummyCourse(),
});

export default afterSchoolAtom;
