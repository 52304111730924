import React from "react";
import { Box, Button, Stack } from "@mui/material";
import { useAtom } from "jotai";
import { drawerOpenAtom } from "@/Atoms/HeaderAtom";
import { useTranslation } from "react-i18next";

export function NoChatbot() {
  const { t } = useTranslation();
  const [drawerOpen, setDrawerOpen] = useAtom(drawerOpenAtom);

  return (
    <Stack
      sx={{
        background: "white",
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          marginTop: "10px",
          padding: "10px 30px",
          borderRadius: "30px",
          color: "white",
          background: "#06C25E",
          cursor: "pointer",
          boxSizing: "border-box",
        }}
        onClick={() => setDrawerOpen(true)}
      >
        {t("makeTextHelper.noChatbot.select")}
      </Box>
    </Stack>
  );
}
