import { accordionSidebarAtom, planToggleAtom } from "@/Atoms/SidebarAtom";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import React from "react";
import ViewSidebarOutlinedIcon from "@mui/icons-material/ViewSidebarOutlined";
import { useLocation, useNavigate } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MovieOutlinedIcon from "@mui/icons-material/MovieOutlined";
import { getUserPosition } from "@/ViewModels/UserViewModel";
import storiesAtom from "@/Atoms/StoryAtom";
import { moveToPlanHipAtom } from "@/ViewModels/Sidebar/ViewModel";
import useNavigateWithWarning from "@/Utils/NavigationWithWarning";
import { isChangedMindmapAtom } from "@/Atoms/Plan/MindmapAtom";
import { updateMindmapAtom } from "@/ViewModels/Plan/Mindmap/ViewModel";
import { languageAtom } from "@/Atoms/RootAtom";
import { useMenteeDashboardAtom, useTeacherDashboardAtom } from "@/Atoms/HookControllerAtom";
import { useTranslation } from "react-i18next";

const TopSectionButtons = () => {
  const [accordionSidebar, setAccordionSidebar] = useAtom(accordionSidebarAtom);
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const userPosition = useAtomValue(getUserPosition);
  const [planToggle, setPlanToggle] = useAtom(planToggleAtom);
  const stories = useAtomValue(storiesAtom);
  const navigateWithWarning = useNavigateWithWarning();
  const moveToPlanHip = useSetAtom(moveToPlanHipAtom);
  const isChangedMindmap = useAtomValue(isChangedMindmapAtom);
  const updateMindmap = useSetAtom(updateMindmapAtom);
  const language = useAtomValue(languageAtom);
  const setTeacherDashboardController = useSetAtom(useTeacherDashboardAtom);
  const setMenteeDashboardController = useSetAtom(useMenteeDashboardAtom);
  const { t } = useTranslation();

  const handleBeforeButtonClick = () => {
    if (currentPath === "/plan" && isChangedMindmap) {
      updateMindmap();
    }
  };

  const topSectionButtons = [
    {
      onClick: () => setAccordionSidebar(!accordionSidebar),
      tooltipTitle: t("sidebar.buttons.openSidebar"),
      icon: ViewSidebarOutlinedIcon,
      iconSize: 25,
      color: "white",
      path: null,
      name: null,
    },
    {
      onClick: () => {
        handleBeforeButtonClick();
        setTeacherDashboardController(true);
        setMenteeDashboardController(true);
        navigate("/");
      },
      tooltipTitle: t("sidebar.buttons.dashboard"),
      icon: DashboardIcon,
      iconSize: currentPath === "/" ? 35 : 25,
      color: "white",
      path: "/",
      name: t("sidebar.buttons.dashboard"),
    },
    // ...(userPosition === "멘토"
    //   ? [
    //     {
    //       onClick: () => {
    //         navigate("/timelapse");
    //       },
    //       tooltipTitle: t("sidebar.buttons.moment"),
    //       icon: MovieOutlinedIcon,
    //       iconSize: currentPath === "/timelapse" ? 35 : 25,
    //       color: "white",
    //       path: "/timelapse",
    //       name: t("sidebar.buttons.moment"),
    //     },
    //   ]
    //   : []),
    {
      onClick: () => {
        handleBeforeButtonClick();
        moveToPlanHip(navigateWithWarning);
      },
      tooltipTitle: t("sidebar.buttons.myCrew"),
      icon: PeopleOutlinedIcon,
      iconSize: currentPath === "/plan-hip" ? 35 : 25,
      color: "white",
      path: "/plan-hip",
      name: t("sidebar.buttons.myCrew"),
    },
    {
      onClick: () => {
        // if (userPosition === "멘토" && stories.length < 3) {
        //   alert(
        //     "타임랩스의 스토리를 3개 이상 작성하시면 내 플랜이 활성화됩니다."
        //   );
        //   navigate("/timelapse");
        //   return;
        // }
        if (!accordionSidebar) setAccordionSidebar(true);
        setPlanToggle(!planToggle);
      },
      tooltipTitle: userPosition === "멘티" ? t("sidebar.buttons.myPlan") : t("sidebar.buttons.myCareer"),
      icon: AccountTreeOutlinedIcon,
      iconSize: currentPath === "/my-plan" ? 35 : 25,
      color: "white",
      path: "/my-plan",
      name: userPosition === "멘티" ? t("sidebar.buttons.myPlan") : t("sidebar.buttons.myCareer"),
      dropdown: {
        open: <KeyboardArrowUpIcon />,
        close: <KeyboardArrowDownIcon />,
      },
    },
  ];
  return topSectionButtons;
};

export default TopSectionButtons;
