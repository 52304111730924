import React from 'react';
import * as S from './styled';
import SpeedDialElementBar from './speedDial';
import CareerPathElementBar from './careerPath';
import DeleteElementBar from './deleteElementBar';
import CreateGroupElementBar from './createGroup';
import CreateMindmapTestElementBar from './createMindmapTestButton';

interface ElementBarProps {
  type: string;
}

const ElementBar = ({ type }: ElementBarProps) => {
  switch (type) {
    case 'speedDial':
      return (
        <S.ElementBarContainer>
          <SpeedDialElementBar />
        </S.ElementBarContainer>
      );
    case 'delete':
      return (
        <S.ElementBarContainer style={{ left: "0px", right: "20px", justifyContent: "flex-end" }}>
          <DeleteElementBar />
        </S.ElementBarContainer>
      );
    case 'group':
      return (
        <S.ElementBarContainer style={{ left: "96px" }}>
          <CreateGroupElementBar />
        </S.ElementBarContainer>
      );
    case 'test':
      return (
        <S.ElementBarContainer style={{ top: "96px" }}>
          <CreateMindmapTestElementBar />
        </S.ElementBarContainer>
      );
    default:
      // 잠시 커리어 패스 비활성화
      return (
        <S.ElementBarContainer style={{ left: "168px" }}>
          <CareerPathElementBar />
        </S.ElementBarContainer>
      );
  }
};

export default ElementBar;
