import React from "react";
import * as S from "./styled";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Avatar, Button, IconButton, List, ListItem, ListItemAvatar, ListItemText, Menu, MenuItem, Popover, Tooltip, Typography } from "@mui/material";
import LoginIcon from '@mui/icons-material/Login';
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import MenuBookIcon from '@mui/icons-material/MenuBook';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import TagIcon from '@mui/icons-material/Tag';
import GradeIcon from '@mui/icons-material/Grade';
import PeopleIcon from '@mui/icons-material/People';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import NotesIcon from '@mui/icons-material/Notes';
import { languageAtom } from "@/Atoms/RootAtom";
import ScheduleOutlinedIcon from "@mui/icons-material/ScheduleOutlined";
import { useAtomValue, useSetAtom } from "jotai";
import useIsMobile from "@/Hooks/useIsMobile";
import CustomColorButton from "@/Components/Common/Buttons/CustomColor";
import { Lecture } from "@/Atoms/Crew";
import { ApplicationPeriodInfo } from "@/Atoms/Dialogs/Create/Course";
import convertDayToKorean from "@/Utils/ConvertDayToKorean";
import theme from "@/Styles/theme";
import { editLectureDialogOpenAtom } from "@/ViewModels/Dialogs/Edit/LectureViewModel";
import deleteLectureDialogAtom from "@/Atoms/Dialogs/Delete/Lecture";
import applyLectureDialogAtom from "@/Atoms/Dialogs/ApplyLecture";
import { getUserId } from "@/ViewModels/UserViewModel";
import cancelLectureDialogAtom from "@/Atoms/Dialogs/Cancel/Lecture";

const formatDateToKorean2 = (date: Date): string => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // 월은 0부터 시작하므로 +1
  const day = date.getDate().toString().padStart(2, "0");

  return `${year}년 ${month}월 ${day}일`;
};

const formatApplicationPeriodDateToKorean = (applicationPeriod: ApplicationPeriodInfo, type: "start" | "end"): string => {
  const date = new Date(applicationPeriod[type].date);
  const hour = applicationPeriod[type].hour;
  const minute = applicationPeriod[type].minute;
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // 월은 0부터 시작하므로 +1
  const day = date.getDate().toString().padStart(2, "0");

  return `${year}년 ${month}월 ${day}일 ${hour.toString().padStart(2, "0")}시 ${minute.toString().padStart(2, "0")}분`;
};

const getAppropriateButton = (applicationPeriod: ApplicationPeriodInfo, coursePeriod: { startDate: string; endDate: string }) => {
  const now = new Date();
  const startApplicationDate = new Date(applicationPeriod.start.date);
  const endApplicationDate = new Date(applicationPeriod.end.date);
  const startCourseDate = new Date(coursePeriod.startDate);
  const endCourseDate = new Date(coursePeriod.endDate);
  let button = "application";

  if (now < startApplicationDate) {
    button = "none";
  } else if (now >= startApplicationDate && now <= endApplicationDate) {
    button = "application";
  } else if (now > endApplicationDate && now < startCourseDate) {
    button = "none";
  } else if (now >= startCourseDate && now <= endCourseDate) {
    button = "none";
  } else if (now > endCourseDate) {
    button = "none";
  }

  return button;
};

const getStatus = (applicationPeriod: ApplicationPeriodInfo, coursePeriod: { startDate: string; endDate: string }) => {
  const now = new Date();
  const startApplicationDate = new Date(applicationPeriod.start.date);
  const endApplicationDate = new Date(applicationPeriod.end.date);
  const startCourseDate = new Date(coursePeriod.startDate);
  const endCourseDate = new Date(coursePeriod.endDate);
  let color = theme.colors.primary;
  let message = "상태 없음";

  if (now < startApplicationDate) {
    color = theme.colors.task;
    message = "준비중";
  } else if (now >= startApplicationDate && now <= endApplicationDate) {
    color = theme.colors.goal;
    message = "신청 중";
  } else if (now > endApplicationDate && now < startCourseDate) {
    color = theme.colors.job;
    message = "운영 시작 전";
  } else if (now >= startCourseDate && now <= endCourseDate) {
    color = theme.colors.primary;
    message = "운영 중";
  } else if (now > endCourseDate) {
    color = theme.colors.customPurple;
    message = "종료";
  }

  return <span style={{ fontSize: "0.9rem", color: color, marginLeft: "5px" }}>({message})</span>
};

interface CrewTaskProps {
  lecture: Lecture
}

const CrewLecture = ({ lecture }: CrewTaskProps) => {
  const userId = useAtomValue(getUserId);
  const { isMobile } = useIsMobile();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const language = useAtomValue(languageAtom);
  const openEditDialog = useSetAtom(editLectureDialogOpenAtom);
  const openDeleteDialog = useSetAtom(deleteLectureDialogAtom);
  const openApplyDialog = useSetAtom(applyLectureDialogAtom);
  const openCancelDialog = useSetAtom(cancelLectureDialogAtom);
  const [popoverAnchorEl, setPopoverAnchorEl] = React.useState<null | HTMLElement>(null);
  const lectureMembers = lecture.members;

  const groupItems = [
    { name: language === "KR" ? "수정" : "edit", value: "edit" },
    { name: "삭제", value: "delete" },
  ];

  const handleMenuItemClick = (value: string) => {
    if (value === "edit") {
      openEditDialog(lecture.id);
    } else if (value === "delete") {
      openDeleteDialog(lecture.id);
    }
    handleClose();
  };

  // Helper function to break title into spans
  const formatTitle = (title: string) => {
    return title.split(/(?<=[?!.])\s+/g).map((part, index) => (
      <span key={index} style={{ display: "block" }}>
        {part}
      </span>
    ));
  };

  return (
    <>
      <S.StoryItem $isMobile={isMobile}>
        <S.StoryTitleContainer>
          <S.StoryTitle>
            {getStatus(lecture.applicationPeriod, lecture.coursePeriod)}
            {formatTitle(lecture.name)}
          </S.StoryTitle>
          {
            lecture.author_id === userId &&
            <>
              <IconButton
                sx={{
                  color: "black",
                  fontWeight: "bold",
                  padding: 0,
                  textTransform: "none",
                  fontSize: "0rem",
                }}
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                <MoreVertIcon sx={{ fontSize: "1.5rem" }} />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                {groupItems.map((item, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => handleMenuItemClick(item.value)}
                  >
                    {item.name}
                  </MenuItem>
                ))}
              </Menu>
            </>
          }
        </S.StoryTitleContainer>
        <S.LectureElement>
          <Tooltip title={<div style={{ fontSize: "1rem", fontWeight: "bold" }}>신청기간</div>}>
            <LoginIcon sx={{ fontSize: "1rem" }} />
          </Tooltip>
          <Typography style={{ fontSize: "0.8rem" }}>
            {formatApplicationPeriodDateToKorean(lecture.applicationPeriod, 'start')}부터
          </Typography>
          <Typography style={{ fontSize: "0.8rem" }}>
            {formatApplicationPeriodDateToKorean(lecture.applicationPeriod, 'end')}까지
          </Typography>
        </S.LectureElement>
        <S.LectureElement>
          <Tooltip title={<div style={{ fontSize: "1rem", fontWeight: "bold" }}>담당교사</div>}>
            <ManageAccountsIcon sx={{ fontSize: "1rem" }} />
          </Tooltip>
          <Typography style={{ fontSize: "0.8rem" }}>
            {lecture.teacher}
          </Typography>
        </S.LectureElement>
        <S.LectureElement>
          <Tooltip title={<div style={{ fontSize: "1rem", fontWeight: "bold" }}>대상 학년</div>}>
            <GradeIcon sx={{ fontSize: "1rem" }} />
          </Tooltip>
          <Typography style={{ fontSize: "0.8rem" }}>
            {lecture.grades.filter((grade) => grade !== 0).map((grade) => grade + "학년").join(", ")}
          </Typography>
        </S.LectureElement>
        <S.LectureElement>
          <Tooltip title={<div style={{ fontSize: "1rem", fontWeight: "bold" }}>현재인원 / 정원</div>}>
            <PeopleIcon sx={{ fontSize: "1rem" }} />
          </Tooltip>
          <Typography style={{ fontSize: "0.8rem" }}>
            {lecture.members.length} / {lecture.headcount}명
          </Typography>
          <Button
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => setPopoverAnchorEl(event.currentTarget)}
            variant="text"
            sx={{
              fontSize: "0.8rem",
              color: theme.colors.primary,
              transition: "color 0.3s, background-color 0.3s",
              "&:hover": {
                color: "#fff",
                backgroundColor: theme.colors.primary,
              },
              "&:active": {
                backgroundColor: theme.colors.primary,
                color: "#e0e0e0",
              },
              "&:focus": {
                outline: `2px solid ${theme.colors.primary}`,
                outlineOffset: "2px",
              },
            }}
          >
            신청 현황
          </Button>
        </S.LectureElement>
        <S.LectureElement>
          <Tooltip title={<div style={{ fontSize: "1rem", fontWeight: "bold" }}>차시</div>}>
            <ScheduleOutlinedIcon sx={{ fontSize: "1rem" }} />
          </Tooltip>
          <Typography style={{ fontSize: "0.8rem" }}>
            {lecture.period}차시
          </Typography>
        </S.LectureElement>
        <S.LectureElement>
          <Tooltip title={<div style={{ fontSize: "1rem", fontWeight: "bold" }}>수강기간</div>}>
            <CalendarTodayOutlinedIcon sx={{ fontSize: "1rem" }} />
          </Tooltip>
          <Typography style={{ fontSize: "0.8rem" }}>
            {formatDateToKorean2(new Date(lecture.coursePeriod.startDate))}부터
          </Typography>
          <Typography style={{ fontSize: "0.8rem" }}>
            {formatDateToKorean2(new Date(lecture.coursePeriod.endDate))}까지
          </Typography>
        </S.LectureElement>
        <S.LectureElement>
          <Tooltip title={<div style={{ fontSize: "1rem", fontWeight: "bold" }}>교재</div>}>
            <MenuBookIcon sx={{ fontSize: "1rem" }} />
          </Tooltip>
          <Typography style={{ fontSize: "0.8rem" }}>
            {lecture.textbook}
          </Typography>
        </S.LectureElement>
        <S.LectureElement>
          <Tooltip title={<div style={{ fontSize: "1rem", fontWeight: "bold" }}>운영시간</div>}>
            <TimelapseIcon sx={{ fontSize: "1rem" }} />
          </Tooltip>
          <Typography style={{ fontSize: "0.8rem" }}>
            {
              Object.keys(lecture.operatingTimes).map((day, index) => (
                lecture.operatingTimes[day].length > 0 &&
                <div key={index}>
                  {convertDayToKorean(day)}요일: {lecture.operatingTimes[day].sort((a, b) => a - b).map((time) => time + "교시").join(", ")}
                </div>
              ))
            }
          </Typography>
        </S.LectureElement>
        <S.LectureElement>
          <Tooltip title={<div style={{ fontSize: "1rem", fontWeight: "bold" }}>운영계획</div>}>
            <NotesIcon sx={{ fontSize: "1rem" }} />
          </Tooltip>
          <Typography style={{ fontSize: "0.8rem" }}>
            {lecture.operatingPlan}
          </Typography>
        </S.LectureElement>
        <S.LectureElement>
          <Tooltip title={<div style={{ fontSize: "1rem", fontWeight: "bold" }}>강의실</div>}>
            <LocationOnOutlinedIcon sx={{ fontSize: "1rem" }} />
          </Tooltip>
          <Typography style={{ fontSize: "0.8rem" }}>
            {lecture.classRoom}
          </Typography>
        </S.LectureElement>
        {lecture.hashtags.length > 0 &&
          <S.LectureElement>
            <TagIcon sx={{ fontSize: "1rem" }} />
            {
              (lecture.hashtags as { backgroundColor: string, textColor: string, value: string }[]).map((tag, index) => (
                <Typography key={index} style={{ fontSize: "0.8rem", backgroundColor: tag.backgroundColor, color: tag.textColor, padding: "2px 5px", borderRadius: "5px" }}>
                  {tag.value}
                </Typography>
              ))
            }
          </S.LectureElement>
        }
        <S.StoryBottomContainer>
          {
            lecture.members.find((member) => member.id === userId) ?
              (
                lecture.isAbleToCancel ?
                  <CustomColorButton text="신청취소" onClick={() => openCancelDialog({ lectureId: lecture.id, userId: userId })} fontSize="0.8rem" /> :
                  <div style={{ fontSize: "0.8rem", color: theme.colors.customPurple }}>신청 완료</div>
              ) :
              getAppropriateButton(lecture.applicationPeriod, lecture.coursePeriod) === "application" &&
              (
                lecture.members.length === lecture.headcount ?
                  <div style={{ fontSize: "0.8rem", color: theme.colors.customPurple }}>마감</div> :
                  <CustomColorButton
                    text="신청하기"
                    onClick={() => openApplyDialog({
                      id: lecture.id,
                      isAbleToCancel: lecture.isAbleToCancel
                    })}
                    fontSize="0.8rem"
                  />
              )
          }
          <Popover
            id="basic-popover"
            open={Boolean(popoverAnchorEl)}
            anchorEl={popoverAnchorEl}
            onClose={() => setPopoverAnchorEl(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <List sx={{ minWidth: "250px" }}>
              {lectureMembers.map((member, index) => (
                <ListItem key={index} alignItems="flex-start" sx={{ padding: "8px" }}>
                  <ListItemAvatar>
                    <Avatar
                      alt={member.name}
                      src={`${process.env.REACT_APP_USER_API_ADDRESS}${member.profileImage}`}
                      sx={{
                        width: 40,
                        height: 40,
                        backgroundColor: "#f5f5f5",
                        fontSize: "1rem",
                      }}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography variant="body1" fontWeight="bold">
                        {member.name}
                      </Typography>
                    }
                    secondary={
                      <Typography variant="body2" color="textSecondary">
                        {member.email}
                      </Typography>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </Popover>
        </S.StoryBottomContainer>
      </S.StoryItem>
    </>
  );
};

export default CrewLecture;
