import { useQuery } from 'react-query';
import { getPlanData } from '@/Queries/PlanQueries';
import GetIdFromQuerystring from '@/Utils/GetIdFromQuerystring';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import innerTabDataAtom from '@/Atoms/Plan/InnerTabDataAtom';
import planAtom from '@/Atoms/Plan';
import { defaultInnerTabNodesAtom } from '@/Atoms/Plan/MindmapAtom';
import { useDefaultPlanDataAtom } from '@/Atoms/HookControllerAtom';
import { handleReactQueryApiResponseWithJson } from '@/Utils/APIUtil';
import { Node } from '@xyflow/react';
import { error401ModalAtom } from '@/Atoms/Dialogs/Error/401Atom';

const useDefaultPlanData = () => {
  const plans = useAtomValue(planAtom);
  const planId = GetIdFromQuerystring("id");
  const plan = plans.find((plan) => plan.id === planId);
  const setInnerTabData = useSetAtom(innerTabDataAtom);
  const setDefaultInnerTabNodes = useSetAtom(defaultInnerTabNodesAtom);
  const [controller, setController] = useAtom(useDefaultPlanDataAtom);
  const setError401Modal = useSetAtom(error401ModalAtom);

  return useQuery(['planData', planId], () => handleReactQueryApiResponseWithJson(getPlanData, () => setError401Modal(true), plan?.innerTabs[0].id || 0), {
    enabled: !!plan && controller,
    onSuccess: async (data) => {
      setController(false);
      if (!data) {
        setInnerTabData((prev) => ([...prev, { id: plan?.innerTabs[0].id || 0, nodes: [], edges: [] }]));
        setDefaultInnerTabNodes([]);
      } else {
        const groupNodes = data.nodes.filter((node: Node) => node.type === "group");
        const nonGroupNodes = data.nodes.filter((node: Node) => node.type !== "group");
        const nodes = [...groupNodes, ...nonGroupNodes].map((node: Node) => ({
          ...node,
          data: {
            ...node.data,
            hashtags: node.data.hashtags || [],
          }
        }));
        setInnerTabData((prev) => ([...prev, { id: plan?.innerTabs[0].id || 0, nodes: nodes, edges: data.edges }]));
        setDefaultInnerTabNodes(nodes);
      }
    },
  });
};

export default useDefaultPlanData;
