import createRoutineDialogAtom, { CreateRoutineDialogOpenAtomProps } from '@/Atoms/Dialogs/Create/Routine';
import { error401ModalAtom } from '@/Atoms/Dialogs/Error/401Atom';
import snackbarAtom from '@/Atoms/Snackbar';
import { createRoutine } from '@/Queries/RoutineQueries';
import { handleReactQueryApiResponse } from '@/Utils/APIUtil';
import GetIdFromQuerystring from '@/Utils/GetIdFromQuerystring';
import { getPlansAtom } from '@/ViewModels/Plan/PlanViewModel';
import { getUserId } from '@/ViewModels/UserViewModel';
import { atom } from 'jotai';

const initialSetting: CreateRoutineDialogOpenAtomProps = {
  dialogTitle: "",
  viewType: "routine",
  currentSelectedTask: null,
  selectedTasks: [],
};

// 루틴 생성 dialog 열기
export const createRoutineDialogOpenAtom = atom(null, (get, set) => {
  set(createRoutineDialogAtom, initialSetting);
});

// routineDialogState 업데이트
export const createRoutineDialogUpdateAtom = atom(null, (get, set, key: string, value: any) => {
  const routineDialogState = get(createRoutineDialogAtom);
  if (!routineDialogState) return;
  set(createRoutineDialogAtom, { ...routineDialogState, [key]: value });
});

// routineDialog 할 일 추가
export const createRoutineDialogAddTaskAtom = atom(null, (get, set) => {
  const routineDialogState = get(createRoutineDialogAtom);
  if (!routineDialogState || !routineDialogState.currentSelectedTask) return;
  const currentSelectedTask = routineDialogState.currentSelectedTask;
  set(createRoutineDialogAtom, { ...routineDialogState, selectedTasks: [...routineDialogState.selectedTasks, currentSelectedTask], currentSelectedTask: null });
})

// 루틴 생성 요청
export const createRoutineDialogCreateRoutineAtom = atom(null, async (get, set) => {
  const userId = get(getUserId);
  const planId = GetIdFromQuerystring("id");
  const routineDialogState = get(createRoutineDialogAtom);
  if (!routineDialogState) return;
  if (!routineDialogState.dialogTitle) {
    set(snackbarAtom, (prev) => ({ ...prev, open: true, message: "루틴명을 입력해주세요.", severity: "error" }));
    return;
  }
  const request = {
    plan_id: planId,
    name: routineDialogState.dialogTitle,
    view_type: routineDialogState.viewType,
    content: "",
    tasks: routineDialogState.selectedTasks,
  }
  const handleError401Modal = () => set(error401ModalAtom, true);
  const response = await handleReactQueryApiResponse(createRoutine, handleError401Modal, userId, request);
  if (!response.ok) {
    set(snackbarAtom, (prev) => ({ ...prev, open: true, message: "루틴 생성에 실패했습니다.", severity: "error" }));
    return;
  }
  set(snackbarAtom, (prev) => ({ ...prev, open: true, message: "루틴이 생성되었습니다.", severity: "success" }));
  set(getPlansAtom);
  set(createRoutineDialogAtom, null);
});
