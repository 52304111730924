import React from 'react';
import * as S from './styled';
import SortIcon from '@mui/icons-material/Sort';
import DropdownButton from '../../../../Common/DropdownButton';
import { storySortAtom } from '@/Atoms/StoryAtom';
import { useAtom } from 'jotai';

const StoryGrouping = () => {
  const [storySort, setStorySort] = useAtom(storySortAtom);
  const sortItems = [
    { name: "최신순", value: "latest" },
    { name: "오래된순", value: "oldest" },
    { name: "시대순", value: "era" },
  ]

  return (
    <S.StoryGroupingContainer>
      <DropdownButton
        label={sortItems.find((item) => item.value === storySort)?.name || "최신순"}
        items={sortItems}
        onSelect={setStorySort}
        startIcon={<SortIcon />}
      />
    </S.StoryGroupingContainer>
  );
}

export default StoryGrouping;
