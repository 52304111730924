import React from "react";
import * as S from "../Components/Error/styled";

const Loading = () => {
  return (
    <S.ErrorFullContainer>
      <S.TitleText>커리어플래너</S.TitleText>
      <S.SubText>로딩중입니다. 잠시만 기다려주세요.</S.SubText>
    </S.ErrorFullContainer>
  );
}

export default Loading;
