// 루틴 생성
export const createRoutine = async (access: string, userId: number, request: any) => {
  const response = await fetch(
    `${process.env.REACT_APP_INNER_TAB_ADDRESS}?user_id=${userId}`,
    {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${access}`,
        "Content-Type": "application/json",
        "accept": "application/json",
      },
      body: JSON.stringify(request),
    },
  );
  return response;
}
