import React from "react";
import { ListItem, ListItemAvatar, ListItemText, Avatar, Typography, Box } from "@mui/material";
import { useSetAtom } from "jotai";
import theme from "@/Styles/theme";
import { Notification } from "@/Atoms/Notification/atom";
import dayjs from "dayjs";
import { readNotificationAtom } from "@/ViewModels/Notification/ViewModel";

interface NotificationItemProps {
  notification: Notification;
}

const NotificationItem = ({ notification }: NotificationItemProps) => {
  const readNotification = useSetAtom(readNotificationAtom);

  return (
    <ListItem
      sx={{
        display: "flex",
        alignItems: "flex-start",
        gap: "10px",
        backgroundColor: notification.unread ? "#f5f5f5" : "white",
        borderRadius: "8px",
        boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
        cursor: "pointer",
        margin: "0px",
        padding: "10px",
        "&:hover": {
          backgroundColor: "#e0e0e0",
        },
        justifyContent: "center",
      }}
      onClick={() => readNotification(notification.id)}
    >
      {/* 프로필 이미지 */}
      <ListItemAvatar
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <Avatar
          src={notification.profileImage}
          alt={notification.profileNickName}
          sx={{ width: 40, height: 40, "& img": { objectFit: "cover", margin: "0", padding: "0" } }}
        >
          {notification.profileNickName}
        </Avatar>
      </ListItemAvatar>

      {/* 텍스트 콘텐츠 */}
      <ListItemText
        primary={
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              sx={{
                fontWeight: notification.unread ? "bold" : "normal",
                fontSize: "0.9rem",
              }}
            >
              {notification.title}
            </Typography>
            <Typography
              sx={{ fontSize: "0.8rem", color: theme.colors.grey }}
            >
              {dayjs(notification.date).format("YYYY-MM-DD HH:mm")}
            </Typography>
          </Box>
        }
        secondary={
          <Typography
            sx={{
              fontSize: "0.8rem",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
              display: "block",
              color: notification.unread ? theme.colors.black : theme.colors.grey,
            }}
          >
            {notification.content}
          </Typography>
        }
      />
    </ListItem>
  );
};

export default NotificationItem;
