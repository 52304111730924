import React from "react";
import { Box, Modal, Typography, Button } from "@mui/material";
import { useAtom } from "jotai";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useNavigate } from "react-router-dom";
import { noticeModalAtom } from "../../../Atoms/RootAtom";
import * as S from "./styled";
import { useTranslation } from "react-i18next";

const NoticeModal: React.FC = () => {
  const [noticeState, setnoticeState] = useAtom(noticeModalAtom);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleClose = () => {
    setnoticeState((prev) => ({ ...prev, state: false }));
  };

  const handleRedirect = () => {
    navigate(noticeState.redirectUrl);
    handleClose();
  };

  return (
    <Modal
      open={noticeState.state}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <S.CustomBox>
        <NotificationsIcon sx={{ fontSize: 50, color: "#FFEB04" }} />{" "}
        {/* Danger icon */}
        <Typography
          id="modal-notice-title"
          variant="h6"
          component="h2"
          sx={{ mt: 2 }}
        >
          {noticeState.title}
        </Typography>
        {noticeState.text.split("\n").map((sent, index) => (
          <Typography
            key={`modal-description-notice-${index}`}
            sx={{ mt: 2, fontSize: 14 }}
          >
            {sent}
          </Typography>
        ))}
        <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
          <Button
            variant="contained"
            onClick={handleClose}
            sx={{
              mx: 1,
              background: "#8f8f8f",
              "&:hover": {
                background: "#8f8f8f",
              },
            }}
          >
            {t("dialogs.common.notice.close")}
          </Button>
          {noticeState.redirectUrl !== "" && (
            <Button
              variant="contained"
              onClick={handleRedirect}
              sx={{
                mx: 1,
                background: "#3B0095",
                "&:hover": {
                  background: "#3B0095",
                },
              }}
            >
              {t("dialogs.common.notice.move")}
            </Button>
          )}
          {noticeState.event !== null && (
            <Button
              variant="contained"
              onClick={noticeState.event}
              sx={{
                mx: 1,
                background: "#3B0095",
                "&:hover": {
                  background: "#3B0095",
                },
              }}
            >
              {noticeState.eventText}
            </Button>
          )}
        </Box>
      </S.CustomBox>
    </Modal>
  );
};

export default NoticeModal;
