import colorPickerDialogAtom from '@/Atoms/Dialogs/ColorPicker';
import createCrewDialogAtom, { createCrewDialogCheckNameStatusAtom } from '@/Atoms/Dialogs/Create/Crew';
import { error401ModalAtom } from '@/Atoms/Dialogs/Error/401Atom';
import { usePlanHipAtom } from '@/Atoms/HookControllerAtom';
import { CrewInfo } from '@/Atoms/PlanHip/Atom';
import snackbarAtom from '@/Atoms/Snackbar';
import { checkCrewName, createCrew } from '@/Queries/CrewQueries';
import { handleReactQueryApiResponse } from '@/Utils/APIUtil';
import { devConsoleError } from '@/Utils/ConsoleLogInDevelopment';
import { getUserId } from '@/ViewModels/UserViewModel';
import { atom } from 'jotai';
import { NavigateFunction } from 'react-router-dom';

export const createCrewDialogUpdateStateAtom = atom(null, (get, set, key: keyof CrewInfo, value: any) => {
  if (key === "name") {
    set(createCrewDialogCheckNameStatusAtom, "init");
  }
  set(createCrewDialogAtom, (prev) => (prev === null) ? null : {
    ...prev,
    [key]: value,
  });
});

export const createCrewDialogOpenAtom = atom(null, (get, set) => {
  set(createCrewDialogCheckNameStatusAtom, "init");
  set(createCrewDialogAtom, {
    name: "",
    content: "",
    headCount: 1,
    hashtags: [],
    imageUrl: null,
    color: "",
    category: "",
  });
});

export const createCrewDialogCloseAtom = atom(null, (get, set) => {
  set(createCrewDialogAtom, null);
  set(createCrewDialogCheckNameStatusAtom, "init");
});

export const createCrewDialogCreateAtom = atom(null, (get, set, navigate: NavigateFunction) => {
  // Create Crew
  const userId = get(getUserId);
  set(createCrewDialogUpdateStateAtom, "color", get(colorPickerDialogAtom).color);
  const formData = new FormData();
  const dialog = get(createCrewDialogAtom);
  if (!dialog) return;
  const imageUrl = dialog.imageUrl;
  if (imageUrl) {
    formData.append("imageFile", imageUrl);
    set(createCrewDialogUpdateStateAtom, "imageUrl", formData);
  } else {
    set(snackbarAtom, { open: true, message: "크루 이미지를 업로드해주세요.", severity: "error" });
    return;
  }
  const handleError401Modal = () => set(error401ModalAtom, true);
  setTimeout(async () => {
    formData.append("name", dialog.name);
    formData.append("content", dialog.content);
    formData.append("headcount", dialog.headCount.toString());
    formData.append("hashtags", dialog.hashtags.join("|||"));
    formData.append("color", dialog.color);
    formData.append("category", dialog.category);

    const response = await handleReactQueryApiResponse(createCrew, handleError401Modal, userId, formData);
    if (response === null) {
      set(snackbarAtom, { open: true, message: "크루 생성에 실패했습니다.", severity: "error" });
      return;
    }
    const jsonedData = await response.json();
    navigate(`/crew?crew_id=${jsonedData.crew_id}&inner_tab_id=${jsonedData.inner_tab_id}`);
    set(snackbarAtom, { open: true, message: "크루가 생성되었습니다.", severity: "success" });
    set(usePlanHipAtom, true);
    set(createCrewDialogAtom, null);
  }, 300);
});

export const createCrewDialogCheckDuplicationAtom = atom(null, async (get, set) => {
  const handleError401Modal = () => set(error401ModalAtom, true);

  // Check Duplication - "loading" 상태로 설정
  set(createCrewDialogCheckNameStatusAtom, "loading");

  const name = get(createCrewDialogAtom)?.name;
  if (!name) {
    set(createCrewDialogCheckNameStatusAtom, "fail");
    return;
  }

  // 새로운 setTimeout을 설정하여 마지막 요청만 처리
  try {
    const response = await handleReactQueryApiResponse(checkCrewName, handleError401Modal, name); // API 호출
    // 결과에 따라 상태 변경
    set(createCrewDialogCheckNameStatusAtom, response.status === 204 ? "success" : "fail");
  } catch (error) {
    devConsoleError("중복 체크 실패:", error);
    set(createCrewDialogCheckNameStatusAtom, "fail"); // 에러 발생 시 "fail" 상태로 설정
  }
});
