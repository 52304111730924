import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  justify-content: center;
  align-items: center;

  overflow-y: auto;
  padding: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    height: calc(100vh - 96px);
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
  height: 100%;
  gap: 20px;
`;

export const StoryPostingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 80px;
  flex-direction: column;
  background-color: white;
  border: 1px solid ${({ theme }) => theme.colors.lightBackground};
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  cursor: pointer;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 40px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.background};
`;

export const TitleButton = styled.button`
  display: flex;
  flex-direction: row;
  gap: 10px;
  font-size: 0.8rem;
  font-weight: 600;
  border: none;
  background-color: transparent;
  cursor: pointer;
`;

export const DescriptionContainer = styled.div`
  display: flex;
  width: calc(100% - 40px);
  height: 40px;
  padding: 0 20px;
  align-items: center;
  font-size: 0.75rem;
  font-weight: 600;
`
