import React, { useState } from "react";
import { Avatar, Typography, Box, InputBase, IconButton, Popover } from "@mui/material";
import convertRelativeTime from "@/Utils/ConvertRelativeTime";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import { useAtomValue, useSetAtom } from "jotai";
import { feedDetailAddCommentAtom, feedDetailGetCommentsAtom, feedDetailGetUserSignatureAtom } from "@/ViewModels/Dialogs/FeedDetailViewModel";
import theme from "@/Styles/theme";
import EmojiPicker, { EmojiClickData } from 'emoji-picker-react';

interface FeedCommentsProps {
  feedId: number;
}

const FeedComments = ({ feedId }: FeedCommentsProps) => {
  const getSignature = useSetAtom(feedDetailGetUserSignatureAtom);
  const [newComment, setNewComment] = useState<string>("");
  const comments = useAtomValue(feedDetailGetCommentsAtom);
  const addComment = useSetAtom(feedDetailAddCommentAtom);
  const [emojiAnchorEl, setEmojiAnchorEl] = useState<null | HTMLElement>(null);

  const handleAddComment = () => {
    if (newComment.trim()) {
      addComment(newComment, feedId);
      setNewComment("");
    }
  };

  const handleEnterKey = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      handleAddComment();
    }
  }

  const handleEmojiClick = (emojiObject: EmojiClickData) => {
    setNewComment(newComment + emojiObject.emoji);
    setEmojiAnchorEl(null);
  }

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      {/* 댓글 리스트 */}
      <Box
        sx={{
          flex: 1,
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
          padding: "10px",
          gap: "10px",
          width: "calc(100% - 20px)",
        }}
      >
        {comments && comments.map((comment) => (
          <Box
            key={comment.id}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              gap: "10px",
            }}
          >
            <Avatar src={comment.profileImage} alt={comment.nickname} onClick={() => getSignature(comment.userId)} sx={{ cursor: "pointer" }} />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                flex: 1,
              }}
            >
              <Typography
                variant="body2"
                sx={{ fontWeight: "bold", fontSize: "0.9rem", cursor: "pointer" }}
                onClick={() => getSignature(comment.userId)}
              >
                {comment.nickname}
              </Typography>
              <Typography variant="body2" sx={{ fontSize: "0.8rem" }}>
                {comment.content}
              </Typography>
              <Typography variant="caption" sx={{ color: "gray" }}>
                {convertRelativeTime(new Date(comment.time))}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>

      {/* 댓글 작성 영역 */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          borderTop: "1px solid #e0e0e0",
          gap: "10px",
        }}
      >
        {/* Emoji Icon */}
        <IconButton
          onClick={(e) => setEmojiAnchorEl(e.currentTarget)}
        >
          <SentimentSatisfiedAltIcon />
        </IconButton>
        <Popover
          id={"notification-popover"}
          open={Boolean(emojiAnchorEl)}
          anchorEl={emojiAnchorEl}
          onClose={() => setEmojiAnchorEl(null)}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <EmojiPicker onEmojiClick={handleEmojiClick} />
        </Popover>

        {/* Comment Input */}
        <InputBase
          placeholder="댓글 달기..."
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          onKeyDown={handleEnterKey}
          sx={{
            flexGrow: 1,
            fontSize: "0.9rem",
          }}
        />

        {/* Post Button */}
        <Box
          component="button"
          onClick={handleAddComment}
          disabled={!newComment.trim()}
          sx={{
            background: "none",
            border: "none",
            color: newComment.trim() ? theme.colors.primary : "#c0c0c0",
            fontSize: "0.8rem",
            fontWeight: "bold",
            cursor: newComment.trim() ? "pointer" : "not-allowed",
          }}
        >
          게시
        </Box>
      </div>
    </div>
  );
};

export default FeedComments;
