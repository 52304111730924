import dateSelectorAtom from "@/Atoms/Components/DateSelectorAtom";
import { Node } from "@xyflow/react";
import dayjs, { Dayjs } from "dayjs";
import { atom } from "jotai";
import weekOfYear from "dayjs/plugin/weekOfYear";
import isoWeek from "dayjs/plugin/isoWeek";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isBetween from "dayjs/plugin/isBetween";
import utc from "dayjs/plugin/utc";
import { TFunction } from "i18next";
import { languageAtom } from "@/Atoms/RootAtom";
import convertToEnglishNumbering from "@/Utils/ConvertToEnglishNumbering";

dayjs.extend(isBetween);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(weekOfYear);
dayjs.extend(isoWeek);
dayjs.extend(utc);

const handleCurrentDateRender = (
  date: Dayjs | null,
  weekOrMonth: "week" | "month",
  t: TFunction,
  language: string
) => {
  if (!date) return dayjs().format(`YYYY-MM-DD (ddd)`);

  if (weekOrMonth === "week") {
    const weekStartDate = date.startOf("isoWeek");
    const weekEndDate = date.endOf("isoWeek");

    const weekStartMonth = weekStartDate.month() + 1;
    const weekEndMonth = weekEndDate.month() + 1;

    const weekStartNumber = Math.max(
      weekStartDate.diff(dayjs(weekStartDate).startOf("month"), "week") + 1,
      1
    );
    const weekEndNumber = Math.max(
      weekEndDate.diff(dayjs(weekEndDate).startOf("month"), "week") + 1,
      1
    );

    if (weekStartMonth !== weekEndMonth) {
      return `${weekStartMonth}월 ${weekStartNumber}주차 ~ ${weekEndMonth}월 ${weekEndNumber}주차`;
    }

    return t("plan.contents.routine.dateSelector.date.weekFormat", {
      year: date.year(),
      month: weekStartMonth,
      week: weekStartNumber,
      monthEn: weekStartDate.format("MMMM"),
    });
  } else {
    return t("plan.contents.routine.dateSelector.date.monthFormat", {
      year: date.year(),
      month: date.month() + 1,
      monthEn: date.format("MMMM"),
    });
  }
};

// 초기 설정
export const dateSelectorInitialSettingAtom = atom((get) => get(dateSelectorAtom), (get, set, tasks: Node[], t: TFunction) => {
  if (get(dateSelectorAtom).isInit) return;
  const currentDate = dayjs();
  const startDate = tasks.length > 0 ? dayjs(tasks.reduce((acc, task) => {
    if (!task.data?.startDate) return acc;
    if (!acc.data?.startDate) return task;
    const taskStartDate = dayjs(task.data?.startDate as string).startOf("isoWeek");
    const accStartDate = dayjs(acc.data?.startDate as string).startOf("isoWeek");
    return taskStartDate.isBefore(accStartDate) ? task : acc;
  }, tasks[0]).data?.startDate as string).local().startOf("isoWeek") : dayjs().local().startOf("isoWeek");
  const endDate = tasks.length > 0 ? dayjs(tasks.reduce((acc, task) => {
    if (!task.data?.endDate) return acc;
    if (!acc.data?.endDate) return task;
    const taskEndDate = dayjs(task.data?.endDate as string).endOf("isoWeek");
    const accEndDate = dayjs(acc.data?.endDate as string).endOf("isoWeek");
    return taskEndDate.isAfter(accEndDate) ? task : acc;
  }, tasks[0]).data?.endDate as string).local().endOf("isoWeek") : dayjs().local().endOf("isoWeek");
  const weekOrMonth = "week";
  const isPrevDisabled = weekOrMonth === "week"
    ? currentDate.startOf("isoWeek").isSameOrBefore(startDate, "day")
    : currentDate.startOf("month").isSameOrBefore(startDate, "day");
  const isNextDisabled = weekOrMonth === "week"
    ? currentDate.endOf("isoWeek").isSameOrAfter(endDate, "day")
    : currentDate.endOf("month").isSameOrAfter(endDate, "day");
  const renderedDate = handleCurrentDateRender(currentDate, weekOrMonth, t, get(languageAtom));

  set(dateSelectorAtom, {
    isInit: true,
    currentDate,
    startDate,
    endDate,
    changedStartDate: startDate,
    changedEndDate: endDate,
    weekOrMonth,
    isPrevDisabled,
    isNextDisabled,
    renderedDate,
  });
});

// 날짜 변경(이전, 다음)
export const dateSelectorChangeDateAtom = atom(
  null,
  (get, set, increment: number, t: TFunction) => {
    const dateSelector = get(dateSelectorAtom);
    let newDate = dateSelector.weekOrMonth === "week"
      ? dateSelector.currentDate.add(increment, "week")
      : dateSelector.currentDate.add(increment, "month").startOf("month");

    // startDate와 endDate 사이로 날짜를 고정
    if (dateSelector.weekOrMonth === "week") {
      newDate = newDate.isBefore(dateSelector.startDate)
        ? dateSelector.startDate
        : newDate.isAfter(dateSelector.endDate)
          ? dateSelector.endDate
          : newDate;
    }

    set(dateSelectorAtom, {
      ...dateSelector,
      currentDate: newDate,
      isPrevDisabled: newDate.isSameOrBefore(dateSelector.startDate, 'day'),
      isNextDisabled: newDate.isSameOrAfter(dateSelector.endDate, 'day'),
      renderedDate: handleCurrentDateRender(newDate, dateSelector.weekOrMonth, t, get(languageAtom)),
    });
  }
);

// 날짜 변경(선택)
export const dateSelectorSelectDateAtom = atom(null, (get, set, date: Dayjs, t: TFunction) => {
  const isPrevDisabled = get(dateSelectorAtom).weekOrMonth === "week"
    ? date.startOf("isoWeek").isSameOrBefore(get(dateSelectorAtom).startDate, "day")
    : date.startOf("month").isSameOrBefore(get(dateSelectorAtom).startDate, "day");
  const isNextDisabled = get(dateSelectorAtom).weekOrMonth === "week"
    ? date.endOf("isoWeek").isSameOrAfter(get(dateSelectorAtom).endDate, "day")
    : date.endOf("month").isSameOrAfter(get(dateSelectorAtom).endDate, "day");

  set(dateSelectorAtom, {
    ...get(dateSelectorAtom),
    currentDate: date,
    isPrevDisabled,
    isNextDisabled,
    renderedDate: handleCurrentDateRender(date, get(dateSelectorAtom).weekOrMonth, t, get(languageAtom)),
  });
});

// 주간/월간 변경
export const dateSelectorChangeWeekOrMonthAtom = atom(
  null,
  (get, set, weekOrMonth: "week" | "month", t: TFunction) => {
    const dateSelector = get(dateSelectorAtom);
    const newStart = weekOrMonth === "week"
      ? dateSelector.currentDate.startOf("isoWeek")
      : dateSelector.currentDate.startOf("month");
    const newEnd = weekOrMonth === "week"
      ? dateSelector.currentDate.endOf("isoWeek")
      : dateSelector.currentDate.endOf("month");
    let newCurrentDate = dateSelector.currentDate;

    if (weekOrMonth === "week") {
      const { startDate, endDate } = get(dateSelectorAtom);
      newCurrentDate = newCurrentDate.isBefore(startDate)
        ? startDate
        : newCurrentDate.isAfter(endDate)
          ? endDate
          : newCurrentDate;
    }

    set(dateSelectorAtom, {
      ...dateSelector,
      currentDate: newCurrentDate,
      weekOrMonth,
      changedStartDate: newStart,
      changedEndDate: newEnd,
      isPrevDisabled: newStart.isSameOrBefore(dateSelector.startDate, 'day'),
      isNextDisabled: newEnd.isSameOrAfter(dateSelector.endDate, 'day'),
      renderedDate: handleCurrentDateRender(newCurrentDate, weekOrMonth, t, get(languageAtom)),
    });
  }
);
