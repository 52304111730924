import { useQuery } from 'react-query';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { getUserId, getUserPosition, userLanguageAtom } from '@/ViewModels/UserViewModel';
import { useMenteeDashboardAtom } from '@/Atoms/HookControllerAtom';
import { getCareerSearch, getContentSearch, getJobSearch, getMyLevel } from '@/Queries/DashboardQueries';
import { dashboardMenteeCareerSearchAtom, dashboardMenteeContentSearchAtom, dashboardMenteeDailyPlanAtom, dashboardMenteeJobSearchAtom, dashboardMenteeMyLevelAtom } from '@/Atoms/Dashboard/MenteeAtom';
import { getPlanData, getPlans, parseGetPlansResponse } from '@/Queries/PlanQueries';
import { getSignature } from '@/Queries/SignatureQueries';
import { handleReactQueryApiResponse, handleReactQueryApiResponseWithJson } from '@/Utils/APIUtil';
import { Signature, SignatureInfo } from '@/Atoms/Signature/Atom';
import { Node } from '@xyflow/react';
import { error401ModalAtom } from '@/Atoms/Dialogs/Error/401Atom';

const convertInfoToObject = (infoArray: SignatureInfo[]): Record<string, string | string[]> => {
  return infoArray.reduce((acc, curr) => {
    acc[curr.label] = curr.value;
    return acc;
  }, {} as Record<string, string | string[]>);
}

const useMenteeDashboard = () => {
  const userId = useAtomValue(getUserId);
  const [controller, setController] = useAtom(useMenteeDashboardAtom);
  const setCareerSearch = useSetAtom(dashboardMenteeCareerSearchAtom);
  const setJobSearch = useSetAtom(dashboardMenteeJobSearchAtom);
  const setContentSearch = useSetAtom(dashboardMenteeContentSearchAtom);
  const setMyLevel = useSetAtom(dashboardMenteeMyLevelAtom);
  const setDailyPlan = useSetAtom(dashboardMenteeDailyPlanAtom);
  const userPosition = useAtomValue(getUserPosition);
  const language = useAtomValue(userLanguageAtom);
  const setError401Modal = useSetAtom(error401ModalAtom);

  const handleError401Modal = () => setError401Modal(true);

  return useQuery(['menteeDashboard', userId], () => handleReactQueryApiResponseWithJson(getSignature, handleError401Modal), {
    enabled: controller && userPosition === "멘티",
    onSuccess: async (data) => {
      if (!data) return;
      setController(false);
      const signatureData = data.signatures as Signature[];
      const careerAdvancementSignature = signatureData.find((signature) => signature.careerAdvancement);
      if (careerAdvancementSignature) {
        const newData = {
          country: language,
          ...convertInfoToObject(careerAdvancementSignature.info)
        };

        const careerResponse = await handleReactQueryApiResponse(getCareerSearch, handleError401Modal, newData);
        const careerJsonedData = await careerResponse.json();
        setCareerSearch({ careerSearch: careerJsonedData.results, isLoading: false });

        const jobResponse = await handleReactQueryApiResponse(getJobSearch, handleError401Modal, newData);
        const jobJsonedData = await jobResponse.json();
        setJobSearch({ jobSearch: jobJsonedData.results, isLoading: false });

        const contentResponse = await handleReactQueryApiResponse(getContentSearch, handleError401Modal, newData);
        const contentJsonedData = await contentResponse.json();
        setContentSearch({ contentSearch: contentJsonedData.results, isLoading: false });

        // const levelResponse = await handleReactQueryApiResponse(getMyLevel, handleError401Modal, newData);
        // if (levelResponse === null) {
        //   setMyLevel({ myLevel: { usingOurService: [], notUsing: [] }, isLoading: false });
        // } else {
        //   const levelJsonedData = await levelResponse.json();
        //   const levelData = levelJsonedData.results;
        //   const { using_our_service, not_using } = levelData;
        //   setMyLevel({ myLevel: { usingOurService: using_our_service, notUsing: not_using }, isLoading: false });
        // }
      }

      // 일정관리에 필요한 데이터를 미리 불러옴
      const response = await handleReactQueryApiResponseWithJson(getPlans, handleError401Modal, userId);
      if (!response) return;
      const dailyPlanResponse = parseGetPlansResponse(response);
      const plans = dailyPlanResponse.map((res) => res.innerTabs.map((innerTab, index) => ({ planId: res.id, innerTabId: innerTab.id, name: index === 0 ? res.name : innerTab.name, viewType: innerTab.viewType }))).flat();
      const plansData = (
        await Promise.all(
          plans.map((plan) =>
            handleReactQueryApiResponseWithJson(getPlanData, handleError401Modal, plan.innerTabId)
              .then((data) => {
                // 그룹과 비그룹 노드 분리
                const groupNodes = data.nodes.filter((node: Node) => node.type === "group");
                const nonGroupNodes = data.nodes.filter((node: Node) => node.type !== "group");

                // 모든 노드에 추가 데이터 매핑
                const nodes = [...groupNodes, ...nonGroupNodes].map((node: Node) => ({
                  ...node,
                  data: {
                    ...node.data,
                    hashtags: node.data.hashtags || [],
                    planId: plan.planId,
                    innerTabId: plan.innerTabId,
                    innerTabName: plan.name,
                    innerTabViewType: plan.viewType,
                  },
                })) as Node[];

                // termType이 null이 아닌 노드 필터링
                return nodes.filter((node) => node.data.termType !== null);
              })
          )
        )
      ).flat();
      setDailyPlan({ dailyPlan: plansData, isLoading: false });
    },
  });
};

export default useMenteeDashboard;
