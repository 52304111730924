import { Task } from "gantt-task-react";
import dayjs from 'dayjs';
import minMax from 'dayjs/plugin/minMax';
import { Node } from "@xyflow/react";
import theme from "@/Styles/theme";

dayjs.extend(minMax);

/**
 * Nodes 배열을 Gantt의 Task 배열로 변환하는 함수
 *
 * @param nodes Node[]
 * @returns Task[] 변환된 Gantt Task 배열
 */
const convertNodesToGanttTasks = (nodes: Node[]): Task[] => {
  const tasks: Task[] = [];
  const groupNodes = nodes.filter((node) => node.type === 'group');
  const taskNodes = nodes.filter((node) => node.type === 'task');

  groupNodes.forEach((node) => {
    // groupNode의 id를 가지고 있는 taskNode들을 찾아서 taskNode의 startDate와 endDate를 비교하여 groupNode의 startDate와 endDate를 설정
    const childTasks = taskNodes.filter((task) => task.parentId === node.id);
    // startDate와 endDate를 Dayjs 객체로 변환
    const startDate = dayjs.min(childTasks.map((task) => task.data.startDate ? dayjs(task.data.startDate as string) : dayjs())) || null;
    const endDate = dayjs.max(childTasks.map((task) => task.data.endDate ? dayjs(task.data.endDate as string) : dayjs())) || null;

    // 그룹 작업 추가
    const groupTask: Task = {
      id: node.id,
      name: node.data.label as string || '',
      start: startDate ? startDate.toDate() : new Date(),
      end: endDate ? endDate.toDate() : new Date(),
      progress: 0,
      type: 'project',
      hideChildren: false,
      styles: { progressColor: theme.colors.darkPrimary, progressSelectedColor: theme.colors.darkPrimary + "CC", backgroundColor: theme.colors.primary, backgroundSelectedColor: theme.colors.primary + "CC" }
    };

    tasks.push(groupTask);

    // 자식 작업 추가
    childTasks.forEach((task) => {
      const unsetDate = task.data.startDate === null || task.data.endDate === null;
      const childTask: Task = {
        id: task.id,
        name: unsetDate ? "" : task.data.label as string || '',
        start: task.data.startDate ? dayjs(task.data.startDate as string).toDate() : new Date(),
        end: task.data.endDate ? dayjs(task.data.endDate as string).toDate() : new Date(),
        progress: 0,
        type: 'task',
        project: node.id,
        dependencies: [],
        isDisabled: false,
        styles: { progressColor: theme.colors.customPurple + "CC", progressSelectedColor: theme.colors.customPurple, backgroundColor: unsetDate ? 'none' : 'gray' }
      };
      tasks.push(childTask);
    });
  });

  // groupNode가 없는 taskNode들을 추가
  taskNodes.forEach((task) => {
    if (!task.parentId) {
      const unsetDate = task.data.startDate === null || task.data.endDate === null;
      const childTask: Task = {
        id: task.id,
        name: unsetDate ? "" : task.data.label as string || '',
        start: task.data.startDate ? dayjs(task.data.startDate as string).toDate() : new Date(),
        end: task.data.endDate ? dayjs(task.data.endDate as string).toDate() : new Date(),
        progress: 0,
        type: 'task',
        project: '',
        dependencies: [],
        isDisabled: false,
        styles: { progressColor: theme.colors.customPurple + "CC", progressSelectedColor: theme.colors.customPurple, backgroundColor: unsetDate ? 'none' : 'gray' }
      };
      tasks.push(childTask);
    }
  });
  return tasks;
};

export default convertNodesToGanttTasks;
