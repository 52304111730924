import { notSavedSignatureDialogAtom } from '@/Atoms/Signature/Atom';
import CustomBackgroundColorButton from '@/Components/Common/Buttons/CustomBackgroundColor';
import CustomColorButton from '@/Components/Common/Buttons/CustomColor';
import { closeNotSavedSignatureDialogAtom } from '@/ViewModels/Signature/ViewModel';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useAtom, useSetAtom } from 'jotai';
import React from 'react';
import { useTranslation } from 'react-i18next';

const NotSaveSignatureDialog = () => {
  const { t } = useTranslation();
  const [dialog, setDialog] = useAtom(notSavedSignatureDialogAtom);
  const prev = useSetAtom(closeNotSavedSignatureDialogAtom);

  return (
    <Dialog open={dialog} onClose={() => setDialog(false)} fullWidth maxWidth="sm">
      <DialogTitle sx={{ fontSize: "0.9rem", fontWeight: "bold" }}>
        {t("signature.notSave.title")}
      </DialogTitle>
      <DialogContent sx={{ display: "flex", flexDirection: "column", gap: "20px", padding: "20px", fontSize: "0.8rem" }}>
        <div>
          {t("signature.notSave.content1")}
        </div>
        <div>
          {t("signature.notSave.content2")}
        </div>
      </DialogContent>
      <DialogActions>
        <CustomColorButton text="취소" color="gray" onClick={() => setDialog(false)} fontSize="0.8rem" />
        <CustomBackgroundColorButton text="뒤로가기" backgroundColor="red" onClick={prev} fontSize="0.8rem" />
      </DialogActions>
    </Dialog>
  )
}

export default NotSaveSignatureDialog;
