import { atom } from "jotai";

export interface LocationProps {
  address: string,
  latitude: number | null,
  longitude: number | null,
}

interface InterviewDataProps {
  text: string;
  type: string;
}

export const fromLandingPageInterviewDataAtom = atom<
  InterviewDataProps[] | null
>(null);

export const initializeStateAtom = atom(false);

interface SuccessModalProps {
  state: boolean;
  redirectUrl: string;
  title: string;
  text: string;
  event: (() => void) | null;
  eventText: string;
}

export const successModalAtom = atom<SuccessModalProps>({
  state: false,
  redirectUrl: "",
  title: "성공 타이틀",
  text: "성공 메시지",
  event: null,
  eventText: "",
});

interface ErrorModalProps {
  state: boolean;
  redirectUrl: string;
  title: string;
  text: string;
  event: (() => void) | null;
  eventText: string;
}

export const errorModalAtom = atom<ErrorModalProps>({
  state: false,
  redirectUrl: "",
  title: "에러 타이틀",
  text: "에러 메시지",
  event: null,
  eventText: "",
});

interface noticeModalProps {
  state: boolean;
  redirectUrl: string;
  title: string;
  text: string;
  event: (() => void) | null;
  eventText: string;
}

export const noticeModalAtom = atom<ErrorModalProps>({
  state: false,
  redirectUrl: "",
  title: "에러 타이틀",
  text: "에러 메시지",
  event: null,
  eventText: "",
});

interface WaitingModalProps {
  state: boolean;
  text: string;
}

export const waitingModalAtom = atom<WaitingModalProps>({
  state: false,
  text: "잠시만 기다려주세요.",
});

export const agreeMentModalAtom = atom(false);

export const languageAtom = atom<"KR" | "US">("KR");
