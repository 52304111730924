import React, { useEffect } from "react";
import * as S from "./styled";
import * as C from "./containers";
import SettingsIcon from "@mui/icons-material/Settings";
import { getUserImage, getUserMembershipAtom, getUserNickname } from "../../../ViewModels/UserViewModel";
import { useLocation, useNavigate } from "react-router-dom";
import { Badge, Box, Divider, IconButton, Tooltip } from "@mui/material";
import useIsMobile from "../../../Hooks/useIsMobile";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import theme from "../../../Styles/theme";
import { getStoriesAtom } from "../../../ViewModels/StoryViewModel";
import storiesAtom from "../../../Atoms/StoryAtom";
import { accordionSidebarAtom, planToggleAtom } from "@/Atoms/SidebarAtom";
import planAtom from "@/Atoms/Plan";
import GetIdFromQuerystring from "@/Utils/GetIdFromQuerystring";
import { createNewPlanAtom } from "@/ViewModels/Plan/PlanViewModel";
import { usePlans } from "@/Hooks/usePlans";
import MobileSidebar from "./mobile";
import MobileDrawer from "./drawer";
import TopSectionButtons from "./topSectionButtons";
import BottomSectionButtons from "./bottomSectionButtons";
import { moveToPlanAtom } from "@/ViewModels/Sidebar/ViewModel";
import useNavigateWithWarning from "@/Utils/NavigationWithWarning";
import { movePlanAtom } from "@/ViewModels/Plan/Mindmap/ViewModel";
import { languageAtom, waitingModalAtom } from "@/Atoms/RootAtom";
import { useTranslation } from "react-i18next";
import { openSignatureDialogAtom } from "@/ViewModels/Signature/ViewModel";
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import { devConsoleLog } from "@/Utils/ConsoleLogInDevelopment";
import { notificationAtom } from "@/Atoms/Notification/atom";
import { useNotificationAtom } from "@/Atoms/HookControllerAtom";

const Sidebar = () => {
  const userImage = useAtomValue(getUserImage);
  const userNickname = useAtomValue(getUserNickname);
  const userMembership = useAtomValue(getUserMembershipAtom);
  const { isMobile, isTablet } = useIsMobile();
  const navigate = useNavigate();
  const accordionSidebar = useAtomValue(accordionSidebarAtom);
  const planToggle = useAtomValue(planToggleAtom);
  const getStories = useSetAtom(getStoriesAtom);
  const stories = useAtomValue(storiesAtom);

  // 새로운 api 적용
  const plan = useAtomValue(planAtom);
  const createPlan = useSetAtom(createNewPlanAtom);
  const { isLoading: isPlansLoading } = usePlans();
  const topSectionButtons = TopSectionButtons();
  const bottomSectionButtons = BottomSectionButtons();
  const navigateWithWarning = useNavigateWithWarning();
  const moveToPlan = useSetAtom(moveToPlanAtom)
  const location = useLocation();
  const currentPath = location.pathname;
  const movePlan = useSetAtom(movePlanAtom);
  const openSignatureDialog = useSetAtom(openSignatureDialogAtom);
  const setWaitingModal = useSetAtom(waitingModalAtom);
  const language = useAtomValue(languageAtom);
  const { t } = useTranslation();
  const [notification, setNotification] = useAtom(notificationAtom);
  const setNotificationController = useSetAtom(useNotificationAtom);

  const handleNotificationOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    setNotificationController(true);
    setNotification((prev) => ({ ...prev, anchorEl: e.currentTarget }));
  };

  useEffect(() => {
    if (stories.length === 0) {
      getStories();
    }
  }, [stories.length, getStories]);

  React.useEffect(() => {
    setWaitingModal({
      state: isPlansLoading,
      text: t("dialogs.common.waiting.loading")
    });
  }, [isPlansLoading, setWaitingModal, t]);

  return (
    <>
      {isMobile ? (
        <MobileSidebar />
      ) : (
        <S.AccordionContainer $open={accordionSidebar}>
          {accordionSidebar ? (
            <S.Container>
              <S.ProfileContainer $membership={userMembership}>
                <div
                  style={{
                    width: "calc(100% - 40px)",
                    height: "30px",
                    padding: "0px 20px",
                    display: "flex",
                    // justifyContent: "space-between",
                    justifyContent: "flex-start"
                  }}
                >
                  <C.SidebarButtons
                    buttons={[
                      {
                        ...topSectionButtons[0],
                        tooltipTitle: t("sidebar.buttons.foldSidebar"),
                        color: "black",
                      },
                    ]}
                  />
                  {/* <Tooltip title={t("sidebar.buttons.noti")}>
                    <IconButton onClick={handleNotificationOpen}>
                      <Badge
                        color="error"
                        variant="dot"
                        invisible={!notification.isUnread} // 읽지 않은 알림이 없으면 빨간 원 숨김
                        sx={{
                          "& .MuiBadge-dot": {
                            top: "5px",
                            right: "5px",
                          },
                        }}
                      >
                        <NotificationsNoneOutlinedIcon sx={{ color: "black" }} />
                      </Badge>
                    </IconButton>
                  </Tooltip> */}
                </div>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ position: "relative" }}>
                    <S.ProfileImage
                      src={userImage}
                      alt="profile"
                      $isMobile={isMobile && !isTablet}
                    />
                    <IconButton
                      sx={{
                        position: "absolute",
                        bottom: 0,
                        left: "5%",
                        background: theme.colors.primary,
                        "&:hover": {
                          background: theme.colors.primary,
                        },
                      }}
                      onClick={() => navigate("/settings")}
                    >
                      <PublicOutlinedIcon sx={{ color: "white" }} />
                    </IconButton>
                    <IconButton
                      sx={{
                        position: "absolute",
                        bottom: 0,
                        right: "5%",
                        background: theme.colors.primary,
                        "&:hover": {
                          background: theme.colors.primary,
                        },
                      }}
                      onClick={openSignatureDialog}
                    >
                      <BadgeOutlinedIcon sx={{ color: "white" }} />
                    </IconButton>
                  </Box>

                  <S.ProfileDescription style={{ backgroundColor: "gray", color: "white", fontSize: "0.9rem", padding: "5px 10px", borderRadius: "20px" }}>{t(`sidebar.elements.${userMembership}`)}</S.ProfileDescription>
                  <S.ProfileDescription>{userNickname}</S.ProfileDescription>
                  <Divider style={{ width: "100%" }} />
                </Box>
                <S.TabContainer>
                  {topSectionButtons.slice(1).map((tab, index) => (
                    <S.Tab
                      key={index}
                      onClick={tab.onClick}
                      $active={
                        tab.path !== "/"
                          ? currentPath.includes(tab.path as string)
                          : currentPath === tab.path
                      }
                      style={{
                        width: "calc(100% - 40px)",
                        padding: "0px 20px",
                        justifyContent: "space-between",
                      }}
                    >
                      {tab.name}
                      {tab.dropdown &&
                        (planToggle
                          ? tab.dropdown.open
                          : tab.dropdown.close)}
                    </S.Tab>
                  ))}
                  {planToggle && (
                    <>
                      <div
                        onClick={() => createPlan(navigate)}
                        style={{
                          display: "flex",
                          width: "90%",
                          minHeight: "40px",
                          fontSize: "0.9rem",
                          backgroundColor: theme.colors.primary,
                          color: "white",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                        }}
                      >
                        {t("sidebar.buttons.createPlan")}
                      </div>
                      {plan.map((tab, index) => (
                        <S.Tab
                          key={`plan-${index}`}
                          onClick={() => {
                            movePlan();
                            moveToPlan(tab.id, tab.innerTabs[0].id, navigateWithWarning);
                          }}
                          $active={GetIdFromQuerystring("id") === tab.id}
                          style={{ width: "calc(100% - 80px)", padding: "0px 40px", fontSize: "0.8rem" }}
                        >
                          {tab.name ? tab.name : language === "KR" ? "새 플랜" : "New Plan"}
                        </S.Tab>
                      ))}
                    </>
                  )}
                </S.TabContainer>
              </S.ProfileContainer>
              <S.ButtonContainer $membership={userMembership}>
                {bottomSectionButtons.map((button, index) => (
                  button &&
                  <S.Tab
                    key={index}
                    onClick={button.onClick}
                    $active={currentPath === button.path}
                    style={{
                      fontSize: "0.8rem",
                      gap: "10px",
                      width: "calc(100% - 40px)",
                      padding: "0px 20px",
                    }}
                  >
                    <button.icon style={{ fontSize: "25px" }} />
                    {button.tooltipTitle}
                  </S.Tab>
                ))}
              </S.ButtonContainer>
            </S.Container>
          ) : (
            <C.AccordionCloseSidebar />
          )}
        </S.AccordionContainer>
      )}
      <MobileDrawer />
    </>
  );
};

export default Sidebar;
