import { atom } from "jotai";
import {
  chatHistoryPageNumAtom,
  chatInputValueAtom,
  chattingListAtom,
  chattingTypeAtom,
  ChatType,
  currentChatbotAtom,
  currentSearchIndexAtom,
  isAIMakingResponseAtom,
  isChattingAskAtom,
  isOpenRecommendQuestionAtom,
  isPageGettingHistoryAtom,
  previousCrewAtom,
  scrollStateAtom,
  searchInputValueAtom,
} from "../Atoms/HelperAtom";
import { getChatbotHistory, requestChatbot } from "../Queries/HelperQueries";
import dayjs from "dayjs";
import { userAtom } from "@/Atoms/UserAtom";
import { handleReactQueryApiResponse } from "@/Utils/APIUtil";
import { error401ModalAtom } from "@/Atoms/Dialogs/Error/401Atom";
import { isChatbotTypingAtom } from "@/Atoms/ChatAtom";
import { devConsoleLog } from "@/Utils/ConsoleLogInDevelopment";
import { getUserEmailAtom } from "./UserViewModel";

export const setPreviousCrewAtom = atom(
  null,
  (get, set, crewId: number, innerTabId: number) => {
    // Update the previousCrewAtom with values from queryString
    set(previousCrewAtom, {
      crew_id: crewId,
      inner_tab_id: innerTabId,
    });
  }
);

export const initializeAtom = atom(null, (get, set) => {
  set(currentChatbotAtom, null);
});

export const handleClickAtom = atom(
  null,
  (_, set, event: React.MouseEvent<HTMLDivElement>) => {
    set(isChattingAskAtom, event.currentTarget);
  }
);

export const handleCloseAtom = atom(null, (_, set) => {
  set(isChattingAskAtom, null);
});

export const handleCloseAndChangeTypeAtom = atom(
  null,
  (get, set, type: ChatType) => {
    const chatType = get(chattingTypeAtom);
    if (chatType !== type) {
      set(isChattingAskAtom, null);
      set(chattingTypeAtom, type);
      set(currentSearchIndexAtom, 0);
    }
  }
);

export const handleInputValueAtom = atom(null, (get, set, value: string) => {
  const chatType = get(chattingTypeAtom);

  if (chatType === ChatType.ASK) set(chatInputValueAtom, value);
  else {
    set(currentSearchIndexAtom, 0);
    set(searchInputValueAtom, value);
  }
});

export const hanldeRecommendQuestionAtom = atom(
  null,
  (get, set, value: string) => {
    set(chattingTypeAtom, ChatType.ASK);
    set(chatInputValueAtom, value);
    set(isOpenRecommendQuestionAtom, false);
  }
);

export const whenChatbotChangeAtom = atom(
  null,
  async (get, set, ref: HTMLDivElement | null) => {
    set(chattingListAtom, []);
    set(currentSearchIndexAtom, 0);
    set(isAIMakingResponseAtom, false);

    const currentChatbot = get(currentChatbotAtom);
    const userId = get(userAtom);
    const handleError401Modal = () => set(error401ModalAtom, true);

    devConsoleLog("currentChatbot", currentChatbot);

    if (currentChatbot && userId) {
      set(chatHistoryPageNumAtom, 1);
      const result = await handleReactQueryApiResponse(
        getChatbotHistory,
        handleError401Modal,
        currentChatbot.id,
        userId.email,
        1
      );

      if (result?.length !== 0 && result) {
        set(chattingListAtom, result);

        if (ref) {
          setTimeout(() => ref.scrollIntoView({ behavior: "smooth" }), 500);
        }
      } else if (result?.length === 0 && result) {
        const handleStreamMessage = (message: any, isFirst: boolean) => {
          if (isFirst) {
            set(chattingListAtom, (current) => [
              ...current,
              {
                name: currentChatbot.name,
                image: `${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${currentChatbot!.character_image!.file_path
                  }`,
                text: message,
                date: dayjs().format("MM.DD HH:mm"),
                chattingUser: "chatbot",
                type: "markdown",
              },
            ]);
          } else {
            set(chattingListAtom, (current) => {
              const lastItem = current[current.length - 1];

              if (lastItem) {
                return [
                  ...current.slice(0, -1),
                  {
                    ...lastItem,
                    text: message,
                  },
                ];
              }

              return current;
            });
          }
        };

        set(isChatbotTypingAtom, true);

        await handleReactQueryApiResponse(
          requestChatbot,
          handleError401Modal,
          "",
          userId.email ?? "",
          currentChatbot.chatbot_socket_parameter,
          "",
          handleStreamMessage,
          () => { }
        );

        set(isChatbotTypingAtom, false);
      }
    }
  }
);

export const fetchMoreChattingListAtom = atom(
  (get) => get(isPageGettingHistoryAtom),
  async (get, set) => {
    const isPageGettingHistory = get(isPageGettingHistoryAtom);
    const handleError401Modal = () => set(error401ModalAtom, true);

    if (isPageGettingHistory) return;

    set(isPageGettingHistoryAtom, true);

    const currentChatbot = get(currentChatbotAtom);
    const userId = get(userAtom);
    if (currentChatbot && userId) {
      const chatHistoryPageNum = get(chatHistoryPageNumAtom);
      const result = await handleReactQueryApiResponse(
        getChatbotHistory,
        handleError401Modal,
        currentChatbot.id,
        userId.email,
        chatHistoryPageNum + 1
      );
      if (result) {
        if (result.length !== 0)
          set(chatHistoryPageNumAtom, (current) => current + 1);

        set(chattingListAtom, (prevChattingList) => [
          ...result,
          ...prevChattingList,
        ]);
      }
    }

    setTimeout(() => set(isPageGettingHistoryAtom, false), 500);
  }
);

export const handleInputFieldKeyDownAtom = atom(
  null,
  async (
    get,
    set,
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      const inputValue = get(chatInputValueAtom).trim();
      if (inputValue === "") return;

      const flow = get(currentChatbotAtom);
      set(isAIMakingResponseAtom, true);

      if (flow) {
        const newMessage = {
          name: flow.name,
          image: `${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${flow!.character_image!.file_path
            }`,
          text: inputValue,
          date: dayjs().format("MM.DD HH:mm"),
          chattingUser: "user",
        };

        set(chattingListAtom, (prevChatting) => [...prevChatting, newMessage]);
        set(chatInputValueAtom, "");
        set(isOpenRecommendQuestionAtom, false);

        set(chattingListAtom, (current) => [
          ...current,
          {
            name: flow.name,
            image: `${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${flow!.character_image!.file_path
              }`,
            text: "",
            date: dayjs().format("MM.DD HH:mm"),
            chattingUser: "chatbot",
          },
        ]);

        // 여기에서 backend랑 통신을 하도록 하자.
        const handleStreamMessage = (
          message: string,
          isFirst: boolean,
          isNotStream: boolean,
          flow_socket_name: string
        ) => {
          const currentChatbot = get(currentChatbotAtom);

          if (
            currentChatbot &&
            currentChatbot.chatbot_socket_parameter !== flow_socket_name
          )
            return;
          set(chattingListAtom, (current) => {
            const lastItem = current[current.length - 1];

            if (isFirst) set(scrollStateAtom, true);
            if (lastItem) {
              if (lastItem.chattingUser === "user") {
                return [
                  ...current,
                  {
                    chattingUser: "chatbot",
                    text: message,
                    date: dayjs().format("MM.DD HH:mm"),
                    image: `${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${currentChatbot!.character_image!.file_path
                      }`,
                    name: currentChatbot!.name,
                  },
                ];
              } else {
                return [
                  ...current.slice(0, -1),
                  {
                    ...lastItem,
                    text: message,
                  },
                ];
              }
            }

            return current;
          });
        };

        const handleError401Modal = () => set(error401ModalAtom, true);
        set(isChatbotTypingAtom, true);

        await handleReactQueryApiResponse(
          requestChatbot,
          handleError401Modal,
          inputValue,
          get(userAtom)?.email ?? "",
          get(currentChatbotAtom)!.chatbot_socket_parameter,
          handleStreamMessage,
          () => { }
        );
      }

      set(isAIMakingResponseAtom, false);
      set(isChatbotTypingAtom, false);
    }
  }
);

export const handleSendBtnAtom = atom(null, async (get, set) => {
  const inputValue = get(chatInputValueAtom).trim();
  if (inputValue === "") return;

  const flow = get(currentChatbotAtom);
  set(isAIMakingResponseAtom, true);

  if (flow) {
    const newMessage = {
      name: flow.name,
      image: `${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${flow!.character_image!.file_path}`,
      text: inputValue,
      date: dayjs().format("MM.DD HH:mm"),
      chattingUser: "user",
    };

    set(chattingListAtom, (prevChatting) => [...prevChatting, newMessage]);
    set(chatInputValueAtom, "");
    set(isOpenRecommendQuestionAtom, false);

    set(chattingListAtom, (current) => [
      ...current,
      {
        name: flow.name,
        image: `${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${flow!.character_image!.file_path
          }`,
        text: "",
        date: dayjs().format("MM.DD HH:mm"),
        chattingUser: "chatbot",
      },
    ]);

    // 여기에서 backend랑 통신을 하도록 하자.
    const handleStreamMessage = (
      message: string,
      isFirst: boolean,
      isNotStream: boolean,
      flow_socket_name: string
    ) => {
      const currentChatbot = get(currentChatbotAtom);
      devConsoleLog("handleStreamMessage", message, isFirst, isNotStream, flow_socket_name);
      if (
        currentChatbot &&
        currentChatbot.chatbot_socket_parameter !== flow_socket_name
      )
        return;
      set(chattingListAtom, (current) => {
        const lastItem = current[current.length - 1];

        if (isFirst) set(scrollStateAtom, true);
        if (lastItem) {
          if (lastItem.chattingUser === "user") {
            return [
              ...current,
              {
                chattingUser: "chatbot",
                text: message,
                date: dayjs().format("MM.DD HH:mm"),
                image: `${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${currentChatbot!.character_image!.file_path
                  }`,
                name: currentChatbot!.name,
              },
            ];
          } else {
            return [
              ...current.slice(0, -1),
              {
                ...lastItem,
                text: message,
              },
            ];
          }
        }

        return current;
      });
    };

    const handleError401Modal = () => set(error401ModalAtom, true);
    set(isChatbotTypingAtom, true);

    await handleReactQueryApiResponse(
      requestChatbot,
      handleError401Modal,
      inputValue,
      get(getUserEmailAtom),
      get(currentChatbotAtom)!.chatbot_socket_parameter,
      "",
      handleStreamMessage,
      () => { }
    );
  }

  set(isAIMakingResponseAtom, false);
  set(isChatbotTypingAtom, false);
});

export const whenDownButtonClickAtom = atom(null, (get, set) => {
  const currentIdx = get(currentSearchIndexAtom);
  const highlightsLength = document.getElementsByClassName(
    "highlight-search-field"
  ).length;

  setTimeout(() => {
    if (currentIdx + 1 < highlightsLength) {
      // currentIdx가 highlight 길이를 넘지 않도록
      const firstHighlight = document.getElementsByClassName(
        "highlight-search-field"
      )[currentIdx + 1];
      if (firstHighlight) {
        firstHighlight.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
      set(currentSearchIndexAtom, (current) => current + 1);
    }
  }, 100);
});

export const whenUpButtonClickAtom = atom(null, (get, set) => {
  const currentIdx = get(currentSearchIndexAtom);

  setTimeout(() => {
    const firstHighlight = document.getElementsByClassName(
      "highlight-search-field"
    )[currentIdx - 1];
    if (firstHighlight) {
      firstHighlight.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }

    set(currentSearchIndexAtom, (current) =>
      current != 0 ? current - 1 : current
    );
  }, 100);
});
