import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
  styled,
  SwitchProps,
  Switch,
} from "@mui/material";
import theme from "../../../../../Styles/theme";
import { getUserNickname } from "@/ViewModels/UserViewModel";
import { useAtomValue } from "jotai";

const rows = [
  {
    service: "C사 솔루션(학생 성적 관리)",
    api_key: "ak-dkn3!dkdkq",
  },
  {
    service: "A사 솔루션(교육 콘텐츠 제작)",
    api_key: "bs1-dk-n13$kdkq",
  },
  {
    service: "B사 솔루션(학생부 관리)",
    api_key: "dkn4^dkdn2sk1",
  },
  {
    service: "D사 솔루션(학생 정보 관리)",
    api_key: "3ksh43@k1!123df",
  },
  {
    service: "E사 솔루션(오프라인 멘토링 프로그램)",
    api_key: "hkdbej4n5&!23",
  },
];

const HabitTable: React.FC = () => {
  const userNickname = useAtomValue(getUserNickname);
  // Color mapping for activities
  const colorMap = {
    study: "#4caf50", // green
    rest: "#ffeb3b", // yellow
    hobbies: "#2196f3", // blue
    career: "#f44336", // red
  };
  return (
    <TableContainer sx={{ flexGrow: 1 }}>
      <Typography
        className="my-skill-sample-data"
        sx={{
          padding: "5px",
          borderRadius: "5px",
          color: "red",
          border: "1px solid red",
        }}
      >
        Sample Data
      </Typography>
      <Table stickyHeader aria-label="habit table">
        <TableHead>
          <TableRow>
            <TableCell
              style={{
                backgroundColor: theme.colors.primary,
                color: "white",
              }}
            >
              연동 서비스 명
            </TableCell>
            <TableCell
              style={{
                backgroundColor: theme.colors.primary,
                color: "white",
              }}
            >
              API Key
            </TableCell>
            <TableCell
              style={{
                backgroundColor: theme.colors.primary,
                color: "white",
              }}
            >
              사용
            </TableCell>

            <TableCell
              style={{
                backgroundColor: theme.colors.primary,
                color: "white",
              }}
            >
              재발급
            </TableCell>
            <TableCell
              style={{
                backgroundColor: theme.colors.primary,
                color: "white",
              }}
            >
              수정
            </TableCell>
            <TableCell
              style={{
                backgroundColor: theme.colors.primary,
                color: "white",
              }}
            >
              삭제
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows
            .map((row) => ({ ...row, mentor: userNickname }))
            .map((row, index) => {
              return (
                <TableRow
                  key={`${index}-student-gawan-rle`}
                  sx={{ fontSize: "0.8rem" }}
                >
                  <TableCell>{row.service}</TableCell>
                  <TableCell>
                    {row.api_key}
                    <Button
                      sx={{
                        padding: 0,
                        margin: 0,
                        color: theme.colors.primary,
                      }}
                    >
                      복사
                    </Button>
                  </TableCell>
                  <TableCell>
                    <IOSSwitch defaultChecked />
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      sx={{
                        padding: 0,
                        margin: 0,
                        color: theme.colors.primary,
                        border: `1px solid ${theme.colors.primary}`,
                      }}
                    >
                      재발급
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      sx={{
                        padding: 0,
                        margin: 0,
                        color: theme.colors.primary,
                        border: `1px solid ${theme.colors.primary}`,
                      }}
                    >
                      수정
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      sx={{
                        padding: 0,
                        margin: 0,
                        color: theme.colors.primary,
                        border: `1px solid ${theme.colors.primary}`,
                      }}
                    >
                      삭제
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default HabitTable;

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#65C466",
        opacity: 1,
        border: 0,
        ...theme.applyStyles("dark", {
          backgroundColor: "#2ECA45",
        }),
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.grey[100],
      ...theme.applyStyles("dark", {
        color: theme.palette.grey[600],
      }),
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.7,
      ...theme.applyStyles("dark", {
        opacity: 0.3,
      }),
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#E9E9EA",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
    ...theme.applyStyles("dark", {
      backgroundColor: "#39393D",
    }),
  },
}));
