import React from 'react';
import { settingsTabAtom } from '@/Atoms/Settings';
import { useAtomValue } from 'jotai';
import SettingsProfile from './Profile';

const SettingsContents = () => {
  const tab = useAtomValue(settingsTabAtom);

  switch (tab) {
    default:
      return <SettingsProfile />;
  }
}

export default SettingsContents;
