import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
} from "@mui/material";
import theme from "../../../../../Styles/theme";
import { getUserNickname } from "@/ViewModels/UserViewModel";
import { useAtomValue } from "jotai";
import { LinearProgress } from "@mui/material";
import { useTranslation } from "react-i18next";

interface HabitData {
  crew: string;
  mentor: string;
  mentee: string;
  content: string;
  lastStudyDate: string;
  totalSessions: number;
  studyTime: number;
  achievement: number;
  study: number;
  rest: number;
  hobbies: number;
  career: number;
}

const rows: HabitData[] = [
  {
    crew: "이음고등학교 1학년 4반 숙제 관리 그룹",
    mentor: "멘토A",
    mentee: "이하나",
    content: "AI 학습 프로젝트",
    lastStudyDate: "24.09.01",
    totalSessions: 15,
    studyTime: 20,
    achievement: 85,
    study: 70,
    rest: 15,
    hobbies: 10,
    career: 5,
  },
  {
    crew: "이음고등학교 1학년 4반 숙제 관리 그룹",
    mentor: "멘토B",
    mentee: "김영민",
    content: "개발자로서의 성장",
    lastStudyDate: "24.08.25",
    totalSessions: 12,
    studyTime: 18,
    achievement: 90,
    study: 80,
    rest: 10,
    hobbies: 5,
    career: 5,
  },
  {
    crew: "이음고등학교 1학년 4반 숙제 관리 그룹",
    mentor: "멘토C",
    mentee: "정우성",
    content: "UX/UI 디자인 기초",
    lastStudyDate: "24.08.30",
    totalSessions: 8,
    studyTime: 15,
    achievement: 70,
    study: 60,
    rest: 20,
    hobbies: 15,
    career: 5,
  },
  {
    crew: "이음고등학교 1학년 4반 일정 관리 그룹",
    mentor: "멘토D",
    mentee: "이서연",
    content: "데이터 사이언스 심화",
    lastStudyDate: "24.09.03",
    totalSessions: 20,
    studyTime: 22,
    achievement: 95,
    study: 75,
    rest: 10,
    hobbies: 5,
    career: 10,
  },
  {
    crew: "이음고등학교 1학년 4반 일정 관리 그룹",
    mentor: "멘토E",
    mentee: "한유정",
    content: "비즈니스 영어",
    lastStudyDate: "24.08.28",
    totalSessions: 10,
    studyTime: 16,
    achievement: 88,
    study: 50,
    rest: 25,
    hobbies: 15,
    career: 10,
  },
  {
    crew: "이음고등학교 1학년 4반 일정 관리 그룹",
    mentor: "멘토F",
    mentee: "최수현",
    content: "미래 직업 관련 도서 읽기",
    lastStudyDate: "24.07.06",
    totalSessions: 10,
    studyTime: 11,
    achievement: 73,
    study: 62,
    rest: 18,
    hobbies: 12,
    career: 8,
  },
  {
    crew: "이음고등학교 1학년 4반 학습 관리 그룹",
    mentor: "멘토G",
    mentee: "김도현",
    content: "경영학 기초",
    lastStudyDate: "24.09.02",
    totalSessions: 18,
    studyTime: 21,
    achievement: 82,
    study: 65,
    rest: 15,
    hobbies: 10,
    career: 10,
  },
  {
    crew: "이음고등학교 1학년 4반 학습 관리 그룹",
    mentor: "멘토H",
    mentee: "이지은",
    content: "웹 개발 실습",
    lastStudyDate: "24.08.18",
    totalSessions: 14,
    studyTime: 19,
    achievement: 78,
    study: 60,
    rest: 20,
    hobbies: 15,
    career: 5,
  },
  {
    crew: "이음고등학교 1학년 4반 학습 관리 그룹",
    mentor: "멘토I",
    mentee: "박지훈",
    content: "공학적 문제 해결",
    lastStudyDate: "24.07.30",
    totalSessions: 12,
    studyTime: 10,
    achievement: 75,
    study: 55,
    rest: 25,
    hobbies: 10,
    career: 10,
  },
  {
    crew: "이음고등학교 1학년 4반 학습 관리 그룹",
    mentor: "멘토J",
    mentee: "김민수",
    content: "마케팅 전략 분석",
    lastStudyDate: "24.08.10",
    totalSessions: 11,
    studyTime: 17,
    achievement: 88,
    study: 65,
    rest: 20,
    hobbies: 10,
    career: 5,
  },
];

const HabitTable: React.FC = () => {
  const userNickname = useAtomValue(getUserNickname);
  const { t } = useTranslation();
  // Color mapping for activities
  const colorMap = {
    study: "#4caf50", // green
    rest: "#ffeb3b", // yellow
    hobbies: "#2196f3", // blue
    career: "#f44336", // red
  };

  const calculateTotalActivities = (row: HabitData): number => {
    return row.study + row.rest + row.hobbies + row.career;
  };

  return (
    <TableContainer sx={{ flexGrow: 1 }}>
      <Table stickyHeader aria-label="habit table">
        <TableHead>
          <TableRow >
            <TableCell
              style={{
                backgroundColor: theme.colors.primary,
                color: "white",
              }}
            >
              {t("dashboard.mentor.careerPlanning.crewName")}
            </TableCell>
            <TableCell
              style={{
                backgroundColor: theme.colors.primary,
                color: "white",
              }}
            >
              {t("dashboard.mentor.careerPlanning.menteeName")}
            </TableCell>
            <TableCell
              style={{
                backgroundColor: theme.colors.primary,
                color: "white",
              }}
            >
              {t("dashboard.mentor.careerPlanning.contentName")}
            </TableCell>
            <TableCell
              style={{
                backgroundColor: theme.colors.primary,
                color: "white",
              }}
            >
              {t("dashboard.mentor.careerPlanning.recentlyEntered")}
            </TableCell>
            <TableCell
              style={{
                backgroundColor: theme.colors.primary,
                color: "white",
              }}
            >
              {t("dashboard.mentor.careerPlanning.numberOfUltimateSkills")}
            </TableCell>
            <TableCell
              style={{
                backgroundColor: theme.colors.primary,
                color: "white",
              }}
            >
              {t("dashboard.mentor.careerPlanning.numberOfTotalTodos")}
            </TableCell>
            <TableCell
              style={{
                backgroundColor: theme.colors.primary,
                color: "white",
              }}
            >
              {t("dashboard.mentor.careerPlanning.careerGrowthRate")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => ({ ...row, mentor: userNickname })).map((row) => {
            return (
              <TableRow key={row.mentor + row.mentee} sx={{ fontSize: "0.8rem" }}>
                <TableCell>{row.crew}</TableCell>
                <TableCell>{row.mentee}</TableCell>
                <TableCell>{row.content}</TableCell>
                <TableCell>{row.lastStudyDate}</TableCell>
                <TableCell>{row.totalSessions}</TableCell>
                <TableCell>{row.studyTime}</TableCell>
                <TableCell sx={{
                  width: "100px"
                }}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box sx={{ width: '100%', mr: 1 }}>
                      <LinearProgress
                        variant="determinate"
                        value={row.achievement}
                        sx={{
                          height: 10,
                          borderRadius: 1,
                          backgroundColor: "gray",
                          "& .MuiLinearProgress-bar": {
                            borderRadius: 1,
                            backgroundColor: colorMap.study,
                          },
                        }}
                      />
                    </Box>
                    <Box sx={{ minWidth: 35 }}>
                      <span>{`${row.achievement}%`}</span>
                    </Box>
                  </Box>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default HabitTable;
