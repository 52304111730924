import theme from "@/Styles/theme";
import styled from "styled-components";

export const SelectButton = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;
  height: 120px;
  justify-content: space-around;
  align-items: center;
  border: 1px solid #c0c0c0;
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
  font-size: 0.8rem;

  &:hover {
    border: 2px solid ${theme.colors.primary};
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 50px;
`;

export const ContentContent = styled.div`
  display: flex;
  width: 85%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-top: 1px solid #c0c0c0;
  border-right: 1px solid #c0c0c0;
  padding: 10px;
  gap: 10px;
  font-size: 0.8rem;
`;

export const ContentContentRow = styled.div`
  display: flex;
  font-size: 0.8rem;
  flex-direction: row;
  gap: 20px;
  align-items: center;
`;

export const ContentContentColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const ContentInputStyle = (width: number) => ({ width: `${width}px`, padding: "5px", border: "1px solid #c0c0c0", borderRadius: "5px" });

export const ContentTitle = styled.div`
  width: 80px;
  font-size: 0.8rem;
  font-weight: bold;
`;

export const TagInputContainer = styled.div`
  display: flex;
  width: 80%;
  flex-wrap: wrap;
  gap: 0.5em;
  padding: 0.4rem 1rem;
  border: 1px solid #c0c0c0;
  border-radius: 4px;
`;

export const TagItem = styled.div`
  display: inline-block;
  padding: 0.2em 0.5em;
  border-radius: 20px;
  color: white;
`;

export const TagInput = styled.input`
  flex-grow: 1;
  min-width: 30px;
  max-width: 150px;
  padding: 0.5em 0;
  border: none;
  outline: none;
  font-family: "Noto Sans KR", sans-serif;
`;
