import { atom } from 'jotai';
import { DokgabiFlowProps } from "@/Models/DokgabiFlow";

// export const currentChatbotAtom = atom<DokgabiFlowProps | null>(null);

export enum ChatType {
  ASK = 0,
  SEARCH = 1,
}

export const chattingTypeAtom = atom<ChatType>(ChatType.ASK);
export const isChattingAskAtom = atom<HTMLElement | null>(null); // 챗봇 대화중인지 여부
export const isOpenRecommendQuestionAtom = atom(false);
export const isAIMakingResponseAtom = atom(false);

export const chatInputValueAtom = atom("");
export const searchInputValueAtom = atom("");
export const currentSearchIndexAtom = atom(0);

export const recommendCurrentPageAtom = atom(0);

export const chatHistoryPageNumAtom = atom(1);
export const isPageGettingHistoryAtom = atom(false);

export const scrollStateAtom = atom(false);

interface DokgabiChatModal {
  isOpen: boolean;
  mode: "chat" | "create";
}

export const initialDokgabiChatModal: DokgabiChatModal = {
  isOpen: false,
  mode: "chat",
};

export const dokgabiChatModalAtom = atom<DokgabiChatModal>(initialDokgabiChatModal);

export const dokgabiChatModalSidebarAtom = atom(false);
