import { FeedComment, feedDetailDialogAtom, feedDetailUserSignatureDialogAtom } from '@/Atoms/Dialogs/FeedDetail/atom';
import { Signature } from '@/Atoms/Signature/Atom';
import { getSignatureById } from '@/Queries/SignatureQueries';
import { handleReactQueryApiResponseWithJson } from '@/Utils/APIUtil';
import { atom } from 'jotai';
import { getUserId, getUserImage, getUserNickname } from '../UserViewModel';
import { error401ModalAtom } from '@/Atoms/Dialogs/Error/401Atom';

// 유저 id를 통한 시그니쳐 조회
export const feedDetailGetUserSignatureAtom = atom(null, async (get, set, userId: number) => {
  const handleError401Modal = () => set(error401ModalAtom, true);
  const response = await handleReactQueryApiResponseWithJson(getSignatureById, handleError401Modal, userId);
  const signatures = response.signatures as Signature[];
  if (signatures.length === 0) {
    return;
  }
  const representativeSignature = signatures.find((signature) => signature.representative);
  if (!representativeSignature) {
    return;
  }
  set(feedDetailUserSignatureDialogAtom, representativeSignature);
})

// 댓글 조회
export const feedDetailGetCommentsAtom = atom((get) => {
  const feed = get(feedDetailDialogAtom);
  if (!feed) {
    return [];
  }
  return feed.data.comments as FeedComment[] || [] as FeedComment[];
})

// 댓글 추가
export const feedDetailAddCommentAtom = atom(null, async (get, set, comment: string, feedId: number) => {
  const feed = get(feedDetailDialogAtom);
  if (!feed) {
    return;
  }
  const comments: FeedComment[] = feed.data.comments as FeedComment[] || [];
  const userId = get(getUserId);
  // 댓글 추가 (임시 - 향후 api 연동 필요)
  comments.push({
    id: comments.length + 1,
    userId: userId,
    profileImage: get(getUserImage), // 기본 프로필 이미지
    nickname: get(getUserNickname),
    time: new Date().toLocaleDateString(),
    content: comment,
  });
  feed.data.comments = comments;
  set(feedDetailDialogAtom, feed);
});
