import { atom } from 'jotai';

export interface Notification {
  id: number;
  title: string;
  content: string;
  date: Date;
  profileImage: string;
  profileNickName: string;
  type: string;
  unread: boolean;
}

export const notificationDataAtom = atom<Notification[]>([]);

interface NotificationProps {
  anchorEl: HTMLElement | null;
  isUnread: boolean;
}

export const notificationAtom = atom<NotificationProps>({
  anchorEl: null,
  isUnread: false,
});
