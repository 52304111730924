import styled from "styled-components";
import { Box, Button, styled as muiStyled, Typography } from "@mui/material";
import theme from "../../../../Styles/theme";

export const Container = muiStyled(Box)({
  position: "relative",  // 추가
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export const ExpandIconButton = muiStyled(Button)({
  backgroundColor: theme.colors.primary,
  color: "#FFFFFF",
  borderRadius: "30px",
  padding: 0,
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  "&:hover": {
    // backgroundColor: theme.colors.primary,
    backgroundColor: "white"
  },
});

export const ElementBarContainer = muiStyled(Box)({
  position: "absolute",  // 수정
  left: "20px",
  // height: "calc(100vh - 112px)",
  background: "transparent",
  zIndex: 100,
  top: "20px",
  display: "flex",
  justifyContent: "center",
  alignItems: "start",
  flexDirection: "row",
  gap: "20px",
});

export const CustomExpandIconButton = muiStyled(ExpandIconButton)({
  padding: "10px",
  gap: "10px",
  color: `${theme.colors.primary}`,
  // backgroundColor: theme.colors.primary,
  backgroundColor: "white",
  fontSize: "1rem",
  fontWeight: "bold",
  border: `1px solid ${theme.colors.primary}`,
  "& .MuiTypography-root": {
    display: "none",
  },
  "&:hover": {
    padding: "10px 20px",
    "& .MuiTypography-root": {
      display: "block",
    },
  },
});

export const CustomTypograpy = muiStyled(Typography)({
  fontSize: "0.7rem",
  fontWeight: "bold",
});
