import React from 'react';
import { Fab, Tooltip } from "@mui/material";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { useSetAtom } from "jotai";
import deletePlanDialogAtom from '@/Atoms/Dialogs/Delete/PlanAtom';
import { useTranslation } from 'react-i18next';

const DeleteElementBar = () => {
  const setDeletePlanDialog = useSetAtom(deletePlanDialogAtom);
  const { t } = useTranslation();

  return (
    <Tooltip title={<div style={{ fontSize: "1rem" }}>{t("plan.contents.mindmap.elementbar.deletePlan")}</div>} arrow placement="left">
      <Fab
        sx={{
          bgcolor: "gray",
          '&:hover': {
            bgcolor: "#9999",
            color: "black"
          },
          color: "#fff", // 아이콘 색상
        }}
        onClick={() => setDeletePlanDialog({ open: true, complete: false })}
      >
        <DeleteOutlinedIcon />
      </Fab>
    </Tooltip>
  );
};

export default DeleteElementBar;
