import React from 'react';
import * as S from './styled';
import * as C from './components';
import { Button, Divider, TextField } from '@mui/material';
import theme from '@/Styles/theme';
import { useAtomValue, useSetAtom } from 'jotai';
import mailAtom from '@/Atoms/Mail/Atom';
import { changeSubjectAtom, sendMailAtom } from '@/ViewModels/Mail/ViewModel';
import { addressBookDialogOpenAtom } from '@/ViewModels/Dialogs/AddressBook/ViewModel';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import useIsMobile from '@/Hooks/useIsMobile';
import CustomBackgroundColorButton from '@/Components/Common/Buttons/CustomBackgroundColor';
import CustomColorButton from '@/Components/Common/Buttons/CustomColor';
import { devConsoleLog } from '@/Utils/ConsoleLogInDevelopment';

const MailController = () => {
  const { isMobile } = useIsMobile();
  const mail = useAtomValue(mailAtom);
  const changeSubject = useSetAtom(changeSubjectAtom);
  const sendMail = useSetAtom(sendMailAtom);
  const openAddressBook = useSetAtom(addressBookDialogOpenAtom);
  const [isRecipientFocused, setIsRecipientFocused] = React.useState(false);

  return (
    <S.Container>
      {
        isMobile ?
          <>
            <S.ElementContainer style={{ height: "auto" }}>
              <S.ElementTitle>
                받는 사람
                {
                  isRecipientFocused ? <KeyboardArrowUpIcon onClick={() => setIsRecipientFocused(false)} /> : <KeyboardArrowDownIcon onClick={() => setIsRecipientFocused(true)} />
                }
              </S.ElementTitle>
              <C.RecipientInput type="recipients" />
            </S.ElementContainer>
            {
              isRecipientFocused && <>
                <S.ElementContainer>
                  <S.ElementTitle>참조</S.ElementTitle>
                  <C.RecipientInput type="copyRecipients" />
                </S.ElementContainer>
                <S.ElementContainer>
                  <S.ElementTitle>숨은 참조</S.ElementTitle>
                  <C.RecipientInput type="hiddenRecipients" />
                </S.ElementContainer>
              </>
            }
            <S.ElementContainer>
              <S.ElementTitle>제목</S.ElementTitle>
              <TextField
                variant="standard"
                size="medium"
                sx={{ width: "calc(100% - 90px)" }}
                value={mail.subject}
                onChange={(e) => changeSubject(e.target.value)}
              />
            </S.ElementContainer>
          </> :
          <>
            <S.Title>메일 쓰기</S.Title>
            <S.ButtonGroup>
              <CustomBackgroundColorButton text="보내기" onClick={sendMail} fontSize="0.9rem" style={{ padding: "3px 10px" }} />
              <CustomBackgroundColorButton text="임시저장" fontSize="0.9rem" onClick={() => devConsoleLog("임시저장")} style={{ padding: "3px 10px" }} />
              <CustomBackgroundColorButton text="미리보기" fontSize="0.9rem" onClick={() => devConsoleLog("미리보기")} style={{ padding: "3px 10px" }} />
            </S.ButtonGroup>
            <Divider />
            <S.ElementContainer>
              <S.ElementTitle>보내는 사람</S.ElementTitle>
              <div style={{ paddingRight: "10px" }}>{mail.senderNickname}</div>
              <div>{mail.sender}</div>
            </S.ElementContainer>
            <S.ElementContainer style={{ height: "auto" }}>
              <S.ElementTitle>
                받는 사람
                {
                  isRecipientFocused ? <KeyboardArrowUpIcon onClick={() => setIsRecipientFocused(false)} /> : <KeyboardArrowDownIcon onClick={() => setIsRecipientFocused(true)} />
                }
              </S.ElementTitle>
              <div style={{ width: "calc(100% - 110px)", display: "flex", flexDirection: "row", gap: "10px", alignItems: "center" }}>
                <C.RecipientInput type="recipients" />
                <CustomColorButton text="주소록" onClick={openAddressBook} style={{ border: `1px solid ${theme.colors.primary}`, width: "100px", height: "40px" }} />
              </div>
            </S.ElementContainer>
            {
              isRecipientFocused && <>
                <S.ElementContainer>
                  <S.ElementTitle>참조</S.ElementTitle>
                  <C.RecipientInput type="copyRecipients" />
                </S.ElementContainer>
                <S.ElementContainer>
                  <S.ElementTitle>숨은 참조</S.ElementTitle>
                  <C.RecipientInput type="hiddenRecipients" />
                </S.ElementContainer>
              </>
            }
            <S.ElementContainer>
              <S.ElementTitle>제목</S.ElementTitle>
              <TextField
                variant="standard"
                size="medium"
                sx={{ width: "calc(100% - 110px)" }}
                value={mail.subject}
                onChange={(e) => changeSubject(e.target.value)}
              />
            </S.ElementContainer>
          </>
      }
    </S.Container>
  );
}

export default MailController;
