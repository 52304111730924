import { List, styled } from "@mui/material";

export const CustomList = styled(List)({
  minWidth: "15vw",
  justifyContent: "start",
  display: "flex",
  flexDirection: "column",
  height: "100vh",
  padding: 0,
  margin: 0,

  // 태블릿
  "@media (max-width: 1024px)": {
    minWidth: "40vw",
  },

  // 모바일
  "@media (max-width: 768px)": {
    minWidth: "80vw",
  },
});
