import React from "react";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import { DatePicker } from "@mui/x-date-pickers";
import * as S from "./styled";
import dayjs from "dayjs";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useAtom, useSetAtom } from "jotai";
import { dateSelectorChangeDateAtom, dateSelectorChangeWeekOrMonthAtom, dateSelectorInitialSettingAtom, dateSelectorSelectDateAtom } from "@/ViewModels/Components/DateSelectorViewModel";
import { Node } from "@xyflow/react";
import CustomBackgroundColorButton from "@/Components/Common/Buttons/CustomBackgroundColor";
import { useTranslation } from "react-i18next";

interface Props {
  tasks: Node[];
}

const DateSelector = ({ tasks }: Props) => {
  const [dateSelector, dateSelectorInitialSetting] = useAtom(dateSelectorInitialSettingAtom);
  const changeDate = useSetAtom(dateSelectorChangeDateAtom);
  const selectDate = useSetAtom(dateSelectorSelectDateAtom);
  const setWeekOrMonth = useSetAtom(dateSelectorChangeWeekOrMonthAtom);
  const { t } = useTranslation();

  React.useEffect(() => {
    if (tasks.length > 0) {
      dateSelectorInitialSetting(tasks, t);
    }
    // eslint-disable-next-line
  }, [tasks]);

  return (
    <S.Container>
      <div style={{ display: "flex", flexDirection: "row", gap: "20px", alignItems: "center" }}>
        <ArrowBackIosNewOutlinedIcon
          onClick={() => !dateSelector.isPrevDisabled && changeDate(-1, t)}
          style={{ color: dateSelector.isPrevDisabled ? 'gray' : 'black', cursor: dateSelector.isPrevDisabled ? 'not-allowed' : 'pointer' }}
        />
        <DatePicker
          sx={{ width: "240px" }}
          value={dateSelector.currentDate}
          onChange={(newDate) => {
            if (newDate && newDate.isBetween(dateSelector.startDate, dateSelector.endDate, 'day', '[]')) {
              selectDate(newDate, t);
            }
          }}
          shouldDisableDate={(day) => day.isBefore(dateSelector.startDate) || day.isAfter(dateSelector.endDate)}
          slotProps={{
            textField: {
              variant: "standard",
              InputProps: {
                disableUnderline: true, // 밑줄 제거
                style: {
                  fontSize: '1rem', // 폰트 크기 설정
                  fontWeight: 'bold', // 폰트 굵기 설정
                },
                readOnly: true,
                value: dateSelector.renderedDate, // 현재 날짜 표시
              },
            },
          }}
          format="YYYY.MM.DD"
        />
        <ArrowForwardIosOutlinedIcon
          onClick={() => !dateSelector.isNextDisabled && changeDate(1, t)}
          style={{ color: dateSelector.isNextDisabled ? 'gray' : 'black', cursor: dateSelector.isNextDisabled ? 'not-allowed' : 'pointer' }}
        />
      </div>
      <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "20px" }}>
        <ToggleButtonGroup
          exclusive
          value={dateSelector.weekOrMonth}
          color="primary"
          sx={{ height: "40px" }}
        >
          <ToggleButton value={"week"} selected={dateSelector.weekOrMonth === "week"} onClick={() => setWeekOrMonth("week", t)}>{t("plan.contents.routine.dateSelector.week")}</ToggleButton>
          <ToggleButton value={"month"} selected={dateSelector.weekOrMonth === "month"} onClick={() => setWeekOrMonth("month", t)}>{t("plan.contents.routine.dateSelector.month")}</ToggleButton>
        </ToggleButtonGroup>
        {
          !dateSelector.currentDate.isSame(dayjs(), 'day') &&
          <CustomBackgroundColorButton onClick={() => selectDate(dayjs(), t)} text={t("plan.contents.routine.dateSelector.today")} fontSize="0.8rem" />
        }
      </div>
    </S.Container>
  );
};

export default DateSelector;
