import { crewLecturesAtom } from '@/Atoms/Crew';
import { ApplicationPeriodInfo } from '@/Atoms/Dialogs/Create/Course';
import deleteLectureDialogAtom from '@/Atoms/Dialogs/Delete/Lecture';
import CustomBackgroundColorButton from '@/Components/Common/Buttons/CustomBackgroundColor';
import CustomColorButton from '@/Components/Common/Buttons/CustomColor';
import GetIdFromQuerystring from '@/Utils/GetIdFromQuerystring';
import { deleteLectureAtom } from '@/ViewModels/Crew/LecturesViewModel';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import React from 'react';

const isAbleToDelete = (applicationPeriod: ApplicationPeriodInfo) => {
  const now = new Date();
  const endApplicationDate = new Date(applicationPeriod.end.date);
  return now <= endApplicationDate
};


const DeleteLectureDialog = () => {
  const [dialog, setDialog] = useAtom(deleteLectureDialogAtom);
  const innerTabId = GetIdFromQuerystring('inner_tab_id');
  const crewLectures = useAtomValue(crewLecturesAtom);
  const lectures = crewLectures.find((x) => x.innerTabId === innerTabId)?.lectures;
  const lecture = lectures?.find((x) => x.id === dialog);
  const deleteLecture = useSetAtom(deleteLectureAtom);

  return (
    dialog && lecture &&
    <Dialog
      open={dialog !== null}
      onClose={() => setDialog(null)}
    >
      <DialogTitle sx={{ fontSize: "0.9rem", fontWeight: "bold" }}>강좌 삭제</DialogTitle>
      <DialogContent>
        {
          isAbleToDelete(lecture.applicationPeriod) ?
            <div style={{ fontSize: "0.8rem" }}>정말로 강좌를 삭제하시겠습니까?</div> :
            <div style={{ fontSize: "0.8rem" }}>삭제 가능 기간이 지나 강좌를 삭제할 수 없습니다.</div>
        }
      </DialogContent>
      <DialogActions>
        {
          isAbleToDelete(lecture.applicationPeriod) ?
            <CustomColorButton fontSize="0.8rem" onClick={() => setDialog(null)} text="취소" /> :
            <CustomColorButton fontSize="0.8rem" onClick={() => setDialog(null)} text="확인" />
        }
        {
          isAbleToDelete(lecture.applicationPeriod) &&
          <CustomBackgroundColorButton
            fontSize="0.8rem"
            onClick={() => {
              setDialog(null);
              deleteLecture(lecture.id);
            }}
            text="삭제"
          />
        }
      </DialogActions>
    </Dialog>
  )
}

export default DeleteLectureDialog;
