import React from "react";
import { Stack, Typography } from "@mui/material";
import { Handle, Position } from "@xyflow/react";
import * as S from "./styled";
import ChatIcon from "@mui/icons-material/Chat";

export function TemplateNode({ data }: any) {
  return (
    <Stack
      sx={{
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        cursor: "pointer",
      }}
    >
      <S.CircleBox id={`${data.id}`}>
        <Handle
          type="target"
          position={Position.Left}
          id={`${data.id}-left`}
          className="handle-left"
        />
        <ChatIcon sx={{ color: "white", width: "100%", height: "80%" }} />
        <Typography sx={{ color: "black", fontSize: "11px" }}>
          {data.label}
        </Typography>
        <Handle
          type="source"
          position={Position.Right}
          id={`${data.id}-right`}
          className="handle-right"
        />
      </S.CircleBox>
    </Stack>
  );
}
