const convertRelativeTime = (date: Date) => {
  if (typeof date === 'string') {
    date = new Date(date);
  }

  // date가 유효한 Date 객체인지 확인
  if (!(date instanceof Date) || isNaN(date.getTime())) {
    return "알 수 없음";  // 유효하지 않은 날짜에 대한 기본값 처리
  }

  const now = new Date();
  const diff = now.getTime() - date.getTime();

  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);
  const years = Math.floor(months / 12);

  if (seconds < 60) {
    return `방금 전`;
  } else if (minutes < 60) {
    return `${minutes}분 전`;
  } else if (hours < 24) {
    return `${hours}시간 전`;
  } else if (days < 30) {
    return `${days}일 전`;
  } else if (months < 12) {
    return `${months}달 전`;
  } else {
    return `${years}년 전`;
  }
};

export default convertRelativeTime;
