import React from 'react';
import * as S from './styled';
import useIsMobile from '../../../../../Hooks/useIsMobile';
import TimelapseVideo from './video';
import { Button, Typography } from '@mui/material';
import theme from '../../../../../Styles/theme';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import storiesAtom from '@/Atoms/StoryAtom';
import { availableCountAtom, createTimelapseVideoAtom } from '@/ViewModels/TimelapseViewModel';
import { waitingModalAtom } from '@/Atoms/RootAtom';

const Timelapse = () => {
  const { isMobile, isTablet } = useIsMobile();
  const stories = useAtomValue(storiesAtom);
  const availableCount = useAtomValue(availableCountAtom);
  const createTimelapseVideo = useSetAtom(createTimelapseVideoAtom);
  const [waitingModal, setWaitingModal] = useAtom(waitingModalAtom);

  const handleCreateTimelapseVideo = () => {
    setWaitingModal({ state: true, text: "영상 제작 중입니다." });
    createTimelapseVideo();
  }

  return (
    <S.TimelapseContainer $isMobile={isMobile || isTablet}>
      <Typography variant="h6" sx={{ fontSize: "1.2rem", fontWeight: "bold", padding: "0 20px", width: "calc(100% - 40px)" }}>지금 이 순간</Typography>
      <div style={{ display: "flex", width: "calc(100% - 40px)", padding: "0 20px", justifyContent: "space-between", fontWeight: "600", fontSize: "1rem" }}>
        <div>{`제작 가능 횟수 ${availableCount} / 1`}</div>
        <Button variant="contained" sx={{ padding: "4px 16px", fontSize: "0.9rem", backgroundColor: theme.colors.primary }} onClick={handleCreateTimelapseVideo}>제작하기</Button>
      </div>
      {
        availableCount === 1 ? <TimelapseVideo /> : stories.length > 2 ? <div style={{ flexGrow: 1, display: "flex", justifyContent: "center", alignItems: "center", fontSize: "1.2rem", fontWeight: "bold", color: theme.colors.primary, width: "90%" }}>제작하기 버튼을 통해 당신의 이야기를 동영상으로 만들어보세요!</div> : <div style={{ flexGrow: 1, display: "flex", justifyContent: "center", alignItems: "center", fontSize: "1.2rem", fontWeight: "bold", color: theme.colors.primary, width: "90%" }}>{`영상 제작을 위해 스토리를 작성해주세요! ${stories.length} / 3`}</div>
      }
    </S.TimelapseContainer>
  );
}

export default Timelapse;
