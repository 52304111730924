import React from "react";
import {
  Divider,
  IconButton,
  Stack,
} from "@mui/material";
import * as S from "../styled";
import { useAtomValue, useSetAtom } from "jotai";
import { Settings } from "@mui/icons-material";
import teacherDashboardAtom from "@/Atoms/Dashboard/TeacherAtom";
import { useTranslation } from "react-i18next";
import { openSignatureDialogAtom } from "@/ViewModels/Signature/ViewModel";
import SignatureCard from "@/Components/Signature/Contents/Card";

const DashboardSignatures = () => {
  const openSignature = useSetAtom(openSignatureDialogAtom);
  const signatures = useAtomValue(teacherDashboardAtom).signatures;
  const { t } = useTranslation();

  return (
    <S.CustomBox>
      <Stack
        direction={"row"}
        sx={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <S.TitleTypo>{t("dashboard.teacher.signature.title")}</S.TitleTypo>
        {/* 나중에는 시그니쳐를 관리하는 부스터로의 이동이 필요 */}
        <IconButton onClick={openSignature}>
          <Settings></Settings>
        </IconButton>
      </Stack>

      <Divider sx={{ margin: "10px 0" }} />
      <S.DescTypo>{t("dashboard.teacher.signature.description")}</S.DescTypo>
      <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: "10px", height: "calc(100% - 80px)", overflowY: "auto" }}>
        {signatures.map((signature, index) => <SignatureCard key={index} signature={signature} isHalf={true} />)}
        <div style={{ width: "430px", minHeight: "30px" }} />
      </div>
    </S.CustomBox>
  );
}

export default DashboardSignatures;
