const convertToEnglishNumbering = (num: number, language: string) => {
  if (language === "KR") return num;
  const returnNum = num % 10;
  if (returnNum === 1) return `${num}st`;
  else if (returnNum === 2) return `${num}nd`;
  else if (returnNum === 3) return `${num}rd`;
  else return `${num}th`;
}

export default convertToEnglishNumbering;
