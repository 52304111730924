import { useQuery } from "react-query";
import {
  DokgabiFlowProps,
  DokgabiFrontElementProps,
} from "../Models/DokgabiFlow";
import { ChattingProps } from "../Components/Helper/Balloon";
import { handleReactQueryApiResponse } from "@/Utils/APIUtil";
import { useSetAtom } from "jotai";
import { error401ModalAtom } from "@/Atoms/Dialogs/Error/401Atom";
import { devConsoleLog } from "@/Utils/ConsoleLogInDevelopment";

async function getMyFlow(access: string, id: string): Promise<DokgabiFlowProps[] | null> {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json", // JSON 형식으로 지정
      Authorization: `Bearer ${access}`,
    },
    body: JSON.stringify({
      chatbot_type: id,
    }),
  };

  const response = await fetch(
    `${process.env.REACT_APP_DOKGABI_API_ADDRESS}/getChatbotTypeFlow`,
    requestOptions
  );
  if (!response.ok) {
    return null;
  }
  const result = await response.json();

  return result.results;
}

export function useGetFlowDataQuery(myId: string) {
  const setError401Modal = useSetAtom(error401ModalAtom);
  return useQuery({
    queryKey: [`${myId}-flow`],
    queryFn: async () => await handleReactQueryApiResponse(getMyFlow, () => setError401Modal(true), myId) as Promise<DokgabiFlowProps[] | null>,
  });
}

export async function requestChatbot(
  access: string,
  input_message: string,
  user_id: string,
  flow_socket_name: string,
  character: string,
  handleStreamMessage: (
    message: string,
    isFirst: boolean,
    isNotStream: boolean,
    flow_socket_name: string
  ) => void,
  handleJson: (data: any) => void
): Promise<any> {
  const requestBody = {
    character_name: character,
    flow: flow_socket_name,
    message: input_message,
    user_id: user_id,
  };
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${access}`,
    },
    body: JSON.stringify(requestBody),
  };

  try {
    const response = await fetch(
      `${process.env.REACT_APP_DOKGABI_API_ADDRESS}/makeChatbotResponseWithCharacter`,
      requestOptions
    );

    if (response.status === 403) {
      return response;
    }

    const reader = response.body?.getReader();
    const decoder = new TextDecoder();
    let isFirst = true;
    let message = "";

    while (true) {
      const { done, value } = await reader!.read();

      if (done) {
        // 여기에 음성을 출력하는 기능 추가
        break;
      }

      const chunk = decoder.decode(value, { stream: true });

      if (chunk.startsWith("JSON:")) {
        // JSON 문자열 파싱 시도
        const json = JSON.parse(chunk.slice(5));
        handleJson(json);
      } else {
        message += chunk;
        handleStreamMessage(message, isFirst, false, flow_socket_name);

        isFirst = false;
      }
    }
  } catch (error) {
    // return ResponseStatus.ERROR;
  }
}

// export async function requestChatbot(
//   access: string,
//   input_message: string,
//   flow_socket_name: string,
//   user_id: string,
//   handleStreamMessage: (
//     message: string,
//     isFirst: boolean,
//     isNotStream: boolean,
//     flow_socket_name: string
//   ) => void
// ): Promise<void> {
//   const requestOptions = {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: `Bearer ${access}`,
//     },
//     body: JSON.stringify({
//       flow: flow_socket_name,
//       message: input_message,
//       user_id: user_id,
//     }),
//   };

//   try {
//     const response = await fetch(
//       `${process.env.REACT_APP_DOKGABI_API_ADDRESS}/makeChatbotResponse`,
//       requestOptions
//     );

//     if (!response.ok) {
//       throw new Error("응답을 불러오지 못했습니다.");
//     }

//     // 스트리밍 응답인지 아닌지 확인
//     if (response.body) {
//       // 스트리밍 응답 처리
//       const reader = response.body.getReader();
//       const decoder = new TextDecoder();
//       let isFirst = true;
//       let message = "";

//       while (true) {
//         const { done, value } = await reader.read();
//         if (done) {
//           isFirst = true;
//           break;
//         }

//         const chunk = decoder.decode(value, { stream: true });
//         message += chunk;
//         handleStreamMessage(message, isFirst, false, flow_socket_name); // 스트리밍이므로 isNotStream = false
//         isFirst = false;
//       }
//     } else {
//       // 일반 응답 처리 (스트리밍이 아닌 경우)
//       const jsonResponse = await response.json();
//       const message = JSON.stringify(jsonResponse); // JSON 구조를 문자열로 변환
//       handleStreamMessage(message, true, true, flow_socket_name); // 스트리밍이 아니므로 isNotStream = true
//     }
//   } catch (error) { }
// }

async function getMyChatbotFrontElement(
  access: string,
  id: number
): Promise<DokgabiFrontElementProps[] | null> {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json", // JSON 형식으로 지정
      Authorization: `Bearer ${access}`,
    },
    body: JSON.stringify({
      id: id,
    }),
  };

  const response = await fetch(
    `${process.env.REACT_APP_DOKGABI_API_ADDRESS}/getMyChatbotFrontElement`,
    requestOptions
  );
  if (!response.ok) {
    return null;
  }
  const result = await response.json();

  return result.results;
}

export function useGetMyChatbotFrontElement(myId: number) {
  const setError401Modal = useSetAtom(error401ModalAtom);

  return useQuery({
    queryKey: [`${myId}-front-element`],
    queryFn: () => handleReactQueryApiResponse(getMyChatbotFrontElement, () => setError401Modal(true), myId),
  });
}

export async function getChatbotHistory(
  access: string,
  flow_id: number,
  user_id: string,
  numpage: number
): Promise<ChattingProps[] | null> {
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json", // JSON 형식으로 지정
    },
    body: JSON.stringify({
      flow_id: flow_id,
      user_id: user_id,
      numpage: numpage,
    }),
  };

  const response = await fetch(
    `${process.env.REACT_APP_DOKGABI_API_ADDRESS}/getChatHistory`,
    requestOptions
  );
  if (!response.ok) {
    return null;
  }
  const result = await response.json();
  return result.results;
}
