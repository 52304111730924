import React from 'react';
import { Dialog, DialogTitle, Button, TextField, ToggleButtonGroup, ToggleButton, DialogActions, DialogContent, Autocomplete } from '@mui/material';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import createRoutineDialogAtom from '@/Atoms/Dialogs/Create/Routine';
import { createRoutineDialogAddTaskAtom, createRoutineDialogCreateRoutineAtom, createRoutineDialogUpdateAtom } from '@/ViewModels/Dialogs/Create/RoutineViewModel';
import { defaultInnerTabNodesAtom, nodesAtom } from '@/Atoms/Plan/MindmapAtom';
import theme from '@/Styles/theme';
import CustomColorButton from '@/Components/Common/Buttons/CustomColor';
import CustomBackgroundColorButton from '@/Components/Common/Buttons/CustomBackgroundColor';


const CreateRoutineDialog = () => {
  const [dialog, setDialog] = useAtom(createRoutineDialogAtom);
  const updateState = useSetAtom(createRoutineDialogUpdateAtom);
  const addTask = useSetAtom(createRoutineDialogAddTaskAtom);
  const createRoutine = useSetAtom(createRoutineDialogCreateRoutineAtom);
  // const groupNodes = useAtomValue(defaultInnerTabNodesAtom).filter((node) => node.type === "group");
  // const nonGroupNodes = useAtomValue(defaultInnerTabNodesAtom).filter((node) => node.type !== "group");
  const groupNodes = useAtomValue(nodesAtom).filter((node) => node.type === "group");
  const nonGroupNodes = useAtomValue(nodesAtom).filter((node) => node.type !== "group");

  return (
    dialog &&
    <Dialog
      open={dialog !== null}
      onClose={() => setDialog(null)}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>루틴 생성</DialogTitle>
      <DialogContent sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        <TextField
          label="루틴명"
          variant="standard"
          fullWidth
          value={dialog.dialogTitle}
          onChange={(e) => updateState("dialogTitle", e.target.value)}
        />
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
          <div style={{ fontSize: "1rem", fontWeight: "bold" }}>보기 설정</div>
          <ToggleButtonGroup
            exclusive
            value={dialog.viewType}
            color="primary"
          >
            <ToggleButton value="routine" selected={dialog.viewType === "routine"} onClick={() => updateState("viewType", "routine")}>일정</ToggleButton>
            {/* <ToggleButton value="custom" selected={dialog.viewType === "custom"} onClick={() => updateState("viewType", "custom")}>커스텀</ToggleButton> */}
            <ToggleButton value="gantt" selected={dialog.viewType === "gantt"} onClick={() => updateState("viewType", "gantt")}>타임라인</ToggleButton>
          </ToggleButtonGroup>
        </div>
        <Autocomplete
          options={nonGroupNodes.filter((node) => !dialog.selectedTasks.map((task) => task.id).includes(node.id))}
          getOptionLabel={(option) => option.data.label as string}
          renderOption={(props, option) => (
            <li {...props} key={option.id}>
              {option.data.label as string}
            </li>
          )}
          value={dialog.currentSelectedTask || null}
          // groupBy={(option) => option.parentId as string}
          renderInput={(params) => <TextField {...params} label="할 일 선택" />}
          onChange={(event, value) => updateState("currentSelectedTask", value)}
        />
        {
          dialog.currentSelectedTask &&
          <CustomColorButton onClick={addTask} text="추가" fontSize="0.9rem" />
        }
        {
          dialog.selectedTasks.length > 0 &&
          <>
            <div style={{ fontSize: "1rem", fontWeight: "bold" }}>할 일 목록</div>
            <div style={{ display: "flex", flexDirection: "row", gap: "10px", flexWrap: "wrap" }}>
              {
                dialog.selectedTasks.map((task) => (
                  <div key={task.id} style={{ backgroundColor: theme.colors.primary, color: "white", padding: "5px 10px", borderRadius: "5px", fontWeight: "600", whiteSpace: "nowrap" }}>{task.data.label as string}</div>
                ))
              }
            </div>
          </>
        }
      </DialogContent>
      <DialogActions>
        <CustomColorButton onClick={() => setDialog(null)} text="취소" fontSize="0.8rem" />
        <CustomBackgroundColorButton onClick={createRoutine} text="생성" fontSize="0.8rem" />
      </DialogActions>
    </Dialog>
  );
};

export default CreateRoutineDialog;
