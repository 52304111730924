import { atom } from 'jotai';
import { getUserId, getUserImage } from '../UserViewModel';
import { uploadUserImage } from '@/Queries/UserQueries';
import snackbarAtom from '@/Atoms/Snackbar';
import { userAtom } from '@/Atoms/UserAtom';
import { TFunction } from 'i18next';
import { handleReactQueryApiResponseWithJson } from '@/Utils/APIUtil';
import { error401ModalAtom } from '@/Atoms/Dialogs/Error/401Atom';

// 유저의 프로필 이미지를 업데이트하는 atom
export const settingsUpdateUserImageAtom = atom((get) => get(getUserImage), async (get, set, event: React.ChangeEvent<HTMLInputElement>, t: TFunction) => {
  if (event.target.files) {
    const file = event.target.files[0];
    if (file) {
      const response = await handleReactQueryApiResponseWithJson(uploadUserImage, () => set(error401ModalAtom, true), get(getUserId), file);
      if (!response) {
        set(snackbarAtom, (prev) => ({ ...prev, open: true, message: "이미지 업로드에 실패했습니다." }));
        return;
      }
      set(userAtom, (current) => current && { ...current, image: response.file_path });
    }
  }
});
