import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  // height: calc(100vh - 350px);
  flex-grow: 1;
  min-height: 300px;
`;
