import { CreateSignature } from '@/Atoms/Signature/Atom';
import { getUserEmailAtom, getUserImage } from '@/ViewModels/UserViewModel';
import { useAtomValue, useSetAtom } from 'jotai';
import React from 'react';
import * as S from './styled';
import { useTranslation } from 'react-i18next';
import { changeModeSignatureDialogAtom } from '@/ViewModels/Signature/ViewModel';
import theme from '@/Styles/theme';
import VerifiedIcon from '@mui/icons-material/Verified';
import SchoolIcon from '@mui/icons-material/School';

interface SignatureCardProps {
  signature: CreateSignature;
}

const SignatureCardPreview = ({ signature }: SignatureCardProps) => {
  const { t } = useTranslation();
  const userImage = useAtomValue(getUserImage);
  const userEmail = useAtomValue(getUserEmailAtom);
  const sortedInfo = (signature.orderList).filter((i) => i.visible);
  const [hovered, setHovered] = React.useState(false);
  const changeMode = useSetAtom(changeModeSignatureDialogAtom);

  return (
    <S.SignatureContainer $backgroundColor={signature.backgroundColor} $borderColor={signature.borderColor} $fontColor={signature.fontColor} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)} onClick={() => changeMode("style")} >
      {
        hovered &&
        <S.HoverOverlay>
          {t("signature.style.editStyle")}
        </S.HoverOverlay>
      }
      <S.SignatureProfileContainer>
        <S.SignatureImage
          src={
            signature.isUserProfile
              ? userImage
              : process.env.REACT_APP_CAREER_PLANNER_USER_MEDIA_ADDRESS + signature.previewImage || userImage
          }
          alt="signature"
        />
        <S.SignatureProfileInfo>
          <S.GridItem style={{ padding: 0 }}>
            <S.ItemTitle style={{ fontSize: "0.6rem" }}>{signature.name}</S.ItemTitle>
            <div style={{ display: "flex", flexDirection: "row", gap: "5px", alignItems: "center" }}>
              {
                signature.representative &&
                <VerifiedIcon sx={{ fontSize: "0.6rem", color: theme.colors.primary }} />
              }
              {
                signature.careerAdvancement &&
                <SchoolIcon sx={{ fontSize: "0.6rem", color: theme.colors.primary }} />
              }
              {
                signature.tag &&
                <S.ItemTitle style={{ fontSize: "0.5rem" }}>
                  {signature.tag}
                </S.ItemTitle>
              }
            </div>
          </S.GridItem>
          <S.ItemTitle style={{ display: "flex", fontSize: "0.6rem", justifyContent: "flex-end" }}>{signature.nickname}</S.ItemTitle>
          <S.ItemValue style={{ display: "flex", justifyContent: "flex-end" }}>{userEmail}</S.ItemValue>
        </S.SignatureProfileInfo>
      </S.SignatureProfileContainer>
      <S.SignatureGrid $height="50%">
        {sortedInfo.slice(0, 4).map((item, index) => (
          <S.GridItem key={index}>
            <S.ItemTitle style={{ minWidth: "40px", maxWidth: "40px" }}>{item.label}</S.ItemTitle>
            <S.ItemValue style={{ minWidth: "50px", maxWidth: "50px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{Array.isArray(item.value) ? item.value.join(", ") : item.value}</S.ItemValue>
          </S.GridItem>
        ))}
      </S.SignatureGrid>
    </S.SignatureContainer>
  )
}

export default SignatureCardPreview;
