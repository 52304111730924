import { Tab } from "@mui/material";
import styled from "styled-components";
import { styled as muiStyled } from "@mui/material/styles";
import { border, lighten } from "polished";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

export const CustomTab = muiStyled(Tab)(({ theme }) => ({
  color: 'gray', // default color
  borderBottom: `2px solid transparent`, // default border
  '&.Mui-selected': {
    color: '#06C25E', // active color
    fontWeight: 'bold', // additional styling for selected tab
    borderBottom: `2px solid #06C25E`, // active border
  },
  '&:hover': {
    color: lighten(0.1, "#06C25E"), // hover color
    backgroundColor: theme.palette.action.hover,
  },
}));
