import React from 'react';
import * as S from './styled';
import * as C from './containers';
import { accordionSidebarAtom } from '@/Atoms/SidebarAtom';
import { useAtomValue, useSetAtom } from 'jotai';
import theme from '@/Styles/theme';
import { v4 as uuidv4 } from 'uuid';
import { createTaskDialogAtom } from '@/Atoms/Dialogs/Create/Task';
import { nodesAtom } from '@/Atoms/Plan/MindmapAtom';
import { createGroupNodeInCustomRoutineAtom } from '@/ViewModels/Plan/Mindmap/GroupViewModel';
import { getTextColorBasedOnBackground } from '@/Utils/GetRandomColor';
import CustomBackgroundColorButton from '@/Components/Common/Buttons/CustomBackgroundColor';
import CreateTaskInGroupDialog from '../../Dialogs/CreateTaskInGroup';
import { createTaskInGroupDialogAtom } from '@/Atoms/Dialogs/Create/TaskInGroup';
import {
  DndContext,
  DragEndEvent,
  DragMoveEvent,
  DragStartEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  UniqueIdentifier,
  closestCorners,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import SortableItem from './sortableItem';
import { useTranslation } from 'react-i18next';
import { devConsoleLog } from '@/Utils/ConsoleLogInDevelopment';

const Custom = () => {
  const { t } = useTranslation();
  const sidebar = useAtomValue(accordionSidebarAtom);
  const [maxHeight, setMaxHeight] = React.useState((window.innerHeight - 120) * 0.8);
  const setCreateTaskDialog = useSetAtom(createTaskDialogAtom);
  const setCreateTaskInGroupDialog = useSetAtom(createTaskInGroupDialogAtom);
  const nodes = useAtomValue(nodesAtom);
  const ungroupedNodes = nodes.filter((node) => !node.parentId && node.type !== "group");
  const groupedNodes = nodes.filter((node) => node.parentId && node.type !== "group");
  const groups = nodes.filter((node) => node.type === "group");
  const createGroupNode = useSetAtom(createGroupNodeInCustomRoutineAtom);
  const [activeId, setActiveId] = React.useState<UniqueIdentifier | null>(null);

  const sensors = useSensors(
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
    useSensor(MouseSensor, { activationConstraint: { distance: 10 } })
  );

  const handleCreateGroupNode = () => {
    // Transform the screen center position to the flow position
    const screenCenter = {
      x: 0,
      y: 0,
    };

    // Pass the flow position to the createGroupNode function
    createGroupNode(screenCenter);
  };


  // x축으로 스크롤 가능하게 만들기 위한 코드
  const [isYScrollActive, setIsYScrollActive] = React.useState(false);
  const handleWheel = (e: React.WheelEvent<HTMLDivElement>) => {
    if (isYScrollActive) {
      e.currentTarget.scrollTop += e.deltaY;
    } else {
      e.currentTarget.scrollLeft += e.deltaY;
    }
  };

  function handleDragStart(event: DragStartEvent) {
    const { active } = event;
    const { id } = active;
    setActiveId(id);
    devConsoleLog("activeId", id);
  }

  const handleDragMove = (event: DragMoveEvent) => {
    const { active, over } = event;
    if (!over) return;

    devConsoleLog("active", active);
    devConsoleLog("over", over);
    // // Handling task moving between groups and within the same group
    // if (active.id.toString().includes('task') && over.id.toString().includes('task')) {
    //   const activeGroup = customGroups.find(group =>
    //     group.tasks.some(task => task.customTaskId === active.id)
    //   );
    //   const overGroup = customGroups.find(group =>
    //     group.tasks.some(task => task.customTaskId === over.id)
    //   );

    //   if (activeGroup && overGroup) {
    //     const activeTaskIndex = activeGroup.tasks.findIndex(task => task.customTaskId === active.id);
    //     const overTaskIndex = overGroup.tasks.findIndex(task => task.customTaskId === over.id);
    //     const movedTask = activeGroup.tasks[activeTaskIndex];

    //     // If the task is moved within the same group
    //     if (activeGroup === overGroup) {
    //       const updatedTasks = arrayMove(activeGroup.tasks, activeTaskIndex, overTaskIndex);
    //       const updatedGroups = customGroups.map(group => {
    //         if (group.customId === activeGroup.customId) {
    //           return { ...group, tasks: updatedTasks };
    //         }
    //         return group;
    //       });
    //       setCustomGroups(updatedGroups);

    //       // If the task is moved to a different group
    //     } else {
    //       const updatedTask = { ...movedTask, customGroupId: overGroup.id };

    //       const updatedGroups = customGroups.map(group => {
    //         if (group.customId === activeGroup.customId) {
    //           return { ...group, tasks: group.tasks.filter(task => task.customTaskId !== active.id) };
    //         }
    //         if (group.customId === overGroup.customId) {
    //           return { ...group, tasks: [...group.tasks.slice(0, overTaskIndex), updatedTask, ...group.tasks.slice(overTaskIndex)] };
    //         }
    //         return group;
    //       });

    //       setCustomGroups(updatedGroups);
    //     }
    //   }
  }

  //   // Handling task moving between a group and a group directly (not task to task)
  //   if (active.id.toString().includes('task') && over.id.toString().includes('group')) {
  //     const activeGroup = customGroups.find(group =>
  //       group.tasks.some(task => task.customTaskId === active.id)
  //     );
  //     const overGroup = customGroups.find(group => group.customId === over.id);

  //     if (activeGroup && overGroup && activeGroup !== overGroup) {
  //       const activeTaskIndex = activeGroup.tasks.findIndex(task => task.customTaskId === active.id);
  //       const movedTask = activeGroup.tasks[activeTaskIndex];

  //       const updatedTask = { ...movedTask, customGroupId: overGroup.id };

  //       // Remove task from old group and add to new group
  //       const updatedGroups = customGroups.map(group => {
  //         if (group === activeGroup) {
  //           return { ...group, tasks: group.tasks.filter(task => task.customTaskId !== active.id) };
  //         }
  //         if (group === overGroup) {
  //           return { ...group, tasks: [...group.tasks, updatedTask] };
  //         }
  //         return group;
  //       });

  //       setCustomGroups(updatedGroups);
  //     }
  //   }
  // };


  // const handleDragEnd = (event: DragEndEvent) => {
  //   const { active, over } = event;
  //   if (!over || active.id === over.id) return;

  //   // Handle container (group) sorting
  //   if (active.id.toString().includes('group') && over.id.toString().includes('group')) {
  //     const activeIndex = customGroups.findIndex(group => group.customId === active.id);
  //     const overIndex = customGroups.findIndex(group => group.customId === over.id);

  //     if (activeIndex !== -1 && overIndex !== -1) {
  //       const updatedGroups = arrayMove(customGroups, activeIndex, overIndex);
  //       setCustomGroups(updatedGroups);
  //     }
  //   }

  //   // Handle task sorting within the same group
  //   if (active.id.toString().includes('task') && over.id.toString().includes('task')) {
  //     const activeGroup = customGroups.find(group => group.tasks.some(task => task.customTaskId === active.id));
  //     const overGroup = customGroups.find(group => group.tasks.some(task => task.customTaskId === over.id));

  //     if (activeGroup && overGroup && activeGroup === overGroup) {
  //       const activeTaskIndex = activeGroup.tasks.findIndex(task => task.customTaskId === active.id);
  //       const overTaskIndex = overGroup.tasks.findIndex(task => task.customTaskId === over.id);

  //       const updatedTasks = arrayMove(activeGroup.tasks, activeTaskIndex, overTaskIndex);
  //       const updatedGroups = customGroups.map(group => {
  //         if (group.customId === activeGroup.customId) {
  //           return { ...group, tasks: updatedTasks };
  //         }
  //         return group;
  //       });

  //       setCustomGroups(updatedGroups);
  //     }
  //   }

  //   // Handle task moving between different groups
  //   if (active.id.toString().includes('task') && over.id.toString().includes('group')) {
  //     const activeGroup = customGroups.find(group => group.tasks.some(task => task.customTaskId === active.id));
  //     const overGroup = customGroups.find(group => group.customId === over.id);

  //     if (activeGroup && overGroup && activeGroup !== overGroup) {
  //       const activeTaskIndex = activeGroup.tasks.findIndex(task => task.customTaskId === active.id);
  //       const movedTask = activeGroup.tasks[activeTaskIndex];

  //       // Update the task to belong to the new group
  //       const updatedTask = { ...movedTask, customGroupId: overGroup.id };

  //       // Remove task from old group and add to new group
  //       const updatedGroups = customGroups.map(group => {
  //         if (group.customId === activeGroup.customId) {
  //           return { ...group, tasks: group.tasks.filter(task => task.customTaskId !== active.id) };
  //         }
  //         if (group.customId === overGroup.customId) {
  //           return { ...group, tasks: [...group.tasks, updatedTask] };
  //         }
  //         return group;
  //       });

  //       setCustomGroups(updatedGroups);
  //     }
  //   }

  //   setActiveId(null);
  // };

  React.useEffect(() => {
    window.addEventListener('resize', () => {
      setMaxHeight((window.innerHeight - 120) * 0.8);
    });
  }, []);

  return (
    <S.Container>
      <CustomBackgroundColorButton onClick={handleCreateGroupNode} text={t("plan.contents.custom.addGroup")} style={{ width: "150px" }} />
      <DndContext
        sensors={sensors}
        collisionDetection={closestCorners}
        onDragStart={handleDragStart}
        onDragMove={handleDragMove}
      >
        <S.InnerContainer
          style={{ width: sidebar ? "calc(100% - 270px)" : "calc(100% - 70px)", minWidth: "800px" }}
          onWheel={handleWheel}
        >
          <SortableContext key={"unclassified"} items={ungroupedNodes.map(task => task.id)}>

            <S.KanbanList
              $backgroundColor={theme.colors.customPurple + "CC"}
              style={{ color: "white", maxHeight: maxHeight }}
            >
              <S.KanbanInnerList
                onMouseEnter={() => setIsYScrollActive(true)}
                onMouseLeave={() => setIsYScrollActive(false)}
              >
                <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: "row", fontWeight: "bold" }}>
                  {t("plan.contents.custom.unclassified")}
                </div>
                <S.AddTaskButton
                  onClick={() => setCreateTaskDialog((prev) => ({ ...prev, open: `task-${uuidv4()}` }))}
                >
                  {t("plan.contents.custom.addTodo")}
                </S.AddTaskButton>
                {ungroupedNodes.map((task) => (
                  <SortableItem key={task.id} id={task.id}>
                    <C.Item key={task.id} task={task} setIsYScrollActive={setIsYScrollActive} date={null} />
                  </SortableItem>
                ))}
              </S.KanbanInnerList>
            </S.KanbanList>
          </SortableContext>
          {groups.map((group, index) =>
            <SortableContext items={groupedNodes.filter((task) => task.parentId === group.id).map(task => task.id)}>
              <S.KanbanList
                key={`${group.id}-${index}`}
                $backgroundColor={group.style?.background as string}
                style={{ maxHeight: maxHeight, color: `${getTextColorBasedOnBackground(group.style?.background as string)}` }}
              >
                <S.KanbanInnerList
                  id={group.id}
                  onMouseEnter={() => setIsYScrollActive(true)}
                  onMouseLeave={() => setIsYScrollActive(false)}
                >
                  <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: "row", fontWeight: "bold" }}>
                    {group.data.label as string}
                  </div>
                  <S.AddTaskButton
                    onClick={() => setCreateTaskInGroupDialog((prev) => ({ ...prev, open: `task-${uuidv4()}`, groupId: group.id }))}
                  >
                    {t("plan.contents.custom.addTodo")}
                  </S.AddTaskButton>
                  {
                    groupedNodes.filter((task) => task.parentId === group.id).map((task) => (
                      <SortableItem key={task.id} id={task.id}>
                        <C.Item key={task.id} task={task} setIsYScrollActive={setIsYScrollActive} date={null} />
                      </SortableItem>
                    ))
                  }
                </S.KanbanInnerList>
              </S.KanbanList>
            </SortableContext>
          )}
        </S.InnerContainer>
      </DndContext>
    </S.Container>
  )
}

export default Custom;
