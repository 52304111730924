import theme from '@/Styles/theme';
import { getTextColorBasedOnBackground } from '@/Utils/GetRandomColor';
import { Button } from '@mui/material';
import { lighten } from 'polished';
import React from 'react';

interface CustomBackgroundColorButtonProps {
  text: string;
  onClick: () => void;
  backgroundColor?: string;
  fontSize?: string;
  fontWeight?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
}

const CustomBackgroundColorButton = ({ text, onClick, backgroundColor = theme.colors.primary, fontSize = "1rem", fontWeight = "600", style = {}, disabled = false }: CustomBackgroundColorButtonProps) => {
  return (
    <Button
      sx={{
        backgroundColor: disabled ? "#cccccc" : backgroundColor, // 비활성화 시 회색 배경
        color: disabled ? "#ffffff" : getTextColorBasedOnBackground(backgroundColor), // 비활성화 시 흰색 텍스트
        fontSize,
        fontWeight,
        "&:hover": {
          backgroundColor: disabled ? "#cccccc" : lighten(0.1, backgroundColor), // 비활성화 시 hover도 회색 유지
        },
        pointerEvents: disabled ? "none" : "auto", // 비활성화 시 클릭 불가
        ...style,
      }}
      onClick={onClick}
      disabled={disabled}
      tabIndex={-1}
    >
      {text}
    </Button>
  );
}

export default CustomBackgroundColorButton;
