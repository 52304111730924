import React from "react";
import * as S from "./styled";
import { useSetAtom } from "jotai";
import { Divider } from "@mui/material";
import CrewInformation from "@/Components/Crew/Contents/Infomation";
import CrewTopSection from "@/Components/Crew/Contents/TopSection";
import useCrew from "@/Hooks/useCrew";
import { waitingModalAtom } from "@/Atoms/RootAtom";
import CreateTaskDialog from "@/Components/Plan/Dialogs/CreateTask";
import usePlanHip from "@/Hooks/usePlanHip";
import CrewTasks from "@/Components/Crew/Contents/Tasks";
import TaskPosting from "@/Components/Crew/Contents/TaskPosting";
import EditTaskDialog from "@/Components/Plan/Dialogs/EditTask";
import { useCrewAtom } from "@/Atoms/HookControllerAtom";
import { useLocation } from "react-router-dom";
import CreateLectureDialog from "@/Components/Crew/Dialogs/Create/Lecture";
import useLecture from "@/Hooks/useLecture";
import EditLectureDialog from "@/Components/Crew/Dialogs/Edit/Lecture";
import DeleteLectureDialog from "@/Components/Crew/Dialogs/Delete/Lecture";
import ApplyLectureDialog from "@/Components/Crew/Dialogs/Apply/Lecture";
import CancelLectureDialog from "@/Components/Crew/Dialogs/Cancel/Lecture";
import { useTranslation } from "react-i18next";
import FeedDetailDialog from "@/Components/Crew/Dialogs/FeedDetail";

const Dialogs = () => {
  return (
    <>
      <CreateTaskDialog />
      <EditTaskDialog />
      <CreateLectureDialog />
      <EditLectureDialog />
      <DeleteLectureDialog />
      <ApplyLectureDialog />
      <CancelLectureDialog />
      <FeedDetailDialog />
    </>
  )
}


const Crew = () => {
  const { isLoading: isCrewLoading } = useCrew();
  const { isLoading: isPlanHipLoading } = usePlanHip();
  const { isLoading: isCrewLectureLoading } = useLecture();
  const setWaitingModal = useSetAtom(waitingModalAtom);
  const setController = useSetAtom(useCrewAtom);
  const location = useLocation();
  const { t } = useTranslation();

  React.useEffect(() => {
    setController(true);
  }, [location.pathname, setController]);

  React.useEffect(() => {
    setWaitingModal({
      state: isCrewLoading || isPlanHipLoading || isCrewLectureLoading,
      text: t("dialogs.common.waiting.loading"),
    });
  }, [isCrewLoading, isPlanHipLoading, isCrewLectureLoading, t, setWaitingModal]);

  return (
    <S.Container>
      <S.ContentContainer>
        <CrewTopSection />
        <CrewInformation />
        <TaskPosting />
        <Divider />
        <CrewTasks />
      </S.ContentContainer>
      <Dialogs />
    </S.Container>
  );
}

export default Crew;
