import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { UniqueIdentifier } from '@dnd-kit/core';
import { Node } from '@xyflow/react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import dayjs from 'dayjs';
import { Box, IconButton, LinearProgress, Menu, MenuItem, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import theme from '@/Styles/theme';
import { languageAtom, LocationProps } from '@/Atoms/RootAtom';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { updateTaskLocationOnDefaultRoutineAtom, updateTaskOnDefaultRoutineAtom } from '@/ViewModels/Plan/TaskViewModel';
import { editRoutineIntervalsDialogAtom } from '@/Atoms/Dialogs/Edit/RoutineOption';
import { togetherDialogAtom } from '@/Atoms/Dialogs/Together';
import { editDefaultRoutineOptionOpenAtom } from '@/ViewModels/Dialogs/Edit/DefaultRoutineOptionViewModel';
import getRemainingDays from '@/Utils/GetRemainingDays';
import getProgressPercentage from '@/Utils/GetProgressPercentage';
import CustomBackgroundColorButton from '@/Components/Common/Buttons/CustomBackgroundColor';
import CustomColorButton from '@/Components/Common/Buttons/CustomColor';
import * as S from './styled';
import { addHashtagNodeAtom, removeHashtagNodeAtom, updateTaskStatusNodeAtom } from '@/ViewModels/Plan/Mindmap/JobNodeViewModel';
import getRandomColorWithText from '@/Utils/GetRandomColor';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import snackbarAtom from '@/Atoms/Snackbar';
import { editTaskDialogLoadDataAtom } from '@/ViewModels/Dialogs/Edit/TaskViewModel';
import { deleteTaskAtom } from '@/ViewModels/Plan/Mindmap/ViewModel';
import { handleExpandedRoutineDataAtom } from '@/ViewModels/Plan/RoutineViewModel';
import TextMarkdown from '@/Components/Common/Markdown';
import { useTranslation } from 'react-i18next';
import { textEditorOpenAtom } from '@/ViewModels/Dialogs/TextEditorViewModel';
// import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
// import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

// dayjs.extend(isSameOrBefore);
// dayjs.extend(isSameOrAfter);

interface SortableItemProps {
  id: UniqueIdentifier;
  children: React.ReactNode;
}

export const SortableItem = ({ id, children }: SortableItemProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {children}
    </div>
  );
};

interface ItemProps {
  task: Node;
  date: string | null;
  setIsYScrollActive: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Item = ({ task, date, setIsYScrollActive }: ItemProps) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  // const [expanded, setExpanded] = React.useState(false);
  const [expanded, setExpanded] = useAtom(handleExpandedRoutineDataAtom);
  const setSnackbar = useSetAtom(snackbarAtom);
  const updateTask = useSetAtom(updateTaskOnDefaultRoutineAtom);
  const openEditDialog = useSetAtom(editDefaultRoutineOptionOpenAtom);
  const updateLocation = useSetAtom(updateTaskLocationOnDefaultRoutineAtom);
  const setEditRoutineDialog = useSetAtom(editRoutineIntervalsDialogAtom);
  const setTogetherDialog = useSetAtom(togetherDialogAtom);
  const openTextEditorDialog = useSetAtom(textEditorOpenAtom);
  const language = useAtomValue(languageAtom);
  const [inputValue, setInputValue] = React.useState("");
  const addHashtag = useSetAtom(addHashtagNodeAtom);
  const removeHashtag = useSetAtom(removeHashtagNodeAtom);
  const updateStatus = useSetAtom(updateTaskStatusNodeAtom);
  const editTask = useSetAtom(editTaskDialogLoadDataAtom);
  const deleteTask = useSetAtom(deleteTaskAtom);
  const today = date;
  const currentDay = dayjs().format("YYYY-MM-DD");
  const isToday = today === currentDay;
  const isExistTodayStatus = (task.data.taskStatus as { taskStatusId: number, taskId: number, date: string, status: number }[]).length !== 0 && (task.data.taskStatus as { taskStatusId: number, taskId: number, date: string, status: number }[]).find((status: any) => status.date === today);
  const todayStatus = isExistTodayStatus ? (task.data.taskStatus as { taskStatusId: number, taskId: number, date: string, status: number }[]).find((status: any) => status.date === today) : null;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const groupItems = [
    isExistTodayStatus && (todayStatus && todayStatus.status === 1 ? { name: "완료 취소", value: "waiting" } : { name: "완료", value: "complete" }),
    { name: t("plan.contents.custom.edit"), value: "edit" },
    { name: t("plan.contents.custom.delete"), value: "delete" },
  ];

  const handleMenuItemClick = (value: string) => {
    if (value === "edit") {
      editTask(task);
    } else if (value === "delete") {
      deleteTask(task);
      setSnackbar({ open: true, message: t("dialogs.common.snackbar.deleteSuccess"), severity: "success" });
    } else if (value === "complete") {
      todayStatus && updateStatus(task, todayStatus.taskStatusId as number, 1);
      setSnackbar({ open: true, message: t("dialogs.common.snackbar.deleteSuccess"), severity: "success" });
    } else if (value === "waiting") {
      todayStatus && updateStatus(task, todayStatus.taskStatusId as number, 0);
      setSnackbar({ open: true, message: t("dialogs.common.snackbar.waitStatusSuccess"), severity: "success" });
    }
    handleClose();
  };

  // 엔터키 입력 시 태그 추가
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && inputValue.trim()) {
      event.preventDefault();
      const { backgroundColor, textColor } = getRandomColorWithText();
      if (!(task.data.hashtags as { backgroundColor: string, textColor: string, value: string }[]).find((tag: { backgroundColor: string, textColor: string, value: string }) => tag.value === inputValue.trim())) {
        addHashtag(task, {
          backgroundColor,
          textColor,
          value: inputValue.trim(),
        });
      }
      setInputValue(""); // 입력창 초기화
    } else if (event.key === 'Backspace' && inputValue === "") {
      if ((task.data.hashtags as { backgroundColor: string, textColor: string, value: string }[]).length > 0) {
        event.preventDefault();
        removeHashtag(task);
      }
    }
  };

  const handleRoutineClick = (id: string, change: number) => {
    openEditDialog(id, change);
    setExpanded(`${task.id}-${date}`);
    setIsYScrollActive(false);
  }

  return (
    task.data &&
    <div
      style={{
        width: "315px",
        height: expanded.includes(`${task.id}-${date}`) ? "auto" : "120px",
        backgroundColor: "white",
        padding: "10px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        borderRadius: "10px",
        gap: "10px",
        color: "black",
      }}
    >
      <div style={{
        display: "flex", flexDirection: "row", justifyContent: "space-between"
      }}>
        {expanded.includes(`${task.id}-${date}`) ? <ExpandLessIcon onClick={() => setExpanded(`${task.id}-${date}`)} /> : <ExpandMoreIcon onClick={() => setExpanded(`${task.id}-${date}`)} />}
        {expanded.includes(`${task.id}-${date}`) ? <TextField value={task.data.label} onChange={(e) => updateTask(task.id, "label", e.target.value)} variant="standard" sx={{ width: "80%" }} />
          :
          <div style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}
          >{task.data.label as string}</div>
        }
        <>
          <IconButton
            sx={{
              color: "black",
              fontWeight: "bold",
              padding: 0,
              textTransform: "none",
              fontSize: "0rem",
            }}
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            <MoreVertIcon sx={{ fontSize: "1.5rem" }} />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            {groupItems.map((item, index) =>
              item && <MenuItem key={index} onClick={() => handleMenuItemClick(item.value)}>
                {item.name}
              </MenuItem>
            )}
          </Menu>
        </>
      </div>
      {expanded.includes(`${task.id}-${date}`) ?
        <>
          <div style={{ display: "flex", width: "100%", fontSize: "0.8rem", fontWeight: "bold" }}>진행 일자 설정</div>
          <DatePicker
            label="시작일"
            sx={{ width: "100%" }}
            value={dayjs(task.data.startDate as string)}
            onChange={(date) => updateTask(task.id, "startDate", date)}
            shouldDisableDate={task.data.endDate ? (day) => day.isAfter(task.data.endDate as string) : undefined}
            format="YYYY.MM.DD"
          />
          <DatePicker
            label="종료일"
            sx={{ width: "100%" }}
            value={dayjs(task.data.endDate as string)}
            onChange={(date) => updateTask(task.id, "endDate", date)}
            shouldDisableDate={task.data.startDate ? (day) => day.isBefore(task.data.startDate as string) : undefined}
            format="YYYY.MM.DD"
          />
          <S.ContentContainer onClick={() => openTextEditorDialog(task.id, task.data.content as string)}>
            {
              task.data.content === "" ?
                <Typography sx={{ fontSize: "0.8rem" }}>설명</Typography> :
                <TextMarkdown text={task.data.content as string} />
            }
          </S.ContentContainer>

          <Box sx={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", alignItems: "center" }}>
            <Typography sx={{ fontSize: "0.8rem", fontWeight: "bold" }}>루틴 간격</Typography>
            <ToggleButtonGroup
              exclusive
              value={task.data.termType}
              color="primary"
            >
              <ToggleButton value={0} selected={task.data.termType === 0}
                onClick={() => handleRoutineClick(task.id, 0)}
              >매일</ToggleButton>
              <ToggleButton value={1} selected={task.data.termType === 1}
                onClick={() => handleRoutineClick(task.id, 1)}
              >매주</ToggleButton>
              <ToggleButton value={2} selected={task.data.termType === 2}
                onClick={() => handleRoutineClick(task.id, 2)}
              >매월</ToggleButton>
            </ToggleButtonGroup>
          </Box>

          {
            (task.data.termType === 1 || task.data.termType === 2) &&
            <>
              <Box sx={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", alignItems: "center" }}>
                <Typography sx={{ fontSize: "0.8rem", fontWeight: "bold" }}>반복</Typography>
                <CustomBackgroundColorButton onClick={() => setEditRoutineDialog({ open: task.id, termType: task.data.termType as number, termData: task.data.termData as string[] })} text={language === "KR" ? "수정" : "edit"} />
              </Box>
              <Box sx={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", alignItems: "center" }}>
                <Typography sx={{ fontSize: "0.8rem", fontWeight: "bold" }}>{task.data.termType === 1 ? "매주" : "매월"}</Typography>
                <Box sx={{ display: "grid", gridTemplateColumns: `repeat(${task.data.termData && (task.data.termData as string[]).length < 7 ? (task.data.termData as string[]).length : 7}, 1fr)`, gap: "5px" }}>
                  {(task.data.termData as string[]).map((interval: string) => (
                    <Typography key={interval} sx={{ fontSize: "1rem", fontWeight: "bold" }}>{interval}{task.data.termType === 1 ? "" : "일"}</Typography>
                  ))}
                </Box>
              </Box>
            </>
          }

          <TextField id="outlined-basic" label="장소" variant="outlined" value={(task.data.location as LocationProps).address || ""}
            onChange={(e) => updateLocation(task.id, e.target.value)}
            style={{ width: "100%" }} />

          <S.TagInputContainer>
            {(task.data.hashtags as { backgroundColor: string, textColor: string, value: string }[]).map((tag: { backgroundColor: string, textColor: string, value: string }, idx: number) => {
              return <S.TagItem key={idx} style={{ backgroundColor: tag.backgroundColor, color: tag.textColor }}>
                <span>{tag.value}</span>
              </S.TagItem>
            })}
            <S.TagInput
              placeholder={(task.data.hashtags as { backgroundColor: string, textColor: string, value: string }[]).length === 0 ? "해시태그" : ""}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onKeyDown={handleKeyDown}
            />
          </S.TagInputContainer>

          <div style={{ display: "flex", width: "100%", flexDirection: "row", justifyContent: "space-between" }}>
            <CustomColorButton onClick={() => setTogetherDialog({ node: task })} text="함께하기" fontSize="0.8rem" />
          </div>
        </>
        :
        <>
          <div style={{ display: "flex", width: "100%", fontSize: "1rem", justifyContent: isExistTodayStatus ? "space-between" : "flex-end", color: `${(!task.data.startDate || !task.data.endDate ? "black" : theme.colors.primary)}`, fontWeight: "bold", alignItems: "center" }}>
            <div style={{ color: "black" }}>
              {isExistTodayStatus ? todayStatus?.status === 1 ? "완료" : "대기" : ""}
            </div>
            {
              isExistTodayStatus && todayStatus && isToday &&
              <CustomBackgroundColorButton onClick={() => todayStatus?.status === 1 ? updateStatus(task, todayStatus.taskStatusId as number, 0) : updateStatus(task, todayStatus.taskStatusId as number, 1)} text={todayStatus?.status === 1 ? "대기" : "완료"} fontSize="0.8rem" />
            }
            <div>
              {(!task.data.startDate || !task.data.endDate) ? "일정 미설정" : getRemainingDays(task.data.startDate as string, task.data.endDate as string)}
            </div>
          </div>
          <div style={{ marginTop: '10px', width: '100%' }}>
            <LinearProgress variant="determinate" value={getProgressPercentage(task.data.startDate as string, task.data.endDate as string)} sx={{
              height: "20px", borderRadius: "5px", backgroundColor: "#e0e0e0", '& .MuiLinearProgress-bar': {
                backgroundColor: theme.colors.primary, // 커스텀 색상 적용
              },
            }} />
          </div>
        </>
      }
    </div>
  )
}
