import {
  applyEdgeChanges,
  applyNodeChanges,
  Edge,
  EdgeChange,
  Node,
  NodeChange,
  Connection,
  addEdge,
  MarkerType,
  useStoreApi,
  useReactFlow,
  InternalNode,
} from "@xyflow/react";
import { atom } from "jotai";
import debounce from 'lodash/debounce';
import { v4 as uuidv4 } from 'uuid';
import { editRoutineIntervalsDialogAtom } from "@/Atoms/Dialogs/Edit/RoutineOption";
import { LocationProps } from "@/Atoms/RootAtom";
import { careerPathAtom, edgesAtom, firstGetNodeStateAtom, isChangedMindmapAtom, nodesAtom, selectedEdgeAtom, selectedNodeAtom, typeAtom } from "@/Atoms/Plan/MindmapAtom";
import theme from "@/Styles/theme";
import getLongestPath, { EdgeData, NodeData } from "@/Utils/GetLongestPath";
import { updateMindmapAtom } from '@/ViewModels/Plan/Mindmap/ViewModel';
import { initializeGroupNodeAtom } from "../GroupViewModel";
import { deleteEdge, deleteNode, deleteNodesAndEdges } from "@/Queries/PlanQueries";
import GetIdFromQuerystring from "@/Utils/GetIdFromQuerystring";
import { getUserId } from "@/ViewModels/UserViewModel";
import { routineSetDataAtom } from "../../RoutineViewModel";
import { ganttChartSetDataAtom } from "../../GanttChartViewModel";
import { devConsoleError, devConsoleLog } from "@/Utils/ConsoleLogInDevelopment";
import { handleReactQueryApiResponse } from "@/Utils/APIUtil";
import { error401ModalAtom } from "@/Atoms/Dialogs/Error/401Atom";

export const initializeNewData = (id: string, label: string) => {
  return {
    id: id,
    backendId: 0,
    label: label,
    startDate: null,
    endDate: null,
    content: "",
    isNewCreated: true,
    termType: null,
    termData: null,
    location: {
      address: "",
      latitude: null,
      longitude: null,
    } as LocationProps,
    taskStatus: [],
    hashtags: [],
  }
}

const initializeTaskNodeAtom = (type: string) => {
  const id = uuidv4();
  return {
    id: `${type}-${id}`,
    type: type,
    position: { x: 0, y: 0 },
    data: initializeNewData(`${type}-${id}`, `${nodeType(type)}을 입력해주세요.`),
    measured: {
      width: 410,
      height: 176
    },
  }
}

export const initializeTaskNodeWithPositionAtom = (type: string, position: { x: number, y: number }) => {
  const id = uuidv4();
  return {
    id: `${type}-${id}`,
    type: type,
    position: position,
    data: initializeNewData(`${type}-${id}`, `${nodeType(type)}을 입력해주세요.`),
    measured: {
      width: 410,
      height: 176
    },
  }
}

// 클릭해서 노드를 생성하는 메서드
export const handleClickCreateNodeAtom = atom(null, (get, set, type: string, position: { x: number, y: number }) => {
  const newNode = initializeTaskNodeWithPositionAtom(type, position);
  set(nodesAtom, (prev) => [...prev, newNode]);
  set(updateMindmapAtom);
});

const nodeType = (type: string) => {
  switch (type) {
    case "dream":
      return "꿈";
    case "goal":
      return "목표";
    case "job":
      return "직업";
    case "ultimate":
      return "필살기";
    case "curriculum":
      return "교육과정";
    case "major":
      return "진학학과";
    case "group":
      return "그룹";
    default:
      return "할 일";
  }
};

// 디바운스된 업데이트 함수
export const debouncedUpdateMindmap = debounce((get, set) => {
  set(updateMindmapAtom);
}, 5000); // 1초 동안 변화가 없을 때만 업데이트 실행

export const onNodesChangeAtom = atom((get) => get(nodesAtom), async (get, set, changes: NodeChange<Node>[]) => {
  const selectedNode = get(selectedNodeAtom);
  const groups = get(nodesAtom).filter((node) => node.type === "group");
  const innerTabId = GetIdFromQuerystring('innner-tab-id');
  // devConsoleLog("changes", changes);

  // 노드가 삭제되었고, 삭제 대상 노드가 선택된 노드일 경우에만 삭제
  if (changes.find((change) => change.type === "remove")) {
    const findChange = changes.find((change) => change.type === "remove");
    if (selectedNode && findChange && findChange.id === selectedNode.id) {
      if (selectedNode.data.backendId !== 0) {
        if (selectedNode.type === "group") {
          const childNodes = get(nodesAtom).filter((node) => node.parentId === selectedNode.id);
          await handleReactQueryApiResponse(deleteNode, () => set(error401ModalAtom, true), [selectedNode.data.backendId as number, ...childNodes.map((node) => node.data.backendId as number)], innerTabId, get(getUserId));
        } else {
          const connectedEdges = get(edgesAtom).filter((edge) => edge.source === selectedNode.id || edge.target === selectedNode.id).map((edge) => edge.data?.backendId as number);
          await handleReactQueryApiResponse(deleteNodesAndEdges, () => set(error401ModalAtom, true), [selectedNode.data.backendId as number], connectedEdges, innerTabId, get(getUserId));
        }
        set(isChangedMindmapAtom, false);
      }
      set(selectedNodeAtom, null);
      set(nodesAtom, (nds) => applyNodeChanges(changes, nds));
    }
    return;
  }

  if (changes.find((change) => change.type === "position") && groups.length > 0) {
    const changeNode = changes.find((change) => change.type === "position");
    const node = get(nodesAtom).find((n) => n.id === changeNode?.id);
    if (!node) return;

    if (node.type === "group") {
      set(nodesAtom, (nds) => {
        const updatedNodes = applyNodeChanges(changes, nds);
        const groupNodes = updatedNodes.filter((n) => n.type === "group");
        const otherNodes = updatedNodes.filter((n) => n.type !== "group");
        return [...groupNodes, ...otherNodes]; // 그룹 노드를 항상 앞에 배치
      });
      set(isChangedMindmapAtom, true);
      return;
    }

    const { x: nodeX, y: nodeY } = node.position;
    const { width: nodeWidth, height: nodeHeight } = node.measured || { width: 0, height: 0 };
    if (!nodeWidth || !nodeHeight) return;

    const parentGroup = groups.find((group) => group.id === node.parentId) || null;

    // 노드 경계 계산
    const nodeBounds = {
      minX: parentGroup ? parentGroup.position.x + nodeX : nodeX,
      maxX: parentGroup ? parentGroup.position.x + nodeX + nodeWidth : nodeX + nodeWidth,
      minY: parentGroup ? parentGroup.position.y + nodeY : nodeY,
      maxY: parentGroup ? parentGroup.position.y + nodeY + nodeHeight : nodeY + nodeHeight,
    };

    for (const group of groups) {
      const { x: groupX, y: groupY } = group.position;
      const { width: groupWidth, height: groupHeight } = group.measured || { width: 0, height: 0 };
      if (!groupWidth || !groupHeight) continue;

      const groupBounds = {
        minX: groupX,
        maxX: groupX + groupWidth,
        minY: groupY,
        maxY: groupY + groupHeight,
      };

      // 노드의 일부라도 그룹 내에 포함되는지 확인
      const isWithinGroup =
        (nodeBounds.maxX > groupBounds.minX &&
          nodeBounds.minX < groupBounds.maxX &&
          nodeBounds.maxY > groupBounds.minY &&
          nodeBounds.minY < groupBounds.maxY);

      if (isWithinGroup && changeNode?.dragging) {
        let newGroup = {
          ...group,
          style: {
            ...group.style,
            filter: "drop-shadow(0 0 10px rgba(0,0,0,0.5))",
          },
        };
        if (node.parentId === group.id) {
          // 그룹 내 노드가 이동 중일 때, 그룹 영역에서 벗어날 경우, 그룹 영역 확장
          const newGroupBounds = {
            minX: Math.min(groupBounds.minX, nodeBounds.minX),
            maxX: Math.max(groupBounds.maxX, nodeBounds.maxX),
            minY: Math.min(groupBounds.minY, nodeBounds.minY),
            maxY: Math.max(groupBounds.maxY, nodeBounds.maxY),
          };

          const newGroupPosition = {
            x: newGroupBounds.minX,
            y: newGroupBounds.minY,
          };

          const newGroupSize = {
            width: newGroupBounds.maxX - newGroupBounds.minX,
            height: newGroupBounds.maxY - newGroupBounds.minY,
          };
          newGroup = {
            ...newGroup,
            width: newGroupSize.width,
            height: newGroupSize.height,
            position: newGroupPosition,
            measured: newGroupSize,
          };
        }
        set(nodesAtom, (nds) => {
          const updatedNodes = applyNodeChanges(changes, nds).map((n) => {
            if (n.id === group.id) return { ...newGroup }; // 깊은 복사
            return { ...n };  // 깊은 복사
          });
          const groupNodes = updatedNodes.filter((n) => n.type === "group");
          const otherNodes = updatedNodes.filter((n) => n.type !== "group");
          return [...groupNodes, ...otherNodes]; // 그룹 노드를 앞에 배치
        });
      } else if (isWithinGroup && node.parentId !== group.id) {
        // 그룹 내 노드를 추가하고 그룹 크기 확장이 아닌, 노드가 그룹 내부에 포함되도록 이동 조정
        let newNodeX = nodeBounds.minX < groupBounds.minX ? groupBounds.minX : nodeBounds.maxX > groupBounds.maxX ? groupBounds.maxX - nodeWidth : nodeX;
        let newNodeY = nodeBounds.minY < groupBounds.minY ? groupBounds.minY : nodeBounds.maxY > groupBounds.maxY ? groupBounds.maxY - nodeHeight : nodeY;

        // 그룹의 스타일 업데이트
        const newGroup = {
          ...group,
          style: {
            ...group.style,
            filter: "none",
          },
        };

        // 그룹 내 상대적인 위치로 노드를 설정
        const newNode = {
          ...node,
          position: {
            x: newNodeX - groupX,
            y: newNodeY - groupY,
          },
          parentId: group.id,
        };

        set(nodesAtom, (nds) => {
          const updatedNodes = applyNodeChanges(changes, nds).map((n) => {
            if (n.id === group.id) return { ...newGroup }; // 깊은 복사
            if (n.id === node.id) return { ...newNode };  // 깊은 복사
            return { ...n };  // 깊은 복사
          });
          const groupNodes = updatedNodes.filter((n) => n.type === "group");
          const otherNodes = updatedNodes.filter((n) => n.type !== "group");
          return [...groupNodes, ...otherNodes]; // 그룹 노드를 항상 앞에 배치
        });
        return;
      } else {
        const newGroup = {
          ...group,
          style: {
            ...group.style,
            filter: "none",
          },
        };
        set(nodesAtom, (nds) => {
          const updatedNodes = applyNodeChanges(changes, nds).map((n) => {
            if (n.id === group.id) return { ...newGroup }; // 깊은 복사
            return { ...n };  // 깊은 복사
          });
          const groupNodes = updatedNodes.filter((n) => n.type === "group");
          const otherNodes = updatedNodes.filter((n) => n.type !== "group");
          return [...groupNodes, ...otherNodes]; // 그룹 노드를 앞에 배치
        });
      }
    }
    set(isChangedMindmapAtom, true);
    return;
  }
  if (get(firstGetNodeStateAtom)) {
    set(firstGetNodeStateAtom, false);
  } else {
    set(isChangedMindmapAtom, true);
  }
  set(nodesAtom, (nds) => {
    const updatedNodes = applyNodeChanges(changes, nds);
    const groupNodes = updatedNodes.filter((n) => n.type === "group");
    const otherNodes = updatedNodes.filter((n) => n.type !== "group");
    return [...groupNodes, ...otherNodes]; // 그룹 노드를 항상 앞에 배치
  });
});

export const onEdgesChangeAtom = atom(
  (get) => get(edgesAtom),
  async (get, set, changes: EdgeChange<Edge>[]) => {
    set(isChangedMindmapAtom, true);
    const selectedEdge = get(selectedEdgeAtom);
    const innerTabId = GetIdFromQuerystring('innner-tab-id');

    // 엣지가 삭제되었고, 삭제 대상 엣지가 선택된 엣지일 경우에만 삭제
    if (changes[0].type === "remove") {

      // 선택된 엣지가 있고, 삭제하려는 엣지와 선택된 엣지가 같을 경우에만 삭제
      if (selectedEdge && changes[0].id === selectedEdge.id) {
        if (selectedEdge.data?.backendId !== 0) {
          await handleReactQueryApiResponse(deleteEdge, () => set(error401ModalAtom, true), [selectedEdge.data?.backendId as number], innerTabId); // 백엔드에서 엣지 삭제
        }
        set(selectedEdgeAtom, null); // 선택된 엣지를 null로 설정
        set(edgesAtom, (eds) => applyEdgeChanges(changes, eds)); // 엣지 삭제
        set(isChangedMindmapAtom, false);
      }
      return;
    } else {
      set(edgesAtom, (eds) => applyEdgeChanges(changes, eds)); // 삭제 이외의 경우 처리
    }
  }
);

export const onConnectAtom = atom(null, (get, set, params: Connection) => {
  devConsoleLog("onConnectAtom", params);
  set(edgesAtom, (eds) => {
    const newEdge = {
      ...params,
      id: `${params.sourceHandle || params.source}-${params.targetHandle || params.target}-${new Date().getTime()}`,
      data: {
        backendId: 0,
      },
      source: params.target,
      target: params.source,
      sourceHandle: `${params.targetHandle?.replace("target", "")}source`,
      targetHandle: `${params.sourceHandle?.replace("source", "")}target`,
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 10,
        height: 10,
        color: process.env.REACT_APP_MAIN_COLOR,
      },
      // style: {
      //   strokeWidth: 3,
      //   stroke: process.env.REACT_APP_MAIN_COLOR,
      // },
      // animated: true,
      style: {
        strokeWidth: 3,
        stroke: theme.colors.primary,
      },
      type: "smoothstep",
    } as Edge;

    let newEds = addEdge(newEdge, eds);

    return newEds;
  });
});

export const onDragStartAtom = atom(
  null,
  (get, set, event: React.DragEvent<HTMLElement>, nodeType: string) => {
    set(typeAtom, nodeType);
    event.dataTransfer.effectAllowed = "move";
  }
);

export const onDragOverAtom = atom(null, (get, set, event: any) => {
  event.preventDefault();
  event.dataTransfer.dropEffect = "move";
});

export const onDropAtom = atom(
  null,
  (get, set, event: any, screenToFlowPosition: any) => {
    const type = get(typeAtom) as keyof typeof typeMap;
    event.preventDefault();

    if (!type) {
      return;
    }

    const position: { x: number; y: number } = screenToFlowPosition({
      x: event.clientX,
      y: event.clientY,
    });

    devConsoleLog("type", type);

    const typeMap = (type: string) => {
      switch (type) {
        case "group":
          return initializeGroupNodeAtom({ x: position.x, y: position.y });
        default:
          return initializeTaskNodeAtom(type);
      }
    }

    const selectedNode = typeMap(type);

    if (selectedNode) {
      const newId = `${type}-${uuidv4()}`;

      const newNode = {
        ...selectedNode,
        id: `${newId}`,
        position: position,
        data: {
          ...selectedNode.data,
          id: `${newId}`,
          isNewCreated: true,
          termType: null,
          termData: null,
          location: {
            address: "",
            latitude: null,
            longitude: null,
          }
        },
      };

      set(nodesAtom, (current) => [...current, newNode]);
      set(updateMindmapAtom);
    }
  }
);

export const updateNodeAtom = atom(
  null,
  (get, set, updatedNode: any) => {
    // 기존 노드 상태를 가져옴
    const currentNodes = get(nodesAtom);

    // 특정 노드를 업데이트
    const updatedNodes = currentNodes.map((node: Node) => {
      if (node.id === updatedNode.id) {
        return {
          ...node,
          data: {
            ...node.data,
            ...updatedNode,
            isNewCreated: false,
          }
        };
      }
      return node;
    });

    // 업데이트된 노드를 다시 저장
    set(nodesAtom, updatedNodes);
    set(routineSetDataAtom, updatedNodes);
    set(ganttChartSetDataAtom, updatedNodes);
  }
);

// 엣지 스타일 변경 함수
const careerPathChangeEdge = (edgeId: string, color: string, setEdges: (fn: (eds: any[]) => any[]) => void) => {
  setEdges((eds) =>
    eds.map((e) => {
      if (e.id === edgeId) {
        // 엣지가 선택되었을 때 스타일을 변경
        return {
          ...e,
          style: { stroke: color, strokeWidth: 3 }, // 기본 스타일
        };
      }
      return e;
    })
  );
};

export const getCareerPathAtom = atom(null, (get, set) => {
  const nodes = get(nodesAtom); // nodesAtom에서 데이터를 가져옴
  const edges = get(edgesAtom); // edgesAtom에서 데이터를 가져옴
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const id = urlParams.get('id');

  if (!id) {
    devConsoleError("No mindmap id found");
    return;
  }

  const nodesData: NodeData[] = nodes.map(node => ({ id: node.id, type: node.type || "", activate: node.data.activate as number }));
  const edgesData: EdgeData[] = edges.map(edge => ({ id: edge.id, source: edge.source, target: edge.target }));

  // 경로 계산 후 atom 상태로 저장
  const deepestPath = getLongestPath(nodesData, edgesData);
  devConsoleLog("Deepest Path:", deepestPath);

  // 계산된 경로를 deepestPathAtom에 저장
  set(careerPathAtom, { id: Number(id), path: deepestPath.map(item => item.id) });
  const notIncludedEdges = edgesData.filter(edge => !deepestPath.map(item => item.id).includes(edge.id));
  devConsoleLog("Not Included Edges:", notIncludedEdges); // 경로에 없는 엣지 확인
  notIncludedEdges.forEach((item) => {
    careerPathChangeEdge(item.id, "gray", (fn) => set(edgesAtom, fn)); // 경로에 없는 엣지는 어둡게
  });
});

export const refreshCareerPathAtom = atom(null, (get, set) => {
  const edges = get(edgesAtom);
  const careerPath = get(careerPathAtom);

  if (!careerPath) {
    return;
  }

  const notIncludedEdges = edges.filter(edge => !careerPath.path.includes(edge.id));
  notIncludedEdges.forEach((item) => {
    careerPathChangeEdge(item.id, theme.colors.primary, (fn) => set(edgesAtom, fn));
  });

  set(careerPathAtom, null);
});

export const nodeClickAtom = atom(null, (get, set, event: React.MouseEvent, node: Node) => {
  const selectedNode = get(selectedNodeAtom);
  devConsoleLog("nodeClickAtom", node);

  if (selectedNode && selectedNode.id === node.id) {
    set(nodesAtom, (nds) => {
      const updatedNodes = nds.map((n) => {
        if (n.id === node.id) {
          return {
            ...n,
            data: {
              ...n.data,
              isNewCreated: false,
            }
          };
        }
        return n;
      });
      return updatedNodes;
    })
    set(selectedNodeAtom, null);
  } else {
    set(selectedNodeAtom, node);
    const nodeId = node.id.split("-")[0];
    set(edgesAtom, (eds) => eds.map((e) => {
      if (e.source === node.id || e.target === node.id) {
        return {
          ...e,
          style: { stroke: theme.colors[nodeId] || theme.colors.customPurple, strokeWidth: 3 },
          animated: false,
        };
      }
      return e;
    }));
    set(selectedEdgeAtom, null);
  }
});

export const edgeClickAtom = atom(null, (get, set, event: React.MouseEvent, edge: Edge) => {
  const selectedEdge = get(selectedEdgeAtom); // 현재 선택된 엣지
  const selectedNode = get(selectedNodeAtom); // 현재 선택된 노드
  devConsoleLog("edgeClickAtom", edge);

  const isSelected = selectedEdge?.id === edge.id;

  // 엣지 선택/해제 처리
  if (isSelected) {
    set(selectedEdgeAtom, null);
  } else {
    set(selectedEdgeAtom, edge);
  }
  // 엣지 스타일 업데이트
  set(edgesAtom, (eds) =>
    eds.map((e) => {
      if (e.id === edge.id) {
        return {
          ...e,
          style: isSelected
            ? { stroke: theme.colors.primary, strokeWidth: 3 } // 선택 해제 시 기본 스타일로 복원
            : { stroke: "red", strokeWidth: 5, strokeDasharray: "10 10" }, // 선택 시 빨간색으로 변경
          animated: !isSelected,
        };
      }
      return e;
    })
  );
  // 엣지가 선택된 경우, 노드를 선택 해제
  if (selectedNode) {
    set(selectedNodeAtom, null);
  }
});

export const backgroundClickAtom = atom(null, (get, set) => {
  set(selectedNodeAtom, null);
  set(nodesAtom, (nds) => {
    const updatedNodes = nds.map((n) => {
      return {
        ...n,
        data: {
          ...n.data,
          isNewCreated: false,
        }
      };
    });
    return updatedNodes;
  });
  set(edgesAtom, (eds) =>
    eds.map((e) => {
      return {
        ...e,
        style: { stroke: theme.colors.primary, strokeWidth: 3 },
        animated: false,
      };
    })
  );
  set(selectedEdgeAtom, null);
});

// 루틴 옵션 변경 함수
export const editRoutineIntervalsInNodeAtom = atom(null, (get, set) => {
  const editRoutineDialog = get(editRoutineIntervalsDialogAtom);
  const nodes = get(nodesAtom);
  const editNode = nodes.find((node) => node.id === editRoutineDialog.open);
  if (!editNode || !editRoutineDialog.termData) return;

  // 업데이트 전 interval 정렬
  const weekDays = ["월", "화", "수", "목", "금", "토", "일"];
  editRoutineDialog.termData = editRoutineDialog.termType === 1 ?
    editRoutineDialog.termData.sort((a, b) => weekDays.indexOf(a) - weekDays.indexOf(b)) :
    editRoutineDialog.termData = editRoutineDialog.termData.sort((a, b) => parseInt(a) - parseInt(b));

  const updatedNode = {
    ...editNode,
    data: {
      ...editNode.data,
      termType: editRoutineDialog.termType,
      termData: editRoutineDialog.termData,
    },
  };

  set(nodesAtom, (nds) => nds.map((n) => n.id === updatedNode.id ? updatedNode : n));
  set(editRoutineIntervalsDialogAtom, { open: null, termType: null, termData: null });
})

type EdgeInfo = {
  id: string;
  source: string;
  target: string;
} | null;

export const getClosestEdgeAtom = atom<null, [Node], EdgeInfo>(
  null,
  (get, set, node: Node): EdgeInfo => {
    if (!node || !node.id) return null;
    const store = useStoreApi();
    const { nodeLookup } = store.getState();
    const { getInternalNode } = useReactFlow();

    // 현재 노드의 InternalNode 가져오기
    const internalNode: InternalNode<Node> | null = getInternalNode(node.id) as InternalNode<Node>;
    if (!internalNode) return null;

    // 가장 가까운 노드를 계산하여 `closestNode`에 저장
    const closestNode = Array.from(nodeLookup.values()).reduce(
      (res: { distance: number; node: InternalNode<Node> | null }, n: InternalNode<Node>) => {
        if (n.id !== internalNode.id) {
          const dx = n.internals.positionAbsolute.x - internalNode.internals.positionAbsolute.x;
          const dy = n.internals.positionAbsolute.y - internalNode.internals.positionAbsolute.y;
          const dist = Math.sqrt(dx * dx + dy * dy);

          // 150 이내의 거리로 가까운 노드를 탐색
          if (dist < res.distance && dist < 200) {
            return { distance: dist, node: n };
          }
        }
        return res;
      },
      { distance: Infinity, node: null }
    );

    if (!closestNode.node) return null;

    // 가까운 노드의 소스 여부에 따라 엣지 반환
    const closeNodeIsSource =
      closestNode.node.internals.positionAbsolute.x < internalNode.internals.positionAbsolute.x;

    return {
      id: closeNodeIsSource
        ? `${closestNode.node.id}-${node.id}`
        : `${node.id}-${closestNode.node.id}`,
      source: closeNodeIsSource ? closestNode.node.id : node.id,
      target: closeNodeIsSource ? node.id : closestNode.node.id,
    };
  }
);

// onNodeDrag atom
export const onNodeDragAtom = atom(
  null,
  (get, set, _, node: Node) => {
    const closeEdge = set(getClosestEdgeAtom, node);

    if (!closeEdge) {
      return; // closeEdge가 null이면 아무 작업도 하지 않음
    }

    set(edgesAtom, (es) => {
      const nextEdges = es.filter((e) => e.className !== 'temp');

      if (
        closeEdge &&
        !nextEdges.find(
          (ne) =>
            ne.source === closeEdge.source && ne.target === closeEdge.target,
        )
      ) {
        // closeEdge.className = 'temp';
        nextEdges.push(closeEdge);
      }

      return nextEdges;
    });
  },
);

// onNodeDragStop atom
export const onNodeDragStopAtom = atom(
  null,
  (get, set, _, node: Node) => {
    const closeEdge = set(getClosestEdgeAtom, node);

    if (!closeEdge) {
      return; // closeEdge가 null이면 아무 작업도 하지 않음
    }

    set(edgesAtom, (es) => {
      const nextEdges = es.filter((e) => e.className !== 'temp');

      if (
        closeEdge &&
        !nextEdges.find(
          (ne) =>
            ne.source === closeEdge.source && ne.target === closeEdge.target,
        )
      ) {
        nextEdges.push(closeEdge);
      }

      return nextEdges;
    });
  },
);

// 노드 삭제 메서드
export const handleClickDeleteNodeAtom = atom(null, async (get, set, id: string) => {
  const nodes = get(nodesAtom);
  const edges = get(edgesAtom);
  const innerTabId = GetIdFromQuerystring('inner_tab_id');
  const node = nodes.find((node) => node.id === id);
  if (!node) return;
  const connectedEdges = edges.filter((edge) => edge.source === node.id || edge.target === node.id).map((edge) => edge.data?.backendId as number);
  await handleReactQueryApiResponse(deleteNode, () => set(error401ModalAtom, true), [node.data.backendId], innerTabId, get(getUserId)); // 백엔드에서 노드 삭제
  await handleReactQueryApiResponse(deleteEdge, () => set(error401ModalAtom, true), connectedEdges, innerTabId); // 백엔드에서 엣지 삭제
  set(selectedEdgeAtom, null); // 선택된 엣지를 null로 설정
  set(nodesAtom, (nds) => nds.filter((n) => n.id !== id)); // 노드 삭제
  set(edgesAtom, (eds) => eds.filter((edge) => edge.source !== node.id && edge.target !== node.id)); // 엣지 삭제
  set(isChangedMindmapAtom, false);
})
