import React from "react";
import * as S from './styled';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useAtom } from "jotai";
import { changeContentAtom } from "@/ViewModels/Mail/ViewModel";
import useIsMobile from "@/Hooks/useIsMobile";

const MailTextEditor = () => {
  const { isMobile } = useIsMobile();
  const [content, setContent] = useAtom(changeContentAtom);

  const modules = {
    toolbar: [
      [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],  // 글꼴 및 헤더
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],  // 리스트
      ['bold', 'italic', 'underline', 'strike'],  // 텍스트 스타일
      [{ 'color': [] }, { 'background': [] }],  // 텍스트 색상 및 배경색
      [{ 'align': [] }],  // 텍스트 정렬
      ['link', 'image'],  // 링크 및 이미지 삽입
      ['clean']  // 포맷 초기화
    ]
  };

  // Quill의 formats 설정
  const formats = [
    'header', 'font', 'list', 'bullet',
    'bold', 'italic', 'underline', 'strike',
    'color', 'background', 'align',
    'link', 'image'
  ];

  return (
    <S.Container>
      {isMobile ?
        <ReactQuill theme="snow" style={{ minHeight: "calc(100% - 50px)" }} value={content} onChange={setContent} />
        :
        <ReactQuill theme="snow" style={{ minHeight: "calc(100% - 50px)" }} modules={modules} formats={formats} value={content} onChange={setContent} />
      }
    </S.Container>
  )
}

export default MailTextEditor;
