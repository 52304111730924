import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import React from "react";
import rehypeRaw from "rehype-raw";

const TextMarkdown = ({ text }: { text: string }) => {
  return (
    <ReactMarkdown
      remarkPlugins={[remarkGfm]}
      rehypePlugins={[rehypeRaw]}
      components={{
        p: ({ node, children }) => (
          <p
            style={{
              margin: "10px",
              wordBreak: "break-word",
              overflowWrap: "break-word",
            }}
          >
            {children}
          </p>
        ),
        h1: ({ node, children }) => (
          <h1
            style={{
              margin: "10px",
              wordBreak: "break-word",
              overflowWrap: "break-word",
            }}
          >
            {children}
          </h1>
        ),
        h2: ({ node, children }) => (
          <h2
            style={{
              margin: "10px",
              wordBreak: "break-word",
              overflowWrap: "break-word",
            }}
          >
            {children}
          </h2>
        ),
        h3: ({ node, children }) => (
          <h3
            style={{
              margin: "10px",
              wordBreak: "break-word",
              overflowWrap: "break-word",
            }}
          >
            {children}
          </h3>
        ),
        h4: ({ node, children }) => (
          <h4
            style={{
              margin: "10px",
              wordBreak: "break-word",
              overflowWrap: "break-word",
            }}
          >
            {children}
          </h4>
        ),
        h5: ({ node, children }) => (
          <h5
            style={{
              margin: "10px",
              wordBreak: "break-word",
              overflowWrap: "break-word",
            }}
          >
            {children}
          </h5>
        ),
        h6: ({ node, children }) => (
          <h6
            style={{
              margin: "10px",
              wordBreak: "break-word",
              overflowWrap: "break-word",
            }}
          >
            {children}
          </h6>
        ),
        ul: ({ node, children }) => (
          <ul
            style={{
              margin: "10px",
              wordBreak: "break-word",
              overflowWrap: "break-word",
            }}
          >
            {children}
          </ul>
        ),
        ol: ({ node, children }) => (
          <ol
            style={{
              margin: "10px",
              wordBreak: "break-word",
              overflowWrap: "break-word",
            }}
          >
            {children}
          </ol>
        ),
        li: ({ node, children }) => (
          <li
            style={{
              margin: "10px",
              wordBreak: "break-word",
              overflowWrap: "break-word",
            }}
          >
            {children}
          </li>
        ),
        table: ({ node, children }) => (
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
              margin: "20px 0",
              backgroundColor: "#fff", // 바탕색 하얀색 설정
              wordBreak: "break-word",
              overflowWrap: "break-word",
            }}
          >
            {children}
          </table>
        ),
        tbody: ({ node, children }) => (
          <tbody>{children}</tbody>
        ),
        tr: ({ node, children }) => (
          <tr style={{ borderBottom: "1px solid #ddd" }}>
            {children}
          </tr>
        ),
        th: ({ node, children }) => (
          <th
            style={{
              backgroundColor: "#f2f2f2",
              textAlign: "left",
              borderBottom: "2px solid #ddd",
              wordBreak: "break-word",
              overflowWrap: "break-word",
            }}
          >
            {children}
          </th>
        ),
        td: ({ node, children }) => (
          <td
            style={{
              padding: "10px",
              borderBottom: "1px solid #ddd",
              backgroundColor: "#fff", // 바탕색 하얀색 설정
              wordBreak: "break-word",
              overflowWrap: "break-word",
            }}
          >
            {children}
          </td>
        ),
        blockquote: ({ node, children }) => (
          <blockquote
            style={{
              width: "100%",
              margin: "10px",
              wordBreak: "break-word",
              overflowWrap: "break-word",
            }}
          >
            {children}
          </blockquote>
        ),
        code: ({ node, children }) => (
          <code
            style={{
              // overflowX: "auto",
              margin: "10px",
              wordBreak: "break-word", // 긴 단어 줄 바꿈
              overflowWrap: "break-word", // 줄 바꿈 허용
            }}
          >
            {children}
          </code>
        ),
        pre: ({ node, children }) => (
          <pre
            style={{
              margin: "10px",
              wordBreak: "break-word", // 줄 바꿈
              overflowX: "auto", // 가로 스크롤 허용
              whiteSpace: "pre-wrap", // 줄 바꿈 처리
              overflowWrap: "break-word", // 줄 바꿈 허용
            }}
          >
            {children}
          </pre>
        ),
        em: ({ node, children }) => (
          <em
            style={{
              margin: "10px",
              wordBreak: "break-word",
              overflowWrap: "break-word",
            }}
          >
            {children}
          </em>
        ),
        strong: ({ node, children }) => (
          <strong
            style={{
              margin: "10px",
              wordBreak: "break-word",
              overflowWrap: "break-word",
            }}
          >
            {children}
          </strong>
        ),
        a: ({ node, href, children }) => (
          <a
            href={href}
            target="_blank" // 새 창에서 열기
            rel="noopener noreferrer" // 보안 관련 속성 추가
            style={{
              color: "#1a0dab",
              textDecoration: "underline",
              wordBreak: "break-word",
              overflowWrap: "break-word",
            }}
          >
            {children}
          </a>
        ),
        img: ({ node, src, alt }) => (
          <img
            src={src}
            alt={alt}
            style={{
              maxWidth: "100%", // 이미지가 부모 요소 너비를 넘지 않도록
              border: "2px solid #ddd", // 얇은 테두리 추가
              borderRadius: "8px", // 모서리를 둥글게
              padding: "5px", // 이미지와 테두리 사이에 약간의 패딩 추가
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // 가벼운 그림자 추가
              margin: "20px 0", // 상하 여백 추가
            }}
          />
        ),
      }}
    >
      {text}
    </ReactMarkdown>
  );
}

export default TextMarkdown;
