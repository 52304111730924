import { error401ModalAtom } from "@/Atoms/Dialogs/Error/401Atom";
import mailAtom, { copyRecipientTextAtom, hiddenRecipientTextAtom, Mail, recipientTextAtom } from "@/Atoms/Mail/Atom";
import snackbarAtom from "@/Atoms/Snackbar";
import { sendMail } from "@/Queries/MailQueries";
import { handleReactQueryApiResponse } from "@/Utils/APIUtil";
import { getUserId } from "@/ViewModels/UserViewModel";
import { atom } from "jotai";

// onkeydown 이벤트 처리
export const recipientOnKeyDownAtom = atom(null, (get, set, e: React.KeyboardEvent<HTMLDivElement>, type: string) => {
  if (e.key === "Enter") {
    switch (type) {
      case "recipients":
        const recipient = get(recipientTextAtom);
        set(addRecipientAtom, "recipients", recipient);
        set(recipientTextAtom, "");
        break;
      case "copyRecipients":
        const copyRecipient = get(copyRecipientTextAtom);
        set(addRecipientAtom, "copyRecipients", copyRecipient);
        set(copyRecipientTextAtom, "");
        break;
      case "hiddenRecipients":
        const hiddenRecipient = get(hiddenRecipientTextAtom);
        set(addRecipientAtom, "hiddenRecipients", hiddenRecipient);
        set(hiddenRecipientTextAtom, "");
        break;
    }
  } else if (e.key === "Backspace") {
    const text = type === "recipients" ? get(recipientTextAtom) : type === "copyRecipients" ? get(copyRecipientTextAtom) : get(hiddenRecipientTextAtom);
    if (text === "") {
      switch (type) {
        case "recipients":
          set(removeRecipientAtom, "recipients", get(mailAtom).recipients[get(mailAtom).recipients.length - 1]);
          break;
        case "copyRecipients":
          set(removeRecipientAtom, "copyRecipients", get(mailAtom).copyRecipients[get(mailAtom).copyRecipients.length - 1]);
          break;
        case "hiddenRecipients":
          set(removeRecipientAtom, "hiddenRecipients", get(mailAtom).hiddenRecipients[get(mailAtom).hiddenRecipients.length - 1]);
          break;
      }
    }
  }
});

// recipient 삭제
export const removeRecipientAtom = atom(null, (get, set, key: keyof Mail, recipient: string) => {
  set(mailAtom, (prev) => ({
    ...prev,
    [key]: (prev[key] as string[]).filter((r) => r !== recipient), // 동적 키 접근
  }));
});

// recipient 추가
export const addRecipientAtom = atom(null, (get, set, key: keyof Mail, recipient: string) => {
  if (recipient === "") return;
  const mailState = get(mailAtom);
  const currentRecipients = mailState[key] as string[] || [];
  if (currentRecipients.includes(recipient)) return;
  set(mailAtom, (prev) => ({
    ...prev,
    [key]: [...currentRecipients, recipient], // key에 따라 수신자를 추가
  }));
}
);

// 제목 변경
export const changeSubjectAtom = atom(null, (get, set, subject: string) => {
  set(mailAtom, (prev) => ({
    ...prev,
    subject,
  }));
});

// 내용 변경
export const changeContentAtom = atom((get) => get(mailAtom).content, (get, set, content: string) => {
  set(mailAtom, (prev) => ({
    ...prev,
    content,
  }));
});

const isValidEmail = (email: string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

// 메일 보내기
export const sendMailAtom = atom(null, async (get, set) => {
  // 받는 사람이 없으면 스낵바 표시
  if (get(mailAtom).recipients.length === 0) {
    set(snackbarAtom, { open: true, message: "받는 사람을 입력해주세요", severity: "error" });
    return;
  }

  // 제목이 없으면 스낵바 표시
  if (get(mailAtom).subject === "") {
    set(snackbarAtom, { open: true, message: "제목을 입력해주세요", severity: "error" });
    return;
  }

  // 받는 사람들 중 이메일 형식이 아니면 스낵바 표시
  for (const recipient of get(mailAtom).recipients) {
    if (!isValidEmail(recipient)) {
      set(snackbarAtom, { open: true, message: "이메일 형식이 아닌 받는 사람이 있습니다", severity: "error" });
      return;
    }
  }

  // 내용이 없으면 스낵바 표시
  if (get(mailAtom).content === "") {
    set(snackbarAtom, { open: true, message: "내용을 입력해주세요", severity: "error" });
    return;
  }

  // 메일 송신 요청
  const userId = get(getUserId);
  const mail = get(mailAtom);
  const request = {
    sender: userId,
    recipients: mail.recipients,
    copyRecipients: mail.copyRecipients,
    hiddenRecipients: mail.hiddenRecipients,
    subject: mail.subject,
    content: mail.content,
  }
  const handleError401Modal = () => set(error401ModalAtom, true);
  const response = await handleReactQueryApiResponse(sendMail, handleError401Modal, request);
  if (!response.ok) {
    set(snackbarAtom, { open: true, message: "메일 송신에 실패했습니다", severity: "error" });
    return;
  } else {
    // 메일 송신 완료
    set(mailAtom, (prev) => ({
      ...prev,
      completed: true,
    }));
  }
});

// 다시 작성
export const rewriteMailAtom = atom(null, (get, set) => {
  set(mailAtom, {
    completed: false,
    senderNickname: "",
    sender: "",
    recipients: [],
    copyRecipients: [],
    hiddenRecipients: [],
    subject: "",
    content: "",
  });
});
