import { settingsTabAtom } from '@/Atoms/Settings';
import CustomColorButton from '@/Components/Common/Buttons/CustomColor';
import theme from '@/Styles/theme';
import { useAtom } from 'jotai';
import React from 'react';
import { useTranslation } from 'react-i18next';

const SettingsSidebar = () => {
  const [currentTab, setCurrentTab] = useAtom(settingsTabAtom);
  const { t } = useTranslation();

  const isCurrentTab = (tab: string): boolean => {
    return currentTab === tab;
  }

  return (
    <div style={{ display: "flex", width: "160px", height: "calc(100% - 40px)", flexDirection: "column", padding: "20px", gap: "20px", borderRight: "1px solid #c0c0c0" }}>
      <div style={{ fontSize: "1.2rem", fontWeight: "bold" }}>{t("settings.sidebar.title")}</div>
      <CustomColorButton onClick={() => setCurrentTab("profile")} color={isCurrentTab("profile") ? theme.colors.primary : "black"} text={t("settings.sidebar.profile")} fontSize='1rem' />
      <CustomColorButton onClick={() => setCurrentTab("group")} color={isCurrentTab("group") ? theme.colors.primary : "black"} text={t("settings.sidebar.group")} fontSize='1rem' />
      <CustomColorButton onClick={() => setCurrentTab("setting")} color={isCurrentTab("setting") ? theme.colors.primary : "black"} text={t("settings.sidebar.setting")} fontSize='1rem' />
      <CustomColorButton onClick={() => setCurrentTab("account")} color={isCurrentTab("account") ? theme.colors.primary : "black"} text={t("settings.sidebar.account")} fontSize='1rem' />
    </div>
  )
}

export default SettingsSidebar;
