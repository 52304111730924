import { atom } from 'jotai';

export interface CrewInfo {
  name: string;
  content: string;
  headCount: number;
  current: number;
  hashtags: string[];
  imageUrl: string;
  color: string;
  category: string;
}

export interface PlanHipCrew {
  crewId: number;
  innerTabId: number;
  name: string;
  content: string;
  headCount: number;
  current: number;
  hashtags: string[];
  imageUrl: string;
  color: string;
  category: string;
}

interface PlanHip {
  myCrews: PlanHipCrew[];
  notMyCrews: PlanHipCrew[];
}

const planHipAtom = atom<PlanHip>({
  myCrews: [],
  notMyCrews: [],
});

export default planHipAtom;
