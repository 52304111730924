import React from "react";
import Root from "./Root";
import { Route, Routes, createBrowserRouter } from "react-router-dom";
import Timelapse from "./Pages/Timelapse";
import Dashboard from "./Pages/Dashboard";
import PlanHip from "./Pages/PlanHip";
import Crew from "./Pages/Crew";
import Settings from "./Pages/Settings";
import Plan from "./Pages/Plan";
import Mail from "./Pages/Mail";
import { MakeTextHelper } from "./Pages/MakeTextHelper";
import AfterSchool from "./Pages/AfterSchool";
import AfterSchoolCourse from "./Pages/AfterSchoolCourse";

const AppRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<Root />}>
        <Route index element={<Dashboard />}></Route>
        <Route path="/timelapse" element={<Timelapse />}></Route>
        <Route path="/plan" element={<Plan />}></Route>
        <Route path="/my-plan" element={<Plan />}></Route>
        <Route path="/plan-hip" element={<PlanHip />}></Route>
        <Route path="/crew" element={<Crew />}></Route>
        <Route path="/settings" element={<Settings />}></Route>
        <Route path="/mail" element={<Mail />}></Route>
        <Route path="/crew-helper" element={<MakeTextHelper />}></Route>
        <Route path="/after-school" element={<AfterSchool />}></Route>
        <Route path="/after-school-course" element={<AfterSchoolCourse />}></Route>
      </Route>
    </Routes>
  );
};

const router = createBrowserRouter([
  {
    path: "*",
    element: <AppRouter />,
  },
]);

export default router;
