import React from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { currentChatRoomAtom } from '@/Atoms/ChatAtom';
import { dokgabiChatModalAtom, dokgabiChatModalSidebarAtom } from '@/Atoms/DokgabiChat/Atom';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const DokgabiCreateHeader = () => {
  const currentChatRoom = useAtomValue(currentChatRoomAtom);
  const [sidebar, setSidebar] = useAtom(dokgabiChatModalSidebarAtom);
  const setModal = useSetAtom(dokgabiChatModalAtom);
  const handleBack = () => setModal((prev) => ({ ...prev, mode: "chat" }));

  return (
    currentChatRoom &&
    <div
      style={{
        position: "sticky",
        top: 0,
        zIndex: 1000,
        width: "cale(100% - 40px)",
        height: "40px",
        backgroundColor: "white",
        borderBottom: "1px solid #c0c0c0",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "10px 20px",
      }}
    >
      <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "20px" }}>
        <ArrowBackIosNewIcon onClick={handleBack} sx={{ cursor: "pointer" }} />
        <div style={{ fontSize: "0.9rem", fontWeight: "bold", color: "black" }}>채팅방 생성</div>
      </div>
      {
        !sidebar &&
        <MenuIcon style={{ cursor: "pointer" }} onClick={() => setSidebar(true)} />
      }
    </div>
  )
}

export default DokgabiCreateHeader;
