import { Plan } from "@/Atoms/Plan";
import { InnerTabData } from "@/Atoms/Plan/InnerTabDataAtom";
import { devConsoleLog } from "@/Utils/ConsoleLogInDevelopment";
import { Edge, Node } from "@xyflow/react";

export const createPlan = async (access: string, userId: number) => {
  const response = await fetch(
    `${process.env.REACT_APP_PLAN_ADDRESS}/empty?user_id=${userId}`,
    {
      method: "POST",
      headers: {
        "accept": "application/json",
        'Authorization': `Bearer ${access}`,
      },
    }
  );
  return response;
}

export const parseGetPlansResponse = (data: any): Plan[] => {
  return data.plans_with_inner_tabs.map((plan: any) => {
    return {
      id: plan.plan_id,
      name: plan.plan_name,
      innerTabs: plan.inner_tabs.map((innerTab: any) => {
        return {
          id: innerTab.inner_tab_id,
          name: innerTab.name,
          viewType: (innerTab.viewType as "mindmap" | "todo" | "routine" | "gantt") || "mindmap",
        };
      })
    };
  });
}

export const getPlans = async (access: string, userId: number) => {
  const request = {
    method: "GET",
    headers: {
      "accept": "application/json",
      "Authorization": `Bearer ${access}`,
    },
  };
  const response = await fetch(
    `${process.env.REACT_APP_PLAN_USER_API_ADDRESS}/${userId}`,
    request
  );
  return response;
};

export const parseGetPlanDataResponse = (data: any | null, innerTabId: number): InnerTabData => {
  if (!data) {
    return {
      id: innerTabId,
      nodes: [],
      edges: [],
    }
  }
  if (!data.nodes) {
    return {
      id: innerTabId,
      nodes: [],
      edges: [],
    }
  }
  const groupNodes = data.nodes.filter((node: Node) => node.type === "group");
  const nonGroupNodes = data.nodes.filter((node: Node) => node.type !== "group");
  return {
    id: innerTabId,
    nodes: [...groupNodes, ...nonGroupNodes].map((node: Node) => ({
      ...node,
      data: {
        ...node.data,
        hashtags: node.data.hashtags || [],
      }
    })),
    edges: data.edges,
  };
}

export const getPlanData = async (access: string, innerTabId: number) => {
  const request = {
    method: "GET",
    headers: {
      "Authorization": `Bearer ${access}`,
      "accept": "application/json",
    },
  }
  const response = await fetch(
    `${process.env.REACT_APP_INNER_TAB_ADDRESS}/${innerTabId}`,
    request
  );
  return response;
}

const parseGroupNodes = (nodes: Node[]): Node[] => {
  return nodes.map((node) => {
    if (node.type === "group") {
      return {
        ...node,
        style: {
          ...node.style,
          width: node.measured?.width || 600,
          height: node.measured?.height || 400,
        },
      };
    }
    return node;
  });
}

export const updatePlanData = async (access: string, userId: number, innerTabId: number, nodes: Node[], edges: Edge[]) => {
  const groupNodes = parseGroupNodes(nodes.filter((node) => node.type === "group"));
  const nonGroupNodes = nodes.filter((node) => node.type !== "group");
  const request = {
    method: "PUT",
    headers: {
      "accept": "application/json",
      "Content-Type": "application/json",
      "Authorization": `Bearer ${access}`,
    },
    body: JSON.stringify({
      nodes: [...groupNodes, ...nonGroupNodes],
      edges: edges
    }),
  }
  const response = await fetch(
    `${process.env.REACT_APP_INNER_TAB_ADDRESS}/${innerTabId}?user_id=${userId}`,
    request
  );
  return response;
}

export const updatePlanName = async (access: string, planId: number, name: string) => {
  const response = await fetch(
    `${process.env.REACT_APP_PLAN_ADDRESS}/${planId}`,
    {
      method: "PUT",
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
        "Authorization": `Bearer ${access}`,
      },
      body: JSON.stringify({
        name: name
      }),
    }
  );
  return response;
}

export const deletePlan = async (access: string, planId: number) => {
  const response = await fetch(
    `${process.env.REACT_APP_PLAN_ADDRESS}/${planId}`,
    {
      method: "DELETE",
      headers: {
        "Authorization": `Bearer ${access}`,
        "accept": "application/json",
      },
    }
  );
  return response;
}

export const deleteInnerTab = async (access: string, userId: number, innerTabId: number) => {
  const response = await fetch(
    `${process.env.REACT_APP_INNER_TAB_ADDRESS}/${innerTabId}?user_id=${userId}`,
    {
      method: "DELETE",
      headers: {
        "Authorization": `Bearer ${access}`,
        "accept": "application/json",
      },
    }
  );
  return response;
}

export const deleteNode = async (access: string, nodeBackendIds: number[], innerTabId: number, userId: number) => {
  const response = await fetch(
    `${process.env.REACT_APP_TASK_ADDRESS}?user_id=${userId}`,
    {
      method: "DELETE",
      headers: {
        "Authorization": `Bearer ${access}`,
        "accept": "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        innerTabId: innerTabId,
        backendIds: nodeBackendIds,
      }),
    }
  );
  return response;
}

export const deleteEdge = async (access: string, edgeBackendIds: number[], innerTabId: number) => {
  const response = await fetch(
    `${process.env.REACT_APP_RELATION_ADDRESS}`,
    {
      method: "DELETE",
      headers: {
        "Authorization": `Bearer ${access}`,
        "accept": "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        innerTabId: innerTabId,
        backendIds: edgeBackendIds,
      }),
    }
  );
  return response;
}

export const deleteNodesAndEdges = async (access: string, nodeBackendIds: number[], edgeBackendIds: number[], innerTabId: number, userId: number) => {
  const response = await fetch(
    `${process.env.REACT_APP_TASK_AND_RELATION_ADDRESS}?user_id=${userId}`,
    {
      method: "DELETE",
      headers: {
        "Authorization": `Bearer ${access}`,
        "accept": "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        innerTabId: innerTabId,
        nodeBackendIds: nodeBackendIds,
        edgeBackendIds: edgeBackendIds,
      }),
    }
  );
  return response;
}

// 함께하기
export const shareWithCrew = async (access: string, nodeId: number, userId: number, crewInnerTabId: number) => {
  const response = await fetch(
    `${process.env.REACT_APP_TASK_ADDRESS}/${nodeId}/share?user_id=${userId}`,
    {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${access}`,
        "accept": "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        crewInnerTabId: crewInnerTabId,
      }),
    }
  );
  return response;
}

// 텍스트 에디터 이미지 업로드 API
export const uploadTextEditorImage = async (access: string, file: File, innerTabId: number) => {
  const formData = new FormData();
  formData.append("imageFile", file);
  const response = await fetch(
    `${process.env.REACT_APP_TASK_ADDRESS}/${innerTabId}/task_image`,
    {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${access}`,
        "accept": "application/json",
      },
      body: formData,
    }
  );
  return response;
}

// 텍스트 에디터 이미지 삭제 API
export const deleteTextEditorImages = async (access: string, innerTabId: number, imageUrls: string[]) => {
  const response = await fetch(
    `${process.env.REACT_APP_TASK_ADDRESS}/${innerTabId}/task_image`,
    {
      method: "DELETE",
      headers: {
        "Authorization": `Bearer ${access}`,
        "accept": "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        imageURLs: imageUrls,
      }),
    }
  );
  return response;
}

// task status 변경 API
export const updateTaskStatus = async (access: string, taskStatusId: number, status: number) => {
  const response = await fetch(
    `${process.env.REACT_APP_TASK_STATUS_ADDRESS}/${taskStatusId}`,
    {
      method: "PUT",
      headers: {
        "Authorization": `Bearer ${access}`,
        "accept": "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        status: status,
      }),
    }
  );
  return response;
}
