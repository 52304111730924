import { accordionSidebarAtom } from "@/Atoms/SidebarAtom";
import { atom } from "jotai";
import { planHeaderChangeViewTypeAtom } from "../Plan/HeaderViewModel";
import { editPlanNameDialogOpenAtom } from "../Dialogs/Edit/PlanNameViewModel";
import { usePlanDataAtom } from "@/Atoms/HookControllerAtom";
import GetIdFromQuerystring from "@/Utils/GetIdFromQuerystring";
import { planFitViewUpdatedAtom } from "@/Atoms/Plan";

export const setIsAccordionOpenAtom = atom(null, (get, set) => {
  set(accordionSidebarAtom, !get(accordionSidebarAtom));
});

export const moveToPlanAtom = atom(null, (get, set, planId: number, innerTabId: number, navigateWithWarning: (path: string) => void) => {
  const currentPath = window.location.pathname;
  const currentPlanId = GetIdFromQuerystring("id");
  const currentInnerTabId = GetIdFromQuerystring("inner_tab_id");
  if (currentPath.includes("plan") && currentPlanId === planId && currentInnerTabId === innerTabId) {
    return;
  }
  set(planHeaderChangeViewTypeAtom, "mindmap");
  set(editPlanNameDialogOpenAtom)
  set(usePlanDataAtom, true);
  set(planFitViewUpdatedAtom, false);
  navigateWithWarning(`/plan?id=${planId}&inner_tab_id=${innerTabId}`);
});

export const moveToPlanHipAtom = atom(null, (get, set, navigateWithWarning: (path: string) => void) => {
  navigateWithWarning("/plan-hip");
})
