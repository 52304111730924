import React from "react";
import {
  Box,
  Divider,
} from "@mui/material";
import * as S from "../styled";
import theme from "../../../../Styles/theme";
import { ScatterPlot } from "../../Charts/Scatter";
import { useAtomValue } from "jotai";
import teacherDashboardAtom from "@/Atoms/Dashboard/TeacherAtom";
import { useTranslation } from "react-i18next";

const TotalCareerProgress = () => {
  const careerProgressData = useAtomValue(teacherDashboardAtom).careerProgress.filter((item) => item.x !== 0 && item.y !== 0).map((item) => ({
    ...item,
    size: 10,
  }));
  const { t } = useTranslation();

  return (
    <S.CustomBox
      sx={{ minHeight: "700px", display: "flex", flexDirection: "column" }}
    >
      <S.TitleTypo>{t("dashboard.teacher.careerProgress.title")}</S.TitleTypo>
      <Divider sx={{ margin: "10px 0" }} />
      <S.DescTypo sx={{ marginBottom: "10px" }}>
        {t("dashboard.teacher.careerProgress.description")}
      </S.DescTypo>

      <Box sx={{ flexGrow: 1 }}>
        <ScatterPlot
          data={careerProgressData}
          color={theme.colors.primary}
          xDesc={t("dashboard.teacher.careerProgress.numberOfUltimateSkills")}
          yDesc={t("dashboard.teacher.careerProgress.numberOfTodos")}
          xMax={24}
          yMax={100}
          xMin={0}
          yMin={0}
        />
      </Box>
    </S.CustomBox>
  );
}

export default TotalCareerProgress;
