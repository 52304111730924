import { atom } from "jotai";
import ChloeImage from "../Assets/Images/chloe-image.png";

export type MessageType = "user" | "chatbot" | "system";

interface JobButtonsProps {
  dream: string;
  goal: string;
  job: string;
}

export interface MessageProps {
  id: number;
  text: string;
  type: MessageType;
  jobButtons: string[] | null;
  date: string;
}

export interface ChatbotProps {
  id: number; // 챗봇 ID
  name: string;
  flow: string;
  image: string;
}

export interface ChatRoomProps {
  id: number;
  title: string;
  chatbots: ChatbotProps[];
  messages: MessageProps[];
  chatHistoryPageNum: number;
}

export const currentChatRoomAtom = atom<ChatRoomProps | null>(null); // 현재 챗봇 대화방
export const chatDialogAtom = atom<boolean>(false); // 챗봇 대화창 열기/닫기
export const chatRoomListAtom = atom<ChatRoomProps[]>([]); // 챗봇 대화방 목록
export const isChatListOpenAtom = atom(false);

export const currentChatbotTextProgressAtom = atom<ChatbotProps | null>(null);
export const createChatRoomSectionAtom = atom<boolean>(false);

export const isChatbotTypingAtom = atom<boolean>(false); // 챗봇이 타이핑 중인지 여부
