import deletePlanDialogAtom from "@/Atoms/Dialogs/Delete/PlanAtom";
import CustomColorButton from "@/Components/Common/Buttons/CustomColor";
import theme from "@/Styles/theme";
import { deletePlanAtom } from "@/ViewModels/Plan/PlanViewModel";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useAtom, useSetAtom } from "jotai";
import React from "react";
import { useNavigate } from "react-router-dom";

const DeletePlanDialog = () => {
  const [deletePlanDialog, setDeletePlanDialog] = useAtom(deletePlanDialogAtom);
  const deletePlan = useSetAtom(deletePlanAtom);
  const navigate = useNavigate();

  const handleConfirm = () => {
    setDeletePlanDialog({ open: false, complete: false });
    navigate("/");
  }

  return (
    <Dialog open={deletePlanDialog.open} onClose={() => setDeletePlanDialog({ open: false, complete: false })} fullWidth maxWidth="xs">
      <DialogTitle sx={{ fontSize: "1rem", fontWeight: "bold" }}>마인드맵 삭제</DialogTitle>
      <DialogContent>
        {
          deletePlanDialog.complete ?
            <>
              <Typography sx={{ fontSize: "0.9rem" }}>마인드맵이 삭제되었습니다.</Typography>
            </> :
            <>
              <Typography sx={{ fontSize: "0.9rem" }}>정말로 삭제하시겠습니까?</Typography>
              <Typography sx={{ fontSize: "0.9rem" }}>삭제한 마인드맵은 복구할 수 없습니다.</Typography>
            </>
        }
      </DialogContent>
      <DialogActions>
        {
          deletePlanDialog.complete ?
            <CustomColorButton onClick={handleConfirm} text="확인" fontSize="0.8rem" /> :
            <>
              <CustomColorButton onClick={() => setDeletePlanDialog({ open: false, complete: false })} text="취소" fontSize="0.8rem" color="black" />
              <CustomColorButton onClick={deletePlan} text="삭제" fontSize="0.8rem" />
            </>
        }
      </DialogActions>
    </Dialog>
  )
}

export default DeletePlanDialog;
