import React from "react";
import { Edge, EdgeProps, getBezierPath } from "@xyflow/react";

const CustomEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  data,
  markerEnd,
}: EdgeProps) => {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <>
      {/* 엣지 경로 */}
      <path
        id={id}
        style={style}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
      />
      {/* 버튼 위치 */}
      <foreignObject
        x={labelX - 20}
        y={labelY - 10}
        width={40}
        height={20}
        requiredExtensions="http://www.w3.org/1999/xhtml"
      >
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <button
            style={{
              background: "white",
              border: "1px solid #d9d9d9",
              borderRadius: "5px",
              fontSize: "10px",
              cursor: "pointer",
            }}
          // onClick={() => {
          //   if (data?.onClick) data.onClick(id); // 버튼 클릭 이벤트
          // }}
          >
            삭제
          </button>
        </div>
      </foreignObject>
    </>
  );
};

export default CustomEdge;
