import React from 'react';
import { Fab, Tooltip } from "@mui/material";
import RouteOutlinedIcon from '@mui/icons-material/RouteOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { useAtom, useSetAtom } from "jotai";
import theme from "../../../../../Styles/theme";
import { careerPathAtom } from '@/Atoms/Plan/MindmapAtom';
import { getCareerPathAtom, refreshCareerPathAtom } from '@/ViewModels/Plan/Mindmap/FlowViewModel';

const CareerPathElementBar = () => {
  const fetchCareerPath = useSetAtom(getCareerPathAtom);
  const refreshCareerPath = useSetAtom(refreshCareerPathAtom);
  const [careerPath] = useAtom(careerPathAtom);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const id = urlParams.get('id');

  return (
    <>
      {careerPath && careerPath.id === Number(id) && careerPath.path ? (
        <Tooltip title={<div style={{ fontSize: "1rem" }}>돌아가기</div>} arrow placement="right">
          <Fab
            sx={{
              bgcolor: theme.colors.goal,
              '&:hover': {
                bgcolor: theme.colors.goal + "CC",
              },
              color: "#fff", // 아이콘 색상
            }}
            onClick={refreshCareerPath}
          >
            <CloseIcon />
          </Fab>
        </Tooltip>
      ) : (
        <Tooltip title={<div style={{ fontSize: "1rem" }}>CAREER PATH</div>} arrow placement="right">
          <Fab
            sx={{
              bgcolor: theme.colors.goal,
              '&:hover': {
                bgcolor: theme.colors.goal + "CC",
              },
              color: "#fff", // 아이콘 색상
            }}
            onClick={() => fetchCareerPath()}
          >
            <RouteOutlinedIcon />
          </Fab>
        </Tooltip>
      )}
    </>
  );
};

export default CareerPathElementBar;
