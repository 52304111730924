import { crewLecturesAtom, Lecture } from '@/Atoms/Crew';
import cancelLectureDialogAtom from '@/Atoms/Dialogs/Cancel/Lecture';
import { error401ModalAtom } from '@/Atoms/Dialogs/Error/401Atom';
import snackbarAtom from '@/Atoms/Snackbar';
import { cancelApplyLecture } from '@/Queries/LectureQueries';
import { handleReactQueryApiResponse } from '@/Utils/APIUtil';
import GetIdFromQuerystring from '@/Utils/GetIdFromQuerystring';
import { atom } from 'jotai';
import { getUserId } from '../UserViewModel';

export const cancelLectureDialogCancelDialogAtom = atom(null, async (get, set) => {
  const dialog = get(cancelLectureDialogAtom);
  if (!dialog) return;

  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  const crewLectures = get(crewLecturesAtom);
  const lectures = crewLectures.find((lecture) => lecture.innerTabId === innerTabId)?.lectures || [];
  const lecture = lectures.find((lecture) => lecture.id === dialog.lectureId);
  if (!lecture) return;

  // Cancel lecture
  const handleError401Modal = () => set(error401ModalAtom, true);
  const response = await handleReactQueryApiResponse(cancelApplyLecture, handleError401Modal, dialog.lectureId, get(getUserId));
  if (!response) {
    set(snackbarAtom, { open: true, message: "신청 취소에 실패했습니다.", severity: "error" });
    return;
  }

  const newLecture: Lecture = {
    ...lecture,
    members: lecture.members.filter((member) => member.id !== dialog.userId)
  }
  const newLectures = lectures.map((lecture) => lecture.id === dialog.lectureId ? newLecture : lecture);
  set(crewLecturesAtom, crewLectures.map((crew) => crew.innerTabId === innerTabId ? { ...crew, lectures: newLectures } : crew));
  set(snackbarAtom, { open: true, message: "신청 취소되었습니다.", severity: "success" });
  set(cancelLectureDialogAtom, null);
})
