import innerTabDataAtom, { InnerTabData } from "@/Atoms/Plan/InnerTabDataAtom";
import { deleteInnerTab, getPlanData, parseGetPlanDataResponse, updatePlanData } from "@/Queries/PlanQueries";
import GetIdFromQuerystring from "@/Utils/GetIdFromQuerystring";
import { atom } from "jotai";
import { editPlanNameDialogOpenAtom } from "../Dialogs/Edit/PlanNameViewModel";
import { Edge, Node } from "@xyflow/react";
import { ganttChartSetDataAtom, ganttChartUpdateAtom } from "./GanttChartViewModel";
import { edgesAtom, nodesAtom } from "@/Atoms/Plan/MindmapAtom";
import { routineSetDataAtom, routineUpdateAtom } from "./RoutineViewModel";
import snackbarAtom from "@/Atoms/Snackbar";
import deleteRoutineDialogAtom from "@/Atoms/Dialogs/Delete/RoutineAtom";
import { getPlansAtom } from "./PlanViewModel";
import { getUserId } from "../UserViewModel";
import { waitingModalAtom } from "@/Atoms/RootAtom";
import { devConsoleError, devConsoleLog } from "@/Utils/ConsoleLogInDevelopment";
import { handleReactQueryApiResponse, handleReactQueryApiResponseWithJson } from "@/Utils/APIUtil";
import { error401ModalAtom } from "@/Atoms/Dialogs/Error/401Atom";

export const setInnerTabDataAtom = atom(null, (get, set, innerTabData: InnerTabData) => {
  set(innerTabDataAtom, (prev) => {
    // 기존 id가 존재하는지 확인하고 인덱스를 얻음
    const existingIndex = prev.findIndex(item => item.id === innerTabData.id);

    if (existingIndex !== -1) {
      // id가 존재하면 해당 요소를 업데이트
      return prev.map((item, index) =>
        index === existingIndex ? innerTabData : item
      );
    } else {
      // id가 존재하지 않으면 새 데이터를 추가
      return [...prev, innerTabData];
    }
  });

  // 중복된 id 값을 필터링하여 고유한 항목들만 남기기
  set(innerTabDataAtom, (prev) => {
    // Set을 이용해 중복 제거
    const uniqueItems = Array.from(
      new Map(prev.map(item => [item.id, item])).values()
    );
    return uniqueItems;
  });
});

// Plan 데이터 가져오는 로직
export const getPlanDataAtom = atom(null, async (get, set) => {
  try {
    const innerTabId = GetIdFromQuerystring("inner_tab_id");
    const handleError401Modal = () => set(error401ModalAtom, true);
    const response = await handleReactQueryApiResponseWithJson(getPlanData, handleError401Modal, innerTabId);
    const parseRespose = parseGetPlanDataResponse(response, innerTabId);
    const nodes = parseRespose.nodes;
    const edges = parseRespose.edges;
    set(nodesAtom, nodes);
    set(edgesAtom, edges);
    set(setInnerTabDataAtom, parseRespose);
    set(routineUpdateAtom);
    set(ganttChartUpdateAtom);
    set(editPlanNameDialogOpenAtom);
  } catch (error) {
    devConsoleError("getPlanDataAtom", error);
  }
});

// viewType === "mindmap"일 때, 데이터 가져오기
export const getPlanDataMindmapAtom = atom(null, async (get, set) => {
  set(nodesAtom, []);
  setTimeout(async () => {
    const innerTabId = GetIdFromQuerystring("inner_tab_id");
    const response = await handleReactQueryApiResponseWithJson(getPlanData, () => set(error401ModalAtom, true), innerTabId);
    const parsedResponse = parseGetPlanDataResponse(response, innerTabId);
    const nodes = parsedResponse.nodes;
    const edges = parsedResponse.edges;
    set(nodesAtom, nodes);
    set(edgesAtom, edges);
    set(setInnerTabDataAtom, parsedResponse);
  }, 10);
});

// viewType === "gantt"일 때, 데이터 가져오기
export const getPlanDataGanttAtom = atom(null, async (get, set) => {
  set(nodesAtom, []);
  setTimeout(async () => {
    const innerTabId = GetIdFromQuerystring("inner_tab_id");
    const response = await handleReactQueryApiResponseWithJson(getPlanData, () => set(error401ModalAtom, true), innerTabId);
    const parsedResponse = parseGetPlanDataResponse(response, innerTabId);
    const nodes = parsedResponse.nodes;
    const edges = parsedResponse.edges;
    set(nodesAtom, nodes);
    set(edgesAtom, edges);
    set(ganttChartSetDataAtom, nodes);
  }, 10);
});

// viewType === "todo"일 때, 데이터 가져오기
export const getPlanDataTodoAtom = atom(null, async (get, set) => {
  set(nodesAtom, []);
  setTimeout(async () => {
    const innerTabId = GetIdFromQuerystring("inner_tab_id");
    const response = await handleReactQueryApiResponseWithJson(getPlanData, () => set(error401ModalAtom, true), innerTabId);
    const parsedResponse = parseGetPlanDataResponse(response, innerTabId);
    const nodes = parsedResponse.nodes;
    const edges = parsedResponse.edges;
    set(nodesAtom, nodes);
    set(edgesAtom, edges);
    set(setInnerTabDataAtom, parsedResponse);
  }, 10);
});

// viewType === "routine"일 때, 데이터 가져오기
export const getPlanDataRoutineAtom = atom(null, async (get, set) => {
  set(nodesAtom, []);
  setTimeout(async () => {
    const innerTabId = GetIdFromQuerystring("inner_tab_id");
    const response = await handleReactQueryApiResponseWithJson(getPlanData, () => set(error401ModalAtom, true), innerTabId);
    const parsedResponse = parseGetPlanDataResponse(response, innerTabId);
    const nodes = parsedResponse.nodes;
    set(nodesAtom, nodes);
    set(routineSetDataAtom, nodes);
    set(setInnerTabDataAtom, parsedResponse);
  }, 10);
});

// mindmap에서 Plan 데이터 업데이트 로직
export const updatePlanDataMindmapAtom = atom(null, async (get, set, innerTabId: number, nodes: Node[], edges: Edge[]) => {
  // if (!get(waitingModalAtom).state) {
  //   set(waitingModalAtom, (prev) => ({ ...prev, state: true }));
  // }
  // set(nodesAtom, []);
  // set(edgesAtom, []);
  setTimeout(async () => {
    const userId = get(getUserId);
    const res = await handleReactQueryApiResponseWithJson(updatePlanData, () => set(error401ModalAtom, true), userId, innerTabId, nodes, edges)

    const response = res.nodes_edges as {
      nodes: {
        id: string,
        backendId: number,
      }[],
      edges: {
        id: string,
        backendId: number,
      }[]
    };
    const newNodes = nodes.map((node) => response.nodes.find((n) => n.id === node.id) ? ({ ...node, data: { ...node.data, backendId: response.nodes.find((n) => n.id === node.id)?.backendId } }) : node);
    const newEdges = edges.map((edge) => response.edges.find((e) => e.id === edge.id) ? ({ ...edge, data: { ...edge.data, backendId: response.edges.find((e) => e.id === edge.id)?.backendId } }) : edge);
    set(nodesAtom, newNodes);
    set(edgesAtom, newEdges);
    set(routineSetDataAtom, newNodes);
    set(ganttChartSetDataAtom, newNodes);
    set(waitingModalAtom, (prev) => ({ ...prev, state: false }));
  }, 5);
});

// Plan 데이터 업데이트 로직
export const updatePlanDataAtom = atom(null, async (get, set, innerTabId: number, nodes: Node[], edges: Edge[]) => {
  const userId = get(getUserId);
  const res = await handleReactQueryApiResponseWithJson(updatePlanData, () => set(error401ModalAtom, true), userId, innerTabId, nodes, edges)
  const response = res.nodes_edges as {
    nodes: {
      id: string,
      backendId: number,
    }[],
    edges: {
      id: string,
      backendId: number,
    }[]
  };
  const newNodes = nodes.map((node) => response.nodes.find((n) => n.id === node.id) ? ({ ...node, data: { ...node.data, backendId: response.nodes.find((n) => n.id === node.id)?.backendId } }) : node);
  const newEdges = edges.map((edge) => response.edges.find((e) => e.id === edge.id) ? ({ ...edge, data: { ...edge.data, backendId: response.edges.find((e) => e.id === edge.id)?.backendId } }) : edge);
  set(nodesAtom, newNodes);
  set(edgesAtom, newEdges);
  // set(getPlanDataAtom);
});

// 기본 innerTab의 데이터 가져오기
export const defaultInnerTabDataAtom = atom(null, async (get, set) => {
  const innerTabId = GetIdFromQuerystring("id");
  const response = await handleReactQueryApiResponse(getPlanData, () => set(error401ModalAtom, true), innerTabId);
  const parsedResponse = parseGetPlanDataResponse(response, innerTabId);
  set(setInnerTabDataAtom, parsedResponse);
});

// 현재 innerTab의 데이터 가져오기
export const currentInnerTabDataAtom = atom((get) => {
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  const innerTabData = get(innerTabDataAtom).find((data) => data.id === innerTabId);
  if (!innerTabData) {
    return {
      id: 0,
      nodes: [],
      edges: [],
    }
  }
  return innerTabData;
});

// 현재 innerTab의 데이터 업데이트
export const updateInnerTabDataAtom = atom(null, async (get, set, nodes: Node[], edges: Edge[]) => {
  const innerTabData = get(currentInnerTabDataAtom);
  set(updatePlanDataAtom, innerTabData.id, nodes, edges);
});

// 현재 innerTab의 nodes 데이터 업데이트
export const updateInnerTabNodesAtom = atom(null, async (get, set, nodes: Node[]) => {
  const innerTabData = get(currentInnerTabDataAtom);
  set(updatePlanDataAtom, innerTabData.id, nodes, innerTabData.edges);
});

// 현재 innerTab의 edges 데이터 업데이트
export const updateInnerTabEdgesAtom = atom(null, async (get, set, edges: Edge[]) => {
  const innerTabData = get(currentInnerTabDataAtom);
  set(updatePlanDataAtom, innerTabData.id, innerTabData.nodes, edges);
});

// delete routine (현재 innerTab 삭제)
export const deleteInnerTabAtom = atom(null, async (get, set) => {
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  const userId = get(getUserId);
  const response = await handleReactQueryApiResponse(deleteInnerTab, () => set(error401ModalAtom, true), userId, innerTabId);
  if (!response.ok) {
    set(snackbarAtom, (prev) => ({ ...prev, open: true, message: "루틴 삭제에 실패하였습니다. 잠시 후 다시 시도해주세요." }));
    return;
  }
  set(deleteRoutineDialogAtom, { open: true, complete: true });
  set(getPlansAtom);
});
