import { atom } from "jotai";
import { getUserId } from "./UserViewModel";
import { waitingModalAtom } from "@/Atoms/RootAtom";
import { devConsoleError } from "@/Utils/ConsoleLogInDevelopment";

export const availableCountAtom = atom(1);

export const createTimelapseVideoAtom = atom(null, async (get, set) => {
  const availableCount = get(availableCountAtom);
  const userId = get(getUserId);
  if (availableCount === 0) {
    alert("금일 영상 제작 가능한 횟수를 모두 사용하셨습니다.");
    return;
  }
  try {
    const response = await fetch(`${process.env.REACT_APP_CAREER_PLANNER_VIDEO_API_ADDRESS}/video/${userId}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });
    if (!response.ok) {
      throw new Error('Failed to create timelapse video');
    }
  } catch (error) {
    devConsoleError(error);
  }
  set(availableCountAtom, availableCount - 1);
  set(waitingModalAtom, { state: false, text: "" });
});
