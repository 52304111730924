import styled from 'styled-components';

export const Container = styled.div<{ $isSelecting: boolean, $color: string, $isActivate: boolean }>`
  display: flex;
  flex-direction: column;
  min-width: 350px;
  max-width: 350px;
  min-height: 100px;
  position: relative;
  color: black;
  background-color: ${({ $isActivate }) => $isActivate ? 'lightgray' : 'white'};
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  text-align: center;
  padding: 20px 30px;
  gap: 20px;
  font-size: 1.3rem;
  border: ${({ $isSelecting, $color }) => $isSelecting ? `5px solid ${$color}` : 'none'};

  .handle {
    position: absolute;
    border-radius: 50%;
    width: 8px;
    height: 8px;
  }
`;

export const TagInputContainer = styled.div`
  display: flex;
  width: calc(100% - 2rem);
  flex-wrap: wrap;
  gap: 0.5em;
  padding: 0.4rem 1rem;
  border: 1px solid #c0c0c0;
  border-radius: 4px;
`;

export const TagItem = styled.div`
  display: inline-block;
  padding: 0.2em 0.5em;
  font-size: 0.8em;
  border-radius: 20px;
  color: white;
`;

export const TagInput = styled.input`
  flex-grow: 1;
  min-width: 30px;
  max-width: 150px;
  padding: 0.5em 0;
  border: none;
  outline: none;
  font-family: "Noto Sans KR", sans-serif;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 22px); // padding 10px * 2 + border 1px * 2
  height: 200px;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  overflow-y: auto;

  &:hover {
    border-color: black;
  }
`;
