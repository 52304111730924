const TemplateMetadata = [
  {
    title: "당신의 관심사는 무엇인가요? 그 관심사가 현재의 직업이나 목표와 어떻게 연결되나요?",
  },
  {
    title: "학교에서 가장 높은 성적을 받았던 과목은 무엇인가요? 그 과목을 통해 얻은 교훈은 무엇인가요?",
  },
  {
    title: "현재 이루고 싶은 가장 큰 꿈은 무엇인가요? 그 꿈을 이루기 위해 구체적으로 어떤 계획을 세우고 있나요?",
  },
  {
    title: "현재 직업을 선택하게 된 계기는 무엇인가요? 만약 다른 직업을 선택했더라면 어떤 일을 하고 있을까요?",
  },
  {
    title: "방송 연출가가 되기 위한 나만의 필살기는 무엇인가요? 그 필살기를 강화하기 위한 할 일들은 무엇인가요?",
  },
  {
    title: "학교 시절, 가장 기억에 남는 학과와 그 이유는 무엇인가요? 그 학과가 현재의 목표와 어떻게 연결되나요?",
  },
  {
    title: "현재 당신의 목표는 무엇인가요? 그 목표를 이루기 위해 필요한 필살기는 무엇이며, 그것을 위해 어떤 노력을 하고 있나요?",
  },
  {
    title: "가장 기억에 남는 여행지에서 어떤 새로운 관심사나 꿈을 발견했나요?",
  },
  {
    title: "당신이 이루고 싶은 필살기는 무엇인가요? 그 필살기를 통해 당신의 꿈을 어떻게 실현할 수 있을까요?",
  },
  {
    title: "가장 좋아하는 관심사에 대해 얘기해 주세요. 그 관심사가 현재의 꿈이나 목표와 어떤 관련이 있나요?",
  },
  {
    title: "현 직업이 아니었다면 어떤 직업을 선택했을 것 같나요? 그 직업을 선택하게 된 이유는 무엇인가요?",
  },
  {
    title: "자신의 성적이나 등수를 기반으로 설정한 목표가 있다면, 그 목표를 이루기 위한 구체적인 계획은 무엇인가요?",
  },
  {
    title: "현재의 관심사를 바탕으로 나만의 필살기를 만들어 본다면, 그 필살기는 무엇인가요?",
  }
]

export default TemplateMetadata;
