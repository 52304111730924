import React from "react";
import * as S from "./styled";
import { useAtomValue } from "jotai";
import Routine from "./Routine";
import Mindmap from "./Mindmap";
import Todo from "./Todo";
import GanttChart from "./GanttChart";
import planHeaderAtom from "@/Atoms/Plan/HeaderAtom";
import Custom from "./Custom";

const PlanContents = () => {
  const planHeader = useAtomValue(planHeaderAtom);

  const whetherToIncludeContents = () => {
    switch (planHeader.currentViewType) {
      case "gantt":
        return <GanttChart />;
      case "todo":
        return <Todo />;
      case "routine":
        return <Routine />;
      case "custom":
        return <Custom />;
      default:
        return <Mindmap />;
    }
  }

  return (
    <S.Container>
      {whetherToIncludeContents()}
    </S.Container>
  );
};

export default PlanContents;
