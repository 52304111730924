import { Box, styled as muiStyled } from "@mui/material";

export const ElementBarContainer = muiStyled(Box)({
  position: "absolute",  // 수정
  left: "20px",
  // height: "calc(100vh - 112px)",
  background: "transparent",
  zIndex: 1,
  top: "20px",
  display: "flex",
  justifyContent: "center",
  alignItems: "start",
  flexDirection: "row",
  gap: "20px",
});
