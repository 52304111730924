import { atom } from 'jotai';

interface CreateJobMindmapDialogProps {
  open: boolean;
  node: any;
  job: string;
}

const createJobMindmapDialogAtom = atom<CreateJobMindmapDialogProps | null>(null);

export default createJobMindmapDialogAtom;
