import { dashboardMenteeDailyPlanAtom, dashboardMenteeMyLevelAtom } from '@/Atoms/Dashboard/MenteeAtom';
import { LinePlotProps } from '@/Components/Dashboard/Charts/LinePlot';
import { Node } from '@xyflow/react';
import dayjs from 'dayjs';
import { atom } from 'jotai';

// 현재 나의 수준은 가져오기
export const dashboardMenteeGetMyLevelAtom = atom((get) => {
  const myLevel = get(dashboardMenteeMyLevelAtom);
  if (!myLevel) return [] as LinePlotProps[];
  // 성장 곡선
  const plotData: LinePlotProps[] = [
    {
      name: "",
      data: myLevel.myLevel.notUsing.map((data) => ({
        key: data.year.toString(),
        value: data.money,
      })),
    },
    {
      name: "예상 성장 곡선",
      data: myLevel.myLevel.usingOurService.map((data) => ({
        key: data.year.toString(),
        value: data.money,
      })),
    },
  ];
  return [plotData, myLevel.isLoading];
});

// 일정관리에 필요한 데이터 가져오기
export const dashboardMenteeGetDailyPlanByYearAndMonthAtom = atom(
  (get) => (year: number, month: number) => {
    const planData = get(dashboardMenteeDailyPlanAtom);
    if (!planData) return [];

    const events: { title: string, date: string, extendedProps: Node }[] = [];

    planData.dailyPlan.forEach((plan: Node) => {
      const { startDate, endDate, termType, termData, label } = plan.data;
      const start = dayjs(startDate as string);
      const end = dayjs(endDate as string);

      let currentDate = start;
      while (currentDate.isBefore(end) || currentDate.isSame(end, "day")) {
        const currentDayOfWeek = currentDate.format("ddd");
        const currentDayOfMonth = currentDate.date();

        if (termType === 0) {
          events.push({ title: label as string, date: currentDate.format("YYYY-MM-DD"), extendedProps: plan as Node });
        } else if (termType === 1 && (termData as string[]).includes(currentDayOfWeek)) {
          events.push({ title: label as string, date: currentDate.format("YYYY-MM-DD"), extendedProps: plan as Node });
        } else if (termType === 2 && (termData as string[]).includes(currentDayOfMonth.toString())) {
          events.push({ title: label as string, date: currentDate.format("YYYY-MM-DD"), extendedProps: plan as Node });
        }

        currentDate = currentDate.add(1, "day");
      }
    });

    return events.filter((event) => {
      const eventDate = dayjs(event.date);
      return eventDate.year() === year && eventDate.month() === month - 1;
    });
  }
);
