import React, { useState } from 'react';
import { Fab, Zoom, Box, IconButton, Tooltip } from "@mui/material";
import CloudOutlinedIcon from '@mui/icons-material/CloudOutlined';
import OutlinedFlagIcon from '@mui/icons-material/OutlinedFlag';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import LocalFireDepartmentOutlinedIcon from '@mui/icons-material/LocalFireDepartmentOutlined';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import LocalActivityOutlinedIcon from '@mui/icons-material/LocalActivityOutlined';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import theme from "../../../../../Styles/theme";
import { useAtom, useSetAtom } from "jotai";
import { handleClickCreateNodeAtom, onDragStartAtom } from '@/ViewModels/Plan/Mindmap/FlowViewModel';
import { useTranslation } from 'react-i18next';
import useIsMobile from '@/Hooks/useIsMobile';
import { useReactFlow } from '@xyflow/react';

const SpeedDialElementBar = () => {
  const [expanded, setExpanded] = useState(false); // FAB 버튼이 확장되었는지 상태를 관리
  const [, onDragStart] = useAtom(onDragStartAtom);
  const { t } = useTranslation();
  const { isMobile } = useIsMobile();
  const { screenToFlowPosition } = useReactFlow();
  const createNode = useSetAtom(handleClickCreateNodeAtom);

  const actions = [
    { icon: <CloudOutlinedIcon sx={{ color: theme.colors.primary }} />, name: t("plan.contents.mindmap.elementbar.createDream"), type: 'dream' },
    { icon: <OutlinedFlagIcon sx={{ color: theme.colors.primary }} />, name: t("plan.contents.mindmap.elementbar.createGoal"), type: 'goal' },
    { icon: <WorkOutlineOutlinedIcon sx={{ color: theme.colors.primary }} />, name: t("plan.contents.mindmap.elementbar.createJob"), type: 'job' },
    { icon: <LocalActivityOutlinedIcon sx={{ color: theme.colors.primary }} />, name: t("plan.contents.mindmap.elementbar.createCurriculum"), type: 'curriculum' },
    { icon: <SchoolOutlinedIcon sx={{ color: theme.colors.primary }} />, name: t("plan.contents.mindmap.elementbar.createMajor"), type: 'major' },
    { icon: <LocalFireDepartmentOutlinedIcon sx={{ color: theme.colors.primary }} />, name: t("plan.contents.mindmap.elementbar.createSkill"), type: 'ultimate' },
    { icon: <FormatListBulletedOutlinedIcon sx={{ color: theme.colors.primary }} />, name: t("plan.contents.mindmap.elementbar.createTodo"), type: 'task' },
  ];

  const handleClickToCreateNode = (type: string) => {
    // Transform the screen center position to the flow position
    const screenCenter = {
      x: window.innerWidth / 2,
      y: window.innerHeight / 2,
    };
    const flowPosition = screenToFlowPosition(screenCenter);

    // Pass the flow position to the createGroupNode function
    createNode(type, flowPosition);
  };


  return (
    <Box sx={{ position: 'absolute', top: 0, left: 0 }}>
      {/* 하위 버튼들 */}
      {actions.map((action, index) => (
        <Zoom
          in={expanded}
          key={action.name}
          style={{ transitionDelay: `${expanded ? index * 100 : 0}ms` }}
        >
          <Tooltip title={action.name} placement="right" sx={{ fontSize: "1rem" }}>
            <IconButton
              sx={{
                position: 'absolute',
                top: `${(index + 1) * 60 + 20}px`,
                left: "8px",
                backgroundColor: "white",
                border: `1px solid ${theme.colors.primary}`,
                // '&:hover': { backgroundColor: theme.colors.primary + 'CC' },
              }}
              onDragStart={(event) => !isMobile && onDragStart(event, action.type)}
              onClick={() => isMobile && handleClickToCreateNode(action.type)}
              draggable
            >
              {action.icon}
            </IconButton>
          </Tooltip>
        </Zoom>
      ))}

      {/* FAB 버튼 */}
      <Fab
        color="primary"
        sx={{ backgroundColor: theme.colors.primary, '&:hover': { backgroundColor: theme.colors.primary + 'CC' } }}
        onClick={() => setExpanded((prev) => !prev)} // 클릭 시 FAB 확장 토글
      >
        {expanded ? <CloseIcon /> : <AddIcon />}
      </Fab>
    </Box>
  );
};

export default SpeedDialElementBar;
