import applyLectureDialogAtom from '@/Atoms/Dialogs/ApplyLecture';
import CustomBackgroundColorButton from '@/Components/Common/Buttons/CustomBackgroundColor';
import CustomColorButton from '@/Components/Common/Buttons/CustomColor';
import { applyLectureDialogApplyAtom } from '@/ViewModels/Dialogs/ApplyLectureViewModel';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useAtom, useSetAtom } from 'jotai';
import React from 'react';

const ApplyLectureDialog = () => {
  const [dialog, setDialog] = useAtom(applyLectureDialogAtom);
  const apply = useSetAtom(applyLectureDialogApplyAtom);

  return (
    dialog &&
    <Dialog open={dialog !== null} onClose={() => setDialog(null)}>
      <DialogTitle sx={{ fontSize: "0.9rem", fontWeight: "bold" }}>강좌 신청</DialogTitle>
      <DialogContent sx={{ fontSize: "0.8rem" }}>
        <div>강좌를 신청하시겠습니까?</div>
        {dialog.isAbleToCancel ? <div>
          신청 후에도 취소가 가능합니다.
        </div> : <div>
          신청 후에는 취소가 불가능합니다.
        </div>}
      </DialogContent>
      <DialogActions>
        <CustomColorButton onClick={() => setDialog(null)} text="취소" color="gray" fontSize="0.8rem" />
        <CustomBackgroundColorButton onClick={apply} text="신청" fontSize="0.8rem" />
      </DialogActions>
    </Dialog>
  )
}

export default ApplyLectureDialog;
