// Hex 코드를 RGB로 변환하는 함수
const hexToRgb = (hex: string) => {
  const bigint = parseInt(hex.slice(1), 16); // #을 제외하고 숫자로 변환
  const r = (bigint >> 16) & 255; // Red
  const g = (bigint >> 8) & 255;  // Green
  const b = bigint & 255;         // Blue
  return { r, g, b };
};

// 텍스트 색상을 결정하는 함수 (명도에 따라 흰색 또는 검정색)
export const getTextColorBasedOnBackground = (hexColor: string) => {
  // Convert HEX to RGB
  const { r, g, b } = hexToRgb(hexColor);

  // Calculate relative luminance
  const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;

  // If luminance is high, return black; otherwise, return white
  return luminance > 186 ? '#000000' : '#FFFFFF';
};

// 랜덤 배경색과 적절한 텍스트 색상을 반환하는 함수
const getRandomColorWithText = () => {
  // 랜덤 배경색 생성
  const randomColor = `#${Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0')}`;

  // 배경색에 따른 텍스트 색상 결정
  const textColor = getTextColorBasedOnBackground(randomColor);

  return { backgroundColor: randomColor, textColor };
};

export default getRandomColorWithText;
