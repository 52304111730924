import { edgesAtom, isChangedMindmapAtom, nodesAtom, selectedNodeAtom } from '@/Atoms/Plan/MindmapAtom';
import getRandomColorWithText from '@/Utils/GetRandomColor';
import { atom } from 'jotai';
import { v4 as uuidv4 } from 'uuid';
import { initializeNewData } from '../FlowViewModel';
import { getPlanDataMindmapAtom, updatePlanDataMindmapAtom } from '../../InnerTabViewModel';
import GetIdFromQuerystring from '@/Utils/GetIdFromQuerystring';
import { updateMindmapAtom } from '../ViewModel';
import { handleReactQueryApiResponse } from '@/Utils/APIUtil';
import { deleteNode, deleteNodesAndEdges } from '@/Queries/PlanQueries';
import { error401ModalAtom } from '@/Atoms/Dialogs/Error/401Atom';
import { getUserId } from '@/ViewModels/UserViewModel';
import { Edge } from '@xyflow/react';

export const initializeGroupNodeAtom = (position: { x: number, y: number }) => {
  const id = uuidv4();
  const { backgroundColor, } = getRandomColorWithText();
  return {
    id: `group-${id}`,
    type: "group",
    position: position,
    data: initializeNewData(`group-${id}`, "그룹명을 입력해주세요."),
    style: {
      background: backgroundColor + "33",
      border: `2px solid ${backgroundColor}`,
      padding: "10px",
      borderRadius: "5px",
      width: 600,
      height: 400,
      display: "flex",
      fontSize: "1.2rem",
    },
    measured: {
      width: 600,
      height: 400,
    }
  }
}

// Group 생성 메서드
export const createGroupNodeAtom = atom(null, (get, set, position: { x: number, y: number }) => {
  const groupNode = initializeGroupNodeAtom(position);
  set(nodesAtom, (prev) => [...prev, groupNode]);
  set(updateMindmapAtom);
});

// Custom routine에서 사용하는 Group 생성 메서드
export const createGroupNodeInCustomRoutineAtom = atom(null, (get, set, position: { x: number, y: number }) => {
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  const groupNode = initializeGroupNodeAtom(position);
  set(nodesAtom, (prev) => [...prev, groupNode]);
  set(updatePlanDataMindmapAtom, innerTabId, get(nodesAtom), []);
});

// Group 해제 메서드
export const detachNodeInGroupAtom = atom(null, (get, set, id: string) => {
  const detachNode = get(nodesAtom).find((node) => node.id === id);
  if (!detachNode || !detachNode.parentId) return;
  const group = get(nodesAtom).find((node) => node.id === detachNode.parentId);
  if (!group) return;

  // 그룹 노드의 위치와 크기 정보를 가져옴
  const groupPosition = group.position;
  const groupMeasure = group.measured;
  if (!groupMeasure || !groupMeasure.width || !groupMeasure.height) return;

  // 노드의 새로운 위치를 계산 (그룹 노드의 위치 + 크기 + 30px)
  const newPosition = { x: groupPosition.x + groupMeasure.width + 30, y: groupPosition.y };

  // 노드의 부모 노드를 undefined로 설정
  set(nodesAtom, (prev) => prev.map((node) => node.id === id ? { ...node, parentId: undefined, position: newPosition } : node));
})

// Group label 변경 메서드
export const changeGroupLabelAtom = atom(null, (get, set, id: string, label: string) => {
  set(isChangedMindmapAtom, true);
  set(nodesAtom, (prev) => prev.map((node) => node.id === id ? { ...node, data: { ...node.data, label } } : node));
});

// Group 삭제 메서드
export const deleteGroupNodeAtom = atom(null, async (get, set, id: string) => {
  const groupNode = get(nodesAtom).find((node) => node.id === id);
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  if (!groupNode) return;
  const childNodes = get(nodesAtom).filter((node) => node.parentId === groupNode.id);
  const connectedChildEdges = get(edgesAtom).filter((edge: Edge) => childNodes.find((node) => node.id === edge.source || node.id === edge.target)).map((edge: Edge) => edge.data?.backendId as number);
  const deleteNodes = [groupNode, ...childNodes].map((node) => node.data.backendId as number);
  await handleReactQueryApiResponse(deleteNodesAndEdges, () => set(error401ModalAtom, true), deleteNodes, connectedChildEdges, innerTabId, get(getUserId));
  set(isChangedMindmapAtom, false);
  set(selectedNodeAtom, null);
  set(getPlanDataMindmapAtom);
});
