import { useQuery } from 'react-query';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { getUserId, getUserPosition } from '@/ViewModels/UserViewModel';
import { useTeacherDashboardAtom } from '@/Atoms/HookControllerAtom';
import teacherDashboardAtom from '@/Atoms/Dashboard/TeacherAtom';
import { getTeacherDashboardData } from '@/Queries/DashboardQueries';
import { handleReactQueryApiResponseWithJson } from '@/Utils/APIUtil';
import { error401ModalAtom } from '@/Atoms/Dialogs/Error/401Atom';

const useTeacherDashboard = () => {
  const userId = useAtomValue(getUserId);
  const [controller, setController] = useAtom(useTeacherDashboardAtom);
  const setDashboard = useSetAtom(teacherDashboardAtom);
  const userPosition = useAtomValue(getUserPosition);
  const setError401Modal = useSetAtom(error401ModalAtom);
  const handleError401Modal = () => setError401Modal(true);

  return useQuery(['teacherDashboard', userId], () => handleReactQueryApiResponseWithJson(getTeacherDashboardData, handleError401Modal, userId), {
    enabled: !!userId && controller && userPosition === "교사",
    onSuccess: async (data) => {
      setController(false);
      setDashboard({
        signatures: data.signatures || [],
        careerProgress: data.careerProgress || [],
        crews: data.crews || [],
        careerPlanning: data.careerPlanning || [],
      })
    },
  });
};

export default useTeacherDashboard;
