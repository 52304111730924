import React, { useEffect } from 'react';
import * as S from './styled';
import PlanContents from '@/Components/Plan/Contents';
import CreateRoutineDialog from '@/Components/Plan/Dialogs/CreateRoutine';
import CreateTaskDialog from '@/Components/Plan/Dialogs/CreateTask';
import EditTaskDialog from '@/Components/Plan/Dialogs/EditTask';
import EditRoutineIntervalsDialog from '@/Components/Plan/Dialogs/EditRoutineIntervals';
import EditDefaultRoutineIntervalsDialog from '@/Components/Plan/Dialogs/EditDefaultRoutineIntervals';
import { usePlans } from '@/Hooks/usePlans';
import { usePlanData } from '@/Hooks/usePlanData';
import EditPlanNameDialog from '@/Components/Plan/Dialogs/EditPlanName';
import PlanHeader from '@/Components/Plan/Header';
import { waitingModalAtom } from '@/Atoms/RootAtom';
import { useSetAtom } from 'jotai';
import DeletePlanDialog from '@/Components/Plan/Dialogs/DeletePlan';
import useDefaultPlanData from '@/Hooks/useDefaultPlanData';
import DeleteRoutineDialog from '@/Components/Plan/Dialogs/DeleteRoutine';
import { usePlanDataAtom, usePlansAtom } from '@/Atoms/HookControllerAtom';
import { useLocation } from 'react-router-dom';
import GetIdFromQuerystring from '@/Utils/GetIdFromQuerystring';
import CreateJobMindmapDialog from '@/Components/Plan/Dialogs/CreateJobMindmap';
import InactivateDialog from '@/Components/Plan/Dialogs/Inactivate';
import CreateTaskInGroupDialog from '@/Components/Plan/Dialogs/CreateTaskInGroup';

const Dialogs = () => {
  return (
    <>
      <CreateRoutineDialog />
      <CreateTaskDialog />
      <CreateTaskInGroupDialog />
      <EditTaskDialog />
      <EditRoutineIntervalsDialog />
      <EditDefaultRoutineIntervalsDialog />
      <EditPlanNameDialog />
      <DeletePlanDialog />
      <DeleteRoutineDialog />
      <CreateJobMindmapDialog />
      <InactivateDialog />
    </>
  )
}

const Plan = () => {
  const { isLoading: isLoadingPlans } = usePlans();
  const { isLoading: isLoadingPlanData } = usePlanData();
  const { isLoading: isLoadingDefaultPlanData } = useDefaultPlanData();
  const setWaitingModal = useSetAtom(waitingModalAtom);
  const setController = useSetAtom(usePlansAtom);
  const setPlanDataController = useSetAtom(usePlanDataAtom);
  const location = useLocation();
  const planId = GetIdFromQuerystring("id");
  const innerTabId = GetIdFromQuerystring("inner_tab_id");

  useEffect(() => {
    setWaitingModal({ state: isLoadingPlans || isLoadingPlanData || isLoadingDefaultPlanData, text: "로딩중입니다." });
  }, [isLoadingPlanData, isLoadingDefaultPlanData, isLoadingPlans, setWaitingModal]);

  useEffect(() => {
    setController(true);
    setPlanDataController(true);
  }, [location.pathname, planId, innerTabId, setController, setPlanDataController]);

  return (
    <S.Container>
      <PlanHeader />
      <PlanContents />
      <Dialogs />
    </S.Container>
  );
}

export default Plan;
