import styled from 'styled-components';

export const TagInputContainer = styled.div`
  display: flex;
  width: 80%;
  flex-wrap: wrap;
  gap: 0.5em;
  padding: 0.4rem 1rem;
  border-bottom: 1px solid black;
`;

export const TagItem = styled.div`
  display: inline-block;
  padding: 0.2em 0.5em;
  border-radius: 20px;
  color: white;
`;

export const TagInput = styled.input`
  flex-grow: 1;
  min-width: 30px;
  max-width: 150px;
  padding: 0.5em 0;
  border: none;
  outline: none;
  font-family: "Noto Sans KR", sans-serif;
`;

export const Typography = styled.div`
  font-size: 0.9rem;
  font-weight: bold;
`;

export const ElementContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 50px;
`;

export const NameFieldContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 60%;
  height: 40px;
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2;
  align-items: center;
`;

export const ImageInputContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2;
`;

export const ColorPickerContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const SelectedColor = styled.div<{ $color: string }>`
  width: 50px;
  height: 50px;
  background-color: ${({ $color }) => $color};
  border-radius: 50%;
`;

