import { Node } from '@xyflow/react';
import { atom } from 'jotai';

export interface CreateRoutineDialogOpenAtomProps {
  dialogTitle: string;
  viewType: string;
  currentSelectedTask: Node | null;
  selectedTasks: Node[];
}

const createRoutineDialogAtom = atom<CreateRoutineDialogOpenAtomProps | null>(null);

export default createRoutineDialogAtom;
