import styled from 'styled-components';

export const Container = styled.div<{ $openSidebar: boolean }>`
  display: flex;
  width: calc(100% - ${({ $openSidebar }) => $openSidebar ? 240 : 0}px);
  height: calc(100% - 40px);
  flex-direction: column;
  padding: 20px;
  gap: 20px;
  overflow-y: auto;
`;

export const Title = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
`;

export const InnerContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 20px;
  align-items: center;
`;

export const ProfileImageContainer = styled.div`
  display: flex;
  width: 200px;
  height: 200px;
  position: relative;
`;

export const ProfileImage = styled.img`
  width: 200px;
  height: 200px;
  border-radius: 50%;
`;

export const EditButtonContainer = styled.div`
  width: 50px;
  height: 50px;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 50%;
  position: absolute;
  right: 15px;
  bottom: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const EditButtonStyle = {
  width: "30px",
  height: "30px",
  color: "white",
}

export const ContentRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 70px;
`;

export const ContentTitle = styled.div`
  display: flex;
  width: 20%;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem;
  font-weight: bold;
`;

export const ContentValue = styled.div`
  display: flex;
  width: 65%;
  justify-content: flex-end;
  align-items: center;
  font-size: 0.8rem;
`;

export const ContentButton = styled.div`
  display: flex;
  width: 15%;
  justify-content: center;
  align-items: center;
`;
