import { Edge, Node } from '@xyflow/react';
import { atom } from 'jotai';
import { updateNodeAtom } from '../FlowViewModel';
import { Dayjs } from 'dayjs';
import { getJobInfo, requestChatbot } from '@/Queries/ChatbotQueries';
import { getUserId, getUserNickname } from '@/ViewModels/UserViewModel';
import jobNodeAtom from '@/Atoms/Nodes/JobNodeAtom';
import createMindmapByJob from '@/Utils/CreateMindmapByJob';
import { edgesAtom, nodesAtom } from '@/Atoms/Plan/MindmapAtom';
import GetIdFromQuerystring from '@/Utils/GetIdFromQuerystring';
import { setInnerTabDataAtom, updatePlanDataMindmapAtom } from '../../InnerTabViewModel';
import createJobMindmapDialogAtom from '@/Atoms/Dialogs/CreateJobMindmap';
import { deleteNodesAndEdges, getPlanData, parseGetPlanDataResponse, updateTaskStatus } from '@/Queries/PlanQueries';
import { waitingModalAtom } from '@/Atoms/RootAtom';
import { handleReactQueryApiResponse, handleReactQueryApiResponseWithJson } from '@/Utils/APIUtil';
import { devConsoleLog } from '@/Utils/ConsoleLogInDevelopment';
import { error401ModalAtom } from '@/Atoms/Dialogs/Error/401Atom';
import { isChatbotTypingAtom } from '@/Atoms/ChatAtom';

export const recomendJobNodeDataAtom = atom(null, async (get, set, node: Node) => {
  set(jobNodeAtom, {
    id: node.id,
    status: 0,
    jobList: [],
  });
  setTimeout(async () => {
    const jobMentor = "4JKukCcXIlQK191EleyFGv1gR7H7nsJ9ABhb";
    const userId = get(getUserId); // 사용자 ID 가져오기
    const userNickname = get(getUserNickname); // 사용자 닉네임 가져오기
    const handleUsingDocs = (data: { job: string }[]) => {
      const jobList = data.map((job) => job.job);
      // 중복 제거
      const uniqueJobList = Array.from(new Set(jobList));
      set(jobNodeAtom, {
        id: node.id,
        status: 1,
        jobList: uniqueJobList,
      });
    };
    const requestMessage = `${node.data.label as string}과 연관된 직업들을 추천해줘.`;
    set(isChatbotTypingAtom, true);

    try {
      await handleReactQueryApiResponse(
        requestChatbot,
        () => set(error401ModalAtom, true),
        requestMessage,
        `${userId}-${userNickname}`,
        jobMentor,
        '',
        () => { },
        handleUsingDocs
      );
    } catch (error) {
      set(jobNodeAtom, {
        id: node.id,
        status: 2,
        jobList: [],
      });
    }
    set(isChatbotTypingAtom, false);
  }, 1000);
});

export const updateJobNodeDataAtom = atom(null, (get, set, node: Node, key: any, value: any) => {
  if (key === "label") {
    set(jobNodeAtom, null);
  }
  const updatedNode: any = {
    ...node.data,
    [key]: value,
  };
  set(updateNodeAtom, updatedNode);
});

export const updateJobNodeDateAtom = atom(null, (get, set, node: Node, date: Dayjs | null, type: 'start' | 'end') => {
  const updatedNode: any = {
    ...node.data,
    startDate: type === 'start' ? date?.toISOString() : node.data.startDate,
    endDate: type === 'end' ? date?.toISOString() : node.data.endDate,
  };
  set(updateNodeAtom, updatedNode);
});

export const updateJobNodeLocationAtom = atom(null, (get, set, node: Node, address: string) => {
  const updatedNode: any = {
    ...node.data,
    location: {
      ...node.data.location || {},
      address: address,
    },
  };
  set(updateNodeAtom, updatedNode);
});

export const updateJobNodeRoutineOptionAtom = atom(null, (get, set, node: Node, key: number) => {
  let updatedNode;
  if (node.data.termType === key) {
    updatedNode = {
      ...node.data,
      termType: null,
      termData: null,
    };
  } else {
    updatedNode = {
      ...node.data,
      termType: key,
      termData: [] as string[],
    };
  }
  set(updateNodeAtom, updatedNode);
});

export const createJobDialogOpenAtom = atom(null, (get, set, node: any, job: string) => {
  const connectedEdge = get(edgesAtom).find((edge) => edge.source === node.id);
  if (connectedEdge) {
    set(createJobMindmapDialogAtom, {
      open: true,
      node: node,
      job: job,
    });
    return;
  }
  set(createJobNodeDataAtom, node, job);
})

// bfs로 하위 노드와 엣지를 전부 가져옴
const getChildrenNodes = (nodes: Node[], targetNode: Node, edges: Edge[]) => {
  const childNodes: Node[] = [];
  const childEdges: Edge[] = [];
  const queue: Node[] = [targetNode];
  while (queue.length > 0) {
    const node = queue.shift();
    if (!node) continue;
    childNodes.push(node);
    const nextEdges = edges.filter(edge => edge.source === node.id);
    nextEdges.forEach(edge => {
      const nextNode = nodes.find(node => node.id === edge.target);
      if (nextNode) {
        queue.push(nextNode);
        childEdges.push(edge);
      }
    });
  }
  return { childNodes, childEdges };
};


// 추천된 직업 클릭 시 해당 직업에 대한 데이터 표시
export const createJobNodeDataAtom = atom(null, async (get, set, node: any, job: string) => {
  set(waitingModalAtom, (prev) => ({ ...prev, state: true }));
  const connectedEdge = get(edgesAtom).find((edge) => edge.source === node.id);
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  if (connectedEdge) {
    const { childNodes, childEdges: deleteEdges } = getChildrenNodes(get(nodesAtom), node, get(edgesAtom));
    const deleteNodes = childNodes.filter((n) => n.id !== node.id);
    await handleReactQueryApiResponse(deleteNodesAndEdges, () => set(error401ModalAtom, true), deleteNodes.map((n) => n.data.backendId as number), deleteEdges.map((edge) => edge.data?.backendId as number), innerTabId, get(getUserId));
  }
  setTimeout(async () => {
    const jobData = await handleReactQueryApiResponseWithJson(getJobInfo, () => set(error401ModalAtom, true), job);
    devConsoleLog("jobData", jobData);
    if (!jobData || jobData.detail) return;
    const { nodes: jobNodes, edges: jobEdges } = createMindmapByJob(node, jobData);
    const response = await handleReactQueryApiResponse(getPlanData, () => set(error401ModalAtom, true), innerTabId);
    const responseJson = await response.json();
    const parsedResponse = parseGetPlanDataResponse(responseJson, innerTabId);
    const responseNodes = parsedResponse.nodes.map((n) => n.id === node.id ? { ...n, data: { ...n.data, label: job } } : n);
    const nodes = [...responseNodes, ...jobNodes];
    const edges = [...parsedResponse.edges, ...jobEdges];
    set(setInnerTabDataAtom, { id: innerTabId, nodes, edges });
    set(updatePlanDataMindmapAtom, innerTabId, nodes, edges);
    set(createJobMindmapDialogAtom, null);
    set(waitingModalAtom, (prev) => ({ ...prev, state: false }));
  }, 2000);
});

// 해시태그 추가
export const addHashtagNodeAtom = atom(null, (get, set, node: Node, hashtag: { backgroundColor: string, textColor: string, value: string }) => {
  const nodeHashtags = node.data.hashtags as { backgroundColor: string, textColor: string, value: string }[] || [];
  const updatedNode: any = {
    ...node.data,
    hashtags: [...nodeHashtags, hashtag],
  };
  set(updateNodeAtom, updatedNode);
});

// 해시태그 삭제(pop)
export const removeHashtagNodeAtom = atom(null, (get, set, node: Node) => {
  const nodeHashtags = node.data.hashtags as { backgroundColor: string, textColor: string, value: string }[] || [];
  const updatedNode: any = {
    ...node.data,
    hashtags: nodeHashtags.slice(0, -1),
  };
  set(updateNodeAtom, updatedNode);
});

// taskStatus 변경
export const updateTaskStatusNodeAtom = atom(null, async (get, set, node: Node, taskStatusId: number, status: number) => {
  const response = await handleReactQueryApiResponse(updateTaskStatus, () => set(error401ModalAtom, true), taskStatusId, status);
  if (response.ok) {
    const updatedNode: any = {
      ...node.data,
      taskStatus: (node.data.taskStatus as { taskStatusId: number, taskId: number, date: string, status: number }[]).map((task) => task.taskStatusId === taskStatusId ? { ...task, status: status } : task),
    };
    set(updateNodeAtom, updatedNode);
  }
})
