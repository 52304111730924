import { atom } from "jotai";

export interface PlanHeaderProps {
  currentViewType: "mindmap" | "todo" | "routine" | "gantt";
}

const planHeaderAtom = atom({
  currentViewType: "mindmap",
})

export default planHeaderAtom;
