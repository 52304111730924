import React from "react";
import {
  Avatar,
  Box,
  Drawer,
  MenuItem,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useAtom } from "jotai";
import { useNavigate } from "react-router-dom";
import { useGetFlowDataQuery } from "@/Queries/HelperQueries";
import { handleWhenChatbotClickAtom } from "@/ViewModels/DrawerViewModel";
import { drawerOpenAtom } from "@/Atoms/HeaderAtom";
import * as S from "./styled";

export function CustomSidebar() {
  const [drawerOpen, setDrawerOpen] = useAtom(drawerOpenAtom);
  const [currentChatbot, handleWhenChatbotClick] = useAtom(
    handleWhenChatbotClickAtom
  );
  const navigate = useNavigate();

  const isMobile = useMediaQuery("(max-width:1280px)");
  const path = window.location.pathname;


  const { data: myChatbotList } = useGetFlowDataQuery("template");
  if (!drawerOpen || myChatbotList === null || myChatbotList === undefined)
    return null;

  if (isMobile)
    return (
      <Drawer
        anchor="right"
        open={drawerOpen && path.includes("/crew-helper")}
        onClick={(event) => setDrawerOpen(false)}
      >
        <S.CustomList
          onClick={(event) => setDrawerOpen(false)}
          onKeyDown={(event) => {
            if (
              event.type === "keydown" &&
              ((event as React.KeyboardEvent).key === "Tab" ||
                (event as React.KeyboardEvent).key === "Shift")
            ) {
              return;
            }

            setDrawerOpen(false);
          }}
        >
          {myChatbotList.map(
            (myChatbot, index) =>
              myChatbot && (
                <MenuItem
                  key={`${index}-my-chatbot-list-sidebar`}
                  sx={{
                    backgroundColor:
                      currentChatbot?.chatbot_socket_parameter ===
                        myChatbot.chatbot_socket_parameter
                        ? "#06C25E"
                        : "white",
                    "&:hover": {
                      backgroundColor:
                        currentChatbot?.chatbot_socket_parameter ===
                          myChatbot.chatbot_socket_parameter
                          ? "#06C25E"
                          : "rgba(0, 0, 0, 0.1)",
                    },
                  }}
                  onClick={() => {
                    if (myChatbot) {
                      handleWhenChatbotClick(myChatbot, { navigate });
                    }
                  }}
                >
                  {
                    myChatbot.character_image?.file_path &&
                    <Avatar
                      src={`${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${myChatbot.character_image?.file_path}`}
                    />
                  }
                  <Stack sx={{ flexGrow: 1, padding: "10px" }}>
                    <Typography
                      sx={{
                        color:
                          currentChatbot?.chatbot_socket_parameter ===
                            myChatbot.chatbot_socket_parameter
                            ? "white"
                            : "#06C25E",
                        fontWeight: "bold",
                        wordBreak: "break-word",
                        whiteSpace: "normal",
                        overflowWrap: "break-word",
                        width: "100%",
                      }}
                    >
                      {myChatbot.name}
                    </Typography>
                    <Typography
                      sx={{
                        color:
                          currentChatbot?.chatbot_socket_parameter ===
                            myChatbot.chatbot_socket_parameter
                            ? "white"
                            : "black",
                        fontSize: "13px",
                        wordBreak: "break-word",
                        whiteSpace: "normal",
                        overflowWrap: "break-word",
                        width: "100%",
                        "@media (max-width: 768px)": {
                          fontSize: "12px",
                        },
                      }}
                    >
                      {myChatbot.desc}
                    </Typography>
                  </Stack>
                </MenuItem>
              )
          )}
        </S.CustomList>
      </Drawer>
    );

  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        backgroundColor: "white",
        borderLeft: "1px solid #ececec",
        overflowY: "auto",
      }}
    >
      {myChatbotList.map(
        (myChatbot, index) =>
          myChatbot && (
            <MenuItem
              key={`${index}-my-chatbot-list-sidebar`}
              sx={{
                backgroundColor:
                  currentChatbot?.chatbot_socket_parameter ===
                    myChatbot.chatbot_socket_parameter
                    ? "#06C25E"
                    : "white",
                "&:hover": {
                  backgroundColor:
                    currentChatbot?.chatbot_socket_parameter ===
                      myChatbot.chatbot_socket_parameter
                      ? "#06C25E"
                      : "rgba(0, 0, 0, 0.1)",
                },
              }}
              onClick={() => {
                if (myChatbot) {
                  handleWhenChatbotClick(myChatbot, { navigate });
                }
              }}
            >
              {
                myChatbot.character_image?.file_path &&
                <Avatar
                  src={`${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${myChatbot.character_image?.file_path}`}
                />
              }
              <Stack sx={{ flexGrow: 1, padding: "10px" }}>
                <Typography
                  sx={{
                    color:
                      currentChatbot?.chatbot_socket_parameter ===
                        myChatbot.chatbot_socket_parameter
                        ? "white"
                        : "#06C25E",
                    fontWeight: "bold",
                    wordBreak: "break-word",
                    whiteSpace: "normal",
                    overflowWrap: "break-word",
                    width: "100%",
                  }}
                >
                  {myChatbot.name}
                </Typography>
                <Typography
                  sx={{
                    color:
                      currentChatbot?.chatbot_socket_parameter ===
                        myChatbot.chatbot_socket_parameter
                        ? "white"
                        : "black",
                    fontSize: "13px",
                    wordBreak: "break-word",
                    whiteSpace: "normal",
                    overflowWrap: "break-word",
                    width: "100%",
                    "@media (max-width: 768px)": {
                      fontSize: "12px",
                    },
                  }}
                >
                  {myChatbot.desc}
                </Typography>
              </Stack>
            </MenuItem>
          )
      )}
    </Box>
  );
}
