import { chatRoomListAtom, currentChatRoomAtom, MessageType } from '@/Atoms/ChatAtom';
import { error401ModalAtom } from '@/Atoms/Dialogs/Error/401Atom';
import { isPageGettingHistoryAtom } from '@/Atoms/HelperAtom';
import { atom } from 'jotai';
import { getUserEmailAtom, getUserId } from '../UserViewModel';
import { handleReactQueryApiResponse } from '@/Utils/APIUtil';
import { getChatbotHistory } from '@/Queries/HelperQueries';

interface ChatbotHistoryProps {
  chattingUser: MessageType;
  date: string;
  image: string;
  name: string;
  text: string;
}

const parseChatHistory = (chatHistory: ChatbotHistoryProps[]) => {
  return chatHistory.map((chat, index) => {
    return {
      id: index,
      text: chat.text,
      type: chat.chattingUser,
      jobButtons: null,
      date: chat.date
    };
  });
}

export const dokgabiChatFetchMoreChattingListAtom = atom(
  (get) => get(isPageGettingHistoryAtom),
  async (get, set) => {
    const isPageGettingHistory = get(isPageGettingHistoryAtom);
    const handleError401Modal = () => set(error401ModalAtom, true);

    if (isPageGettingHistory) return;

    set(isPageGettingHistoryAtom, true);

    const currentChatRoom = get(currentChatRoomAtom);
    if (currentChatRoom) {
      const result = await handleReactQueryApiResponse(
        getChatbotHistory,
        handleError401Modal,
        currentChatRoom.chatbots[0].id,
        get(getUserEmailAtom),
        currentChatRoom.chatHistoryPageNum + 1
      );
      if (result) {
        setTimeout(() => {
          const parsedChatHistory = parseChatHistory(result);
          const newCurrentChatRoom = {
            ...currentChatRoom,
            messages: [...parsedChatHistory, ...currentChatRoom.messages],
            chatHistoryPageNum: currentChatRoom.chatHistoryPageNum + (result.length !== 0 ? 1 : 0),
          }
          set(currentChatRoomAtom, newCurrentChatRoom);
          set(chatRoomListAtom, (prevChatRoomList) => {
            const newChatRoomList = prevChatRoomList.map((chatRoom) => {
              if (chatRoom.id === newCurrentChatRoom.id) {
                return newCurrentChatRoom;
              }
              return chatRoom;
            });
            return newChatRoomList;
          });
        })
      }
    }

    setTimeout(() => {
      set(isPageGettingHistoryAtom, false)
    }, 500);
  }
);
