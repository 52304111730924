import React from 'react';
import { Tabs, Tab, TabsProps } from '@mui/material';
import theme from '../../../../../Styles/theme';

interface StoryTabsProps extends TabsProps {
  tabLabels: string[];
  tabValue: number;
  onTabChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
}

const StoryTabs: React.FC<StoryTabsProps> = ({ tabLabels, tabValue, onTabChange, ...props }) => {
  return (
    <Tabs
      value={tabValue}
      onChange={onTabChange}
      variant="fullWidth"
      sx={{
        '& .MuiTabs-indicator': {
          backgroundColor: theme.colors.primary, // 원하는 색상 코드
        },
      }}
      {...props}
    >
      {tabLabels.map((label, index) => (
        <Tab
          key={index}
          label={label}
          sx={{
            color: 'black', // 기본 텍스트 색상
            '&.Mui-selected': {
              color: theme.colors.primary, // 선택된 탭의 텍스트 색상
            },
          }}
        />
      ))}
    </Tabs>
  );
};

export default StoryTabs;
