import React, { useState } from "react";
import {
  Box,
  Divider,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import * as S from "../styled";
import { Settings } from "@mui/icons-material";
import MultipleStopIcon from "@mui/icons-material/MultipleStop";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import theme from "@/Styles/theme";
import { devConsoleLog } from "@/Utils/ConsoleLogInDevelopment";

interface ApiItem {
  name: string;
  second: string;
  another_name: string;
  reverse: boolean;
  another_secod: string;
}

interface ApiData {
  [key: string]: ApiItem[];
}

const apiData: ApiData = {
  "A사 솔루션": [
    {
      name: "A사 유저 아이디 연동",
      second: "필수",
      another_name: "A사 아이디",
      reverse: false,
      another_secod: "_A_NB_ID",
    },
    {
      name: "A사 유저 이름 연동",
      second: "기본",
      another_name: "A사 회원이름",
      reverse: false,
      another_secod: "A_UDF01",
    },
    {
      name: "A사 유저 이메일 주소 연동",
      second: "기본",
      another_name: "A사 이메일주소",
      reverse: true,
      another_secod: "A_UDF02",
    },
    {
      name: "A사 유저 전화번호 연동",
      second: "기본",
      another_name: "A사 전화번호",
      reverse: false,
      another_secod: "A_UDF03",
    },
    {
      name: "A사 유저 주소 연동",
      second: "필수",
      another_name: "A사 주소",
      reverse: false,
      another_secod: "A_UDF04",
    },
    {
      name: "A사 유저 생년월일 연동",
      second: "기본",
      another_name: "A사 생년월일",
      reverse: false,
      another_secod: "A_UDF05",
    },
    {
      name: "A사 유저 성별 연동",
      second: "기본",
      another_name: "A사 성별",
      reverse: true,
      another_secod: "A_UDF06",
    },
    {
      name: "A사 유저 우편번호 연동",
      second: "기본",
      another_name: "A사 우편번호",
      reverse: true,
      another_secod: "A_UDF07",
    },
    {
      name: "A사 유저 계좌번호 연동",
      second: "필수",
      another_name: "A사 계좌번호",
      reverse: false,
      another_secod: "A_UDF08",
    },
    {
      name: "A사 유저 직업 정보 연동",
      second: "기본",
      another_name: "A사 직업",
      reverse: true,
      another_secod: "A_UDF09",
    },
  ],
  "B사 솔루션": [
    {
      name: "B사 유저 아이디 연동",
      second: "필수",
      another_name: "B사 아이디",
      reverse: false,
      another_secod: "_B_NB_ID",
    },
    {
      name: "B사 유저 이름 연동",
      second: "기본",
      another_name: "B사 회원이름",
      reverse: true,
      another_secod: "B_UDF01",
    },
    {
      name: "B사 유저 전화번호 연동",
      second: "기본",
      another_name: "B사 전화번호",
      reverse: true,
      another_secod: "B_UDF03",
    },
    {
      name: "B사 유저 이메일 연동",
      second: "필수",
      another_name: "B사 이메일",
      reverse: false,
      another_secod: "B_UDF02",
    },
    {
      name: "B사 유저 회사명 연동",
      second: "기본",
      another_name: "B사 회사명",
      reverse: false,
      another_secod: "B_UDF04",
    },
    {
      name: "B사 유저 직책 연동",
      second: "기본",
      another_name: "B사 직책",
      reverse: true,
      another_secod: "B_UDF05",
    },
    {
      name: "B사 유저 직장 주소 연동",
      second: "필수",
      another_name: "B사 직장주소",
      reverse: false,
      another_secod: "B_UDF06",
    },
    {
      name: "B사 유저 취미 연동",
      second: "기본",
      another_name: "B사 취미",
      reverse: true,
      another_secod: "B_UDF07",
    },
    {
      name: "B사 유저 결제 정보 연동",
      second: "기본",
      another_name: "B사 결제 정보",
      reverse: false,
      another_secod: "B_UDF08",
    },
    {
      name: "B사 유저 계정 상태 연동",
      second: "필수",
      another_name: "B사 계정 상태",
      reverse: true,
      another_secod: "B_UDF09",
    },
  ],
  "C사 솔루션": [
    {
      name: "C사 유저 이메일 수신 동의 연동",
      second: "기본",
      another_name: "C사 이메일수신동의",
      reverse: true,
      another_secod: "C_UDF04",
    },
    {
      name: "C사 유저 이름 연동",
      second: "기본",
      another_name: "C사 회원이름",
      reverse: false,
      another_secod: "C_UDF01",
    },
    {
      name: "C사 유저 전화번호 연동",
      second: "기본",
      another_name: "C사 전화번호",
      reverse: true,
      another_secod: "C_UDF03",
    },
    {
      name: "C사 유저 가입일 연동",
      second: "필수",
      another_name: "C사 가입일",
      reverse: false,
      another_secod: "C_UDF05",
    },
    {
      name: "C사 유저 결제 내역 연동",
      second: "기본",
      another_name: "C사 결제내역",
      reverse: true,
      another_secod: "C_UDF06",
    },
    {
      name: "C사 유저 포인트 연동",
      second: "기본",
      another_name: "C사 포인트",
      reverse: false,
      another_secod: "C_UDF07",
    },
    {
      name: "C사 유저 주문 내역 연동",
      second: "기본",
      another_name: "C사 주문내역",
      reverse: true,
      another_secod: "C_UDF08",
    },
    {
      name: "C사 유저 배송지 정보 연동",
      second: "필수",
      another_name: "C사 배송지",
      reverse: false,
      another_secod: "C_UDF09",
    },
    {
      name: "C사 유저 쿠폰 연동",
      second: "기본",
      another_name: "C사 쿠폰",
      reverse: true,
      another_secod: "C_UDF10",
    },
    {
      name: "C사 유저 선호 카테고리 연동",
      second: "기본",
      another_name: "C사 선호카테고리",
      reverse: false,
      another_secod: "C_UDF11",
    },
  ],
};

export function API() {

  const [value, setValue] = useState("A사 솔루션");

  return (
    <S.CustomBox>
      <Typography
        className="my-skill-sample-data"
        sx={{
          padding: "5px",
          borderRadius: "5px",
          color: "red",
          border: "1px solid red",
        }}
      >
        Sample Data
      </Typography>
      <Stack
        direction={"row"}
        sx={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <S.TitleTypo>IEUM API</S.TitleTypo>
        <IconButton onClick={() => devConsoleLog("API 설정 개발 필요")}>
          <Settings></Settings>
        </IconButton>
      </Stack>

      <Divider sx={{ margin: "10px 0" }} />
      <S.DescTypo>IEUM과 제휴맞은 회사의 API와 연동해보세요.</S.DescTypo>
      <div style={{ height: "10px" }}></div>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Box
          sx={{
            borderRadius: "5px",
            boxSizing: "border-box", // 수정된 부분
            border: "1px solid #ababab",
            flex: 3,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography>AI 커리어 플래너</Typography>
        </Box>
        <MultipleStopIcon sx={{ flex: 1 }} />
        <Box sx={{ flex: 3 }}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            placeholder="연동할 솔루션을 선택해주세요"
            value={value}
            sx={{ flex: 3, boxSizing: "border-box", width: "100%" }}
            label="Age"
            onChange={(e) => setValue(e.target.value)}
          >
            <MenuItem value={"A사 솔루션"}>A사 솔루션</MenuItem>
            <MenuItem value={"B사 솔루션"}>B사 솔루션</MenuItem>
            <MenuItem value={"C사 솔루션"}>C사 솔루션</MenuItem>
          </Select>
        </Box>
      </Stack>
      <div style={{ height: "10px" }}></div>
      <Box sx={{ flexGrow: 1, overflow: "auto" }}>
        {apiData[value].map((d, index) => (
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            marginBottom={"15px"}
          >
            <Box
              sx={{
                flex: 3,
                border: `1px solid ${theme.colors.primary}`,
                padding: "5px",
                borderRadius: "5px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography>{d.name}</Typography>
              <Box
                sx={{
                  background: theme.colors.primary,
                  padding: "5px",
                  borderRadius: "5px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  color: "white",
                  fontSize: "12px",
                }}
              >
                {d.second}
              </Box>
            </Box>
            {d.reverse ? (
              <ArrowCircleLeftOutlinedIcon
                sx={{ flex: 1, color: theme.colors.primary }}
              />
            ) : (
              <ArrowCircleRightOutlinedIcon
                sx={{ flex: 1, color: theme.colors.primary }}
              />
            )}
            <Box
              sx={{
                flex: 3,
                border: `1px solid ${theme.colors.primary}`,
                padding: "5px",
                borderRadius: "5px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography>{d.another_name}</Typography>
              <Box
                sx={{
                  background: theme.colors.primary,
                  padding: "5px",
                  borderRadius: "5px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  color: "white",
                  fontSize: "12px",
                }}
              >
                {d.another_secod}
              </Box>
            </Box>
          </Stack>
        ))}
      </Box>
    </S.CustomBox>
  );
}
