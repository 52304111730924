import React from 'react';
import { Fab, Tooltip } from "@mui/material";
import theme from "../../../../../Styles/theme";
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import { useSetAtom } from 'jotai';
import { useReactFlow } from '@xyflow/react';
import { createGroupNodeAtom } from '@/ViewModels/Plan/Mindmap/GroupViewModel';
import { useTranslation } from 'react-i18next';

const CreateGroupElementBar = () => {
  const createGroupNode = useSetAtom(createGroupNodeAtom);
  const { screenToFlowPosition } = useReactFlow();
  const { t } = useTranslation();

  const handleCreateGroupNode = () => {
    // Transform the screen center position to the flow position
    const screenCenter = {
      x: window.innerWidth / 2,
      y: window.innerHeight / 2,
    };
    const flowPosition = screenToFlowPosition(screenCenter);

    // Pass the flow position to the createGroupNode function
    createGroupNode(flowPosition);
  };

  return (
    <Tooltip title={<div style={{ fontSize: "1rem" }}>{t("plan.contents.mindmap.elementbar.createGroup")}</div>} arrow placement="right">
      <Fab
        sx={{
          bgcolor: theme.colors.customSky,
          '&:hover': {
            bgcolor: theme.colors.customSky + "CC",
          },
          color: "#fff", // 아이콘 색상
        }}
        onClick={handleCreateGroupNode}
      >
        <WorkspacesIcon />
      </Fab>
    </Tooltip>
  );
};

export default CreateGroupElementBar;
