import dailyPlanDialogAtom from '@/Atoms/Dialogs/DailyPlan';
import CustomBackgroundColorButton from '@/Components/Common/Buttons/CustomBackgroundColor';
import CustomColorButton from '@/Components/Common/Buttons/CustomColor';
import { dailyPlanDialogMoveAtom } from '@/ViewModels/Dialogs/DailyPlanViewModel';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useAtom, useSetAtom } from 'jotai';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const DailyPlanDialog = () => {
  const [dialog, setDialog] = useAtom(dailyPlanDialogAtom);
  const navigate = useNavigate();
  const move = useSetAtom(dailyPlanDialogMoveAtom);

  return (
    dialog &&
    <Dialog open={!!dialog} onClose={() => setDialog(null)}>
      <DialogTitle sx={{ fontSize: "0.9rem", fontWeight: "bold" }}>이동하기</DialogTitle>
      <DialogContent sx={{ fontSize: "0.8rem" }}>
        {dialog.data.label as string}으로 이동하시겠습니까?
      </DialogContent>
      <DialogActions>
        <CustomColorButton onClick={() => setDialog(null)} text="취소" />
        <CustomBackgroundColorButton onClick={() => move(navigate)} text="이동" />
      </DialogActions>
    </Dialog>
  );
}

export default DailyPlanDialog;
